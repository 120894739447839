import React, { useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { css, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Banner } from '@skyslope/mache';

import CenteredLoader from 'components/CenteredLoader';
import DialogCloseButton from 'components/DialogCloseButton';
import LinkButton from 'components/LinkButton/LinkButton';
import LoaderButton from 'components/LoaderButton';
import useIsMobile from 'hooks/useIsMobile';

import OreaAuthDialogTermsView from './components/OreaAuthDialogTermsView';

import { css as emotionCss } from '@emotion/css';

export interface OreaAuthDialogViewProps {
  areTermsAlreadyAccepted: boolean;
  is8HourUpdate: boolean;
  isInvalidOreaMember?: boolean;
  isLoading: boolean;
  isLoggingIn: boolean;
  isLoggingOut: boolean;
  isSuccess?: boolean;
  onClose: () => void;
  onContinue: () => void;
  onLoginClick: () => void;
  onLogoutClick: () => void;
}

const OreaAuthDialogView: React.FC<OreaAuthDialogViewProps> = ({
  areTermsAlreadyAccepted,
  is8HourUpdate,
  isInvalidOreaMember,
  isLoading,
  isLoggingIn,
  isLoggingOut,
  isSuccess,
  onClose,
  onContinue,
  onLoginClick,
  onLogoutClick,
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();

  const [isTermsAndConditionsChecked, setIsTermsAndConditionsChecked] = useState<boolean>(areTermsAlreadyAccepted);

  const loginButtonCallback = (): void => {
    if (isInvalidOreaMember) {
      onLogoutClick();
    } else if (isSuccess) {
      onContinue();
    } else {
      onLoginClick();
    }
  };

  const loginButtonText = useMemo((): string => {
    if (isInvalidOreaMember) {
      return 'Try Again';
    }
    if (isSuccess) {
      return 'Continue';
    }
    return 'Authorize OREA';
  }, [isInvalidOreaMember, isSuccess]);

  return (
    <Dialog
      open
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          textAlign: 'center',
          padding: theme.spacing(5),
          [theme.breakpoints.only('xs')]: {
            padding: theme.spacing(2),
          },
        },
      }}
      fullScreen={isMobile}
    >
      <DialogContent
        sx={{
          padding: 0,
        }}
      >
        <DialogCloseButton onClick={onClose} />
        <DialogTitle>
          <Typography variant="h3">Authorization Required</Typography>
        </DialogTitle>
        {isLoading ? (
          <Box mt={13}>
            <CenteredLoader />
          </Box>
        ) : (
          <>
            {is8HourUpdate && !isInvalidOreaMember && (
              <>
                {!isLoggingIn && !isSuccess && (
                  <Box mt={2} mb={3}>
                    <Banner
                      variant="error"
                      message={
                        <Box textAlign="left">
                          <div>{'OREA requires account verification every 8 hours. '}</div>
                          <div>{'Please re-enter your login information.'}</div>
                        </Box>
                      }
                    />
                  </Box>
                )}
                <Box mt={1} mb={3}>
                  <Typography
                    variant="body1"
                    sx={{
                      maxWidth: 420, // blaze
                      margin: 'auto',
                    }}
                  >
                    {
                      "You need to log in to access the forms library. We'll authorize your access to the OREA forms library and you’ll be redirected back to our website."
                    }
                  </Typography>
                </Box>
              </>
            )}
            {!is8HourUpdate && !isInvalidOreaMember && (
              <Typography
                variant="body1"
                sx={{
                  maxWidth: 420, // blaze
                  margin: 'auto',
                }}
              >
                {
                  "You need to log in to access the forms library. We'll authorize your access to the OREA forms library and you’ll be redirected back to our website. "
                }
              </Typography>
            )}
            {isInvalidOreaMember && (
              <>
                <Box mt={2}>
                  <Banner
                    variant="error"
                    message="Your membership could not be authenticated. Please try again."
                    classes={{
                      messageContainer: emotionCss(css({ padding: theme.spacing(2, 1) })),
                    }}
                  />
                </Box>
                <Box mt={4} mb={3}>
                  <Typography
                    variant="body1"
                    sx={{
                      maxWidth: 420, // blaze
                      margin: 'auto',
                    }}
                  >
                    {
                      'Please log out of Realtor Link and try to authenticate again with valid OREA account information.'
                    }
                  </Typography>
                </Box>
              </>
            )}
            {isLoggingIn && (
              <Box mt={4} mb={2}>
                <Banner
                  message={
                    <>
                      {
                        'Use the new tab on your browser window to authenticate your OREA Forms account. Don’t see the OREA tab? '
                      }
                      <LinkButton
                        onClick={onLoginClick}
                        aria-label="click to redirect to OREA Auth"
                        id="orea-login-link"
                      >
                        Click here
                      </LinkButton>
                    </>
                  }
                />
              </Box>
            )}
            {isSuccess && (
              <Box mt={2} mb={2}>
                <Banner variant="success" message="Your OREA Forms access has been successfully authorized!" />
              </Box>
            )}

            {!areTermsAlreadyAccepted && !isInvalidOreaMember && !isSuccess && !isLoggingIn && (
              <OreaAuthDialogTermsView
                value={isTermsAndConditionsChecked}
                onChange={(isChecked) => setIsTermsAndConditionsChecked(isChecked)}
              />
            )}
          </>
        )}
      </DialogContent>
      {/*not using dialogActions because this is a different setup from normal dialogs*/}
      <Box
        component="div"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: theme.spacing(2),
          '& > *': {
            marginBottom: theme.spacing(3),
            [theme.breakpoints.only('xs')]: {
              width: '100%',
            },
          },
        }}
      >
        {!isLoggingOut && (
          <LoaderButton
            variant="contained"
            color="primary"
            onClick={loginButtonCallback}
            id="orea-action-btn"
            isLoading={isLoggingIn}
            disabled={!isTermsAndConditionsChecked || isLoading}
            fullWidth={isMobile}
          >
            {loginButtonText}
          </LoaderButton>
        )}
        {!isSuccess && (
          <div>
            <Button color="primary" variant="text" onClick={onClose} id="orea-cancel-btn" fullWidth={isMobile}>
              Cancel
            </Button>
          </div>
        )}
      </Box>
    </Dialog>
  );
};
export default OreaAuthDialogView;
