import { useSelector } from 'react-redux';
import { useInfiniteQuery } from '@tanstack/react-query';

import useGetDelegatedUserSettingsQuery from 'hooks/domain/user/queries/useGetDelegatedUserSettingsQuery';
import useDelegatedDerivedUserData from 'hooks/domain/user/useDelegatedDerivedUserData';
import formVersionServer from 'resources/server/FormVersionServer';

import type { FormVersionsFilterRequest } from '../../../resources/types/FormVersionServer';
import { searchQuerySelector, selectedFormTypesSelector, selectedLibraryIdsSelector } from '../Filter/store/selectors';

const key = 'form-versions';

const useGetFilteredFormVersions = (onBehalfOf: string) => {
  const { data: userSettings, isInitialLoading: isUserLoading } = useGetDelegatedUserSettingsQuery(onBehalfOf);
  const { availableLibraries, isLoading: isLoadingLibraries } = useDelegatedDerivedUserData(onBehalfOf);

  const selectedLibraryIds = useSelector(selectedLibraryIdsSelector);
  const selectedFormTypes = useSelector(selectedFormTypesSelector);
  const searchQuery = useSelector(searchQuerySelector);

  const request: FormVersionsFilterRequest = {
    selectedLibraryIds: selectedLibraryIds,
    formTypes: selectedFormTypes,
    optinLibraryIds: availableLibraries.map((l) => l.id),
    name: searchQuery,
    onBehalfOf: onBehalfOf,
  };
  //because of how infinite query works, we need to spread the request info with the key in order to paginate properly
  const compoundKey = [{ ...request, key }];

  const useInfiniteQueryResult = useInfiniteQuery(
    compoundKey,
    ({ pageParam }) => formVersionServer.GetFormVersions(request, pageParam),
    {
      getNextPageParam: (lastData, allData) => {
        const formVersionsLength = allData.map((page) => page.formVersionViewModels.length).reduce((a, b) => a + b, 0);
        if (formVersionsLength === lastData.totalRecords) return undefined;
        return {
          page: allData.length + 1,
          pageSize: 50,
        };
      },
      refetchInterval: 600000, // 10 minutes
      refetchIntervalInBackground: true,
    }
  );

  return {
    ...useInfiniteQueryResult,
    hasLibraries: availableLibraries.length > 0 || isLoadingLibraries,
    hasRegions: (userSettings?.regions && userSettings.regions.length > 0) || isUserLoading,
    isLoading: useInfiniteQueryResult.isLoading || isLoadingLibraries || isUserLoading,
  };
};

export default useGetFilteredFormVersions;
