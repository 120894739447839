import { useMemo } from 'react';

import LocalStorageItem from 'constants/LocalStorageItem';
import useGetUserSettingsQuery from 'hooks/domain/user/queries/useGetUserSettingsQuery';

import { useUtilityConnectEnablement } from '../../../hooks/domain/utilityConnect/useUtilityConnectEnablement';

const ninetyDaysAgo = new Date().getTime() - 90 * 24 * 60 * 60 * 1000;

export const useShowUtilityConnectUI = () => {
  const userSettings = useGetUserSettingsQuery();
  const userId = userSettings.data?.id;
  const utilityConnect = useUtilityConnectEnablement();
  const lastDismissed = useMemo(
    () => localStorage.getItem(`${LocalStorageItem.UtilityConnectModalLastDismissed}-${userId}`),
    [userId]
  );

  const lastDismissedIsOneMonthAgoOrNever =
    lastDismissed != null ? new Date(lastDismissed).getTime() < ninetyDaysAgo : true;

  /**
   * @isEligible is determined based on the users brokerage (toggled in styler). Data for broker enablement is stored in mongo in the utilityconnectsubscribers collection
   * @isEnabled is determined based on the users preference. Stored in utilityconnectagents collection
   * @lastDismissedIsOneMonthAgoOrNever is a local storage value combining the user id and last dismissed
   */
  return {
    show:
      utilityConnect.isEligible === true &&
      utilityConnect.isEnabled === false &&
      lastDismissedIsOneMonthAgoOrNever &&
      userId != null,
    isLoading: userSettings.isLoading || utilityConnect.isLoading,
    isEligible: utilityConnect.isEligible,
    isEnabled: utilityConnect.isEnabled,
  };
};
