import React from 'react';
import { useAuth } from '@skyslope/auth-react';

import DelayVisible from 'components/DelayVisible';
import LoadingSplashPage from 'components/LoadingSplashPage';
import { routes } from 'constants/routes';

const LoginCallbackPlaceholderMessage: React.FC = () => {
  const { authState } = useAuth();

  return (
    <DelayVisible delayBy={authState.error ? 0 : 1000}>
      <LoadingSplashPage
        titleText="We're working on it!"
        bodyText={
          <>
            If the page does not redirect you automatically{' '}
            <a href={routes.homeTownBuffet} id="redirect-home">
              Click Here
            </a>{' '}
            or{' '}
            <a href={routes.logout} id="logout">
              Logout
            </a>{' '}
            instead.
          </>
        }
      />
    </DelayVisible>
  );
};

export default LoginCallbackPlaceholderMessage;
