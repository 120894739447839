import update from 'immutability-helper';

import {
  AUTO_SAVE_ERROR_STATUS,
  AUTO_SAVE_INITIAL_STATUS,
  AUTO_SAVE_PENDING_STATUS,
  AUTO_SAVE_SAVED_STATUS,
  AUTO_SAVE_SAVING_STATUS,
} from 'constants/AutoSaveDeprecated';
import EnvelopeStatus from 'constants/EnvelopeStatus';
import { ActionTypes } from 'store/actions';
import type { SetCommissionFieldValue } from 'store/domain/actions';
import { FileActions } from 'store/domain/actions';
import type { AppAction, EnvelopeSummary } from 'store/types';
import type ChangeStatus from 'types/changeStatus';

import { EnvelopeActions } from './actions';

export interface FileDetailsPageState {
  envelopeSummary?: EnvelopeSummary;
  changeStatus: ChangeStatus;
}

export const initialState: FileDetailsPageState = {
  changeStatus: AUTO_SAVE_INITIAL_STATUS,
};

const fileDetailsReducer = (state: FileDetailsPageState = initialState, action: AppAction): FileDetailsPageState => {
  switch (action.type) {
    case FileActions.SAVE_CONTACT: {
      const contact = action.payload;
      const isNewContact = contact.id == null;

      if (isNewContact) {
        return update(state, {
          changeStatus: {
            $set: AUTO_SAVE_SAVING_STATUS,
          },
        });
      }

      return update(state, {
        changeStatus: {
          $set: AUTO_SAVE_SAVING_STATUS,
        },
      });
    }
    case FileActions.DELETE_CONTACT: {
      return update(state, {
        changeStatus: {
          $set: AUTO_SAVE_SAVING_STATUS,
        },
      });
    }
    case FileActions.UPDATE_FILE_DETAILS_DONE:
    case FileActions.SAVE_FILE_DETAILS_DONE: {
      return update(state, {
        changeStatus: { $set: AUTO_SAVE_SAVED_STATUS },
      });
    }
    case FileActions.UPDATE_FILE_DETAILS_ERROR: {
      return update(state, {
        changeStatus: { $set: AUTO_SAVE_ERROR_STATUS },
      });
    }
    case FileActions.TRY_SAVE_FILE_DETAILS: {
      return update(state, {
        changeStatus: {
          $set: state.changeStatus === AUTO_SAVE_PENDING_STATUS ? AUTO_SAVE_SAVING_STATUS : state.changeStatus,
        },
      });
    }
    case FileActions.SET_PROPERTY: {
      return update(state, {
        changeStatus: {
          $set: AUTO_SAVE_SAVING_STATUS,
        },
      });
    }
    case FileActions.SET_FILE_FIELD:
    case FileActions.SET_PROPERTY_FIELD: {
      return update(state, {
        changeStatus: {
          $set: AUTO_SAVE_PENDING_STATUS,
        },
      });
    }
    case FileActions.SET_COMMISSION_FIELD: {
      const payload: SetCommissionFieldValue = action.payload;

      return update(state, {
        changeStatus: {
          $set: payload.fieldName === 'calculationType' ? AUTO_SAVE_SAVING_STATUS : AUTO_SAVE_PENDING_STATUS,
        },
      });
    }
    case EnvelopeActions.GET_ENVELOPES_DONE: {
      return update(state, {
        envelopeSummary: {
          $set: action.payload,
        },
      });
    }
    case EnvelopeActions.UPDATE_ENVELOPE_TO_CANCELLED_DONE: {
      const envelopeIndex = state.envelopeSummary?.envelopes.findIndex(
        (e) => e.externalId === action.payload.externalId
      );

      if (envelopeIndex === -1 || envelopeIndex == null) {
        return state;
      }

      return update(state, {
        envelopeSummary: {
          envelopes: {
            [envelopeIndex]: {
              status: { $set: EnvelopeStatus.Cancelled },
            },
          },
        },
      });
    }
    case ActionTypes.RESET: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default fileDetailsReducer;
