import { all, put, takeEvery } from 'redux-saga/effects';
import { createAsyncAction } from 'typesafe-actions';

import envelopeResendDialogSagas from 'containers/EnvelopeResendDialog/store/sagas';
import { addErrorNotification } from 'containers/Notifier/actions';
import uploadDocumentsSagas from 'containers/UploadDocuments/store/sagas';
import envelopeHistorySagas from 'pages/EnvelopeHistory/store/sagas';
import FileDetailsSagas from 'pages/FileDetails/store/sagas';
import FileFormsSagas from 'pages/FileForms/store/sagas';
import dashboardSagas from 'pages/FilesDashboard/store/sagas';
import nrdsProfileSagas from 'pages/UserSettings/AuthProfileUserSettings/store/sagas';
import fileServer from 'resources/server/FileServer';
import { apiCall } from 'utils/apiCall';

import type { DeleteTemplateRequest } from './domain/actions';
import { FileActions } from './domain/actions';

const deleteTemplate = createAsyncAction(
  FileActions.DELETE_TEMPLATE,
  FileActions.DELETE_TEMPLATE_DONE,
  FileActions.DELETE_TEMPLATE_ERROR
)<DeleteTemplateRequest, number, string>();

export function* handleDeleteTemplate(action: ReturnType<typeof deleteTemplate.request>): any {
  try {
    const results = yield apiCall({
      resources: [fileServer, fileServer.DeleteTemplate],
      payload: action.payload,
    });
    yield put(deleteTemplate.success(results));
  } catch (error) {
    yield put(
      addErrorNotification({
        message: 'Failed to delete the template, please try refreshing.',
      })
    );
    deleteTemplate.failure(error as string);
  }
}

export default function* rootSaga(): any {
  yield all([
    takeEvery(FileActions.DELETE_TEMPLATE, handleDeleteTemplate),
    ...dashboardSagas(),
    ...FileDetailsSagas(),
    ...envelopeHistorySagas(),
    ...FileFormsSagas(),
    ...envelopeResendDialogSagas(),
    ...nrdsProfileSagas(),
    ...uploadDocumentsSagas(),
  ]);
}
