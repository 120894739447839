import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import DelegationServer from 'resources/server/DelegationServer';
import type { UserPermissionsRequests } from 'resources/types/DelegateAccess';

export const getUserDelegateRequestsQueryKey = 'useGetUserDelegationRequestsQuery';

const useGetUserDelegationRequestsQuery = (isEnabled?: boolean): UseQueryResult<UserPermissionsRequests> =>
  useQuery({
    queryKey: [getUserDelegateRequestsQueryKey],
    queryFn: () => DelegationServer.GetUserDelegationRequests(),
    enabled: isEnabled ?? false,
  });

export default useGetUserDelegationRequestsQuery;
