import React, { useEffect, useState } from 'react';
import { css } from '@mui/material/styles';
import clsx from 'classnames';

import { css as emotionCss } from '@emotion/css';

export interface DelayVisibleProps {
  delayBy: number;
  className?: string;
}

const DelayVisible: React.FC<DelayVisibleProps> = ({ className, delayBy, children }) => {
  const [isHidden, setIsHidden] = useState(true);

  useEffect(() => {
    const id = setTimeout(() => {
      setIsHidden(false);
    }, delayBy);
    return () => {
      clearTimeout(id);
    };
  }, [delayBy]);

  return <div className={clsx(className, emotionCss(css({ display: isHidden ? 'none' : undefined })))}>{children}</div>;
};

export default DelayVisible;
