import update from 'immutability-helper';

import { ActionTypes } from 'store/actions';
import type { AppAction, FormLibrary } from 'store/types';

import { NRDSProfileActions } from './actions';
import type { VerifyNRDSIdResult } from './types';
import { AddAuthProfileErrorCode, NRDSProfileStep } from './types';

export interface NRDSProfileState {
  currentStep: NRDSProfileStep;
  nrdsId: string;
  lastName: string;
  isVerifying: boolean;
  member?: VerifyNRDSIdResult;
  errorTitle: string;
  errorMessage: string;
  libraries: FormLibrary[];
  areTermsAccepted: boolean;
}

export const initialState: NRDSProfileState = {
  currentStep: NRDSProfileStep.FORM,
  nrdsId: '',
  lastName: '',
  isVerifying: false,
  member: undefined,
  errorTitle: '',
  errorMessage: '',
  libraries: [],
  areTermsAccepted: false,
};

export default (state: NRDSProfileState = initialState, action: AppAction): NRDSProfileState => {
  switch (action.type) {
    case NRDSProfileActions.VERIFY_NRDS_ID: {
      return update(state, {
        isVerifying: { $set: true },
      });
    }
    case NRDSProfileActions.VERIFY_NRDS_ID_ERROR: {
      return update(state, {
        isVerifying: { $set: false },
        currentStep: { $set: NRDSProfileStep.ERROR_FORM },
        errorTitle: { $set: action.payload.title },
        errorMessage: { $set: action.payload.message },
      });
    }
    case NRDSProfileActions.VERIFY_NRDS_ID_DONE: {
      return update(state, {
        isVerifying: { $set: false },
        currentStep: { $set: NRDSProfileStep.VERIFY },
        member: { $set: action.payload },
      });
    }
    case NRDSProfileActions.SHOW_SEARCH: {
      return update(state, {
        currentStep: { $set: NRDSProfileStep.FORM },
      });
    }
    case NRDSProfileActions.SET_NRDS_ID: {
      return update(state, {
        nrdsId: { $set: action.payload },
      });
    }
    case NRDSProfileActions.SET_NRDS_LAST_NAME: {
      return update(state, {
        lastName: { $set: action.payload },
      });
    }
    case NRDSProfileActions.SET_ARE_TERMS_ACCEPTED: {
      return update(state, {
        areTermsAccepted: { $set: action.payload },
      });
    }
    case NRDSProfileActions.ADD_NRDS_AUTH_PROFILE_ERROR: {
      if (action.payload === AddAuthProfileErrorCode.NRDS_ID_IN_USE) {
        return update(state, {
          currentStep: { $set: NRDSProfileStep.ERROR_NRDS_ID_IN_USE },
        });
      }
      return state;
    }
    case ActionTypes.RESET: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
