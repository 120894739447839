import type { UserManager } from '@skyslope/auth-js';
import queryString from 'query-string';

import { userManager } from 'auth/userManager';
import { FILE_API_URL } from 'constants/environments';
import type { MarkupBlock } from 'pages/Fill/components/Controls/markup/types';
import type { FileServiceResult, FormFieldValue } from 'store/types';
import type { AddedForm, DocumentDetailsV2 } from 'types/documents';

import type { AddDocumentToFileBulkRequest } from '../types/AddDocumentToFile';
import type { AddFormsToFileRequest } from '../types/AddFormsToFile';
import type { EmailDocumentRequest } from '../types/EmailDocuments';
import type { GetDocumentDetailsRequest } from '../types/GetDocumentDetails';
import type { FormsMarkupBlock } from '../types/GetEnvelopeDocumentMarkup';
import type {
  DownloadSignedDocumentsRequest,
  DownloadSignedDocumentsResponse,
  ShareSignedDocumentRequest,
  ShareSignedDocumentResponse,
} from '../types/SignedDocument';
import type { DownloadDocumentsRequest, DownloadDocumentsResponse } from '../types/StampDocument';
import type { UploadDocumentPayload } from '../types/UploadDocuments';

import Server from './Server';

export type UploadDocumentRequest = UploadDocumentPayload & {
  onProgress: (progressEvent: ProgressEvent) => void;
};

export interface PatchDocumentMarkupRequest {
  fileId: number;
  documentId: number;
  payload: MarkupBlock[];
}

export interface PatchDocumentMarkupResponse {
  documentId: number;
  markupBlocksCreated: number;
  markupBlocksUpdated: number;
}

export interface PatchDocumentRequest {
  fileId: number;
  documentId: number;
  payload: {
    [dataRef: string]: FormFieldValue;
  };
}

export interface PatchDocumentV2Response {
  documentId: number;
  didAddendumsChange: boolean;
}

export interface UpdateDocumentOrderPayload {
  fileId: number;
  documentIdOrder: number[];
}

export interface UpdateDocumentFormPayload {
  documentId: number;
  formId: number;
  fileId: number;
  onBehalfOf?: string;
}

export interface DeleteDocumentMarkupRequest {
  fileId: number;
  documentId: number;
  blockKeys: string[];
}

export interface ExportDocumentRequest {
  fileId: number;
  documentId: number;
  onBehalfOf?: string;
}

export class BaseDocumentServer extends Server {
  public constructor(manager: UserManager) {
    super(manager);
  }

  public async DownloadDocumentsPdf(request: DownloadDocumentsRequest): Promise<DownloadDocumentsResponse> {
    const queryParameters = queryString.stringify({
      documentIds: request.documentIds,
      finalize: request.isFinalize ?? true,
    });

    const resp = await this.api.get<Blob>(`${FILE_API_URL}/files/${request.fileId}/documents/pdf?${queryParameters}`, {
      responseType: 'arraybuffer',
    });

    const documentsReturned: DownloadDocumentsResponse = {
      data: resp.data,
      contentType: resp.headers['content-type'],
    };

    return documentsReturned;
  }

  public async DownloadDocuments(request: DownloadDocumentsRequest): Promise<DownloadDocumentsResponse> {
    const queryParameters = queryString.stringify({
      documentIds: request.documentIds,
    });

    const resp = await this.api.get<Blob>(
      `${FILE_API_URL}/files/${request.fileId}/documents/download?${queryParameters}`,
      {
        responseType: 'arraybuffer',
      }
    );

    const documentsReturned: DownloadDocumentsResponse = {
      data: resp.data,
      contentType: resp.headers['content-type'],
    };
    return documentsReturned;
  }

  public async DownloadSignedDocuments(
    request: DownloadSignedDocumentsRequest
  ): Promise<DownloadSignedDocumentsResponse> {
    const data = {
      documents: request.documents,
      isSinglePdf: request.isSinglePdf,
    };

    const resp = await this.api.post<Blob>(`${FILE_API_URL}/files/${request.fileId}/signed-documents/download`, data, {
      responseType: 'arraybuffer',
    });

    const documentsReturned: DownloadSignedDocumentsResponse = {
      data: resp.data,
      contentType: resp.headers['content-type'],
    };

    return documentsReturned;
  }

  public async GetDocumentDetailsV2(request: GetDocumentDetailsRequest): Promise<DocumentDetailsV2> {
    return (
      await this.api.get<FileServiceResult<DocumentDetailsV2>>(
        `${FILE_API_URL}/documents/${request.documentId}?api-version=2.0`
      )
    ).data.result;
  }

  public async AddDocumentsToFileV2(request: AddDocumentToFileBulkRequest): Promise<number[]> {
    return (
      await this.api.patch<FileServiceResult<number[]>>(
        `${FILE_API_URL}/files/${request.fileId}/documents?api-version=2.0`,
        request
      )
    ).data.result;
  }

  public async AddFormsToFile(request: AddFormsToFileRequest): Promise<AddedForm[]> {
    return (await this.api.patch<FileServiceResult<AddedForm[]>>(`${FILE_API_URL}/rpc/add-forms`, request)).data.result;
  }

  public async UploadDocumentToFile(request: UploadDocumentRequest): Promise<number> {
    const formData = new FormData();
    formData.append('documentName', request.documentName);
    formData.append('documentBody', request.document);
    if (request.onBehalfOf) formData.append('onBehalfOf', request.onBehalfOf ?? '');
    if (request.isSignedDocument) formData.append('isSignedDocument', request.isSignedDocument.toString());

    return (
      await this.api.post(`${FILE_API_URL}/files/${request.fileId}/documents/upload`, formData, {
        onUploadProgress: request.onProgress,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
    ).data.result;
  }

  public async UpdateDocumentForm(request: UpdateDocumentFormPayload): Promise<number> {
    return (
      await this.api.put(`${FILE_API_URL}/documents/${request.documentId}/form`, {
        formId: request.formId,
        onBehalfOf: request.onBehalfOf,
      })
    ).data.result;
  }

  public async UpdateDocumentOrder(request: UpdateDocumentOrderPayload): Promise<number> {
    return (
      await this.api.put(`${FILE_API_URL}/files/${request.fileId}/documents/order`, {
        documentIdOrder: request.documentIdOrder,
      })
    ).data.result;
  }

  public async PatchDocumentV2(request: PatchDocumentRequest): Promise<PatchDocumentV2Response> {
    return (
      await this.api.patch<FileServiceResult<PatchDocumentV2Response>>(
        `${FILE_API_URL}/documents/${request.documentId}?api-version=2.0`,
        request.payload
      )
    ).data.result;
  }

  public async PatchDocumentMarkup(request: {
    documentId: number;
    payload: FormsMarkupBlock[];
  }): Promise<PatchDocumentMarkupResponse> {
    return (
      await this.api.patch<FileServiceResult<PatchDocumentMarkupResponse>>(
        `${FILE_API_URL}/documents/${request.documentId}/markup`,
        request.payload
      )
    ).data.result;
  }

  public async DeleteDocumentMarkup(request: DeleteDocumentMarkupRequest): Promise<void> {
    await this.api.delete<unknown>(`${FILE_API_URL}/documents/${request.documentId}/markup`, {
      data: request.blockKeys,
    });
  }

  public async ExportDocument(request: ExportDocumentRequest): Promise<number> {
    return (
      await this.api.post<FileServiceResult<number>>(
        `${FILE_API_URL}/file/${request.fileId}/documents/${request.documentId}/export`,
        {
          onBehalfOf: request.onBehalfOf,
        }
      )
    ).data.result;
  }

  public async ShareSignedDocuments(
    request: ShareSignedDocumentRequest
  ): Promise<string | ShareSignedDocumentResponse> {
    return await (
      await this.api.post<FileServiceResult<ShareSignedDocumentResponse>>(
        `${FILE_API_URL}/files/${request.fileId}/share`,
        {
          to: request.to,
          cc: request.cc,
          bcc: request.bcc,
          subject: request.subject,
          body: request.body,
          attachments: request.attachments,
          sentByAgentFirstName: request.sentByAgentFirstName,
        }
      )
    ).data.result;
  }

  public async EmailDocuments(request: EmailDocumentRequest): Promise<number> {
    return await (
      await this.api.post<FileServiceResult<number>>(`${FILE_API_URL}/files/${request.fileId}/documents/email`, {
        documentIds: request.documentIds,
        isFinalize: request.isFinalize,
        isSingle: request.isSingle,
        toRecipients: request.to,
        ccRecipients: request.cc,
        bccRecipients: request.bcc,
        body: request.body,
        subjectLine: request.subject,
      })
    ).data.result;
  }
}

export default new BaseDocumentServer(userManager);
