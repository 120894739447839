import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import pluralize from 'pluralize';

import DialogCloseButton from 'components/DialogCloseButton';
import useIsMobile from 'hooks/useIsMobile';
import { stringArrayToReadableList } from 'utils/array';

import { setTargetLibraries } from '../../../pages/UserOnboarding/store/actions';
import { useMissingLibraryNames } from '../hooks';

export const MissingOnboardingLibraries: React.FC = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useIsMobile();
  const missingLibraryNames = useMissingLibraryNames();

  const onClose = useCallback(() => {
    dispatch(setTargetLibraries([]));
  }, [dispatch]);

  return (
    <Dialog
      open
      sx={{
        '& .MuiDialog-paper': {
          [theme.breakpoints.up('sm')]: {
            minWidth: 600,
          },
          padding: theme.spacing(3, 2),
        },
      }}
      onClose={onClose}
      fullScreen={isMobile}
    >
      <DialogCloseButton onClick={onClose} />
      <DialogTitle>Heads up...</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          {`Your association(s) have not approved access to the following ${pluralize(
            'library',
            missingLibraryNames.length
          )}:`}
        </Typography>
        <Typography variant="body2">{stringArrayToReadableList(missingLibraryNames)}</Typography>
        <Box marginTop={4} marginBottom={5}>
          <Typography variant="body1">
            If you feel this is an error or would like access, please contact your association. You can manage your
            association libraries at anytime in your account settings.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" id="ok-got-it-missing-libraries" onClick={onClose}>
          Ok, Got It.
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MissingOnboardingLibraries;
