import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Close as CloseIcon, ExpandMoreOutlined as ExpandIcon } from '@mui/icons-material';
import { Button, Collapse, IconButton, Typography } from '@mui/material';
import { SrOnly, useSessionStorage, Visible } from '@skyslope/mache';
import { useQueryClient } from '@tanstack/react-query';
import { twMerge } from 'tailwind-merge';

import { getRoute } from 'appRoutes';
import { ReactComponent as Megaphone } from 'assets/images/Megaphone.svg';
import { routes } from 'constants/routes';
import { userSubscriptionKey } from 'hooks/domain/user/queries/useGetUserSubscriptionQuery';
import useUpdateUserSubscriptionQuery from 'hooks/domain/user/queries/useUpdateUserSubscriptionQuery';

import useGetUserSubscription from '../../hooks/domain/user/queries/useGetUserSubscription';
import useIsOnboarding from '../../hooks/useIsOnboarding';

export const bannerExcludedRoutes = [
  routes.fillTemplateForms,
  routes.fillEnvelope,
  routes.fillFileForms,
  routes.subscriptions,
];

export const TrialBanner = (props: React.ComponentProps<'div'>) => {
  const { className, ...rest } = props;

  const location = useLocation();
  const currentRoute = getRoute(location.pathname);
  const subscription = useGetUserSubscription({ enabled: true });
  const queryClient = useQueryClient();
  const isOnOnboardingPage = useIsOnboarding();

  const [dismissed, setDismissed] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [wasPreviouslyDismissed, setWasPreviouslyDismissed] = useSessionStorage('trial-banner-dismissed', false);
  const { refetch } = useUpdateUserSubscriptionQuery({ enabled: true });

  const bannerTitle = subscription.trialExpired
    ? 'Your free access to SkySlope Forms has expired.'
    : 'Your free access to SkySlope Forms is coming to an end.';
  const bannerSubtitle = subscription.trialExpired
    ? 'You can still keep the good times rolling. Sign up today for full access to Forms.'
    : `You have ${subscription.daysLeftInTrial} days remaining to access Forms and all of its features. Sign up today to avoid any service disruption.`;

  useEffect(() => {
    async function handleCheckUserSubscription() {
      const updatedSubscription = await refetch();
      if (
        updatedSubscription &&
        updatedSubscription?.data?.validUntil &&
        updatedSubscription?.data?.validUntil > new Date().toISOString()
      ) {
        queryClient.invalidateQueries([userSubscriptionKey]);
      }
    }
    if (subscription.validSubscriptionExpired) {
      handleCheckUserSubscription();
    }
  }, [subscription, refetch, queryClient]);

  const onDismissed = () => {
    setDismissed(true);
    setWasPreviouslyDismissed(true);
  };

  const toggleMobileExpand = () => {
    setExpanded((ex) => !ex);
  };

  if (
    isOnOnboardingPage ||
    wasPreviouslyDismissed ||
    dismissed ||
    bannerExcludedRoutes.includes(currentRoute?.key || '') ||
    !subscription.subscriptionExpired
  ) {
    return null;
  }

  return (
    <div
      {...rest}
      className={twMerge(
        'TrialBanner',
        'z-40 md:min-w-[800px]',
        'bg-gradient-to-r from-purple-500 to-blue-400',
        'px-8 py-6',
        className
      )}
    >
      <Visible below="md">
        <div className="flex justify-between cursor-pointer" onClick={toggleMobileExpand}>
          <Typography className="text-white font-bold flex items-center">{bannerTitle}</Typography>
          <IconButton
            disableFocusRipple
            disableTouchRipple
            disableRipple
            onClick={(e) => {
              e.stopPropagation();
              toggleMobileExpand();
            }}
            className={twMerge('text-white', expanded && 'transform rotate-180')}
          >
            <SrOnly>Expand or collapse banner</SrOnly>
            <ExpandIcon />
          </IconButton>
        </div>
        <Collapse in={expanded} unmountOnExit>
          <div className="flex flex-col pt-7">
            <Typography sx={{ color: 'white', paddingBottom: '24px' }}>{bannerSubtitle}</Typography>
            <Button component="a" href={routes.subscriptions} className="bg-white">
              Choose My Plan
            </Button>
            <Button onClick={onDismissed} className="py-4 px-6 text-white hover:bg-white/20">
              Remind Me Later
            </Button>
          </div>
        </Collapse>
      </Visible>

      <Visible above="md" className="flex justify-between items-center gap-8">
        <div className="flex items-center gap-4">
          <IconButton onClick={onDismissed} className="text-white hover:bg-white/20 flex-shrink-0 self-center">
            <SrOnly>Remind me later</SrOnly>
            <CloseIcon />
          </IconButton>
          <Megaphone className="flex-shrink-0" />
          <div className="flex flex-col gap-2">
            <Typography variant="body2" color={'white'}>
              {bannerTitle}
            </Typography>
            <Typography color={'white'}>{bannerSubtitle}</Typography>
          </div>
        </div>
        <div className="shrink-0 flex flex-wrap justify-end gap-2 grow">
          <Button
            variant="text"
            component="a"
            href="https://skyslope.com/forms-price-comparison"
            className="text-white font-bold hover:bg-white/20"
          >
            Learn More
          </Button>
          <Button variant="text" className="bg-white" component="a" href={routes.subscriptions}>
            Choose My Plan
          </Button>
        </div>
      </Visible>
    </div>
  );
};
