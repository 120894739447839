import type { SnackbarKey } from 'notistack';
import { action } from 'typesafe-actions';

import type { AppNotification } from './store';

export enum NotifierActions {
  ADD = 'Notifier/ADD',
  REMOVE = 'Notifier/REMOVE',
  CLOSE = 'Notifier/CLOSE',
}

export const addAppNotification = (
  notification: AppNotification
): { type: NotifierActions.ADD; payload: AppNotification } => action(NotifierActions.ADD, notification);

export const addErrorNotification = (
  notification: Partial<AppNotification> & { message: string }
): { type: NotifierActions.ADD; payload: AppNotification } =>
  action(NotifierActions.ADD, {
    key: new Date().getTime(),
    options: { variant: 'error' },
    ...notification,
  });

export const closeAppNotification = (key: SnackbarKey): { type: NotifierActions.CLOSE; payload: SnackbarKey } =>
  action(NotifierActions.CLOSE, key);

// this is only meant to be used in the notifier, closeAppNotification should be used every where else
export const removeAppNotification = (key: SnackbarKey): { type: NotifierActions.REMOVE; payload: SnackbarKey } =>
  action(NotifierActions.REMOVE, key);
