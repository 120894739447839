import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { AxiosError } from 'axios';

import UserProfileServer from 'resources/server/UserProfileServer';
import type { UserSettings } from 'store/types';

import { userMetaKey } from '../queries/useGetUserSettingsMetaQuery';
import useGetUserSettingsQuery, { userSettingsKey } from '../queries/useGetUserSettingsQuery';

const useUserSettingsMutation = (
  options: UseMutationOptions<string | undefined, AxiosError, Partial<UserSettings>> = {}
) => {
  const { data: userSettings } = useGetUserSettingsQuery();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: Partial<UserSettings>) => {
      if (!userSettings) return;

      const userSettingsPayload = {
        ...userSettings,
        ...payload,
      };

      return await UserProfileServer.UpdateUser(userSettingsPayload);
    },
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries([userSettingsKey]);
      await queryClient.invalidateQueries([userMetaKey]);
      options.onSuccess?.(...args);
    },
  });
};

export default useUserSettingsMutation;
