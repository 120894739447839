import type { UserManager } from '@skyslope/auth-js';

import { userManager } from 'auth/userManager';
import { FORM_API_URL, USER_API_URL } from 'constants/environments';
import type {
  VerifyNRDSIdPayload,
  VerifyNRDSIdResult,
  VerifyRamcoMemberPayload,
} from 'pages/UserSettings/AuthProfileUserSettings/store/types';
import type { FileServiceResult } from 'store/types';

import type {
  AccessCodeVerificationResponse,
  CaNrdsVerificationResponse,
  GarVerificationResponse,
  NWMLSMlsVerificationResponse,
  OreaVerificationResponse,
  OrefVerificationResponse,
  RamcoVerificationResponse,
  TRREBMlsVerificationResponse,
} from '../types/AuthProfileVerificationResponses';

import Server from './Server';

class AuthProfileVerificationServer extends Server {
  public constructor(manager: UserManager) {
    super(manager);
  }

  public async VerifyNRDSMember(request: VerifyNRDSIdPayload): Promise<VerifyNRDSIdResult> {
    return (
      await this.api.get<FileServiceResult<VerifyNRDSIdResult>>(
        `${USER_API_URL}/nrds?lastName=${request.lastName}&nrdsId=${request.nrdsId}&source=${request.source}`
      )
    ).data.result;
  }

  public async VerifyNWLSMember(lagNumber: string): Promise<NWMLSMlsVerificationResponse> {
    return (
      await this.api.get<FileServiceResult<NWMLSMlsVerificationResponse>>(
        `${USER_API_URL}/nwmls?lagNumber=${lagNumber}`
      )
    ).data.result;
  }

  public async VerifyTRREBMember(membershipId: string): Promise<TRREBMlsVerificationResponse> {
    return (
      await this.api.get<FileServiceResult<TRREBMlsVerificationResponse>>(
        `${USER_API_URL}/trreb?memberId=${membershipId}`
      )
    ).data.result;
  }

  public async VerifyOrefMember(accessId: string): Promise<OrefVerificationResponse> {
    return (
      await this.api.get<FileServiceResult<OrefVerificationResponse>>(`${USER_API_URL}/oref?accessId=${accessId}`)
    ).data.result;
  }

  public async VerifyCaNrdsMember(lastName: string, nrdsId: string): Promise<CaNrdsVerificationResponse> {
    return (
      await this.api.get<FileServiceResult<CaNrdsVerificationResponse>>(
        `${USER_API_URL}/verify/car?lastName=${lastName}&nrdsId=${nrdsId}`
      )
    ).data.result;
  }

  public async VerifyRamcoMember(request: VerifyRamcoMemberPayload): Promise<RamcoVerificationResponse> {
    return (
      await this.api.get<FileServiceResult<RamcoVerificationResponse>>(`${USER_API_URL}/ramco`, {
        params: request,
      })
    ).data.result;
  }

  public async VerifyAccessCodeMember(accessCode: string, libraryId?: number): Promise<AccessCodeVerificationResponse> {
    return (
      await this.api.post<FileServiceResult<AccessCodeVerificationResponse>>(
        `${FORM_API_URL}/libraries/verify?api-version=2.0`,
        { accessCode: accessCode, libraryId: libraryId }
      )
    ).data.result;
  }

  public async VerifyOreaMember(eqs: string): Promise<OreaVerificationResponse> {
    const params = new URLSearchParams();
    params.set('eqs', eqs);
    return (
      await this.api.get<FileServiceResult<OreaVerificationResponse>>(`${USER_API_URL}/verify/orea`, {
        params,
      })
    ).data.result;
  }

  public async GetOreaServiceToken(): Promise<string> {
    return (await this.api.get<FileServiceResult<string>>(`${USER_API_URL}/verify/orea/token`)).data.result;
  }

  public async VerifyGARMember(accessId: string): Promise<GarVerificationResponse> {
    return (await this.api.get(`${USER_API_URL}/gar/${accessId}`)).data.result;
  }
}

export default new AuthProfileVerificationServer(userManager);
