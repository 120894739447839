import type { UseMutationResult } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { AxiosError } from 'axios';

import type { LinkUserRequest } from 'resources/server/UserProfileServer';
import UserProfileServer from 'resources/server/UserProfileServer';

import { userFileOwnersMetaKey } from '../../../../components/layouts/hooks/useGetDelegatedUsers';
import { userMetaKey } from '../queries/useGetUserSettingsMetaQuery';
import { userSettingsKey } from '../queries/useGetUserSettingsQuery';

const useLinkOktaMutation = (): UseMutationResult<string, AxiosError, LinkUserRequest, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (payload: LinkUserRequest) => await UserProfileServer.LinkUser(payload?.memberId ?? ''),
    onSuccess: async () => {
      // There's no skyslope-auth-js API to force a refresh of the tokens, so we'll just force an update of all the tokens
      // Also have to delay a bit to let Okta update the user profile
      setTimeout(() => sessionStorage.removeItem('com.skyslope.id.tokens'), 300);
      await queryClient.invalidateQueries([userSettingsKey]);
      await queryClient.invalidateQueries([userFileOwnersMetaKey]);
      // auth profiles drive a lot of logic in the app and we should not use cached versions after updates
      // so removeQueries instead of invalidate
      queryClient.removeQueries([userMetaKey]);
    },
    onError: (e: AxiosError) => {
      throw e;
    },
  });
};

export default useLinkOktaMutation;
