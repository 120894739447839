import type { UserManager } from '@skyslope/auth-js';

import { userManager } from 'auth/userManager';
import { USER_API_URL } from 'constants/environments';
import type {
  AddAccessCodeAuthProfileRequest,
  AuthProfile,
  FileServiceResult,
  FormLibrarySource,
  TrackingMetadata,
  UserSettings,
  UserSettingsMeta,
} from 'store/types';

import type { GetDelegatedUsersResponse } from '../types/GetFileOwners';
import type { CreateUserSettingsRequest } from '../types/UserRestInterfaces';

import Server from './Server';

export interface UserLibraryRequest {
  libraryId: number;
  userId: string;
}

export interface AddAuthProfileRequest {
  userId: string;
  type: string;
  profile: AuthProfile;
  areTermsAccepted?: boolean;
}

export interface UpdateAuthProfileRequest extends AddAuthProfileRequest {
  authProfileId: string;
}

export interface UpdateAuthProfileCacheRequest {
  userId: string;
}

export interface AddNotificationEmailRequest {
  userId: string;
  notificationEmail: string;
}

export interface PatchUserProfileRequest {
  email: string;
}

export interface LinkUserRequest {
  memberId: string;
}

export interface GetOktaUserResponse {
  id: string;
}

export interface PopulateRamcoUserInformationRequest {
  userProfileId: string;
  libraryAuthProfileTypes: FormLibrarySource[];
}

class UserProfileServer extends Server {
  public constructor(manager: UserManager) {
    super(manager);
  }

  public async GetUser(userId: string): Promise<UserSettings> {
    return (await this.api.get(`${USER_API_URL}/user-profiles/${userId}`)).data.result;
  }

  public async GetUserSettingsMeta(userId: string): Promise<UserSettingsMeta> {
    return (await this.api.get(`${USER_API_URL}/user-profiles/${userId}/meta`)).data.result;
  }

  public async CreateUser(user: CreateUserSettingsRequest): Promise<string> {
    return (await this.api.post(`${USER_API_URL}/user-profiles`, user)).data.result;
  }

  public async UpdateUser(user: UserSettings): Promise<string> {
    const response: {
      result: string;
      statusCode: string;
      errors: unknown[];
    } = (await this.api.put(`${USER_API_URL}/user-profiles/${user.id}`, user)).data;

    if (response?.statusCode?.toLowerCase() === 'ok') {
      // There's no skyslope-auth-js API to force a refresh of the tokens, so we'll just force an update of all the tokens
      // Also have to delay a bit to let Okta update the user profile
      setTimeout(() => sessionStorage.removeItem('com.skyslope.id.tokens'), 300);
    }

    return response.result;
  }

  public async PatchUserTrackingMetadata(userId?: string, trackingMetadata?: TrackingMetadata): Promise<string> {
    const response: {
      result: string;
      statusCode: string;
      errors: unknown[];
    } = (
      await this.api.patch(`${USER_API_URL}/user-profiles/${userId}/tracking-metadata`, { payload: trackingMetadata })
    ).data;

    return response.result;
  }

  public async PatchUserProfile(userId?: string, email?: string): Promise<string> {
    const response: {
      result: string;
      statusCode: string;
      errors: unknown[];
    } = (
      await this.api.patch(`${USER_API_URL}/user-profiles/${userId}`, {
        payload: { email: email },
      })
    ).data;
    return response.result;
  }

  public async AddLibrary(request: UserLibraryRequest): Promise<string> {
    return (
      await this.api.post(`${USER_API_URL}/user-profiles/${request.userId}/libraries`, { libraryId: request.libraryId })
    ).data.result;
  }

  public async AddLibraries(request: UserLibraryRequest[]): Promise<string> {
    if (request.length === 0) {
      return Promise.reject('must be at least one library in bulk add');
    }

    const userId = request[0].userId;

    return (
      await this.api.patch(`${USER_API_URL}/user-profiles/${userId}/libraries`, {
        addedLibraryIds: request.map((r) => r.libraryId),
      })
    ).data.result;
  }

  public async RemoveLibrary(request: UserLibraryRequest): Promise<string> {
    return (await this.api.delete(`${USER_API_URL}/user-profiles/${request.userId}/libraries/${request.libraryId}`))
      .data.result;
  }

  public async AddAuthProfile(request: AddAuthProfileRequest): Promise<string> {
    return (
      await this.api.post(`${USER_API_URL}/user-profiles/${request.userId}/auth-profiles`, {
        type: request.type,
        profile: request.profile,
      })
    ).data.result;
  }

  public async AddAuthProfiles(request: AddAuthProfileRequest[]): Promise<string> {
    if (request.length === 0) {
      return Promise.reject('must be at least one auth profile in bulk add');
    }

    const userId = request[0].userId;
    return (await this.api.patch(`${USER_API_URL}/user-profiles/${userId}/auth-profiles`, { profiles: request })).data
      .result;
  }

  public async UpdateAuthProfile(request: UpdateAuthProfileRequest): Promise<string> {
    return (
      await this.api.put<FileServiceResult<string>>(
        `${USER_API_URL}/user-profiles/${request.userId}/auth-profiles/${request.authProfileId}`,
        {
          profile: request.profile,
          type: request.type,
        }
      )
    ).data.result;
  }

  public async UpdateAuthProfileCache(request: UpdateAuthProfileCacheRequest): Promise<string> {
    return (
      await this.api.put<FileServiceResult<string>>(
        `${USER_API_URL}/user-profiles/${request.userId}/auth-profiles/cache`
      )
    ).data.result;
  }

  public async AddNotificationEmail(request: AddNotificationEmailRequest): Promise<string> {
    return (
      await this.api.post(`${USER_API_URL}/user-profiles/${request.userId}/notification-email`, {
        notificationEmail: request.notificationEmail,
      })
    ).data.result;
  }

  public async AddAccessCodeLibraryProfile(request: AddAccessCodeAuthProfileRequest): Promise<string> {
    return (
      await this.api.post(`${USER_API_URL}/user-profiles/${request.userId}/library-profile`, {
        encryptedVerification: request.encryptedVerification,
      })
    ).data.result;
  }

  public async FindUsersByProfileIds(profileIds: string[]): Promise<UserSettings[]> {
    if (!profileIds) {
      return Promise.resolve([]);
    }
    return (
      await this.api.get<FileServiceResult<UserSettings[]>>(
        `${USER_API_URL}/delegation-requests/user-profiles-bulk?profileIds=${profileIds}`
      )
    ).data.result;
  }

  public async GetDelegatedUsers(): Promise<GetDelegatedUsersResponse> {
    return (
      await this.api.get<FileServiceResult<GetDelegatedUsersResponse>>(
        `${USER_API_URL}/delegation-requests/user-profiles-bulk`
      )
    ).data.result;
  }

  public async LinkUser(userId: string): Promise<string> {
    return (
      await this.api.put(`${USER_API_URL}/rpc/user/relink`, {
        memberId: userId,
      })
    )?.data?.result;
  }

  public async GetUserFromOktaByEmail(email: string): Promise<GetOktaUserResponse> {
    return (await this.api.get(`${USER_API_URL}/users?loginOrUserId=${encodeURIComponent(email)}`))?.data?.result;
  }

  public async PopulateRamcoUserInformation(request: PopulateRamcoUserInformationRequest): Promise<string> {
    //  Don't attempt if nothing sent
    if (request.libraryAuthProfileTypes.length == 0) return request.userProfileId;

    // NM Ramco is intended to be the source of truth for user data.  It's
    // possible in the future, that multiple ramco auth profiles can exist
    // on a user profile and thus potentially multiple sources of truth.  In
    // the future we'll need a more robust way to handle this scenario, for
    // now we're just sending the first auth profile.
    return (
      await this.api.post(`${USER_API_URL}/user-profiles/${request.userProfileId}/populate-ramco`, {
        libraryAuthProfileType: request.libraryAuthProfileTypes[0],
      })
    ).data.result;
  }
}

export default new UserProfileServer(userManager);
