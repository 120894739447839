import { pdfjs } from 'react-pdf';

export const pdfHeightAt100PercentZoom = 792;
export const pdfWidthAt100PercentZoom = 612;

export const thumbnailHeight = 144;
export const thumbnailWidth = 112;

const thumbnailScale = pdfHeightAt100PercentZoom / thumbnailHeight;

export const createThumbnailFromPdf = (pdfFile: File | Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const url = reader.result;
      pdfjs.getDocument(url as string).promise.then((pdf) => {
        pdf.getPage(1).then((page) => {
          const viewport = page.getViewport({
            scale: thumbnailScale,
          });
          const canvas = document.createElement('canvas');
          const canvasContext = canvas.getContext('2d') as CanvasRenderingContext2D;
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          page.render({ canvasContext, viewport }).promise.then(() => {
            resolve(canvas.toDataURL());
          });
        });
      });
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(pdfFile);
  });

export const getNumberOfPdfPages = (pdfFile: File | Blob): Promise<number> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const url = reader.result;
      pdfjs.getDocument(url as string).promise.then((pdf) => {
        resolve(pdf.numPages);
      });
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(pdfFile);
  });
