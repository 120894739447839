import React from 'react';
import { SnackbarProvider as MacheSnackbarProvider } from '@skyslope/mache';

import { WelcomeToFormsSnackbar } from './WelcomeToFormsSnackbar';

export const DEFAULT_SNACKBAR_DURATION = 3000;

export function SnackbarProvider({ children }: { children: React.ReactNode }) {
  return (
    <MacheSnackbarProvider Components={{ welcomeToForms: WelcomeToFormsSnackbar }}>{children}</MacheSnackbarProvider>
  );
}

declare module 'notistack' {
  interface VariantOverrides {
    welcomeToForms: true;
  }
}
