import React from 'react';
import { Helmet } from 'react-helmet-async';

import { useFeatureFlags } from 'hooks/useFeatureFlags';
import useIsMobile from 'hooks/useIsMobile';

export enum MobileTab {
  Envelopes = 'envelopes',
  Forms = 'forms',
  Documents = 'documents',
  Details = 'details',
}

interface SmartAppBannerProps {
  fileId?: number;
  tab?: MobileTab;
}
const SmartAppBanner = ({ fileId, tab }: SmartAppBannerProps): JSX.Element => {
  const { isSmartBannerEnabled } = useFeatureFlags();
  const isMobile = useIsMobile();
  let bannerArguments = 'app-id=1608944544';

  if (fileId && tab) {
    bannerArguments += ` app-argument=skyslope://transactions?id=${fileId}&tab=${tab}`;
  }

  return (
    <Helmet>{isSmartBannerEnabled && isMobile && <meta name="apple-itunes-app" content={bannerArguments} />}</Helmet>
  );
};

export default SmartAppBanner;
