import useGetUserIDP from 'auth/hooks/useGetUserIDP';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

const useIsOffersLinkEnabled = (): boolean => {
  const { allowedOmLinkIdps, isOfferManagementLinkEnabled } = useFeatureFlags();
  const userIDP = useGetUserIDP();

  if (userIDP && allowedOmLinkIdps?.length) {
    if (allowedOmLinkIdps.includes(userIDP.toString())) return true;
  }

  return isOfferManagementLinkEnabled;
};

export default useIsOffersLinkEnabled;
