import type { UserManager } from '@skyslope/auth-js';
import queryString from 'query-string';

import { userManager } from 'auth/userManager';
import { FILE_API_URL } from 'constants/environments';
import FileType from 'constants/FileType';
// TODO this file should not be importing from a page
import type { FileSummary, FileSummaryPage } from 'pages/FilesDashboard/store/types';
import type {
  DeleteTemplateRequest,
  DuplicateFileRequest,
  RenameFileRequest,
  SaveFileAsTemplateRequest,
} from 'store/domain/actions';
import type {
  BrokerTemplateStatus,
  DocumentSummary,
  File,
  FileServiceResult,
  FormFieldValue,
  Property,
} from 'store/types';
import SieveBuilder from 'utils/sieve';

import type { DeleteFileDocumentRequest } from '../types/DeleteFileDocument';
import type { GetEnvelopesRequest, GetEnvelopesResult } from '../types/GetEnvelopes';
import type { GetFileDocumentsRequest } from '../types/GetFileDocuments';
import type { PaginationOptions } from '../types/PaginationOptions';
import type { UpdateFileDetailsPayload, UpdateFileDetailsResponse } from '../types/UpdateFileDetails';

import Server from './Server';

interface FileMetaRegion {
  country: string;
  region: string;
}

interface FileMetaAccountGroup {
  groupId: string;
}

interface FileMetaData {
  externalId: string;
  regions: FileMetaRegion[];
  brokerTemplateStatus: BrokerTemplateStatus;
  accountGroups?: FileMetaAccountGroup[];
}

export interface FileCreateRequest {
  name: string;
  type?: FileType;
  representationType?: string;
  property?: Property;
  templateCategory?: string;
  meta?: FileMetaData;
  onBehalfOf?: string;
  tenantScreeningProvider?: 'RentSpree' | 'None';
  isUtilityConnectEnabled?: boolean;
}

export interface PatchFileV3Response {
  fileId: number;
  didAddendumsChange: boolean;
}

export interface PatchFileV3Payload {
  name?: string;
  isArchived?: boolean;
  fileData?: {
    [dataRef: string]: FormFieldValue;
  };
  templateCategory?: string;
  type?: FileType;
  meta?: FileMetaData;
  onBehalfOf?: string;
}

export interface PatchFileV3Request {
  fileId: number;
  payload: PatchFileV3Payload;
}

export interface GetFilesRequest {
  isArchived?: boolean;
  isDeleted?: boolean;
  isDelegateUser?: boolean;
  fileType?: FileType;
  regions?: string[];
  shouldHaveDocuments?: boolean;
  searchText?: string;
  selectedRepresentations?: string[];
  selectedFileOwners?: string[];
  onBehalfOf?: string;
}

export interface ApplyTemplateToFilePayload {
  fileId: number;
  templateId: number;
  onBehalfOf?: string;
}

export class BaseFileServer extends Server {
  public constructor(manager: UserManager) {
    super(manager);
  }

  public async FileCreate(request: FileCreateRequest): Promise<number> {
    return (await this.api.post<FileServiceResult<number>>(`${FILE_API_URL}/files`, request)).data.result;
  }

  public async GetFile(fileId: number): Promise<File> {
    return (await this.api.get<FileServiceResult<File>>(`${FILE_API_URL}/files/${fileId}`)).data.result;
  }

  public async GetFileDocuments(request: GetFileDocumentsRequest): Promise<DocumentSummary> {
    const sieveBuilder: SieveBuilder = new SieveBuilder();
    if (request.page && request.pageSize) {
      sieveBuilder.page(request.page).pageSize(request.pageSize);
    } else {
      sieveBuilder.pageSize(999999);
    }

    const params = sieveBuilder.build() ?? new URLSearchParams();

    return (
      await this.api.get<FileServiceResult<DocumentSummary>>(`${FILE_API_URL}/files/${request.fileId}/documents`, {
        params,
      })
    ).data.result;
  }

  public async DeleteFileDocument(request: DeleteFileDocumentRequest): Promise<number> {
    return (await this.api.delete<FileServiceResult<number>>(`${FILE_API_URL}/documents/${request.documentId}`)).data
      .result;
  }

  public async GetFileEnvelopes(request: GetEnvelopesRequest): Promise<GetEnvelopesResult> {
    const sieveBuilder: SieveBuilder = new SieveBuilder();
    sieveBuilder.page(request.page).pageSize(request.pageSize);

    const params: URLSearchParams | undefined = sieveBuilder.build();

    return (
      await this.api.get<FileServiceResult<GetEnvelopesResult>>(
        `${FILE_API_URL}/files/${request.fileId}/envelopes?includeAll=true`,
        { params }
      )
    ).data.result;
  }

  public async UpdateFileDetails(request: UpdateFileDetailsPayload): Promise<UpdateFileDetailsResponse> {
    return (
      await this.api.put<FileServiceResult<UpdateFileDetailsResponse>>(`${FILE_API_URL}/files/${request.fileId}`, {
        ...request,
        fileId: undefined,
      })
    ).data.result;
  }

  public async GetFilesPage(
    {
      fileType = FileType.File,
      isDeleted = false,
      isArchived = false,
      isDelegateUser = false,
      regions = [],
      shouldHaveDocuments,
      searchText,
      selectedRepresentations,
      selectedFileOwners,
      onBehalfOf,
    }: Partial<GetFilesRequest> = {},
    pagination: PaginationOptions | undefined = undefined
  ): Promise<FileSummaryPage> {
    const builder = new SieveBuilder().pageSize(pagination?.pageSize ?? 100).page(pagination?.page ?? 1);
    if (regions && regions.length > 0) {
      builder.equals('Regions', SieveBuilder.or(...regions));
    }

    if (shouldHaveDocuments) {
      builder.greater('documentCount', 0);
    }

    if (searchText) {
      builder.containsIgnoreCase(
        `(${SieveBuilder.or('Name', 'Address', 'ContactInfo')})`,
        SieveBuilder.escapeCharacters(searchText)
      );
    }

    if (selectedRepresentations !== undefined && selectedRepresentations.length > 0) {
      builder.equals('RepresentationType', SieveBuilder.or(...selectedRepresentations));
    }

    if (selectedFileOwners !== undefined && selectedFileOwners.length > 0) {
      builder.equals('OwnedBy', SieveBuilder.or(...selectedFileOwners));
    }

    const params = builder.build() ?? new URLSearchParams();
    params.append('isArchived', (!!isArchived).toString());
    params.append('isDeleted', (!!isDeleted).toString());
    params.append('type', fileType);
    params.append('onBehalfOf', onBehalfOf ?? '');

    if (isDelegateUser) {
      params.append('includeSharedFiles', (!!isDelegateUser).toString());
    }

    return (
      await this.api.get<FileServiceResult<FileSummaryPage>>(`${FILE_API_URL}/files/`, {
        params,
      })
    ).data.result;
  }

  public async GetFiles(
    request: Partial<GetFilesRequest> = {},
    pagination: PaginationOptions | undefined = undefined
  ): Promise<FileSummary[]> {
    return (await this.GetFilesPage(request, pagination)).files;
  }

  public async ApplyTemplateToFile(payload: ApplyTemplateToFilePayload): Promise<undefined> {
    return (await this.api.put<FileServiceResult<undefined>>(`${FILE_API_URL}/rpc/applyTemplateToFile`, payload)).data
      .result;
  }

  public async DeleteTemplate(request: DeleteTemplateRequest): Promise<number> {
    return (await this.api.delete<FileServiceResult<number>>(`${FILE_API_URL}/files/${request.templateId}`)).data
      .result;
  }

  public async RenameFile(payload: RenameFileRequest): Promise<PatchFileV3Response> {
    return (
      await this.api.patch<FileServiceResult<PatchFileV3Response>>(
        `${FILE_API_URL}/files/${payload.id}?api-version=3.0`,
        payload
      )
    ).data.result;
  }

  public async PatchFileV3(request: PatchFileV3Request): Promise<PatchFileV3Response> {
    return (
      await this.api.patch<FileServiceResult<PatchFileV3Response>>(
        `${FILE_API_URL}/files/${request.fileId}?api-version=3.0`,
        request.payload
      )
    ).data.result;
  }

  public async DuplicateFile(payload: DuplicateFileRequest): Promise<number> {
    return (await this.api.post<FileServiceResult<number>>(`${FILE_API_URL}/files?sourceId=${payload.fileId}`, payload))
      .data.result;
  }

  public async SaveFileAsTemplate(payload: SaveFileAsTemplateRequest): Promise<number> {
    const queryParameters = queryString.stringify({
      documentIds: payload.documentIds,
    });

    return (
      await this.api.post<FileServiceResult<number>>(
        `${FILE_API_URL}/files?${queryParameters}&sourceId=${payload.fileId}`,
        payload
      )
    ).data.result;
  }

  public async CreateTenantScreening(fileId: number): Promise<string> {
    // there is an optional `tenantScreeningProvider` parameter that can be passed if needed
    return (await this.api.post<FileServiceResult<string>>(`${FILE_API_URL}/files/${fileId}/tenant-screening`, {})).data
      .result;
  }
}

export default new BaseFileServer(userManager);
