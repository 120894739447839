import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import useQueryParams from 'hooks/useQueryParams';
import AccountUserServer from 'resources/server/AccountUserServer';
import type { UserSubscription } from 'resources/types/GetUserSubscriptionSettings';

import useGetUserSettingsQuery from './useGetUserSettingsQuery';

export const updateUserSubscriptionKey = 'updateUserSubscription';

const useUpdateUserSubscriptionQuery = (
  options: UseQueryOptions<{ enabled: boolean }> = {}
): UseQueryResult<UserSubscription> => {
  const queryParams = useQueryParams();
  const paymentSuccess = queryParams.get('paymentSuccess');
  const { data } = useGetUserSettingsQuery();
  const { enqueueSnackbar } = useSnackbar();
  return useQuery({
    queryKey: [updateUserSubscriptionKey, data?.id],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: async () => AccountUserServer.updateUserSubscription(data!.id),
    enabled: (options.enabled && !!data && !!paymentSuccess) ?? false,
    onSuccess: (res: UserSubscription) => {
      if (new Date(res.validUntil) > new Date()) {
        enqueueSnackbar('Your subscription has been successfully updated!', {
          variant: 'success',
          preventDuplicate: true,
          key: 'subscriptionSuccess',
        });
      }
    },
    onError: () => {
      enqueueSnackbar('We were unable to validate your subscription, please try again or reach out to support', {
        variant: 'error',
        preventDuplicate: true,
        key: 'subscriptionError',
      });
    },
  });
};

export default useUpdateUserSubscriptionQuery;
