import type { UserManager } from '@skyslope/auth-js';
import type { AxiosRequestConfig } from 'axios';

import type { FormsUserManager } from 'auth/userManager';
import { userManager } from 'auth/userManager';
import { FILE_API_URL } from 'constants/environments';
import type { SignedDocumentsFromEnvelope } from 'pages/FileDetails/SignedDocuments/hooks/useGetSignedDocuments';
import type { Envelope, EnvelopeHistory, FileServiceResult, PendingEnvelope } from 'store/types';

import type {
  AddDocumentsToEnvelopeRequest,
  AddDocumentToEnvelopeRequest,
  CreateDocusignEnvelopeRequest,
  CreateDocusignEnvelopeResponse,
  CreateEnvelopeRequest,
  RemoveDocumentFromEnvelopeRequest,
  SendEnvelopeDocumentToDs3Request,
  SendEnvelopeToDs3Request,
  SetEnvelopeSignersRequest,
  UpdateEnvelopeDocumentOrderRequest,
  UpdateEnvelopeNameRequest,
} from '../types/CreateEnvelope';
import type { FormsMarkupBlock } from '../types/GetEnvelopeDocumentMarkup';
import type { EnvelopeResponse } from '../types/GetPendingEnvelope';

import Server from './Server';

class EnvelopeServer extends Server {
  public constructor(manager: UserManager) {
    super(
      manager,
      {},
      {
        customRequestConfigMutation: async (userManager, config) => {
          if (config.headers['X-DS3-Authorization'] === undefined) {
            config.headers['X-DS3-Authorization'] = await (userManager as FormsUserManager).getAccessToken();
          }
        },
      }
    );
  }

  public async GetPendingEnvelope(request: number): Promise<PendingEnvelope | undefined> {
    return (await this.api.get<FileServiceResult<PendingEnvelope>>(`${FILE_API_URL}/files/${request}/pendingEnvelope`))
      .data.result;
  }

  public async SetEnvelopeSigners(request: SetEnvelopeSignersRequest): Promise<number> {
    return (
      await this.api.post<FileServiceResult<number>>(`${FILE_API_URL}/envelopes/${request.envelopeId}/signers`, request)
    ).data.result;
  }

  public async CreateEnvelope(request: CreateEnvelopeRequest): Promise<PendingEnvelope> {
    return (await this.api.post<FileServiceResult<PendingEnvelope>>(`${FILE_API_URL}/envelopes`, request)).data.result;
  }

  public async AddDocumentToEnvelope(request: AddDocumentToEnvelopeRequest): Promise<EnvelopeResponse> {
    return (
      await this.api.post<FileServiceResult<PendingEnvelope>>(
        `${FILE_API_URL}/envelopes/${request.envelopeId}/document`,
        request
      )
    ).data.result;
  }

  public async AddDocumentsToEnvelope(request: AddDocumentsToEnvelopeRequest): Promise<EnvelopeResponse> {
    return (
      await this.api.post<FileServiceResult<PendingEnvelope>>(
        `${FILE_API_URL}/envelopes/${request.envelopeId}/documents`,
        request
      )
    ).data.result;
  }

  public async RemoveDocumentFromEnvelope(request: RemoveDocumentFromEnvelopeRequest): Promise<EnvelopeResponse> {
    return (
      await this.api.delete<FileServiceResult<PendingEnvelope>>(
        `${FILE_API_URL}/envelopes/${request.envelopeId}/document/${request.documentId}`
      )
    ).data.result;
  }

  public async UpdateEnvelopeDocumentOrder(request: UpdateEnvelopeDocumentOrderRequest): Promise<number> {
    return (
      await this.api.put<FileServiceResult<number>>(
        `${FILE_API_URL}/envelopes/${request.envelopeId}/documents/order`,
        request
      )
    ).data.result;
  }

  public async UpdateEnvelopeName(request: UpdateEnvelopeNameRequest): Promise<number> {
    return (await this.api.patch<FileServiceResult<number>>(`${FILE_API_URL}/envelopes/${request.envelopeId}`, request))
      .data.result;
  }

  public async UpdateEnvelopeStatusToDraft(envelopeId: number): Promise<PendingEnvelope> {
    return (
      await this.api.put<FileServiceResult<PendingEnvelope>>(`${FILE_API_URL}/envelopes/${envelopeId}`, {
        status: 'DRAFT',
      })
    ).data.result;
  }

  public async UpdateEnvelopeStatusToCancelled(envelopeId: number): Promise<EnvelopeResponse> {
    return (
      await this.api.put<FileServiceResult<PendingEnvelope>>(`${FILE_API_URL}/envelopes/${envelopeId}/status`, {
        status: 'CANCELLED',
      })
    ).data.result;
  }

  public async UpdateEnvelopeStatusToCorrecting(envelopeId: number): Promise<EnvelopeResponse> {
    return (await this.api.put<FileServiceResult<PendingEnvelope>>(`${FILE_API_URL}/envelopes/${envelopeId}/correct`))
      .data.result;
  }

  public async GetEnvelope(envelopeId: number): Promise<Envelope> {
    return (await this.api.get<FileServiceResult<Envelope>>(`${FILE_API_URL}/envelopes/${envelopeId}`)).data.result;
  }

  public async GetEnvelopeDocumentMarkup(fileId: number): Promise<Record<string, Record<string, FormsMarkupBlock>>> {
    return (
      await this.api.get<FileServiceResult<Record<number, Record<string, FormsMarkupBlock>>>>(
        `${FILE_API_URL}/files/${fileId}/documents/markup`
      )
    ).data.result;
  }

  public async GetEnvelopeHistory(envelopeId: number): Promise<EnvelopeHistory> {
    return (await this.api.get<FileServiceResult<EnvelopeHistory>>(`${FILE_API_URL}/envelopes/${envelopeId}/history`))
      .data.result;
  }

  public async CreateExternalEnvelope(request: SendEnvelopeToDs3Request): Promise<PendingEnvelope> {
    return (
      await this.api.post<FileServiceResult<PendingEnvelope>>(
        `${FILE_API_URL}/envelopes/${request.envelopeId}/external`,
        request
      )
    ).data.result;
  }

  public async CreateExternalEnvelopeDocument(request: SendEnvelopeDocumentToDs3Request): Promise<EnvelopeResponse> {
    return (
      await this.api.post<FileServiceResult<PendingEnvelope>>(
        `${FILE_API_URL}/envelopes/${request.envelopeId}/document/external`,
        request
      )
    ).data.result;
  }

  public async DeleteEnvelope(envelopeId: number): Promise<number> {
    return (await this.api.delete<FileServiceResult<number>>(`${FILE_API_URL}/envelopes/${envelopeId}`)).data.result;
  }

  public async DeleteDs3EnvelopeInfo(envelopeId: number): Promise<number> {
    return (await this.api.delete<FileServiceResult<number>>(`${FILE_API_URL}/envelopes/${envelopeId}/external`)).data
      .result;
  }

  public async HardDeleteDs3Envelope(envelopeId: number): Promise<number> {
    return (await this.api.delete<FileServiceResult<number>>(`${FILE_API_URL}/envelopes/${envelopeId}/external/hard`))
      .data.result;
  }

  public async RefreshEnvelopeStatus(envelopeId: string): Promise<number> {
    return (await this.api.put<FileServiceResult<number>>(`${FILE_API_URL}/envelopes/${envelopeId}/refresh`)).data
      .result;
  }

  /**
   * Get signed docs from envelopes.
   * To get all signed docs on a file just pass fileId.
   * To get all signed docs from a particular envelope just pass fileId & formsEnvelopeId.
   * To get a particular signed doc from a particular envelope pass fileId, formsEnvelopeId & documentId.
   */
  public async GetSignedDocs(args: {
    fileId: number;
    formsEnvelopeId?: number;
    documentId?: number;
    axiosConfig?: AxiosRequestConfig;
  }): Promise<SignedDocumentsFromEnvelope[]> {
    const { fileId, formsEnvelopeId, documentId, axiosConfig } = args;

    let url = `${FILE_API_URL}/files/${fileId}/signed-documents`;
    if (formsEnvelopeId) {
      url += `/envelope/${formsEnvelopeId}`;
      if (documentId) url += `/document/${documentId}`;
    }

    const res = await this.api.get<FileServiceResult<SignedDocumentsFromEnvelope[]>>(url, axiosConfig);
    return res.data.result;
  }

  public async CreateDocusignEnvelope(
    envelopeId: number,
    accessToken: string,
    request: CreateDocusignEnvelopeRequest
  ): Promise<CreateDocusignEnvelopeResponse> {
    return (
      await this.api.post<FileServiceResult<CreateDocusignEnvelopeResponse>>(
        `${FILE_API_URL}/envelopes/${envelopeId}/docusign`,
        request,
        {
          headers: {
            'x-docusign-authorization': accessToken,
          },
        }
      )
    ).data.result;
  }
}

export default new EnvelopeServer(userManager);
