/* eslint-disable no-console, @typescript-eslint/no-explicit-any */
import React, { useCallback } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import styled from '@mui/system/styled';
import { useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';

import useGetUserSettingsQuery from 'hooks/domain/user/queries/useGetUserSettingsQuery';
import useAddKeyDownEvent from 'hooks/useAddKeyDownEvent';
import useConfirmation from 'hooks/useConfirmation';
import downloadJson, { downloadJsonHiddenLinkId } from 'utils/downloadJson';

import useLocationHistory from './hooks/useLocationHistory';

const StyledAnchor = styled('a')(() => ({
  display: 'none',
}));

const CustomerSupportReportingTool: React.FC = () => {
  const { data: userSettings } = useGetUserSettingsQuery();
  const { confirm, closeDialog } = useConfirmation();
  const { currentLocation, locationHistory } = useLocationHistory();
  const queryClient = useQueryClient();

  const createDebugLog = useCallback(
    (stripPII: boolean): Record<string, any> => {
      const queryCache = queryClient.getQueryCache();
      const queryData = queryCache
        .findAll()
        .filter((query) => query.state.data != null)
        .map((query) => {
          const key = typeof query.queryKey === 'object' ? JSON.stringify(query.queryKey) : `${query.queryKey}`;
          return {
            [key]: query.state.data,
          };
        });
      const maxConsoleLogs = 20;
      const consoleErrors = (
        console?.everything?.filter(
          (log) => log.type === 'promiseRejection' || log.type === 'exception' || log.type === 'error'
        ) as Array<unknown>
      ).slice(maxConsoleLogs * -1);
      return {
        userId: userSettings?.id,
        email: userSettings?.email,
        firstName: stripPII ? undefined : userSettings?.firstName,
        lastName: stripPII ? undefined : userSettings?.lastName,
        locationHistory,
        currentLocation,
        consoleErrors: consoleErrors ?? 'Error retrieving console logs',
        networkErrors: window?.fetchErrors ?? 'none',
        reactQueryClient: stripPII ? undefined : Object.assign({}, ...queryData),
      };
    },
    [
      currentLocation,
      locationHistory,
      queryClient,
      userSettings?.email,
      userSettings?.firstName,
      userSettings?.id,
      userSettings?.lastName,
    ]
  );

  const confirmDownload = useCallback(() => {
    confirm(
      {
        title: 'Send System Log?',
        description:
          'This action will a system log to our developers consisting of the details and history of your session.',
        confirmButtonLabel: 'Send System Log',
        cancelButtonLabel: 'Download Instead',
        clickOutCallbackOverride: () => {
          closeDialog();
        },
      },
      () => {
        datadogLogs.logger.error(
          `user-reported-error-${userSettings?.id}-${userSettings?.email}`,
          createDebugLog(true)
        );
      },
      () => {
        confirm(
          {
            title: 'Download System Log?',
            description: 'WARNING: Do not share this log file with anyone except a SkySlope support expert.',
            confirmButtonLabel: 'Download Log',
          },
          () => {
            downloadJson(`${userSettings?.id}-debug-${format(Date.now(), 'HH:mm-MM/dd/yy')}`, createDebugLog(false));
          }
        );
      }
    );
  }, [closeDialog, confirm, createDebugLog, userSettings]);

  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.ctrlKey && e.shiftKey && e.key === 'E') {
        confirmDownload();
      }
    },
    [confirmDownload]
  );

  useAddKeyDownEvent(handleKeyPress);

  return <StyledAnchor id={downloadJsonHiddenLinkId} />;
};

export default CustomerSupportReportingTool;
