export const downloadJsonHiddenLinkId = 'csToolHiddenLink';

/**
 * This function is dependent on a hidden <a/> link being rendered
 * One is currently being rendered in CustomerSupportReportingTool.tsx
 * @param nameOfFile
 * @param obj
 */
const downloadJson = (nameOfFile: string, obj: unknown): void => {
  const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(obj, null, 2))}`;
  const dlAnchorElem = document.getElementById(downloadJsonHiddenLinkId);
  dlAnchorElem?.setAttribute('href', dataStr);
  dlAnchorElem?.setAttribute('download', `${nameOfFile}.json`);
  dlAnchorElem?.click();
};

export default downloadJson;
