import { useEffect } from 'react';
import { useAuth } from '@skyslope/auth-react';
// eslint-disable-next-line no-restricted-imports
import { useLDClient } from 'launchdarkly-react-client-sdk';

import useDerivedUserData from '../../../hooks/domain/user/useDerivedUserData';
import { isAppRunningInIframe } from '../../../utils/isAppRunningInIframe';

const useSetupLaunchDarkly = (): void => {
  const { userContext } = useAuth();
  const ldClient = useLDClient();
  const { optInLibraries, regions } = useDerivedUserData();

  useEffect(() => {
    if (userContext && ldClient) {
      ldClient.identify({
        key: userContext.id,
        lastName: userContext.lastName,
        email: userContext.email,
        custom: {
          isPrimeUser: !!userContext.prime?.userId,
          subscriberId: userContext.prime?.subscriberId ?? '',
          isWidgetUser: isAppRunningInIframe(),
          libraries: optInLibraries.map(({ id }) => id),
          regions: regions ?? [],
        },
      });
    }
  }, [userContext, ldClient, optInLibraries, regions]);
};

export default useSetupLaunchDarkly;
