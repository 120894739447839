import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from 'store';

import type { OreaDialogStatus } from 'store/actions';
import { setOreaDialogStatus } from 'store/actions';

import OreaAuthDialog from './OreaAuthDialog';

export const OreaAuthDialogContainer: React.FC = () => {
  const dialogStatus = useSelector((state: RootState) => state.globalState.oreaDialogStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    if (dialogStatus === 'success') {
      dispatch(setOreaDialogStatus('closed'));
    }
  }, [dialogStatus, dispatch]);

  const handleSetDialogStatus = (status: OreaDialogStatus) => {
    dispatch(setOreaDialogStatus(status));
  };

  return <OreaAuthDialog setDialogStatus={handleSetDialogStatus} />;
};
