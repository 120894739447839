import type { UserContext } from '@skyslope/auth-js';

import type { IDP } from '../../../../auth/hooks/useGetUserIDP';

const idpLoginIdPrefix: Partial<Record<IDP, string>> = {
  recolorado: 'REC',
};

const useGetAgentId = (idp: IDP | undefined, userContext?: UserContext): string | undefined => {
  if (!userContext) return '';
  if (!idp) return userContext.loginId;
  switch (idp) {
    case 'stellar':
      return userContext.nrdsId;
    default:
      return `${idpLoginIdPrefix[idp] ?? ''}${userContext.loginId}`;
  }
};

export default useGetAgentId;
