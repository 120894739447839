import { alpha, createTheme, type ThemeOptions } from '@mui/material/styles';
import type { StyleRules } from '@mui/styles/withStyles';
import deepmerge from '@mui/utils/deepmerge';
import { colors, macheTheme, typography } from '@skyslope/mache';

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
  }

  interface PaletteOptions {
    neutral?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    neutral: true;
  }
}

const theme = createTheme();

const baseChipRoot = {
  margin: theme.spacing(0.5, 1, 0.5, 0),
  backgroundColor: colors.grey[100],
  borderRadius: theme.spacing(2),
};

const chipDeleteIcon = {
  color: colors.grey[800],
  marginRight: theme.spacing(1),
  marginLeft: theme.spacing(0.25),
};

const chipIcon = {
  marginLeft: theme.spacing(2),
};

const formsThemeModifications: ThemeOptions = {
  palette: {
    neutral: {
      // light: colors.grey[800],
      // dark: colors.grey[800],
      // contrastText: colors.grey[800],
      main: colors.grey[800],
    },
  },
  components: {
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          '& h2': {
            ...typography.h3,
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          padding: '12px 24px',
          '&.MuiListItemButton-gutters': {
            paddingLeft: '24px',
          },
          '&:hover': {
            backgroundColor: colors.blue[50],
          },
          '&:focus': {
            backgroundColor: colors.blue[100],
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '8px 24px 8px 16px',
          '@media only screen and (max-width: 600px)': {
            padding: '16px 8px !important',
            display: 'flex',
            flexDirection: 'column-reverse',
            '& *': {
              marginRight: '0 !important',
              marginLeft: '0 !important',
            },
            '& > *': {
              width: '100%',
              marginBottom: theme.spacing(2),
            },
          },
        },
        spacing: {
          '& > :not(:first-of-type)': {
            marginLeft: '16px',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '6px',
          margin: '2px',
          '@media only screen and (max-width: 600px)': {
            // Add bigger area to click for mobile, without taking up more space
            padding: '12px',
            margin: '-4px',
          },
          '&.Mui-disabled': {
            color: colors.grey[800],
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        colorPrimary: {
          color: colors.blue[700],
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
        message: {
          fontSize: 16,
          lineHeight: '24px',
        },
      },
    },
    MuiCardActionArea: {
      styleOverrides: {
        root: {
          transition: `background-color ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut} 0ms`,
          '&:hover': {
            backgroundColor: colors.blue[50],
          },
          '&:focus': {
            backgroundColor: colors.blue[50],
          },
        },
        focusHighlight: {
          opacity: '0 !important', // disable MUI focus highlight, use our own so we can get direct mache colors
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: alpha(colors.grey[900], 0.9),
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: baseChipRoot,
        outlined: {
          ...baseChipRoot,
          backgroundColor: colors.grey[200],
          border: `1px solid ${colors.grey[400]}`,
          '& .MuiChip-deleteIcon': chipDeleteIcon,
          '& .MuiChip-icon': chipIcon,
          '& .MuiChip-label': {
            ...(typography.body2 as StyleRules),
          },
        },
        label: {
          ...(typography.body1 as StyleRules),
          color: colors.grey[800],
          paddingRight: theme.spacing(2),
          paddingLeft: theme.spacing(2),
        },
        icon: chipIcon,
        deleteIcon: { ...chipDeleteIcon, marginRight: theme.spacing(2) },
      },
      defaultProps: {
        clickable: false,
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          subtitle2: 'p',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-focusVisible': {
            backgroundColor: colors.blue[50],
          },
          '&.Mui-selected': {
            '&.Mui-focusVisible': { backgroundColor: colors.blue[800] },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '.MuiFilledInput-root': {
            padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
            '.MuiFilledInput-input': {
              padding: 'initial',
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          '&.MuiTableCell-paddingCheckbox': {
            boxSizing: 'content-box',
          },
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          zIndex: 2,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          boxSizing: 'border-box',
          width: 42,
        },
      },
    },
  },
};

const formsTheme = createTheme(deepmerge(macheTheme, formsThemeModifications));
export default formsTheme;
