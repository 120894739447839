enum EnvelopeStatus {
  PreDraft = 'PreDraft',
  Pending = 'Pending',
  Draft = 'Draft',
  Sent = 'Sent',
  Correcting = 'Correcting',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
  Expired = 'Expired',
}

export default EnvelopeStatus;
