import { createSelector } from 'reselect';
import type { RootState } from 'store';

import type { Contact, Ds3Recipient } from 'store/types';

import { Ds3Role, Ds3SignerStatus } from './types';

const recipientsSelector = (state: RootState): Ds3Recipient[] => state.envelopeResendDialogState.recipients;

export const signersWhoHaveNotSignedSelector = createSelector(recipientsSelector, (recipients): Ds3Recipient[] =>
  recipients
    .filter(
      (recipient: Ds3Recipient) => recipient.role === Ds3Role.SIGNER && recipient.status === Ds3SignerStatus.IN_PROGRESS
    )
    .sort((r1, r2) => r1.group.order - r2.group.order)
);

export const contactsSelector = (state: RootState): Contact[] => state.envelopeResendDialogState.contacts;
