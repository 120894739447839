import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import UserProfileServer from 'resources/server/UserProfileServer';
import type { UserSettings } from 'store/types';

import { userSettingsKey } from './useGetUserSettingsQuery';

const useGetDelegatedUserSettingsQuery = (
  userId: string,
  options:
    | Omit<UseQueryOptions<UserSettings, unknown, UserSettings, string[]>, 'queryFn' | 'queryKey'>
    | undefined = undefined
): UseQueryResult<UserSettings> => {
  const enabled = options?.enabled !== undefined ? options.enabled : true;
  return useQuery({
    queryKey: [userSettingsKey, 'delegated', userId],
    queryFn: async () => UserProfileServer.GetUser(userId),
    ...options,
    enabled: !!userId && enabled,
  });
};

export default useGetDelegatedUserSettingsQuery;
