import type { UserManager } from '@skyslope/auth-js';

import { userManager } from 'auth/userManager';
import { FORM_API_URL } from 'constants/environments';
import type { FileServiceResult, FormLibrary } from 'store/types';
import SieveBuilder from 'utils/sieve';

import Server from './Server';

class FormLibraryServer extends Server {
  public constructor(manager: UserManager) {
    super(manager);
  }

  public async GetFormLibraries(params: URLSearchParams | undefined = undefined): Promise<FormLibrary[]> {
    const searchParams = params ?? new URLSearchParams();

    searchParams.append('api-version', '2.0');
    return (await this.api.get<FileServiceResult<FormLibrary[]>>(`${FORM_API_URL}/libraries`, { params: searchParams }))
      .data.result;
  }

  public async GetFormLibrariesByRegions(regions: string[]): Promise<FormLibrary[]> {
    const sieveBuilder = new SieveBuilder();
    sieveBuilder.pageSize(10000);
    sieveBuilder.page(1);
    sieveBuilder.ascendBy('Name');
    if (regions && regions.length > 0) {
      sieveBuilder.contains('Regions', SieveBuilder.or(...regions));
    }

    const params = sieveBuilder.build();
    params?.set('api-version', '2.0');
    return await this.GetFormLibraries(params);
  }
}

export default new FormLibraryServer(userManager);
