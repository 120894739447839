import { action } from 'typesafe-actions';

import type { PopUnderSettings } from './store';

export enum PopUnderActions {
  SET_POP_UNDER = 'PopUnder/SET_POP_UNDER',
}

export const addPopUnder = (
  popUnderUrl: string,
  delayMs: number
): {
  type: PopUnderActions.SET_POP_UNDER;
  payload: PopUnderSettings;
} =>
  action(PopUnderActions.SET_POP_UNDER, {
    popUnderUrl,
    delayMs,
  });
