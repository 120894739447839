import type { UserContext } from '@skyslope/auth-js';
import { useAuth } from '@skyslope/auth-react';
import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';

import UserProfileServer from 'resources/server/UserProfileServer';
import type { CreateUserSettingsRequest } from 'resources/types/UserRestInterfaces';
import type { FileServiceError, UserSettings } from 'store/types';
import { sanitizeUserContext } from 'utils/userSettings';

import useGetUserIDP from '../../../../auth/hooks/useGetUserIDP';

import useGetAgentId from './useGetAgentId';

export const userSettingsKey = 'user-settings';

const useGetUserSettingsQuery = (
  options:
    | Omit<UseQueryOptions<UserSettings, unknown, UserSettings, string[]>, 'initialData' | 'queryFn' | 'queryKey'>
    | undefined = undefined
): UseQueryResult<UserSettings> => {
  const { userContext } = useAuth();
  const idp = useGetUserIDP();
  const agentId: string | undefined = useGetAgentId(idp, userContext ?? undefined);

  return useQuery({
    queryKey: [userSettingsKey],
    queryFn: async () => {
      if (!userContext) {
        throw new Error('context was not loaded yet');
      }

      let user: UserSettings;
      try {
        user = await UserProfileServer.GetUser(userContext.id);
      } catch (e) {
        if (
          !(e as AxiosError<FileServiceError>)?.isAxiosError ||
          (e as AxiosError<FileServiceError>)?.response?.status !== 404
        )
          throw e;

        const sanitizedContext: UserContext = sanitizeUserContext(userContext);
        const createRequest: CreateUserSettingsRequest = {
          firstName: sanitizedContext.firstName ?? '',
          middleName: sanitizedContext.middleName,
          lastName: sanitizedContext.lastName ?? '',
          email: sanitizedContext.email ?? '',
          primaryPhoneNumber: sanitizedContext.primaryPhone,
          regions: [],
          areTermsAccepted: !sanitizedContext.prime?.userId,
          agentId,
        };

        await UserProfileServer.CreateUser(createRequest);
        user = await UserProfileServer.GetUser(userContext.id);
      }

      // Used to JIT migrate subscriber ids into user profiles if they aren't there, or are out of date
      // No need to explicitly pass in the subscriber id, the API can set subscriber id using user context
      // for standalone users: user.subscriberId is null, context.prime.subscriberId is undefined so check for loose equality
      if (user.subscriberId != userContext.prime?.subscriberId?.toString()) {
        await UserProfileServer.UpdateUser(user);
        user = await UserProfileServer.GetUser(userContext.id);
      }

      return user;
    },
    ...options,
    enabled: (options?.enabled == null || options.enabled) && !!userContext,
  });
};

export default useGetUserSettingsQuery;
