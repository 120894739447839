import { userManager } from '../userManager';

import getLastIdp from './helpers/getLastIdp';

export type IDP =
  | 'ure'
  | 'glar'
  | 'flex_mls'
  | 'stellar'
  | 'trreb'
  | 'sfaor'
  | 'gaar'
  | 'weichert'
  | 'nmmls'
  | 'recolorado'
  | 'paragon'
  | 'prime'
  | 'google'
  | 'facebook'
  | 'linkedIn'
  | 'trreb_amp'
  | 'brightmls'
  | 'realmls'
  | 'silvercity'
  | 'alamogordo'
  | 'newmexico'
  | 'taosmls';

const useGetUserIDP = (): IDP | undefined => {
  const idp = getLastIdp();
  switch (idp) {
    case userManager.idps.associations.ure:
      return 'ure';
    case userManager.idps.associations.glar:
      return 'glar';
    case userManager.idps.platforms.flex_mls:
      return 'flex_mls';
    case userManager.idps.associations.stellar:
      return 'stellar';
    case userManager.idps.associations.trreb:
      return 'trreb';
    case userManager.idps.associations.trreb_amp:
      return 'trreb_amp';
    case userManager.idps.associations.sfaor:
      return 'sfaor';
    case userManager.idps.associations.gaar:
      return 'gaar';
    case userManager.idps.associations.weichert:
      return 'weichert';
    case userManager.idps.associations.nmmls:
      return 'nmmls';
    case userManager.idps.associations.recolorado:
      return 'recolorado';
    case userManager.idps.associations.realmls:
      return 'realmls';
    case userManager.idps.associations.paragon:
      return 'paragon';
    case userManager.idps.associations.bright:
      return 'brightmls';
    case userManager.idps.associations.silvercity:
      return 'silvercity';
    case userManager.idps.associations.alamogordo:
      return 'alamogordo';
    case userManager.idps.associations.newmexico:
      return 'newmexico';
    case userManager.idps.associations.taosmls:
      return 'taosmls';
    case userManager.idps.primeAuth:
      return 'prime';
    case userManager.idps.social.google:
      return 'google';
    case userManager.idps.social.facebook:
      return 'facebook';
    case userManager.idps.social.linkedin:
      return 'linkedIn';
    default:
      return undefined;
  }
};

export default useGetUserIDP;
