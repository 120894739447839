import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useGetEnvelopeQuery from 'hooks/domain/envelope/queries/useGetEnvelopeQuery';

/**
 * Hook for add forms page to get envelope data
 */
const useEnvelopeForAddForms = () => {
  const { envelopeId } = useParams<{ envelopeId?: string }>();
  const parsedId = parseInt(envelopeId || '');
  const {
    data: envelope,
    isInitialLoading: isEnvelopeLoading,
    refetch: refetchEnvelope,
  } = useGetEnvelopeQuery(parsedId);

  return useMemo(
    () => ({
      fileId: envelope?.fileId ?? 0,
      envelopeId: parsedId,
      isEnvelope: !isNaN(parsedId),
      isLoading: envelopeId === 'loading' || isEnvelopeLoading,
      envelope,
      refetchEnvelope,
    }),
    [envelope, envelopeId, isEnvelopeLoading, parsedId, refetchEnvelope]
  );
};

export default useEnvelopeForAddForms;
