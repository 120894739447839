import React from 'react';
import { css, useTheme } from '@mui/material/styles';
import { colors, typography } from '@skyslope/mache';
import cx from 'classnames';

import { useTabsContext } from './Tabs';

import { css as emotionCss } from '@emotion/css';

export interface TabProps {
  path: string;
  label: string;
  isActive?: boolean;
  ariaLabel?: string;
  className?: string;
  onClick?: (path: string) => void;
}

/**
 * Child of Tabs component, this is a generic tab component built without a
 * router library so it can be used across React, Next, etc.
 *
 * @param path {string} URL / path to resource
 * @param label {string} Defines a string value that labels the current element.
 * @param isActive {boolean} Defines whether tab is active
 * React router example: !!matchPath(location.pathname, path)
 * @param ariaLabel {string} Defines a string value that labels the current element.
 * @param className {string} class override
 * @param onClick {function} function to overrride onClick functionality
 * Suggested usage is to pass something like history.push
 */
const Tab: React.FC<TabProps> = ({ path, label, isActive, ariaLabel, className, onClick }) => {
  const theme = useTheme();
  const { onChange } = useTabsContext();
  const typographyStyles = isActive ? (typography.body2 as Record<string, unknown>) : typography.body1;

  return (
    <a
      aria-selected={isActive ?? false}
      aria-label={ariaLabel ?? `Navigate to ${label}`}
      className={cx(
        emotionCss(
          css({
            marginRight: theme.spacing(3),
            border: 0,
            marginTop: 0,
            textDecoration: 'none',
            padding: theme.spacing(0, 0.5),
            backgroundColor: isActive ? 'inherit' : undefined,
            borderBottom: isActive ? `3px solid ${colors.blue[800]}` : 0,
            ...typographyStyles,
            '&:hover': {
              ...typographyStyles,
              backgroundColor: `${colors.blue[50]}`,
            },
          })
        ),
        className
      )}
      id={`app-header-tab-button-${label}`}
      href={path}
      onClick={(e) => {
        onChange?.(path);
        if (onClick) {
          e.preventDefault();
          onClick(path);
        }
      }}
    >
      {label}
    </a>
  );
};

export default Tab;
