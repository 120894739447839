import { Environment } from '@skyslope/auth-js';

// This should only be used to hold us over until a service endpoint solution can be implemented.

const INTEG = 'integ';
const STAGING = 'staging';
const PROD = 'prod';
const DEV = 'dev';
// const dynamicApiPath = ['https://', window.location.host, '/api'].join('');

window.skySlopeCKey = 'SIMVvFC0cW5aRmXMWnBWuSrsLp6oY3utpm8ZD5ciik5ByF-1H74NM-Bgha6VrqZM0nduVB';

window.dataDogClientToken = 'pubd6235522b5169f472f001ffcb77bf6a6';

if (window.location.host.includes('localhost') || window.location.host.includes('ngrok')) {
  window.environmentKey = DEV;
} else if (window.location.host.includes('integ')) {
  window.environmentKey = INTEG;
} else if (window.location.host.includes('staging')) {
  window.environmentKey = STAGING;
} else {
  window.environmentKey = PROD;
}

if (window.environmentKey === STAGING) {
  window.auth = {
    okta: {
      env: Environment.Staging,
      clientId: '0oapx41mjl11K2ust0h7',
      idpDiscovery: {
        enabled: true,
        requestContext: '/home/oidc_client/0oapx41mjl11K2ust0h7/aln5z7uhkbM6y7bMy0g7',
      },
    },
    primeAuth: {
      clientId: 'rOhbKAu7Vk00Pa19Dgwq',
    },
  };
} else if (window.environmentKey === PROD) {
  window.auth = {
    okta: {
      env: Environment.Prod,
      clientId: '0oa25otc9Lfn6YXvH4x6',
      idpDiscovery: {
        enabled: true,
        requestContext: '/home/oidc_client/0oa25otc9Lfn6YXvH4x6/aln177a159h7Zf52X0g8',
      },
    },
    primeAuth: {
      clientId: 'Om2Nc8G2FVxR8RGLTR3O',
    },
    externalLoginUrl: 'https://www.skyslope.com/forms-login',
  };
} else {
  // integ / dev
  window.auth = {
    okta: {
      env: Environment.Integ,
      clientId: '0oa141qbv0UCUoATp357',
      idpDiscovery: {
        enabled: true,
        requestContext: '/home/oidc_client/0oa141qbv0UCUoATp357/aln177a159h7Zf52X0g8',
      },
    },
    primeAuth: {
      clientId: 'lHW6iaX0aojFh4VHhCFf',
    },
  };
}
