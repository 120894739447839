import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import startOfYesterday from 'date-fns/startOfYesterday';

import LocalStorageItem from 'constants/LocalStorageItem';
import { addPopUnder } from 'containers/PopUnder/actions';
import useGetUserSettingsMetaQuery from 'hooks/domain/user/queries/useGetUserSettingsMetaQuery';
import useGetUserSettingsQuery from 'hooks/domain/user/queries/useGetUserSettingsQuery';
import useIsOnboarding from 'hooks/useIsOnboarding';
import useWidgetQueryParams from 'hooks/useWidgetQueryParams';
import type { UserSettings } from 'store/types';
import { FormLibrarySource } from 'store/types';
import { isAppRunningInIframe } from 'utils/isAppRunningInIframe';

interface CarParams {
  exitUrl?: string;
  lastCached?: string;
}
const useCarParams = (userSettings: UserSettings | undefined): CarParams =>
  useMemo(() => {
    const carProfile = userSettings?.authProfiles?.find((p) => p.type === FormLibrarySource.CaNrds);
    const carLastCached = carProfile?.cachedAt;
    const carExitUrl = carProfile?.membershipCache?.exitUrl;

    return {
      lastCached: carLastCached,
      exitUrl: carExitUrl,
    };
  }, [userSettings]);

const useCarExitUrl = (): void => {
  const refetchInterval = 60_000; // make sure this data gets refreshed every minute
  const { data: userSettings } = useGetUserSettingsQuery({
    refetchInterval,
  });
  // meta is needed to trigger re validation
  useGetUserSettingsMetaQuery({
    refetchInterval,
  });
  const { exitUrl, lastCached } = useCarParams(userSettings);
  const { isWidget } = useWidgetQueryParams();
  const isUserInitialized = userSettings?.isInitialized;
  const userId = userSettings?.id;
  const dispatch = useDispatch();
  const isOnOnboardingPage = useIsOnboarding();
  const isInIframe = isAppRunningInIframe();

  useEffect(() => {
    if (exitUrl && lastCached && !isWidget && !isOnOnboardingPage && isUserInitialized && !isInIframe) {
      const localStorageKey = `${LocalStorageItem.CarLastPopUnder}-${userId}`;
      const lastPopUnderItem = localStorage.getItem(localStorageKey);
      const lastPopUnderAt = lastPopUnderItem != null ? new Date(lastPopUnderItem) : startOfYesterday();
      const cachedAt = new Date(lastCached);

      if (cachedAt > lastPopUnderAt) {
        dispatch(addPopUnder(exitUrl, 300));
        localStorage.setItem(localStorageKey, new Date().toUTCString());
      }
    }
  }, [lastCached, exitUrl, dispatch, isUserInitialized, isOnOnboardingPage, userId, isWidget, isInIframe]);
};

export default useCarExitUrl;
