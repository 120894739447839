import { action } from 'typesafe-actions';

import type { FormLibrary } from 'store/types';

export enum OnboardingActions {
  SET_TARGET_LIBRARIES = 'Onboarding/SET_TARGET_LIBRARIES',
}

export const setTargetLibraries = (
  libraries: FormLibrary[]
): { type: OnboardingActions.SET_TARGET_LIBRARIES; payload: FormLibrary[] } =>
  action(OnboardingActions.SET_TARGET_LIBRARIES, libraries);
