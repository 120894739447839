import React from 'react';
import { Link } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { css, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import type { Paths } from 'store/types';

import { css as emotionCss } from '@emotion/css';

interface BreadcrumbsProps {
  breadcrumbs: Paths;
  title: string;
}

const MobileMenuBreadcrumbs: React.FC<BreadcrumbsProps> = ({ breadcrumbs, title }) => {
  const theme = useTheme();

  return (
    <Link
      tabIndex={0}
      to={breadcrumbs[0].path}
      key={breadcrumbs[0].path}
      className={emotionCss(
        css({
          border: 0,
          padding: 0,
          marginTop: 0,
          cursor: 'pointer',
          backgroundColor: '#fff',
          display: 'flex',
          alignItems: 'center',
        })
      )}
    >
      <ArrowBackIosIcon
        sx={{
          width: 24,
          height: 24,
          color: theme.palette.grey[500],
        }}
      />
      <Typography
        variant="body2"
        component="h1"
        sx={{
          paddingLeft: theme.spacing(0.5),
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          maxWidth: '80vw',
        }}
      >
        {title}
      </Typography>
    </Link>
  );
};

export default MobileMenuBreadcrumbs;
