import type React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import type { AuthState } from '@skyslope/auth-js';
import { useAuth } from '@skyslope/auth-react';

import { getIdpByKey } from 'auth';
import { computeTrebbAmpMigrationIdp } from 'auth/userManager';
import FeatureFlagKeys from 'constants/FeatureFlagKeys';
import { routes } from 'constants/routes';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import useQueryParams from 'hooks/useQueryParams';

const Sso: React.FC = () => {
  const { userManager } = useAuth();
  const idpKey: string = useQueryParams().get('idp') ?? '';
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const flags = useFeatureFlags();

  useEffect(() => {
    // Be careful about moving idpMappings outside of useEffect, as it seems to cause excessive renders and network calls
    if (Object.keys(flags).length) setIsLoading(false);
    if (!isLoading) {
      const idp = getIdpByKey(idpKey, userManager);

      userManager.getAuthState().then((authState: AuthState) => {
        if (idp && !authState.isAuthenticated) {
          // Trigger SSO flow if a valid IDP is provided and user is not already authenticated
          if (flags[FeatureFlagKeys.ssoAmp]) {
            //temporary code for trreb SSO migration
            userManager.login({ idp: computeTrebbAmpMigrationIdp(idp, true, userManager) });
          } else {
            userManager.login({ idp });
          }
        } else {
          // Redirect to dashboard by default
          history.push(routes.homeTownBuffet);
        }
      });
    }
  }, [userManager, idpKey, history, flags, isLoading]);

  return null;
};

export default Sso;
