import update from 'immutability-helper';

import type { AppAction, FormLibrary } from 'store/types';

import { OnboardingActions } from './actions';

export interface UserOnboardingPageState {
  targetOptInLibraries: FormLibrary[];
}

export const initialState: UserOnboardingPageState = {
  targetOptInLibraries: [],
};

export default (state: UserOnboardingPageState = initialState, action: AppAction): UserOnboardingPageState => {
  switch (action.type) {
    case OnboardingActions.SET_TARGET_LIBRARIES:
      return update(state, {
        targetOptInLibraries: {
          $set: action.payload,
        },
      });
    default: {
      return state;
    }
  }
};
