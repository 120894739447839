import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import AccountUserServer from 'resources/server/AccountUserServer';
import DigiSignServer from 'resources/server/DigiSignServer';
import type { UserSubscription, UserTrial } from 'resources/types/GetUserSubscriptionSettings';

import useGetMeSubscriptionQuery from './useGetMeSubscriptionQuery';
import useGetUserSettingsQuery from './useGetUserSettingsQuery';

export const userSubscriptionKey = 'user-subscription';

const useGetUserSubscriptionQuery = (
  options: Omit<
    UseQueryOptions<
      { subscription: UserSubscription; trial: UserTrial },
      unknown,
      { subscription: UserSubscription; trial: UserTrial } | undefined,
      (string | undefined)[]
    >,
    'initialData' | 'queryFn' | 'queryKey'
  >
): UseQueryResult<{ subscription: UserSubscription; trial: UserTrial } | undefined> => {
  const { data } = useGetUserSettingsQuery();
  const subscriptionData = useGetMeSubscriptionQuery();
  const isFormsStandaloneUser = data?.subscriberId === null;

  return useQuery({
    queryKey: [userSubscriptionKey, data?.id],
    queryFn: async () => {
      const subscription = await AccountUserServer.getUserSubscriptionSettings(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        data!.id
      );

      let trial: UserTrial | undefined;
      const hasAccessToDs3 = subscriptionData.data?.subscriptions?.some(
        (p) => p.permissions.includes('ds3.*') || p.permissions.includes('forms.*')
      );

      if (!hasAccessToDs3) {
        trial = await DigiSignServer.getAndStartUserTrial();
      }

      return {
        subscription,
        trial,
      };
    },
    enabled:
      (!!data?.id && isFormsStandaloneUser && !!options.enabled && subscriptionData.isFetched && data.isInitialized) ??
      false,
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export default useGetUserSubscriptionQuery;
