import { action } from 'typesafe-actions';

import type { FormVersion } from 'store/types';

export enum SelectFormsActions {
  RESET = 'SelectFormsActions/RESET',
  SET_TOTAL_PAGES = 'SelectFormsActions/SET_TOTAL_PAGES',
  SET_SELECTED_LIBRARY_IDS = 'SelectFormsActions/SET_SELECTED_LIBRARY_IDS',
  SET_SELECTED_FORM_TYPES = 'SelectFormsActions/SET_SELECTED_FORM_TYPES',
  TOGGLE_FILTERS = 'SelectFormsActions/TOGGLE_FILTERS',
  SET_SEARCH_QUERY = 'SelectFormsActions/SET_SEARCH_QUERY',
  ADD_FORM_TO_SELECTION = 'SelectFormsActions/ADD_FORM_TO_SELECTION',
  REMOVE_FORM_FROM_SELECTION = 'SelectFormsActions/REMOVE_FORM_FROM_SELECTION',
}

export const reset = (): { type: SelectFormsActions.RESET } => action(SelectFormsActions.RESET);

export const setSelectedLibraries = (
  ids: number[]
): {
  type: SelectFormsActions.SET_SELECTED_LIBRARY_IDS;
  payload: number[];
} => action(SelectFormsActions.SET_SELECTED_LIBRARY_IDS, ids);

export const setSelectedFormTypes = (
  formTypes: string[]
): {
  type: SelectFormsActions.SET_SELECTED_FORM_TYPES;
  payload: string[];
} => action(SelectFormsActions.SET_SELECTED_FORM_TYPES, formTypes);

export const toggleFilters = (): {
  type: SelectFormsActions.TOGGLE_FILTERS;
} => action(SelectFormsActions.TOGGLE_FILTERS);

export const setSearchQuery = (
  query: string
): {
  type: SelectFormsActions.SET_SEARCH_QUERY;
  payload: string;
} => action(SelectFormsActions.SET_SEARCH_QUERY, query);

export const addFormToSelection = (
  form: FormVersion
): {
  type: SelectFormsActions.ADD_FORM_TO_SELECTION;
  payload: FormVersion;
} => action(SelectFormsActions.ADD_FORM_TO_SELECTION, form);

export const removeFormFromSelection = (
  id: number
): {
  type: SelectFormsActions.REMOVE_FORM_FROM_SELECTION;
  payload: number;
} => action(SelectFormsActions.REMOVE_FORM_FROM_SELECTION, id);
