export interface localStorageUtility {
  /**
   * Adds the key/value pair to localStorage
   * only if key doesn't exist in localStorag
   * @param {string} key
   * @param {string} value
   * @returns
   */
  addItem(key: string, value: string): void;
  /**
   * Adds/Updates the key/value pair to localStorage
   * if key already exists, it is updated
   * @param {string} key
   * @param {string} value
   */
  updateItem(key: string, value: string): void;
  /**
   * Gets the item from localStorage
   * @param {string} key
   */
  getItem(key: string): string | null;
  /**
   * Removes the item from localStorage
   * @param {string} key
   */
  removeItem(key: string): void;
}

export default (): localStorageUtility => ({
  addItem(key: string, value: string) {
    const item = localStorage.getItem(key);
    if (item) return;
    localStorage.setItem(key, value);
  },
  /**
   * Adds/Updates the key/value pair to localStorage
   * if key already exists, it is updated
   * @param {string} key
   * @param {string} value
   */
  updateItem(key: string, value: string) {
    localStorage.setItem(key, value);
  },
  getItem(key: string) {
    return localStorage.getItem(key);
  },
  removeItem(key: string) {
    localStorage.removeItem(key);
  },
});
