import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import GlobalStyles from '@mui/material/GlobalStyles';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { SrOnly } from '@skyslope/mache';

import SkyslopeFormsImg from 'assets/images/ss-apps/SkySlope.svg';
import SkyslopeDefaultImg from 'assets/images/ss-apps/SkySlopeLogoDefault.svg';
import useIsLoginProcessComplete from 'auth/hooks/useIsLoginProcessComplete';
import { TrialBanner } from 'components/TrialBanner/TrialBanner';
import { onboarding, routes } from 'constants/routes';
import { useCanApproveBuyerAgreements } from 'hooks/domain/Roles/queries/useCanApproveBuyerAgreements';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useThemeQueryParam } from 'hooks/useThemeQueryParam';
import type { Paths } from 'store/types';
import { isAppRunningInIframe } from 'utils/isAppRunningInIframe';

import { AppHeaderLinkNames } from '../AppHeader';
import FormsTabs from '../Tabs/FormsTabs';

import BrowseLibrariesNavButton from './BrowseLibrariesNavButton';
import MainMenuAppDropdown from './MainMenuAppDropdown';
import MainMenuBreadcrumbs from './MainMenuBreadcrumbs';
import MainMenuHelpDropdown from './MainMenuHelpDropdown';
import MainMenuUserDropdown from './MainMenuUserDropdown';
import NavMenuItem from './NavMenuItem';

// UX wants the header left menu to line up vertically with the page content
// so manually calculated the max width and set the container margin to auto
const HEADER_MENU_MAX_WIDTH = '1477px';
export interface MainMenuProps {
  title?: string;
  tabs: Paths;
  smallHeader?: boolean;
  breadcrumbs?: { label: string; path: string }[];
}

const debounceWaitMs = 300;
const minScrollToOpenClose = 80;
const openIfAbove = 5;

const MainMenu: React.FC<MainMenuProps> = ({ title, tabs = [], smallHeader = false, breadcrumbs }: MainMenuProps) => {
  const theme = useTheme();
  const themeQueryParam = useThemeQueryParam();
  const [isHideOnScroll, setIsHideOnScroll] = useState(true);
  const { pathname } = useLocation();
  const isLoginProcessComplete = useIsLoginProcessComplete();
  const isOnboarding = !isLoginProcessComplete || onboarding.includes(pathname);
  const isInIframe = isAppRunningInIframe();
  const canApproveBuyerAgreements = useCanApproveBuyerAgreements();
  const flags = useFeatureFlags();

  const shouldHideOnScroll = (): boolean => {
    if (smallHeader) {
      return false;
    }
    return isHideOnScroll;
  };

  useScrollPosition(
    ({ prevPos, currPos }) => {
      let isShow = isHideOnScroll;
      if (currPos.y < openIfAbove) {
        isShow = true;
      } else if (currPos.y > prevPos.y + minScrollToOpenClose) {
        isShow = false;
      } else if (currPos.y < prevPos.y - minScrollToOpenClose) {
        isShow = true;
      }
      if (isShow !== isHideOnScroll) setIsHideOnScroll(isShow);
    },
    [isHideOnScroll],
    undefined,
    true,
    debounceWaitMs
  );

  if (themeQueryParam === 'agnostic') {
    return (
      <AppBar elevation={4} color="inherit" position="sticky" id="desktop-main-menu-app-bar">
        <Toolbar
          sx={{
            paddingLeft: '0px !important',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '78px !important',
          }}
        >
          <img alt="SkySlope" src={SkyslopeDefaultImg} width={146} height={30} />
        </Toolbar>
      </AppBar>
    );
  }

  return (
    <>
      <GlobalStyles
        styles={{
          body: {
            // NOTE: in the long run both of these can be removed for ResizeObserver
            '--header-height': theme.spacing(14),
            '--header-height-with-fallback': theme.spacing(24),
          },
          a: {
            textDecoration: 'none',
          },
        }}
      />
      <AppBar elevation={4} color="inherit" position="sticky" id="desktop-main-menu-app-bar">
        <TrialBanner />
        <Toolbar
          sx={{
            paddingLeft: '0px !important',
            paddingTop: theme.spacing(3),
            display: 'flex',
            alignItems: 'flex-start',
            minHeight: '78px !important',
          }}
        >
          <Box
            component="div"
            sx={{
              position: 'relative',
              marginLeft: theme.spacing(4),
              width: theme.spacing(18.5),
              '& a': {
                position: 'absolute',
                left: 0,
                transition: 'top ease-in-out 0.25s',
                top: !shouldHideOnScroll() ? theme.spacing(0.0) : theme.spacing(-1.5),
              },
            }}
          >
            <Link to={routes.homeTownBuffet} id="files-dashboard-link">
              <SrOnly>Go to files dashboard</SrOnly>
              <img alt="" src={SkyslopeFormsImg} width={120} height={30} />
            </Link>
          </Box>
          <Box
            component="div"
            sx={{
              paddingRight: theme.spacing(4),
              width: '100%',
              minWidth: 0,
              maxWidth: HEADER_MENU_MAX_WIDTH,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <Collapse in={shouldHideOnScroll()}>
              <Box
                component="div"
                sx={{
                  height: theme.spacing(3),
                  paddingRight: theme.spacing(4),
                  marginLeft: theme.spacing(1),
                  marginBottom: theme.spacing(5),
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexGrow: 1,
                }}
              >
                <Box component="div" sx={{ height: theme.spacing(4) }}>
                  <NavMenuItem
                    name={AppHeaderLinkNames.Files}
                    path={routes.homeTownBuffet}
                    width={34}
                    isActive={/dashboard|file-details/.test(pathname)}
                    id="Files-Header-Nav-Button"
                  />
                  <NavMenuItem
                    name={AppHeaderLinkNames.Templates}
                    path={routes.templateManagement}
                    width={76}
                    id="Templates-Header-Nav-Button"
                  />
                  {!isOnboarding && <BrowseLibrariesNavButton />}
                  {flags.isMyClausesEnabled && (
                    <NavMenuItem
                      name={AppHeaderLinkNames.Clauses}
                      path={routes.clauses}
                      width={76}
                      id="Clauses-Header-Nav-Button"
                    />
                  )}
                  {canApproveBuyerAgreements && (
                    <NavMenuItem
                      name={AppHeaderLinkNames.BuyerAgreements}
                      path={routes.buyerAgreements}
                      width={196}
                      id="BuyerAgreements-Header-Nav-Button"
                    />
                  )}
                </Box>
                <Box
                  component="div"
                  sx={{
                    height: theme.spacing(4),
                    marginTop: theme.spacing(-0.75),
                    '& > button': {
                      marginTop: theme.spacing(-0.125),
                      padding: theme.spacing(0.75, 1),
                    },
                  }}
                >
                  {!isOnboarding && (
                    <>
                      <MainMenuHelpDropdown />
                      {!isInIframe && <MainMenuAppDropdown />}
                      <MainMenuUserDropdown />
                    </>
                  )}
                </Box>
              </Box>
            </Collapse>
            {smallHeader && <MainMenuBreadcrumbs breadcrumbs={breadcrumbs} />}
            {!tabs.length && (
              <Box
                component="div"
                sx={
                  smallHeader
                    ? {
                        paddingLeft: theme.spacing(4),
                        paddingBottom: theme.spacing(3),
                      }
                    : {
                        paddingLeft: theme.spacing(4),
                        paddingBottom: theme.spacing(4),
                      }
                }
              >
                {title === '' ? (
                  <Skeleton animation="wave" width={300} height={32} />
                ) : (
                  <Typography
                    sx={
                      smallHeader
                        ? {
                            fontSize: theme.spacing(3),
                            lineHeight: theme.spacing(4), // bug in material-ui
                          }
                        : undefined
                    }
                    variant="h3"
                    component="h1"
                    noWrap
                  >
                    {title}
                  </Typography>
                )}
              </Box>
            )}
            {!!tabs.length && (
              <>
                <Box
                  component="div"
                  sx={{
                    paddingLeft: theme.spacing(4),
                    paddingBottom: theme.spacing(3),
                  }}
                >
                  {title === '' ? (
                    <Skeleton animation="wave" width={300} height={40} />
                  ) : (
                    <Typography variant="h3" component="h1" noWrap>
                      {title}
                    </Typography>
                  )}
                </Box>
                <FormsTabs paths={tabs} />
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default MainMenu;
