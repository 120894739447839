import React from 'react';

import { useFeatureFlags } from 'hooks/useFeatureFlags';
import useIsSmall from 'hooks/useIsSmall';
import type { Paths } from 'store/types';

import MainMenu from './MainMenu/MainMenu';
import MobileMenu from './MobileMenu/MobileMenu';

export enum AppHeaderLinkNames {
  Files = 'Files',
  Templates = 'Templates',
  BrowseLibraries = 'Browse Libraries',
  BuyerAgreements = 'Buyer Agreements',
  Clauses = 'Clauses',
  UserSettings = 'User Settings',
  BrokerManagement = 'Broker Management',
  Apps = 'Apps',
  Help = 'Help',
}

export interface AppHeaderProps {
  title?: string;
  tabs?: Paths;
  spacing?: boolean;
  smallHeader?: boolean;
  breadcrumb?: { label: string; path: string }[];
}

export function AppHeader(props: AppHeaderProps) {
  const { title, tabs = [], spacing = true, smallHeader = false, breadcrumb } = props;
  const isSmall = useIsSmall();
  const flags = useFeatureFlags();

  React.useEffect(() => {
    const headerEl = document.querySelector('header');
    if (headerEl && window.ResizeObserver) {
      document.body.style.setProperty('--header-height-with-fallback', 'var(--header-height)');
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.contentRect && entry.target === headerEl) {
            document.body.style.setProperty('--header-height', `${entry.contentRect.height}px`);
          }
        }
      });
      resizeObserver.observe(headerEl);
      return () => {
        resizeObserver.unobserve(headerEl);
      };
    }
  }, [isSmall]);

  const enabledTabs = tabs?.filter((tab) => {
    const flagState = tab.featureFlag ? flags[tab.featureFlag] : true;
    const flagMethod = tab.featureFlagMethod ? tab.featureFlagMethod() : true;
    return flagState && flagMethod;
  });

  const Menu = isSmall ? MobileMenu : MainMenu;

  return (
    <>
      <Menu title={title} tabs={enabledTabs} smallHeader={smallHeader} breadcrumbs={breadcrumb} />
      {spacing && <div id="app-header-spacing" className="h-6 md:h-14" />}
    </>
  );
}
