// eslint-disable-next-line simple-import-sort/imports
import 'utils/loadenv'; // Needs to be imported first to ensure environment is setup

import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SecurityProvider } from '@skyslope/auth-react';
import formsTheme from 'formsTheme';

import { userManager } from 'auth/userManager';
import store from 'store';

import { ConfirmationDialogServiceProvider } from '../ConfirmationServiceContext';

import FormsAdsProvider from './FormsAdsProvider';
import { ReactQueryProvider } from './ReactQueryProvider';
import { SnackbarProvider } from 'components/Snackbar';

const AppProviders: React.FC = ({ children }) => (
  <SecurityProvider userManager={userManager}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={formsTheme}>
        <SnackbarProvider>
          <ReactQueryProvider>
            <Provider store={store}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <ConfirmationDialogServiceProvider>
                  <FormsAdsProvider>
                    <HelmetProvider>{children}</HelmetProvider>
                  </FormsAdsProvider>
                </ConfirmationDialogServiceProvider>
              </LocalizationProvider>
            </Provider>
          </ReactQueryProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </SecurityProvider>
);

export default AppProviders;
