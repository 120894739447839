import { useCallback, useMemo } from 'react';
import { useAuth } from '@skyslope/auth-react';

import { useFeatureFlags } from 'hooks/useFeatureFlags';

import useGetUserDelegationRequestsQuery from './queries/useGetUserDelegationRequestsQuery';

const useDelegateUser = (ownerId?: string): string | undefined => {
  const { userContext } = useAuth();

  const { isTcAccessAddFormsEnabled } = useFeatureFlags();

  const delegateRequests = useGetUserDelegationRequestsQuery(isTcAccessAddFormsEnabled).data;

  const handleOnBehalfOf = useCallback(
    (ownerId?: string): string => {
      const isDelegateUser = delegateRequests == undefined ? false : delegateRequests?.accessReceivedFrom.length > 0;

      return isDelegateUser && ownerId ? ownerId : userContext?.id ?? '';
    },
    [delegateRequests, userContext?.id]
  );

  return useMemo(() => handleOnBehalfOf(ownerId), [handleOnBehalfOf, ownerId]);
};

export default useDelegateUser;
