import update from 'immutability-helper';

import {
  CALCULATION_TYPE_FLAT,
  COMMISSION_TYPE_LISTING,
  COMMISSION_TYPE_SALE,
  DEFAULT_COMMISSION_AMOUNT,
} from 'constants/Commissions';
import type { AppAction, Commission, Contact, File, HttpResponseStatus, Property } from 'store/types';
import type { Address } from 'types/address';

import type { SetCommissionFieldValue, SetStringValue } from './actions';
import { FileActions } from './actions';

export interface FileState {
  file?: File;
  getFileErrorCode?: HttpResponseStatus;
}

export const initialFileState: FileState = {};

interface CommissionsByType {
  sale: Commission;
  listing: Commission;
}

export const ensureCorrectCommissions = (commissions: Commission[]): Commission[] => {
  const commissionsByType: CommissionsByType = Object.assign(
    {},
    ...commissions.map((c) => ({ [c.type.toLowerCase()]: c }))
  );
  const validCommissions: Commission[] = [];
  const listingCommission: Commission = commissionsByType.listing;
  const saleCommission: Commission = commissionsByType.sale;

  validCommissions.push(
    listingCommission || {
      type: COMMISSION_TYPE_LISTING,
      calculationType: CALCULATION_TYPE_FLAT,
      amount: DEFAULT_COMMISSION_AMOUNT,
    }
  );

  validCommissions.push(
    saleCommission || {
      type: COMMISSION_TYPE_SALE,
      calculationType: CALCULATION_TYPE_FLAT,
      amount: DEFAULT_COMMISSION_AMOUNT,
    }
  );

  return validCommissions;
};

const fileReducer = (state: FileState = initialFileState, action: AppAction): FileState => {
  switch (action.type) {
    case FileActions.GET_FILE: {
      return update(state, { getFileErrorCode: { $set: undefined } });
    }
    case FileActions.GET_FILE_DONE: {
      const fileResponse: File = action.payload;

      return update(state, {
        file: {
          $set: {
            ...fileResponse,
            commissions: ensureCorrectCommissions(fileResponse.commissions),
          },
        },
      });
    }
    case FileActions.GET_FILE_ERROR: {
      return update(state, { getFileErrorCode: { $set: action.payload } });
    }
  }

  if (state.file == null) {
    return state;
  }

  switch (action.type) {
    case FileActions.SAVE_CONTACT: {
      const contact = action.payload;
      const isNewContact = contact.id == null;

      if (isNewContact) {
        return update(state, {
          file: {
            contacts: {
              $push: [contact],
            },
          },
        });
      }

      const index = state.file.contacts.findIndex((c) => c.id === contact.id);

      if (index === -1) {
        return state;
      }

      return update(state, {
        file: {
          contacts: {
            [index]: {
              $set: contact,
            },
          },
        },
      });
    }
    case FileActions.DELETE_CONTACT: {
      const contactId: number = action.payload;

      const index = state.file.contacts.findIndex((c) => c.id === contactId);

      if (index === -1) {
        return state;
      }

      return update(state, {
        file: {
          contacts: {
            $splice: [[index, 1]],
          },
        },
      });
    }
    case FileActions.UPDATE_FILE_DETAILS_DONE: {
      const payload = action.payload;
      const commissions: Commission[] = payload.commissions;

      return update(state, {
        file: {
          contacts: {
            $set: payload.contacts,
          },
          commissions: {
            $set: commissions,
          },
        },
      });
    }
    case FileActions.UPDATE_FILE_DETAILS_ERROR: {
      return update(state, {
        file: {
          contacts: {
            $set: state.file.contacts.filter((item: Contact) => item.id != null),
          },
        },
      });
    }
    case FileActions.SET_PROPERTY: {
      const payload: Address = action.payload;
      const property: Property = {
        streetNumber: payload.streetNumber,
        streetName: payload.streetName,
        unitNumber: payload.unitNumber,
        city: payload.city,
        state: payload.state,
        postalCode: payload.postalCode,
        county: payload.county,
        isCompleteAddress: !!(
          payload.streetNumber &&
          payload.streetName &&
          payload.city &&
          payload.state &&
          payload.postalCode
        ),
      };

      return update(state, {
        file: {
          property: {
            $set: property,
          },
        },
      });
    }
    case FileActions.SET_PROPERTY_FIELD: {
      const payload: SetStringValue = action.payload;
      return update(state, {
        file: {
          property: {
            [payload.fieldName]: {
              $set: payload.value,
            },
          },
        },
      });
    }
    case FileActions.SET_FILE_FIELD: {
      const payload: SetStringValue = action.payload;
      return update(state, {
        file: {
          [payload.fieldName]: {
            $set: payload.value,
          },
        },
      });
    }
    case FileActions.SET_COMMISSION_FIELD: {
      const payload: SetCommissionFieldValue = action.payload;
      const commissionIndex = state.file.commissions.findIndex((c) => c.type === payload.type);
      if (commissionIndex === -1) {
        return state;
      }
      return update(state, {
        file: {
          commissions: {
            [commissionIndex]: {
              [payload.fieldName]: { $set: payload.value },
            },
          },
        },
      });
    }
    default: {
      return state;
    }
  }
};

export default fileReducer;
