import React from 'react';
import { useHistory } from 'react-router';
import { matchPath, useLocation } from 'react-router-dom';

import { useFeatureFlags } from 'hooks/useFeatureFlags';
import type { Paths } from 'store/types';

import Tab from './Tab';
import Tabs from './Tabs';

export interface FormsTabProps {
  paths: Paths;
}

const FormsTabs: React.FC<FormsTabProps> = ({ paths }) => {
  const location = useLocation();

  const history = useHistory();
  const flags = useFeatureFlags();

  return (
    <Tabs>
      {paths
        .filter(({ featureFlag }) => (featureFlag ? flags?.[featureFlag] : true))
        .map(({ path, label }) => {
          const isExternal = path.startsWith('http');
          return (
            <Tab
              key={path}
              path={path}
              label={label}
              onClick={isExternal ? undefined : history.push}
              isActive={!!matchPath(location.pathname, path)}
            />
          );
        })}
    </Tabs>
  );
};

export default FormsTabs;
