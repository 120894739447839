import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Slide from '@mui/material/Slide';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { colors } from '@skyslope/mache';

import useAssignLocation from 'hooks/window/useAssignLocation';

import DialogCloseButton from '../../../../DialogCloseButton';

import MobileMenuRow from './MobileMenuRow';

export interface SubMenuItem {
  name: string;
  link: string;
  icon?: JSX.Element;
  isSelected?: boolean;
  openInNewTab?: boolean;
}

export interface MobileMenuSubMenuProps {
  title: string;
  menuLinks: SubMenuItem[];
  handleDrawerClose: () => void;
  handleBackClick: () => void;
  id: string;
}

const MobileMenuSubMenu: React.FC<MobileMenuSubMenuProps> = ({
  title,
  menuLinks,
  handleDrawerClose,
  handleBackClick,
  id,
}) => {
  const theme = useTheme();
  const assignLocation = useAssignLocation();

  return (
    <Slide direction="left" in>
      <div>
        <Box
          component="div"
          sx={{
            display: 'flex',
            padding: theme.spacing(4),
            justifyContent: 'space-between',
          }}
        >
          <IconButton
            data-spec="back-btn"
            onClick={handleBackClick}
            sx={{ marginLeft: theme.spacing(-2), color: colors.grey[800] }}
            aria-label="Navigate back to main menu"
            size="large"
          >
            <ArrowBackIcon />
          </IconButton>

          <DialogCloseButton absolutePosition={false} onClick={handleDrawerClose} />
        </Box>
        <Typography variant="h6" component="h1" sx={{ paddingLeft: theme.spacing(4), color: colors.grey[700] }}>
          {title}
        </Typography>
        <List>
          {menuLinks.map((menuLink) => (
            <MobileMenuRow
              data-spec={`menu-link-${menuLink.name}`}
              key={menuLink.name}
              onClick={() =>
                menuLink.openInNewTab ? window.open(menuLink.link, '_blank') : assignLocation(menuLink.link)
              }
              name={menuLink.name}
              icon={menuLink.icon}
              isSelected={menuLink.isSelected}
              id={`${id}-menu-link-${menuLink.name}`}
            />
          ))}
        </List>
      </div>
    </Slide>
  );
};

export default MobileMenuSubMenu;
