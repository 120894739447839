import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import MobileMenuRow from './MobileMenuRow';

export interface MobileMenuLinkMenuItemProps {
  name: string;
  path: string;
  icon: JSX.Element;
  isSelected?: boolean;
  handleDrawerClose: () => void;
  id: string;
}

const MobileMenuLinkMenuItem: React.FC<MobileMenuLinkMenuItemProps> = ({
  path,
  name,
  icon,
  isSelected: isSelectedOverride,
  handleDrawerClose,
  id,
}) => {
  const { pathname } = useLocation();
  const isSelected = isSelectedOverride || pathname === path;
  const history = useHistory();

  return (
    <MobileMenuRow
      id={id}
      name={name}
      icon={icon}
      isSelected={isSelected}
      onClick={() => {
        history.push(path);
        handleDrawerClose();
      }}
      ariaLabel={`Navigate to ${name} page`}
    />
  );
};

export default MobileMenuLinkMenuItem;
