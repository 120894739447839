import { useMemo } from 'react';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';

import useCustomSnackbar from '../../hooks/useCustomSnackbar';

export const ReactQueryProvider: React.FC = ({ children }) => {
  const { addErrorSnackbar } = useCustomSnackbar();
  const reactqueryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 1000 * 60 * 5, // data is considered stale (background refetch) after 5 mins (default is 0)
            cacheTime: 1000 * 60 * 10, // data is cached for 10 mins (default is 5 mins )
          },
        },
        queryCache: new QueryCache({
          onError: (error, query) => {
            // React has deprecated onError, onSuccess, onSettled hooks on useQuery https://tkdodo.eu/blog/breaking-react-querys-api-on-purpose
            const errorMessage = query?.meta?.errorMessage;
            if (errorMessage) {
              addErrorSnackbar(errorMessage === true ? undefined : errorMessage);
            }
          },
        }),
      }),
    [addErrorSnackbar]
  );

  return <QueryClientProvider client={reactqueryClient}>{children}</QueryClientProvider>;
};
