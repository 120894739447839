import React from 'react';
import { PrimeAuthLoginCallback } from '@skyslope/auth-react';

import DefaultAppBar from 'pages/ErrorBoundary/components/DefaultAppBar';

import LoginCallbackPlaceholderMessage from '../LoginCallback/LoginCallbackPlaceholderMessage';

const PrimeLoginCallback: React.FC = () => (
  <>
    <DefaultAppBar />
    <LoginCallbackPlaceholderMessage />
    <PrimeAuthLoginCallback />
  </>
);

export default PrimeLoginCallback;
