import useQueryParams from './useQueryParams';

type Theme = 'default' | 'agnostic';

/**
 * Checks if the "theme" query param.
 * This theme is used by Prime when hosting Forms in an iFrame to replace
 * the “Forms” branding with “SkySlope” branding.
 */
export function useThemeQueryParam(): Theme {
  const theme = useQueryParams().get('theme');

  switch (theme as Theme | null) {
    case 'agnostic':
      return 'agnostic';
    default:
      return 'default';
  }
}
