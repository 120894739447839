import type { UserManager } from '@skyslope/auth-js';
import type { AxiosRequestConfig } from 'axios';

import type { FormsUserManager } from 'auth/userManager';
import { userManager } from 'auth/userManager';
import { DIGISIGN_API_URL } from 'constants/environments';
import type { GetPresignedUrlRequest, GetPresignedUrlResponse } from 'resources/types/GetPresignedUrl';
import type { UserTrial } from 'resources/types/GetUserSubscriptionSettings';
import { type SetEmailConfigRequest } from 'resources/types/SetEmailConfig';
import type { Ds3Envelope, Ds3Recipient, FileServiceResult } from 'store/types';

import type { DownloadEnvelopeRequest, DownloadEnvelopeResponse } from '../types/DownloadEnvelope';
import type { GetEnvelopeRequest, GetEnvelopeResponse } from '../types/GetEnvelope';
import type { GetRecipientsRequest, GetRecipientsResponse } from '../types/GetRecipients';
import type { UpdateRecipientPayload } from '../types/UpdateRecipient';

import Server from './Server';

class DigiSignServer extends Server {
  public constructor(manager: UserManager, axiosConfig: AxiosRequestConfig = {}) {
    super(manager, axiosConfig, {
      tokenGetter: (userManager) => (userManager as FormsUserManager).getAccessToken(),
    });
  }

  public async downloadEnvelope(request: DownloadEnvelopeRequest): Promise<DownloadEnvelopeResponse> {
    const resp = await this.api.get<Blob>(
      `${DIGISIGN_API_URL}/envelopes/${request.externalId}/${request.merged ? 'pdf' : 'download'}?api-version=2.0`,
      {
        responseType: 'arraybuffer',
      }
    );

    const documentReturned: DownloadEnvelopeResponse = {
      data: resp.data,
      contentType: resp.headers['content-type'],
    };

    return documentReturned;
  }

  public async getEnvelope(request: GetEnvelopeRequest): Promise<GetEnvelopeResponse> {
    const resp = await this.api.get<FileServiceResult<Ds3Envelope>>(
      `${DIGISIGN_API_URL}/envelopes/${request.externalId}`
    );

    return { envelope: resp.data.result };
  }

  public async getRecipients(request: GetRecipientsRequest): Promise<GetRecipientsResponse> {
    const response = await this.api.get<Ds3Recipient[]>(
      `${DIGISIGN_API_URL}/envelopes/${request.externalId}/recipients?api-version=2.0`
    );

    const recipients: GetRecipientsResponse = {
      recipients: response.data,
    };

    return recipients;
  }

  public async createEmail(request: UpdateRecipientPayload): Promise<number> {
    return (
      await this.api.post(`${DIGISIGN_API_URL}/envelopes/${request.envelopeId}/emails`, {
        recipient: {
          recipientId: request.recipientId,
          isForSender: false,
        },
        emailType: 'ReviewAndSign',
        status: 'Sent',
        isResend: true,
      })
    ).data.result;
  }

  public async updateRecipient(request: UpdateRecipientPayload): Promise<number> {
    return (
      await this.api.patch(
        `${DIGISIGN_API_URL}/envelopes/${request.envelopeId}/recipients/${request.recipientId}?api-version=2.0`,
        {
          firstName: request.updatedContact?.firstName,
          middleName: request.updatedContact?.middleName,
          lastName: request.updatedContact?.lastName,
          email: request.updatedContact?.email,
          role: 'Signer',
        }
      )
    ).data.result;
  }

  public async getPresignedUrl(request: GetPresignedUrlRequest): Promise<string> {
    const response = await this.api.get<GetPresignedUrlResponse>(
      `${DIGISIGN_API_URL}/envelopes/${request.envelopeGuid}/documents/${request.documentGuid}/downloadUrl`
    );

    return response.data.result.preSignedDownloadUrl;
  }

  public async getAndStartUserTrial(): Promise<UserTrial> {
    const response = await this.api.post(`${DIGISIGN_API_URL}/templatesgraphql`, {
      query: `{
      trial {
        endDate
        daysLeftInTrial
      }
    }`,
    });

    return response.data.data.trial;
  }

  /**
   * An endpoint that allows us to configure email-specific
   * properties of an envelope - e,g: attachments, subject, body, emailTemplates
   */
  public async SetEmailConfig(args: { envelopeId: string; request: SetEmailConfigRequest }) {
    const { envelopeId, request } = args;
    const response = await this.api.patch(`${DIGISIGN_API_URL}/envelopes/${envelopeId}/emailConfig`, request);
    return response.data.result;
  }
}

export default new DigiSignServer(userManager);
