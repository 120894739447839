/* eslint-disable @typescript-eslint/no-explicit-any */
import type { SagaIterator } from 'redux-saga';
import { END, eventChannel } from 'redux-saga';
import { put, select, take } from 'redux-saga/effects';

import type { UploadedDocumentProgressEvent } from 'containers/UploadDocuments/store/types';

import type { RootState } from './index';
import type { UploadProgressAction } from './types';

export function* waitForStateToHaveValue<T>(selector: (state: RootState) => T, expectedValue: T): any {
  let stateSlice = yield select(selector);
  while (stateSlice !== expectedValue) {
    yield take();
    stateSlice = yield select(selector);
  }
}

export const createProgressChannel = (requestId: number): [any, (progressEvent: ProgressEvent) => void] => {
  let emit: (progressEvent: UploadedDocumentProgressEvent | END) => void;
  const channel = eventChannel((emitter) => {
    emit = emitter;
    return () => {};
  });
  const onProgress = (progressEvent: ProgressEvent): void => {
    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    emit({ progress, requestId });

    if (progress === 100) {
      emit(END);
    }
  };

  return [channel, onProgress];
};

export function* watchUploadProgress(
  channel: any,
  actionCreator: (progress: number, requestId: number) => UploadProgressAction
): SagaIterator {
  while (true) {
    const data: UploadedDocumentProgressEvent = yield take(channel);
    yield put(actionCreator(data.progress, data.requestId));
  }
}
