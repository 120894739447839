// it's important that this file does not depend on any component code to avoid circular dependencies

export const routes = {
  homeTownBuffet: '/',
  filesDashboard: '/dashboard',
  fileCreate: '/create',
  fillFileForms: '/files/:fileId/fill',
  fileDetailsDocuments: '/file-details/:id/documents',
  fileDetailsEnvelopes: '/file-details/:id/envelopes',
  fileDetailsDetails: '/file-details/:id/details',
  fileDetailsSignedDocuments: '/file-details/:id/signed-documents',
  addFormsToFile: '/files/:fileId/add-forms',
  browseLibraries: '/browse-libraries',
  addFormsToEnvelope: '/envelope/:envelopeId/add-forms',
  templateCreate: '/templates/create',
  templateManagement: '/templates/manage',
  clauses: '/clauses',
  brokerageClauses: '/brokerage-clauses',
  templateType: '/templates/:id/add-type',
  templateDetails: '/templates/:id/details',
  addFormsToTemplate: '/templates/:templateId/add-forms',
  fillTemplateForms: '/templates/:fileId/fill',
  fillEnvelope: '/envelopes/:envelopeId/fill',
  envelopeHistory: '/envelope-history/:fileId/envelope/:envelopeId',
  logout: '/logout',
  loggedOut: '/logged_out',
  sso: '/sso',
  unauthorized: '/unauthorized',
  relinkingError: '/relinkingError',
  authCallback: '/auth/callback',
  primeAuthCallback: '/auth/callback/prime',
  userSettings: '/settings',
  userSettingsProfile: '/settings/profile',
  userSettingsSecurity: '/settings/security',
  userSettingsAssociations: '/settings/associations',
  userSettingsAdditionalInfo: '/settings/associations/additionalInfo',
  userSettingsAuthProfile: '/settings/associations/authProfile',
  userSettingsMyTeam: '/settings/team',
  userSettingsMyTeamAddPeople: '/settings/team/add-people',
  userSettingsPreferences: '/settings/preferences',
  brokerageManagement: '/brokerage-management/people',
  onboardingEmail: `/welcome/email`,
  onboardingRegion: `/welcome/regions`,
  onboardingAssociations: `/welcome/associations`,
  onboardingAuthProfiles: `/welcome/authorization`,
  onboardingNoFormsForState: `/welcome/not-available`,
  onboardingAdditionalInformation: `/welcome/additional-information`,
  widgetOnboardingAssociations: `/welcome/widget/associations`,
  welcomeSkySlope: `/welcome-skyslope`,
  verifyOreaHandler: `/verify/orea-handler`,
  sandbox: `/sandbox`,
  backgroundLoad: `/background-load`,
  clauseCreate: '/clauses/create',
  clauseEdit: '/clauses/:id/edit',
  clauseSharing: '/clauses/:id/details',
  docusignLogin: '/docusign-login',
  subscriptions: '/subscriptions',
  buyerAgreements: '/buyer-agreements',
  fileQuickCreate: '/quick-create',

  // Deprecated routes
  fillDocumentDeprecated: '/file-details/:fileId/fill-documents',
  addFormsToFileDeprecated: '/add-forms/:id',
  login: '/login',
};

interface ParamObject {
  [paramName: string]: string | number;
}

export const getPath = (route: string, params: ParamObject = {}): string => {
  const keys = Object.keys(params);
  if (keys.length === 0) {
    return route;
  }
  return keys.reduce((acc: string, key: string | number) => acc.replace(`:${key}`, `${params[key]}`), route);
};

export const onboarding = [
  routes.onboardingEmail,
  routes.onboardingRegion,
  routes.onboardingAssociations,
  routes.onboardingAuthProfiles,
  routes.onboardingNoFormsForState,
  routes.onboardingAdditionalInformation,
];
