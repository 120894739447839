import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import useLocationQuery from './useLocationQuery';

/**
 * The widget v2 passes metadata via `widgetTrack` query param to the widget iframe.
 * We want to pick the metadata up and pass them to heap as properties.
 */
export const useWidgetTracking = (loaded: boolean) => {
  const q = useLocationQuery();
  const history = useHistory();

  useEffect(() => {
    const widgetTrack = q.get('widgetTrack');

    if (widgetTrack && loaded) {
      // remove it from the query string after using it
      q.delete('widgetTrack');
      history.replace({ search: q.toString() });

      try {
        window.heap?.track('Forms Widget V2 - Iframe Loaded', JSON.parse(widgetTrack));
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
  }, [loaded, q, history]);
};
