import { combineReducers } from 'redux';

import type { FileState } from './file';
import fileReducer, { initialFileState } from './file';

export interface DomainState {
  fileState: FileState;
}

export const initialDomainState = {
  fileState: initialFileState,
};

const domainReducer = combineReducers({
  fileState: fileReducer,
});

export default domainReducer;
