/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import { css, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import type { TermsAndConditionsViewProps } from './store/types';
import { getTermsSx } from './store/types';

import { css as emotionCss } from '@emotion/css';

const ScNrdsTermsAndConditionsView: React.FC<TermsAndConditionsViewProps> = ({ title, inlineView = false }) => {
  const theme = useTheme();
  const termsSx = getTermsSx(theme);
  return (
    <Box right="0" sx={termsSx.contentWrapper} p={inlineView ? 2 : 8}>
      <Box mb={4}>
        <Typography id="typTitle" variant="h4" sx={termsSx.title}>
          {title}
        </Typography>
        <Typography variant="caption">Effective Date: September 1, 2021</Typography>
      </Box>
      <Typography variant="body1">
        NOTICE TO USER: THIS LICENSE AGREEMENT ("AGREEMENT" AND SOMETIMES REFERRED TO AS THE "FORMS USE POLICIES") IS A
        LEGAL AGREEMENT BETWEEN YOU ("USER" OR "YOU") AND THE SOUTH CAROLINA ASSOCIATION OF REALTORS® ("SCR") SETTING
        FORTH THE TERMS AND CONDITIONS UPON WHICH THE 2021 SCR REAL ESTATE FORMS, INCLUSIVE OF ALL AMENDMENTS,
        MODIFICATIONS, CORRECTIONS, AND SUCCESSOR VERSIONS AND DERIVATIVE WORKS (COLLECTIVELY, THE "FORMS" AND
        INDIVIDUALLY, A "FORM"), MAY BE USED.
      </Typography>
      <Typography variant="body1">
        BY PRINTING, DOWNLOADING, OR USING ANY OF THE FORMS, IN WHOLE OR IN PART, AND WHETHER RECEIVED OR OTHERWISE
        OBTAINED DIRECTLY FROM SCR OR A THIRD-PARTY VENDOR (EACH A "VENDOR"), YOU AGREE TO ACCEPT AND BE STRICTLY BOUND
        BY THE TERMS AND CONDITIONS SET FORTH HEREIN.
      </Typography>
      <Typography variant="body1">
        THIS AGREEMENT (AND ANY OTHER AGREEMENT INTO WHICH THIS AGREEMENT IS INCORPORATED AND WHICH IS APPLICABLE TO YOU
        AND THE LICENSE GRANTED HEREUNDER) CONSTITUTES THE COMPLETE AGREEMENT BETWEEN YOU AND SCR RELATIVE TO THE USE OF
        THE 2021 SCR FORMS. THIS AGREEMENT MAY BE UPDATED OR MODIFIED BY SCR, FROM TIME TO TIME, WITH NOTICE TO YOU;
        PROVIDED, HOWEVER, THAT NO MODIFICATION OF THIS AGREEMENT MAY BE ENFORCED AGAINST SCR UNLESS AND UNTIL APPROVED
        BY SCR IN WRITING.
      </Typography>
      <Typography variant="body1">
        IF YOU DO NOT AGREE TO THE TERMS OF THIS AGREEMENT, DO NOT USE THE FORMS OR DOWNLOAD OR OTHERWISE ACCESS THE
        SAME. VIOLATIONS OF THIS AGREEMENT CAN RESULT IN THE LICENSE RIGHTS GRANTED TO YOU BEING SUSPENDED OR REVOKED
        AND SUBJECT THE USER TO LEGAL SANCTIONS AND CLAIMS AND/OR LOSS, SUSPENSION, OR OTHER RESTRICTION OF USER'S
        MEMBERSHIP WITH OR OTHER AUTHORIZATION FROM SCR.
      </Typography>

      <ol>
        <li>
          <Typography variant="h6">Grant and Terms of License; Limitations.</Typography>{' '}
          <Typography component="span" variant="body1">
            SCR grants and User accepts a non-transferable, non-exclusive, and revocable license to use the Forms, only
            so long as and provided that User complies with all of the terms and conditions of this Agreement (the
            "License"). The Forms shall only be used in connection with the operation of User's business as a real
            estate licensee in specific, South Carolina real estate transactions (each a "Transaction").
            <ol className={emotionCss(css(termsSx.lowercaseList))}>
              <li>
                User acknowledges and agrees that the License granted to User herein is limited to the 2021 release of
                the Forms by SCR, as the same may be amended, modified, and revised from time to time.
              </li>
              <li>
                {' '}
                User agrees not to use the 2021 Forms (or earlier versions), in whole or in part, later than the date
                that SCR issues a 2022 release of the Forms (except where such a 2021 Form is already being utilized in
                an ongoing Transaction started before the 2022 release and continuing thereafter, but only for such
                Transaction until completion), at which time the term of this particular License, for the 2021 SCR
                Forms, shall expressly expire and terminate, and User shall execute or otherwise consent to new Forms
                Use Policies pertaining to the 2022 SCR Forms; provided, however, that should User access, download, or
                otherwise use SCR's 2022 Forms (or subsequent releases) without executing or consenting to SCR's Forms
                Use Policies in place for 2022 or thereafter, regardless of the existence of such policies, the full
                protections afforded SCR in this Agreement shall be considered as in full force and effect for such 2022
                Forms and thereafter. The release date of SCR's Forms is identified in the lower right hand corner of
                the applicable Form.
              </li>
              <li>
                {' '}
                User further agrees to use any revised, corrected, modified, or amended SCR Form that is a part of the
                Forms (except that any Form already being used in a specific Transaction may continue to be used in that
                Transaction only to completion). A revised, corrected, modified, or amended Form will be so identified
                in the lower right hand corner of the Form.
              </li>
              <li>
                {' '}
                As of January 1, 2021, the license granted to use any earlier dated release printings of the Forms shall
                be permanently revoked. At that time, for prior versions of the Forms, User shall destroy any unused
                Forms and delete any unused file copy of the Forms in their possession, custody, or control.
              </li>
              <li>
                {' '}
                User may only use the SCR Forms if User is an, and only for so long as User remains an, "Authorized
                User'' as defined hereinafter. An "Authorized User'' shall be any of the following persons: (1) an
                individual person who is a SCR REALTOR® primary or secondary member; or (2) an individual otherwise
                approved in writing by SCR to be an Authorized User for training or educational purposes. Non-Authorized
                Users may not use the SCR
              </li>
              <li>
                {' '}
                Authorized Users shall only be permitted to use the SCR Forms in South Carolina real estate Transactions
                in which the Authorized User is involved as a real estate licensee. The SCR Forms may not be utilized in
                a non-South Carolina Transaction.
              </li>
              <li>
                {' '}
                It shall be a violation of this Agreement for an User or any other Authorized User to do, perform, or
                permit any of the following:
                <ol className={emotionCss(css(termsSx.romanSmallList))}>
                  <li>
                    {' '}
                    to provide any SCR Form to a third party, except as part of a real estate Transaction in which the
                    Authorized User is involved as a licensee or as otherwise provided herein. In all cases where one or
                    more of the SCR Forms are provided to a third party, the Authorized User shall be responsible for
                    ensuring that the third party strictly complies with the terms and conditions of this Agreement.
                  </li>
                  <li>
                    {' '}
                    to provide their National REALTORS® Database System ("NRDS") number or Temporary User Number to a
                    third party or permit a third party to access and use the SCR Forms by entering the login
                    credentials of any Authorized User.
                  </li>
                  <li>
                    {' '}
                    to post the SCR Forms on a website which may be accessed by members of the public or by any other
                    person who is not an Authorized User. However, it shall not be a violation for the SCR Forms used in
                    specific real estate Transactions to be posted on a website that may be accessed only by the parties
                    to that specific real estate Transaction and such other persons involved in providing professional
                    or administrative services for that specific Transaction, so long as such other persons strictly
                    comply with the terms and conditions of this Agreement.
                  </li>
                  <li>
                    {' '}
                    to allow an affiliated licensee of the Authorized User or a licensed real estate assistant of the
                    Authorized User to use the SCR Forms in real estate Transactions in which the licensed assistant or
                    affiliated licensee is either a listing or selling agent or a lease listing or leasing agent, unless
                    the real estate assistant or affiliated licensee has first become an Authorized User.
                  </li>
                </ol>
              </li>
              <li>
                {' '}
                It shall not be a violation of this Agreement for sample copies of the SCR Forms to be used and
                distributed for educational purposes in a classroom or virtual classroom, provided that the body of each
                Form so used is watermarked with a clear, conspicuous disclosure that will plainly appear if the Forms
                are copied or sent by facsimile, which provides "For Educational Purposes Only" or some other similar
                phrase with the same meaning.
              </li>
              <li>
                {' '}
                Notwithstanding any provision to the contrary contained herein, nothing herein shall prohibit an
                Authorized User from using administrative, support, or information technology assistants (hereinafter
                "Support Personnel") from providing assistance to the Authorized User, including accessing the SCR Forms
                in the name of the Authorized User, provided that such use is limited to Transactions in which the
                Authorized User is a real estate licensee and the Support Personnel are not otherwise involved in the
                Transaction as a real estate licensee. Such limited use by Support Personnel shall not be a violation of
                this Agreement.
              </li>
              <li>
                {' '}
                This License is specific to You. Except as otherwise provided herein, no other person or entity,
                including any heir, personal representative, affiliate, subsidiary, parent company, successor, or
                assign, as applicable, of User may exercise any rights as to the License granted under this Agreement,
                without the prior written consent of SCR, which may be given or withheld in SCR's sole and complete
                discretion.
              </li>
              <li>
                {' '}
                Notwithstanding any provision to the contrary contained herein, persons/firms who are not licensed by
                the South Carolina Real Estate Commission ("SCREC") to sell real estate shall not be permitted to
                purchase, access, or use SCR's Forms, except with the express prior written approval of SCR, which may
                be given or withheld in SCR's sole and complete discretion.
              </li>
              <li>
                {' '}
                In the event You learn of any unauthorized use of or access to any of the SCR Forms, You shall
                immediately notify SCR of such unauthorized use or access, the details of the underlying facts and
                circumstances, and the actions taken, or otherwise to be taken, by You to mitigate and prevent further
                unauthorized use and/or access, if any.
              </li>
            </ol>
          </Typography>
        </li>
        <li>
          {' '}
          <Typography variant="h6">SCR's Proprietary Forms & Content: Copyright; Copying.</Typography>
          <Typography component="span" variant="body1">
            <ol className={emotionCss(css(termsSx.lowercaseList))}>
              <li>
                {' '}
                The SCR Forms are proprietary, original works of authorship of SCR, which are protected under United
                States copyright, trademark, and trade secret laws of general applicability, including as to all
                modifications, amendments, and successor versions of the SCR Forms or derivative works thereof. You
                acknowledge and agree that the SCR Forms are valuable intellectual property, created, at great expense
                by SCR.
              </li>
              <li>
                {' '}
                At all times and in all respects, SCR shall retain sole title to, copyright in, full ownership of, and
                all other intellectual property rights in and to the Forms and all parts and subsequent copies,
                versions, and derivative works thereof, together with all modifications, enhancements, and amendments,
                regardless of the media or form in or on which the original and other or subsequent copies may exist.
                SCR reserves all rights not expressly granted herein; as between You and SCR, all rights not granted in
                this Agreement are reserved and maintained for SCR. SCR shall be deemed to be the exclusive owner of the
                Forms and of all rights therein, whether or not any portion thereof is or may be validly copyrighted or
                similarly protected. The License granted herein does not constitute a sale of the copyright to the
                Forms, or give User any ownership interest in the Forms.
              </li>
              <li>
                {' '}
                Unauthorized copying of the Forms is expressly prohibited. You may be held legally responsible for any
                copyright infringement that is caused or encouraged by Your failure to abide by the terms of this
                Agreement. Neither the Forms nor any portion thereof (including any upgraded, updated, or subsequent
                version thereof) may be amended, reverse engineered, used to create derivative works, modified, copied,
                de-compiled, disassembled, reprinted, duplicated, adapted, altered, or recreated, directly or
                indirectly, in whole or in part, without the express prior written consent of SCR, which may be given or
                withheld in SCR's sole and complete discretion.
              </li>
              <li>
                {' '}
                Except as otherwise provided herein, User is expressly prohibited from sharing, distributing, selling,
                transferring, disseminating, or otherwise permitting the use of or provided access to the Forms or any
                reproduction thereof, in any medium whatsoever, with or to any other person or entity. User may
                customize the Forms with User's company logo and with information about User's company, including, but
                not limited to, User's company name, address, telephone number, and/or MLS number. However, under no
                circumstances shall User be permitted or authorized to alter any preprinted matter contained in any Form
                (except by conspicuously drawing a line through or crossing out material in the Form to be deleted so
                that another Authorized User can easily identify such deletions), and User may not remove, alter, or
                modify the SCR seal, logo, trademark, release date, Form number, or copyright information.
              </li>
              <li>
                {' '}
                If User received access to the SCR Forms through the Forms Software or an authorized Vendor's web-based
                program, by virtue of being a SCR member and does not maintain continued membership in SCR, User will
                lose access to the SCR Forms.
              </li>
            </ol>
          </Typography>
        </li>
        <li>
          {' '}
          <Typography variant="h6">DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY; INDEMNIFICATION.</Typography>
          <Typography component="span" variant="body1">
            <ol className={emotionCss(css(termsSx.lowercaseList))}>
              <li className={emotionCss(css(termsSx.underline))}>
                {' '}
                <b>
                  SCR HAS MADE NO WARRANTIES OR REPRESENTATIONS, EXPRESS OR IMPLIED, ABOUT THE SCR FORMS OR OTHERWISE,
                  EXCEPT AS IS SET FORTH IN THIS LICENSE AGREEMENT. THE SCR FORMS ARE PROVIDED ON AN "AS IS," WHERE AS,"
                  AND "AS AVAILABLE" BASIS, ACCORDINGLY. SCR DISCLAIMS ALL WARRANTIES, INCLUDING, BUT NOT LIMITED TO,
                  THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR PARTICULAR PURPOSE. TITLE, ACCURACY AND COMPLETENESS OF
                  INFORMATION OR SERVICES PROVIDED. AND ALL OTHER WARRANTIES OF ANY KIND OR NATURE. FURTHERMORE. SCR
                  DOES NOT WARRANT OR GUARANTEE THAT THE FORMS OR ANY PORTIONS THEREOF, AS MAY BE AMENDED OR OTHERWISE
                  MODIFIED FROM TIME TO TIME, WILL MEET USER'S REQUIREMENTS. ARE ERROR-FREE, LACK VIRUSES. OR WILL
                  ALWAYS COMPLY WITH ALL APPLICABLE LOCAL, STATE, AND FEDERAL LAWS. USER ASSUMES FULL RESPONSIBILITY FOR
                  THE PROPER USE OF ALL SCR FORMS,
                </b>
              </li>
              <li className={emotionCss(css(termsSx.underline))}>
                {' '}
                <b>
                  NEITHER SCR NOR ANY OF ITS AGENTS, EMPLOYEES, OFFICERS, DIRECTORS. MEMBERS, SUCCESSORS, OR ASSIGNS
                  SHALL HAVE ANY LIABILITY TO YOU, ANOTHER AUTHORIZED USER, OR ANY OTHER PERSON FOR ANY CLAIM, LOSS, OR
                  DAMAGE OF ANY KIND OR NATURE WHATSOEVER ARISING OUT OF, RELATED TO, OR IN CONNECTION WITH THE
                  DEFICIENCY OR INADEQUACY OF THE FORMS FOR ANY PURPOSE. WHETHER OR NOT KNOWN OR DISCLOSED TO SCR,
                  NOTWITHSTANDING AND WITHOUT LIMITING THE FOREGOING, UNDER NO CIRCUMSTANCES SHALL SCR BE LIABLE TO YOU.
                  ANOTHER AUTHORIZED USER. OR ANY OTHER PERSON FOR CONSEQUENTIAL. INCIDENTAL. SPECIAL. OR EXEMPLARY
                  DAMAGES ARISING OUT OF OR RELATED TO THIS AGREEMENT. INCLUDING, BUT NOT LIMITED TO, LOST PROFITS OR
                  LOSS OF BUSINESS, EVEN IF APPRISED OF THE LIKELIHOOD OF SUCH DAMAGES OCCURRING.
                </b>
              </li>
              <li className={emotionCss(css(termsSx.underline))}>
                {' '}
                <b>
                  USER HEREBY AGREES TO INDEMNIFY AND HOLD SCR HARMLESS FROM ANY AND ALL ALLEGED DAMAGES, COSTS.
                  EXPENSES, OR OTHER LIABILITIES OF ANY KIND OR NATURE WHICH MAY RESULT FROM A REVOCATION OF USER'S
                  LICENSE RIGHTS HEREIN OR A BREACH OF ANY REPRESENTATION OR WARRANTY MADE BY USER HEREIN.
                </b>
              </li>
              <li>
                {' '}
                The Forms are not a substitute for the independent professional judgment of User as to the
                appropriateness of a Form or Forms in different situations and/or Transactions. The Forms do not
                constitute legal or real estate advice and should be reviewed in full and in advance by User's legal
                counsel or the legal counsel of the parties in a real estate Transaction in which they are used and
                permission for such a review by counsel is hereby granted by SCR as part of the License granted.
              </li>
            </ol>
          </Typography>
        </li>
        <li>
          {' '}
          <Typography variant="h6">Your Representations.</Typography>
          <Typography component="span" variant="body1">
            {' '}
            You represent and warrant as follows:
            <ol className={emotionCss(css(termsSx.lowercaseList))}>
              <li>
                {' '}
                All of Your agreements, representations, and warranties made in this Agreement are true, accurate, and
                complete, in all material respects, at all times while You utilize any of the SCR Forms (inclusive of
                all amendments, modifications, corrections, subsequent and successor versions, and derivative works) and
                in regards to all Transactions.
              </li>
              <li> You are eighteen (18) years or older.</li>
              <li> You have full power and authority to agree to this Agreement and its terms.</li>
              <li>
                {' '}
                You are fully able and competent to agree to and satisfy the terms of this Agreement, which You do so
                voluntarily, according to Your own free will, and without any coercion, undue influence, threat, or
                intimidation of any kind or type whatsoever.
              </li>
              <li>
                {' '}
                Your use of the SCR Forms will be in compliance with all applicable laws and regulations, and You will
                not use the SCR Forms for any unlawful purpose.
              </li>
            </ol>
          </Typography>
        </li>
        <li>
          {' '}
          <Typography variant="h6">Confidentiality.</Typography>
          <Typography component="span" variant="body1">
            User acknowledges that the Forms contain confidential and proprietary information of SCR, disclosed to User
            on a confidential basis. User hereby covenants and agrees that during the term of this Agreement and for all
            times thereafter, User shall not, except in accordance with the terms of this Agreement or after obtaining
            the express prior written consent of SCR, publish, disclose, divulge, sell, assign, give, or otherwise
            transfer or release, any confidential and proprietary information of SCR or the Forms, in whole or in part,
            alone or in combination with any other information, to any person for User's own benefit or the benefit of
            any person other than SCR.
          </Typography>
        </li>
        <li>
          {' '}
          <Typography variant="h6">Violations.</Typography>
          <Typography component="span" variant="body1">
            In the event User violates this Agreement, User shall be subject to any or all of the following sanctions
            and penalties, at the sole discretion of SCR:
            <ol className={emotionCss(css(termsSx.lowercaseList))}>
              <li> SCR may terminate or suspend the User's right to use the SCR Forms;</li>
              <li>
                {' '}
                SCR may terminate, suspend, or otherwise restrict User's membership with, or other authorization
                received from, SCR; and/or
              </li>
              <li>
                {' '}
                SCR may pursue any claim or cause of action available to it against the User, whether civil or criminal,
                or in law or in equity, arising out of or relating to such violation.
              </li>
            </ol>
          </Typography>
        </li>
        <li>
          {' '}
          <Typography variant="h6">General Provisions.</Typography>
          <Typography component="span" variant="body1">
            <ol className={emotionCss(css(termsSx.lowercaseList))}>
              <li>
                {' '}
                Non-Exclusivity. User acknowledges and agrees that the relationship between User and SCR is a
                non-exclusive relationship and that SCR is free to enter into a similar relationship with other
                Authorized Users and other third parties.
              </li>
              <li>
                {' '}
                Assignment. Neither User nor any heir, personal representative, affiliate, subsidiary, parent company,
                successor, or assign, as applicable. shall sell, trade, assign, sublicense, or in any way transfer this
                Agreement or the limited License provided to User hereunder to any other individual or entity. SCR may
                assign its rights and responsibilities in this Agreement to a SCR affiliate, subsidiary, successor, or
                assign, at the sole and complete discretion of SCR.
              </li>
              <li>
                {' '}
                Notice. All notices and other communications allowed or required to be delivered to SCR hereunder shall
                be in writing and shall be deemed duly given, delivered, and received, as of the day of mailing. if:
                hand delivered; sent by certified mail, return receipt requested, postage prepaid; or by overnight
                express delivery service (e.g., Federal Express or UPS Next Day Air), in each instance addressed as set
                forth below; provided, however, SCR may change the address to which notices and other communications
                hereunder are to be delivered by giving User notice in the manner herein set forth or by updating such
                notice address in subsequent Forms Use Policies: 3780 Fernandina Road, Columbia, SC 29210, Attn: CEO.
              </li>
              <li>
                {' '}
                No Waiver. The waiver by SCR of, or the failure of SCR to take action with respect to, any breach of any
                term. covenant, or condition herein contained shall not be deemed to be a waiver of such term, covenant,
                or condition, or subsequent breach of the same, or any other term, covenant, or condition contained in
                the Agreement.
              </li>
              <li>
                {' '}
                Entire Agreement; Integration: Amendments. This Agreement represents the entire agreement between User
                and SCR. There are no oral agreements, understandings, or communications, of any kind, with respect to
                the subject matter contained herein that are not stated in this Agreement. No statement, warranty, or
                representation not contained herein shall be part of this Agreement. This Agreement supersedes, and the
                terms of this Agreement govern, all previous oral and written communications regarding these matters.
                This Agreement may be updated or modified by SCR, from time to time, with notice to User; provided.
                however. that no modification of this Agreement may be enforced against SCR unless and until approved by
                SCR in writing. The terms of this Agreement are contractual and are not a mere recital.
              </li>
              <li>
                {' '}
                Headings: Construction. The headings and captions used herein are for convenience only and do not in any
                way limit or amplify the terms and provisions of this Agreement. The language in all parts of this
                Agreement shall be, in all cases and respects, construed according to its fair meaning and not strictly
                for or against any one of either User or SCR. User and SCR, collectively, shall be deemed to have
                drafted this Agreement.
              </li>
              <li>
                {' '}
                Relationship Between User & SCR. User has no authority to bind or commit SCR in any matter or respect.
                Nothing in this Agreement shall be construed or deemed to create a joint venture, partnership. agency,
                or any other legal or employment relationship, except that as parties to this Agreement.
              </li>
              <li>
                {' '}
                Governing Law: Venue. This Agreement shall be construed and governed, in all respects, in accordance
                with the laws of the State of South Carolina. User and SCR voluntarily submit to the jurisdiction of
                state courts located in the State of South Carolina, County of Lexington. or the nearest South Carolina
                federal district court thereto, which shall be the venue as to all matters and/or disputes relating to
                or arising from this Agreement. Without limiting the foregoing, both User and SCR irrevocably and
                unconditionally agree not to assert: (a) an objection to venue; (b) any claim of inconvenient forum; and
                (c) any claim that the South Carolina courts referenced herein do not have jurisdiction.
              </li>
              <li className={emotionCss(css(termsSx.underline))}>
                {' '}
                WAIVER OF JURY TRIAL. BOTH USER AND SCR IRREVOCABLY AND UNCONDITIONALLY WAIVE ANY RIGHT TO A TRIAL BY
                JURY AND AGREE THAT EITHER USER OR SCR MAY FILE A COPY OF THIS PARAGRAPH WITH ANY COURT AS WRITTEN
                EVIDENCE OF THE KNOWING. VOLUNTARY AND BARGAINED-FOR AGREEMENT AMONG USER AND SCR IRREVOCABLY TO WAIVE
                THEIR RIGHT TO TRIAL BY JURY IN ANY LITIGATION.
              </li>
              <li>
                {' '}
                Equitable Relief. User and SCR acknowledge and agree that the terms, covenants, restrictions, and
                affirmative obligations set forth in this Agreement do not impose any undue hardship or unreasonable
                burden on them. Additionally. User and SCR acknowledge and agree that these same terms. covenants,
                restrictions, and affirmative obligations are commercially reasonable and necessary and, further, that
                any breach or threatened breach of these terms. covenants, restrictions, and/or affirmative obligations
                will cause immediate and irreparable harm, which could not be adequately remedied through the payment of
                monetary damages. As such, if any breach or threatened breach occurs, then the non-breaching party shall
                be entitled to injunctive relief (without the posting of a bond or similar security, which the parties
                expressly waive such right to the posting of a bond), in addition to such other legal and equitable
                remedies that may be available, including, but not limited to, specific performance.
              </li>
              <li>
                {' '}
                Severability. The invalidity or unenforceability of any provision of this Agreement shall not affect the
                validity or enforceability of any other provision of this Agreement, which shall remain in full force
                and effect.
              </li>
              <li>
                {' '}
                Attorneys' Fees. In the event that any party institutes any legal suit, action, or proceeding against
                the other party to enforce the conditions and covenants contained in this Agreement or obtain any other
                remedy in respect of any breach arising out of or relating to this Agreement, the prevailing party in
                the suit, action, or proceeding shall be entitled to receive, in addition to all other damages to which
                it may be entitled, the costs incurred by such party in conducting the suit, action, or proceeding,
                including reasonable attorneys' fees and expenses and court costs.
              </li>
              <li>
                {' '}
                Successors: Third Party Rights. This Agreement shall be binding upon and inure to the benefit of the
                respective and permitted successors, assigns, and legal representatives of User and SCR. Nothing in this
                Agreement shall create or be deemed to create any third-party beneficiary rights in any person or entity
                not a party to this Agreement.
              </li>
              <li>
                {' '}
                Cooperation. User agrees to cooperate fully and execute any and all supplementary documents and to take
                all additional actions that may be necessary or appropriate to give full force and effect to the terms
                and intent of this Agreement and the protection of SCR's ownership of and rights in and to the Forms.
              </li>
            </ol>
          </Typography>
        </li>
      </ol>
      <Typography variant="body1">
        USER HEREBY EXPRESSLY ACKNOWLEDGES THAT USER HAS HAD AMPLE TIME TO AND HAS IN FACT CONSULTED WITH THEIR
        ATTORNEY(S), IF SO DESIRED, CONCERNING THE TERMS, CONDITIONS, AND CONSEQUENCES OF THIS AGREEMENT AND THAT USER
        HAS READ AND FULLY UNDERSTANDS THE SAME. IF USER DOES NOT AGREE TO EACH AND ALL OF THESE TERMS, USER SHOULD NOT
        USE, DOWNLOAD, OR OTHERWISE ACCESS SCR'S FORMS.
      </Typography>
    </Box>
  );
};

export default ScNrdsTermsAndConditionsView;
