import { useEffect, useState } from 'react';
import { Datacenter, datadogLogs } from '@datadog/browser-logs';
import { useAuth } from '@skyslope/auth-react';

import useGetUserIDP from 'auth/hooks/useGetUserIDP';

export default (): void => {
  const [isInitialized, setIsInitialized] = useState(false);
  const { userContext } = useAuth();
  const idp = useGetUserIDP();

  useEffect(() => {
    if (!isInitialized) {
      datadogLogs.init({
        clientToken: window.dataDogClientToken,
        datacenter: Datacenter.US,
        forwardErrorsToLogs: true,
        sampleRate: 100,
        service: 'files-ui',
        env: window.environmentKey,
      });
      setIsInitialized(true);
    } else {
      datadogLogs.addLoggerGlobalContext('service', 'files-ui');
      datadogLogs.addLoggerGlobalContext('userId', userContext?.id);
      datadogLogs.addLoggerGlobalContext('email', userContext?.email);
      datadogLogs.addLoggerGlobalContext('idp', idp);
      datadogLogs.addLoggerGlobalContext('subscriberId', userContext?.prime?.subscriberId);
    }
  }, [userContext, idp, isInitialized]);
};
