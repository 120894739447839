import { action } from 'typesafe-actions';

export enum EnvelopeHistoryActions {
  GET_ENVELOPE_HISTORY = 'EnvelopeHistoryActions/GET_ENVELOPE_HISTORY',
  GET_ENVELOPE_HISTORY_DONE = 'EnvelopeHistoryActions/GET_ENVELOPE_HISTORY_DONE',
  DOWNLOAD_ENVELOPE_CERTIFICATE = 'EnvelopeHistoryActions/DOWNLOAD_ENVELOPE_CERTIFICATE',
  DOWNLOAD_ENVELOPE_CERTIFICATE_DONE = 'EnvelopeHistoryActions/DOWNLOAD_ENVELOPE_CERTIFICATE_DONE',
}

export const getEnvelopeHistory = (
  id: number
): {
  type: EnvelopeHistoryActions.GET_ENVELOPE_HISTORY;
  payload: number;
} => action(EnvelopeHistoryActions.GET_ENVELOPE_HISTORY, id);

export const downloadEnvelopeCertificate = (
  externalId: string
): {
  type: EnvelopeHistoryActions.DOWNLOAD_ENVELOPE_CERTIFICATE;
  payload: string;
} => action(EnvelopeHistoryActions.DOWNLOAD_ENVELOPE_CERTIFICATE, externalId);
