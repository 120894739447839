import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { css, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import useMenuItemStyles from './MenuItemStyles';

import { css as emotionCss } from '@emotion/css';

interface NavMenuItemProps {
  name: string;
  path: string;
  width: number;
  id: string;
  isActive?: boolean;
}

const NavMenuItem: React.FC<NavMenuItemProps> = ({ path, name, width, id, isActive: isActiveOverride }) => {
  const theme = useTheme();
  const classes = useMenuItemStyles(theme);
  const { pathname } = useLocation();
  const isActive = isActiveOverride || pathname === path;

  return (
    <Link tabIndex={0} to={path}>
      <Typography
        variant="body1"
        sx={{
          ...classes.menuItem,
          ...(isActive ? classes.menuItemSelected : undefined),
          display: 'inline-block',
        }}
        style={{ width }}
        className={emotionCss(css(classes.link))}
        id={id}
      >
        {name}
      </Typography>
    </Link>
  );
};

export default NavMenuItem;
