import update from 'immutability-helper';

import type {
  UploadDocumentFailed,
  UploadDocumentPayload,
  UploadDocumentResponse,
} from 'resources/types/UploadDocuments';
import type { AppAction, UploadProgressPayload } from 'store/types';

import { UploadDocumentsActions } from './actions';
import type { UploadedDocument } from './types';

export interface UploadDocumentsState {
  uploadedDocuments: UploadedDocument[];
}

export const initialState: UploadDocumentsState = {
  uploadedDocuments: [],
};

const uploadDocumentsReducer = (
  state: UploadDocumentsState = initialState,
  action: AppAction
): UploadDocumentsState => {
  switch (action.type) {
    case UploadDocumentsActions.UPLOAD_DOCUMENT: {
      const payload: UploadDocumentPayload = action.payload;
      const existingDocumentIndex = state.uploadedDocuments.findIndex((doc) => doc.requestId === payload.requestId);
      if (existingDocumentIndex === -1) {
        return update(state, {
          uploadedDocuments: {
            $push: [
              {
                requestId: payload.requestId,
                name: payload.documentName,
                document: payload.document,
              },
            ],
          },
        });
      }

      return update(state, {
        uploadedDocuments: {
          [existingDocumentIndex]: {
            didUploadFail: {
              $set: false,
            },
            progress: {
              $set: undefined,
            },
          },
        },
      });
    }
    case UploadDocumentsActions.UPLOAD_DOCUMENT_PROGRESS: {
      const payload: UploadProgressPayload = action.payload;
      return update(state, {
        uploadedDocuments: (docs) => {
          const index = docs.findIndex((doc) => doc.requestId === payload.requestId);
          docs[index].progress = payload.progress;

          return docs.concat();
        },
      });
    }
    case UploadDocumentsActions.UPLOAD_DOCUMENT_DONE: {
      const payload: UploadDocumentResponse = action.payload;
      return update(state, {
        uploadedDocuments: (uploadedDocuments: UploadedDocument[]): UploadedDocument[] =>
          uploadedDocuments.map((document) => {
            if (document.requestId === payload.requestId) {
              document.id = payload.id;
            }
            return document;
          }),
      });
    }
    case UploadDocumentsActions.UPLOAD_DOCUMENT_ERROR: {
      const payload: UploadDocumentFailed = action.payload;
      const uploadedDocumentIndex = state.uploadedDocuments.findIndex((doc) => doc.requestId === payload.requestId);

      if (uploadedDocumentIndex === -1) {
        return state;
      }

      return update(state, {
        uploadedDocuments: {
          [uploadedDocumentIndex]: {
            didUploadFail: {
              $set: true,
            },
          },
        },
      });
    }
    case UploadDocumentsActions.CLEAR_DOCUMENTS: {
      return update(state, {
        uploadedDocuments: {
          $set: [],
        },
      });
    }
    case UploadDocumentsActions.REMOVE_DOCUMENT: {
      return update(state, {
        uploadedDocuments: (docs) => docs.filter((doc) => doc.requestId !== action.payload),
      });
    }
    default: {
      return state;
    }
  }
};

export default uploadDocumentsReducer;
