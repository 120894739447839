import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import EnvelopeServer from 'resources/server/EnvelopeServer';
import type { Envelope } from 'store/types';

export const getEnvelopeQueryKey = 'getEnvelope';

const useGetEnvelopeQuery = (
  envelopeId: number,
  config?: Omit<
    UseQueryOptions<Envelope, unknown, Envelope, (string | number)[]>,
    'initialData' | 'queryFn' | 'queryKey'
  >
): UseQueryResult<Envelope> =>
  useQuery({
    queryKey: [getEnvelopeQueryKey, envelopeId],
    queryFn: () => EnvelopeServer.GetEnvelope(envelopeId),
    enabled: !isNaN(envelopeId) && envelopeId >= 0 && (config?.enabled ?? true),
    ...config,
  });

export default useGetEnvelopeQuery;
