import React, { useCallback, useContext, useEffect, useState } from 'react';
import merge from 'lodash.merge';

import useIsSkySlopeMobileApp from 'hooks/useIsSkySlopeMobileApp';
import type { Paths } from 'store/types';
import type { ValueOf } from 'types/helpers';

import { AppHeader } from './AppHeader';
import LayoutContext from './Context';

interface LayoutProviderApi {
  setLayoutSection: (section: keyof LayoutConfig, value: Partial<ValueOf<LayoutConfig>>) => void;
}

/**
 * This is the proper hook to dynamically alter the layout/header
 *
 * @param layoutConfig New config changes that do NOT need to include old/previous state
 * @returns New Layout
 */
export const useLayout = (layoutConfig?: LayoutConfig): LayoutProviderApi => {
  const { layout, updateLayout } = useContext(LayoutContext);

  useEffect(() => {
    if (layoutConfig && updateLayout) {
      updateLayout(layoutConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setLayoutSection = useCallback(
    (section: keyof LayoutConfig, value: Partial<ValueOf<LayoutConfig>>): void => {
      if (!layout) {
        updateLayout?.({ [section]: value });
        return;
      }

      if (!layout[section]) {
        layout[section] = {};
      }
      const newLayout: LayoutConfig = {
        ...layout,
        [section]: { ...layout[section], ...value },
      };
      updateLayout?.(newLayout);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return { setLayoutSection };
};

export interface LayoutConfig {
  header?: {
    title?: string;
    tabs?: Paths;
    spacing?: boolean;
    hidden?: boolean;
    isDistractionFree?: boolean;
    breadcrumb?: { label: string; path: string }[];
  };
}

const defaultLayoutConfig: LayoutConfig = {
  header: { title: '', tabs: [], spacing: true, hidden: true },
};

/**
 * If you want to dynamically alter this component use the useLayout hook
 * @see useLayout
 */
const LayoutProvider: React.FC = ({ children }) => {
  const isMobileApp = useIsSkySlopeMobileApp();
  const [layout, setLayout] = useState(defaultLayoutConfig);
  const updateLayout = (updates: LayoutConfig, isFresh = false): void => {
    if (!isFresh) {
      setLayout({ ...merge(layout, updates) });
    } else {
      setLayout(updates);
    }
  };
  // header is always hidden if web loaded in mobile
  const isHeaderHidden = layout.header?.hidden === undefined ? isMobileApp : layout.header.hidden;

  return (
    <LayoutContext.Provider value={{ layout, updateLayout }}>
      {!isHeaderHidden && (
        <AppHeader
          title={layout.header?.title}
          tabs={layout.header?.tabs}
          spacing={layout.header?.spacing}
          smallHeader={layout.header?.isDistractionFree}
          breadcrumb={layout.header?.breadcrumb}
        />
      )}
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutProvider;
