import type { UserManager } from '@skyslope/auth-js';

import { userManager } from 'auth';
import { FILE_API_URL } from 'constants/environments';
import type { FileSummary, FileSummaryPage } from 'pages/FilesDashboard/store/types';
import type { BrokerTemplateDocumentDetail, FileServiceResult } from 'store/types';

import type { UploadDocumentRequest } from './DocumentServer';
import type { PatchFileV3Request, PatchFileV3Response } from './FileServer';
import Server from './Server';

class BrokerTemplateServer extends Server {
  public constructor(manager: UserManager) {
    super(manager);
  }

  public async GetBrokerTemplateDocuments(
    brokerTemplateId: number,
    onBehalfOf?: string
  ): Promise<BrokerTemplateDocumentDetail> {
    const params = new URLSearchParams();
    if (onBehalfOf) {
      params.append('onBehalfOf', onBehalfOf);
    }
    return (
      await this.api.get<FileServiceResult<BrokerTemplateDocumentDetail>>(
        `${FILE_API_URL}/broker-templates/${brokerTemplateId}/documents`,
        { params }
      )
    ).data.result;
  }

  public async GetBrokerTemplates(): Promise<FileSummary[]> {
    return (await this.api.get<FileServiceResult<FileSummaryPage>>(`${FILE_API_URL}/broker-templates`)).data.result
      .files;
  }

  public async UploadDocumentToBrokerTemplate(request: UploadDocumentRequest): Promise<number> {
    const formData = new FormData();
    formData.append('documentName', request.documentName);
    formData.append('documentBody', request.document);
    if (request.onBehalfOf) formData.append('onBehalfOf', request.onBehalfOf ?? '');
    if (request.isSignedDocument) formData.append('isSignedDocument', request.isSignedDocument.toString());

    return (
      await this.api.post(`${FILE_API_URL}/broker-templates/${request.fileId}/documents/upload`, formData, {
        onUploadProgress: request.onProgress,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
    ).data.result;
  }

  public async PatchBrokerTemplate(request: PatchFileV3Request): Promise<PatchFileV3Response> {
    return (
      await this.api.patch<FileServiceResult<PatchFileV3Response>>(
        `${FILE_API_URL}/broker-templates/${request.fileId}`,
        request.payload
      )
    ).data.result;
  }
}

export default new BrokerTemplateServer(userManager);
