import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { popUnderSettingsSelector } from 'containers/PopUnder/store';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import useAssignLocation from 'hooks/window/useAssignLocation';
import { getDocumentUrl, isPopupWindowOpen } from 'utils/windowUtils';

const usePopUnder = (): void => {
  const popUnderSettings = useSelector(popUnderSettingsSelector);
  const popUnderUrl = popUnderSettings?.popUnderUrl;
  const delayMs = popUnderSettings?.delayMs;
  const assignLocation = useAssignLocation();
  const { enablePopUnder = false } = useFeatureFlags();

  const popupSuccessHandler = useCallback(() => {
    setTimeout(() => {
      if (popUnderUrl) {
        assignLocation(popUnderUrl);
      }
    }, delayMs);
  }, [assignLocation, delayMs, popUnderUrl]);

  const popupFailedHandler = useCallback(() => {
    const currentURL = getDocumentUrl();
    const delayForReactRouterChange = 300;

    setTimeout(() => {
      const clickResultedInNavigation = getDocumentUrl() !== currentURL;
      if (popUnderUrl && clickResultedInNavigation) {
        window.open(getDocumentUrl());
        assignLocation(popUnderUrl);
      }
    }, delayForReactRouterChange);
  }, [assignLocation, popUnderUrl]);

  useEffect(() => {
    if (popUnderUrl && enablePopUnder) {
      const popupWindow = window.open(getDocumentUrl());
      const isPopupOpen = isPopupWindowOpen(popupWindow); // browsers block non user initiated popups sometimes

      if (isPopupOpen) {
        popupSuccessHandler();
      } else {
        // next time user clicks anywhere, do popunder (browsers should not block popup because it's from the result of a user click)
        const root = document.getElementById('root');
        root?.addEventListener('click', popupFailedHandler);

        return () => {
          root?.removeEventListener('click', popupFailedHandler);
        };
      }
    }
  }, [delayMs, enablePopUnder, popUnderUrl, popupFailedHandler, popupSuccessHandler]);
};

export default usePopUnder;
