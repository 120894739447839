import type { UseMutationResult } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { AxiosError } from 'axios';

import type { UpdateAuthProfileRequest } from 'resources/server/UserProfileServer';
import UserProfileServer from 'resources/server/UserProfileServer';

import { userMetaKey } from '../queries/useGetUserSettingsMetaQuery';
import { userSettingsKey } from '../queries/useGetUserSettingsQuery';

const useUpdateAuthProfileMutation = (): UseMutationResult<
  string | undefined,
  AxiosError,
  UpdateAuthProfileRequest,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: UserProfileServer.UpdateAuthProfile.bind(UserProfileServer),
    onSuccess: () => {
      queryClient.invalidateQueries([userSettingsKey]);
      // auth profiles drive a lot of logic in the app and we should not use cached versions after updates
      // so removeQueries instead of invalidate
      queryClient.removeQueries([userMetaKey]);
    },
  });
};

export default useUpdateAuthProfileMutation;
