export const isPopupWindowOpen = (poppedWindow: Window | null): boolean => {
  if (!poppedWindow) return false;
  try {
    // Popup window should already be in focus, so this doesn't do anything but if the window doesn't exist an exception will be thrown
    poppedWindow.focus();
    return true;
  } catch (e) {
    return false;
  }
};

// used for ease of testing
export const getDocumentUrl = (): string => window.document.URL;
