import { useHasLibraries } from 'components/Layout/Redesign/useHasLibraries';
import { routes } from 'constants/routes';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

import { AppHeaderLinkNames } from '../AppHeader';

import NavMenuItem from './NavMenuItem';

const BrowseLibrariesNavButton = () => {
  const { isViewFormsWithNoFileEnabled } = useFeatureFlags();
  const hasLibraries = useHasLibraries();

  if (!isViewFormsWithNoFileEnabled || !hasLibraries) return null;

  return (
    <NavMenuItem
      name={AppHeaderLinkNames.BrowseLibraries}
      path={routes.browseLibraries}
      width={124}
      id="Browse-Libraries-Header-Nav-Button"
    />
  );
};

export default BrowseLibrariesNavButton;
