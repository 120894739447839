import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { routes } from 'constants/routes';
import AuthProfileVerificationServer from 'resources/server/AuthProfileVerificationServer';

const OREA_LOGIN_URL = 'https://connect.orea.com/oreaextsvc/authgateway/redirecttonaflogin';

const OREA_LOGOUT_URL = 'https://secure.realtorlink.ca/naflogin/logoff.aspx';

export interface UserOreaAuthFlowResult {
  login: (isinNewTab?: boolean) => Window | null;
  logout: () => void;
  baseUrl: string;
  isLoading: boolean;
  isLoggingOut: boolean;
}

const useOreaAuthFlow = (): UserOreaAuthFlowResult => {
  const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false);
  const location = window.location;
  const baseUrl = `${location.protocol}//${location.hostname}${location.port ? `:${location.port}` : ''}`;
  const handlerUrl = `${baseUrl}${routes.verifyOreaHandler}`;

  const { data: serviceToken, isInitialLoading } = useQuery<string>({
    queryKey: ['orea-svc-token'],
    queryFn: AuthProfileVerificationServer.GetOreaServiceToken.bind(AuthProfileVerificationServer),
  });

  return {
    login: (isInNewTab = true) => {
      const loginUrl = `${OREA_LOGIN_URL}?clientUrl=${handlerUrl}&svctoken=${serviceToken}`;
      if (isInNewTab) {
        return window.open(loginUrl, '_blank');
      } else {
        location.replace(loginUrl);
        return null;
      }
    },
    logout: () => {
      window.open(`${OREA_LOGOUT_URL}?clientUrl=${handlerUrl}`, '_blank');
      setIsLoggingOut(true);

      setTimeout(() => {
        setIsLoggingOut(false);
      }, 1_000);
    },
    isLoggingOut,
    baseUrl,
    isLoading: isInitialLoading,
  };
};

export default useOreaAuthFlow;
