import update from 'immutability-helper';
import type { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';
import type { RootState } from 'store';

import type { AppAction } from 'store/types';

import { NotifierActions } from './actions';

export interface AppNotification {
  key: SnackbarKey;
  message: SnackbarMessage;
  options?: OptionsObject;
  isDismissed?: boolean;
}

export interface NotifierState {
  notifications: AppNotification[];
}

export const initialNotifierState: NotifierState = {
  notifications: [],
};

export const appNotificationsSelector = (state: RootState): AppNotification[] =>
  state.globalState.notifierState.notifications;

export default (state: NotifierState = initialNotifierState, action: AppAction): NotifierState => {
  switch (action.type) {
    case NotifierActions.ADD: {
      const addedNotification: AppNotification = action.payload;
      return update(state, {
        notifications: {
          $push: [addedNotification],
        },
      });
    }
    case NotifierActions.CLOSE: {
      const closeNotificationKey: SnackbarKey = action.payload;
      return update(state, {
        notifications: (notifications) =>
          notifications.map((notification) => ({
            ...notification,
            isDismissed: notification.isDismissed || closeNotificationKey === notification.key,
          })),
      });
    }
    case NotifierActions.REMOVE: {
      const removeNotificationKey: SnackbarKey = action.payload;
      return {
        ...state,
        notifications: state.notifications.filter((notification) => notification.key !== removeNotificationKey),
      };
    }
    default:
      return state;
  }
};
