import type { UseMutationResult } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from 'axios';

import RentSpreeServer from 'resources/server/RentSpreeServer';
import type { CreateTenantScreeningRequest } from 'resources/types/CreateUserRentSpree';

export default (): UseMutationResult<void, AxiosError, CreateTenantScreeningRequest, unknown> =>
  useMutation({
    mutationFn: async (request: CreateTenantScreeningRequest) => {
      await RentSpreeServer.CreateTenantScreening(request);
    },
  });
