import dateFormatter from 'date-fns/format';
import update from 'immutability-helper';

import { ActionTypes } from 'store/actions';
import type { AppAction, EnvelopeHistory, EnvelopeHistoryActivity } from 'store/types';

import { EnvelopeHistoryActions } from './actions';

export interface EnvelopeHistoryState {
  envelopeHistory?: EnvelopeHistory;
}

export const defaultState: EnvelopeHistoryState = {};

const envelopeHistoryReducer = (
  state: EnvelopeHistoryState = defaultState,
  action: AppAction
): EnvelopeHistoryState => {
  switch (action.type) {
    case EnvelopeHistoryActions.GET_ENVELOPE_HISTORY_DONE: {
      return update(state, {
        envelopeHistory: {
          $set: {
            ...action.payload,
            dateSent: dateFormatter(new Date(action.payload.dateSent), "MMMM do, h:mm aaaaa'm'"),
            activityLog: action.payload.activityLog.map((a: EnvelopeHistoryActivity) => {
              a.formattedDate = dateFormatter(new Date(a.timeStamp), 'PPP');
              a.formattedTime = dateFormatter(new Date(a.timeStamp), "h:mm aaaaa'm'");
              return a;
            }),
          },
        },
      });
    }
    case ActionTypes.RESET: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
};

export default envelopeHistoryReducer;
