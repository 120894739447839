import update from 'immutability-helper';

import { ActionTypes } from 'store/actions';
import type { AppAction } from 'store/types';
import relativeDate from 'utils/relativeDate';

import { DashboardActions } from './actions';
import type { FileSummary } from './types';

export interface FileDashboardPageState {
  files?: FileSummary[];
  archives?: FileSummary[];
}

export const initialState: FileDashboardPageState = {};

export default (state: FileDashboardPageState = initialState, action: AppAction): FileDashboardPageState => {
  switch (action.type) {
    case DashboardActions.GET_FILES_DONE: {
      const files: FileSummary[] = action.payload.map((f: FileSummary) => ({
        ...f,
        lastUpdated: relativeDate(f.lastUpdated),
      }));

      return update(state, {
        files: { $set: files },
      });
    }
    case ActionTypes.RESET: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export const filesSelector = (state: FileDashboardPageState): FileSummary[] | undefined => state.files;
