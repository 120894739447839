import update from 'immutability-helper';

import type { DownloadFormsResult } from 'hooks/domain/document/mutations/useDownloadForms';
import { ActionTypes } from 'store/actions';
import type { AppAction, FormVersion } from 'store/types';

import { SelectFormsActions } from './actions';

export interface FormsListDrawerState {
  selectedForms: FormVersion[];
  isAddingFormsInProgress: boolean;
  selectedDisplayDocuments: DownloadFormsResult;
}

export const defaultState: FormsListDrawerState = {
  selectedForms: [],
  isAddingFormsInProgress: false,
  selectedDisplayDocuments: { url: undefined, blob: undefined },
};

const formsListDrawerReducer = (
  state: FormsListDrawerState = defaultState,
  action: AppAction
): FormsListDrawerState => {
  switch (action.type) {
    case SelectFormsActions.ADD_FORM_TO_SELECTION: {
      const i = state.selectedForms.findIndex((form) => form.id === action.payload.id);
      if (i !== -1) {
        return state;
      }

      return update(state, {
        selectedForms: {
          $push: [action.payload],
        },
      });
    }
    case SelectFormsActions.REMOVE_FORM_FROM_SELECTION: {
      const i = state.selectedForms.findIndex((form) => form.id === action.payload);
      if (i === -1) {
        return state;
      }

      return update(state, {
        selectedForms: {
          $splice: [[i, 1]],
        },
      });
    }
    case SelectFormsActions.RESET: {
      return update(state, {
        selectedForms: { $set: [] },
      });
    }
    case ActionTypes.RESET: {
      return defaultState;
    }
    case SelectFormsActions.SET_SELECTED_DISPLAY_DOCUMENTS: {
      return update(state, {
        selectedDisplayDocuments: { $set: action.payload },
      });
    }
    default: {
      return state;
    }
  }
};

export default formsListDrawerReducer;
