import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import useCustomSnackbar from 'hooks/useCustomSnackbar';
import TrrebServer from 'resources/server/TrrebServer';

import type { TrrebMember } from '../../UserSettings/AuthProfileUserSettings/store/types';

export const trrebMemberKey = 'trreb-member';

const useGetTrrebMemberByMemberId = (trrebMemberId: string | undefined): UseQueryResult<TrrebMember | undefined> => {
  const { addErrorSnackbar } = useCustomSnackbar();

  return useQuery({
    queryKey: [trrebMemberKey],
    queryFn: async () => {
      let trrebMember: TrrebMember;

      try {
        (trrebMember = await TrrebServer.GetTrrebMember(trrebMemberId ?? '')),
          {
            enabled: !!trrebMemberId,
          };
        return trrebMember;
      } catch (e) {
        addErrorSnackbar('Failed to retrieve an active TRREB Member record.');
        return undefined;
      }
    },
    enabled: !!trrebMemberId,
  });
};

export default useGetTrrebMemberByMemberId;
