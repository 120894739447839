import { useFeatureFlags } from 'hooks/useFeatureFlags';

import useDoesUserHaveRegion from './useDoesUserHaveRegion';

const useIsBreezeButtonEnabled = (): boolean => {
  const breezeEnabledRegions: string[] = useFeatureFlags()?.isBreezeMenuButtonEnabledV2?.regions ?? [];
  return useDoesUserHaveRegion(breezeEnabledRegions);
};

export default useIsBreezeButtonEnabled;
