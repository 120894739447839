/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import pluralize from 'pluralize';

import LocalStorageItem from 'constants/LocalStorageItem';
import useIsMobile from 'hooks/useIsMobile';

export interface ITimeoutProps {
  onStaySignedIn: () => void;
}

export const convertMillisecondsToTimeStamp = (
  expireTime: number,
  currentTime = performance.now()
): {
  total: number;
  hours: number;
  minutes: number;
  seconds: number;
} => {
  const total = expireTime - currentTime;
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / 1000 / 60 / 60) % 24);
  return {
    total,
    hours,
    minutes,
    seconds,
  };
};

const TimeoutModal = (props: ITimeoutProps): React.ReactElement => {
  const [open, setOpen] = React.useState(true);
  const [timeRemaining, setTimeRemaining] = useState<string | undefined>('-:--');
  const isMobile = useIsMobile();
  const handleLogout = (): void => {
    window.location.pathname = '/logout';
  };
  const [interval, setTimer] = React.useState<number | undefined>();
  const handleClose = (): void => {
    setOpen(false);
    clearInterval(interval);

    if (props.onStaySignedIn) {
      props.onStaySignedIn();
    }
  };

  useEffect(() => {
    const expireTime = performance.now() + 300000;
    const value = setInterval(() => {
      const { total, minutes, seconds } = convertMillisecondsToTimeStamp(expireTime);

      if (total > 0) {
        setTimeRemaining(
          minutes > 0
            ? `${minutes}:${seconds > 9 ? seconds : `0${seconds}`} ${pluralize('minute', minutes)}`
            : `${seconds} ${pluralize('second', seconds)}`
        );
      } else {
        setTimeRemaining(undefined);
        localStorage.removeItem(LocalStorageItem.TrrebRedirectUrl);
        handleLogout();
      }
    }, 1000);

    setTimer(value as unknown as number);

    return () => clearTimeout(value);
  }, []);

  return (
    <Dialog
      open={open}
      aria-labelledby="session-timeout-title"
      aria-describedby="session-timeout-description"
      fullScreen={isMobile}
      PaperProps={{
        style: {
          padding: '32px 32px 32px 32px',
        },
      }}
    >
      <DialogTitle id="session-timeout-title">Your session is about to expire</DialogTitle>
      <DialogContent>
        <DialogContentText id="session-timeout-description">
          You've been inactive for a while. For security reasons, we're <br />
          automatically signing you out in&nbsp;
          {timeRemaining ? <b>{timeRemaining}</b> : <b>Session Expired</b>}
          .
          <br />
          <br />
          Choose "Stay Signed In" to continue working or "Sign Out" if you're
          <br /> done. All progress will be saved.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={handleLogout}>
          Sign Out
        </Button>
        <Button variant="contained" color="primary" onClick={handleClose} autoFocus>
          Stay Signed In
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TimeoutModal;
