import type { FunctionComponent } from 'react';

import type { AuthProfileTermsLanguageProps } from 'components/AuthProfileSetup/AuthProfileTermsLanguage';
import {
  FLTermsLanguage,
  GCAARTermsLanguage,
  OARTermsLanguage,
  SCTermsLanguage,
} from 'components/AuthProfileSetup/AuthProfileTermsLanguage';
import FlNrdsTermsAndConditionsView from 'pages/TermsAndConditions/FlNrdsTermsAndConditionsView';
import ScNrdsTermsAndConditionsView from 'pages/TermsAndConditions/ScNrdsTermsAndConditionsView';
import type { TermsAndConditionsViewProps } from 'pages/TermsAndConditions/store/types';
import type { FormLibrary } from 'store/types';
import { FormLibrarySource } from 'store/types';

export enum NRDSProfileStep {
  ERROR_NRDS_ID_IN_USE = -2,
  ERROR_FORM = -1,
  FORM = 1,
  VERIFY = 2,
}

export interface VerifyNRDSIdPayload {
  nrdsId: string;
  lastName: string;
  source: string;
  areTermsAccepted: boolean;
}

export interface VerifyRamcoMemberPayload {
  mlsId?: string;
  nrdsId?: string;
  source: FormLibrarySource;
}
export enum TermsActionType {
  Dialog = 'Dialog',
  Link = 'Link',
}

export interface TermsAuthProfile {
  isRequired: boolean;
  actionType: TermsActionType;
  termsComponent?: FunctionComponent<TermsAndConditionsViewProps>;
  termsLink?: string;
  termsName?: string;
  termsLanguage?: React.FC<AuthProfileTermsLanguageProps>;
}
export const REQUIRES_TERMS_AUTH_PROFILE_TYPES: Partial<Record<FormLibrarySource, TermsAuthProfile>> = {
  [FormLibrarySource.Orea]: {
    isRequired: true,
    actionType: TermsActionType.Dialog,
  },
  [FormLibrarySource.OregonOARNrds]: {
    isRequired: true,
    actionType: TermsActionType.Link,
    termsLanguage: OARTermsLanguage,
    termsLink: 'https://skyslope.com/wp-content/uploads/2023/01/Oregon-Realtor-Forms-User-Agreement.pdf',
  },
  [FormLibrarySource.ScNrds]: {
    isRequired: true,
    actionType: TermsActionType.Dialog,
    termsComponent: ScNrdsTermsAndConditionsView,
    termsLanguage: SCTermsLanguage,
    termsName: 'Forms Use Policy',
  },
  [FormLibrarySource.FlNrds]: {
    isRequired: true,
    actionType: TermsActionType.Dialog,
    termsComponent: FlNrdsTermsAndConditionsView,
    termsLanguage: FLTermsLanguage,
    termsName: 'Statement of Ownership',
  },
  [FormLibrarySource.GCAARNrds]: {
    isRequired: true,
    actionType: TermsActionType.Link,
    termsLanguage: GCAARTermsLanguage,
    termsLink: 'https://skyslope.com/wp-content/uploads/2022/10/GCAAR-Realtor-Terms.pdf',
  },
};

export interface NRDSMemberData {
  memberFirstName: string;
  memberLastName: string;
  memberOfficeBusinessName: string;
  memberOfficeStreetZip: string;
}

export interface VerifyNRDSIdResult {
  isVerified: boolean;
  memberData?: NRDSMemberData;
  isAlreadyUsed?: boolean;
}

export interface NRDSVerificationError {
  title?: string;
  message?: string;
}

export interface AddNRDSAuthProfileRequest {
  userId: string;
  nrdsId: string;
  lastName: string;
  type: FormLibrarySource;
  library?: FormLibrary;
  andThen?: () => void;
}

export interface AddNrdsAuthProfilesRequestItem {
  userId: string;
  nrdsId: string;
  lastName: string;
  library?: FormLibrary;
}

export enum AddAuthProfileErrorCode {
  NRDS_ID_IN_USE,
  OTHER,
}

export interface TrrebMember {
  title: string;
  officeId: string;
  lastName: string;
  uid: string;
  memberId: string;
  officeName: string;
  firstName: string;
  phone: string;
  officePhone: string;
  officeAddress: string;
  officeCity: string;
  officeProvince: string;
  officePCode: string;
  lud: string;
  email: string;
}
