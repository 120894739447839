import { all } from '@redux-saga/core/effects';
import { put, takeEvery } from 'redux-saga/effects';
import { createAsyncAction } from 'typesafe-actions';

import fileServer from 'resources/server/FileServer';
import type { DeleteFileDocumentRequest } from 'resources/types/DeleteFileDocument';
import type DeleteFileDocuments from 'resources/types/DeleteFileDocuments';
import type { GetFileDocumentsRequest } from 'resources/types/GetFileDocuments';
import { ActionTypes } from 'store/actions';
import type { DocumentSummary, FormDocument } from 'store/types';
import { apiCall } from 'utils/apiCall';
import { batch } from 'utils/array';
import { base64ToBlob } from 'utils/fileUtils';
import { createThumbnailFromPdf } from 'utils/pdfUtils';

import type { CreateThumbnailResult } from '../../FileDetails/store/types';

const getFileDocuments = createAsyncAction(
  ActionTypes.GET_FILE_DOCUMENTS,
  ActionTypes.GET_FILE_DOCUMENTS_DONE,
  ActionTypes.ERROR
)<GetFileDocumentsRequest, DocumentSummary, string>();

const deleteFileDocument = createAsyncAction(
  ActionTypes.DELETE_FILE_DOCUMENT,
  ActionTypes.DELETE_FILE_DOCUMENT_DONE,
  ActionTypes.ERROR
)<DeleteFileDocumentRequest, number, string>();

const deleteFileDocuments = createAsyncAction(
  ActionTypes.DELETE_FILE_DOCUMENTS,
  ActionTypes.DELETE_FILE_DOCUMENTS_DONE,
  ActionTypes.ERROR
)<DeleteFileDocuments, DeleteFileDocuments, string>();

const createThumbnail = createAsyncAction(
  ActionTypes.CREATE_FILE_DOCUMENT_THUMBNAIL,
  ActionTypes.CREATE_FILE_DOCUMENT_THUMBNAIL_DONE,
  ActionTypes.CREATE_FILE_DOCUMENT_THUMBNAIL_ERROR
)<FormDocument, CreateThumbnailResult, CreateThumbnailResult>();

export function* handleGetFileDocuments(action: ReturnType<typeof getFileDocuments.request>): any {
  try {
    const payload = action.payload || [];
    const response: DocumentSummary = yield apiCall({
      resources: [fileServer, fileServer.GetFileDocuments],
      payload: {
        fileId: payload.fileId,
        page: payload.page,
        pageSize: payload.pageSize,
      },
    });

    yield put(getFileDocuments.success(response));

    for (const doc of response.documents) {
      if (doc.thumbnailURL == null)
        yield put({
          type: ActionTypes.CREATE_FILE_DOCUMENT_THUMBNAIL,
          payload: doc,
        });
    }
  } catch (error) {
    yield put(getFileDocuments.failure(error as string));
  }
}

export function* handleDeleteFileDocument(action: ReturnType<typeof deleteFileDocument.request>): any {
  try {
    const payload = action.payload || [];
    yield apiCall({
      resources: [fileServer, fileServer.DeleteFileDocument],
      payload: {
        documentId: payload.documentId,
      },
    });
    yield put(deleteFileDocument.success(payload.documentId));
  } catch (error) {
    yield put(deleteFileDocument.failure(error as string));
  }
}

export function* handleDeleteFileDocuments(action: ReturnType<typeof deleteFileDocuments.request>): any {
  try {
    const payload = action.payload.documentIds || [];

    const documents = payload.map(
      (id: number): DeleteFileDocumentRequest => ({
        documentId: id,
      })
    );

    for (const documentBatch of batch<DeleteFileDocumentRequest>(documents, 10)) {
      yield all(
        documentBatch.map((request: DeleteFileDocumentRequest) =>
          apiCall({
            resources: [fileServer, fileServer.DeleteFileDocument],
            payload: {
              documentId: request.documentId,
            },
          })
        )
      );
    }

    yield put(deleteFileDocuments.success(action.payload));
  } catch (error) {
    yield put(deleteFileDocuments.failure(error as string));
  }
}

export function* handleCreateThumbnail(action: ReturnType<typeof createThumbnail.request>): any {
  try {
    const pdfBlob = yield base64ToBlob(action.payload.formURL);
    const thumbnailDataUrl = yield createThumbnailFromPdf(pdfBlob);

    yield put(
      createThumbnail.success({
        documentId: action.payload.id,
        thumbnailDataUrl,
      })
    );
  } catch (e) {
    yield put(
      createThumbnail.failure({
        documentId: action.payload.id,
        thumbnailDataUrl: undefined,
      })
    );
  }
}

export default (): any => [
  takeEvery(ActionTypes.GET_FILE_DOCUMENTS, handleGetFileDocuments),
  takeEvery(ActionTypes.DELETE_FILE_DOCUMENT, handleDeleteFileDocument),
  takeEvery(ActionTypes.DELETE_FILE_DOCUMENTS, handleDeleteFileDocuments),
  takeEvery(ActionTypes.CREATE_FILE_DOCUMENT_THUMBNAIL, handleCreateThumbnail),
];
