import { useLocation } from 'react-router-dom';

import { getRoute } from 'appRoutes';

const useIsOnboarding = (): boolean => {
  const location = useLocation();
  const currentRoute = getRoute(location.pathname);
  const currentPath = currentRoute?.routerProps.path;
  return !!currentPath?.includes('/welcome');
};

export default useIsOnboarding;
