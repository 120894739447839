import type { RouteProps as ReactRouterProps } from 'react-router';
import { matchPath } from 'react-router';
import type { RedirectProps } from 'react-router-dom';

import FeatureFlagKeys from 'constants/FeatureFlagKeys';
import type { FormsLDFlags } from 'hooks/useFeatureFlags';
import LoginCallback from 'pages/LoginCallback';
import Logout from 'pages/Logout/Logout';
import PrimeLoginCallback from 'pages/PrimeLoginCallback';
import RelinkingErrorPage from 'pages/RelinkingErrorPage';
import Sso from 'pages/Sso';
import UnauthorizedPage from 'pages/UnauthorizedPage';

import { routes } from './constants/routes';
import LazyLoadPage from './LazyLoadPage';

export interface AppRouteFeatureFlagConfig {
  name: keyof FormsLDFlags;
  // LD has different variations for flags, right now we just use true/false, later we could use string too
  variation: boolean;
}

export interface AppRoute {
  key: string;
  routerProps: ReactRouterProps;
  isNaked?: boolean;
  requiresSubscription?: boolean;
  featureFlag?: AppRouteFeatureFlagConfig;
}

export const appRoutes: AppRoute[] = [
  {
    key: routes.homeTownBuffet,
    routerProps: {
      path: routes.homeTownBuffet,
      component: LazyLoadPage({ name: 'FilesDashboard' }),
      exact: true,
    },
  },
  {
    key: routes.templateCreate,
    routerProps: {
      path: routes.templateCreate,
      component: LazyLoadPage({ name: 'TemplateCreate' }),
      exact: true,
    },
    featureFlag: {
      name: FeatureFlagKeys.isTemplateCreationWizardEnabled,
      variation: true,
    },
  },
  {
    key: routes.templateManagement,
    requiresSubscription: true,
    routerProps: {
      path: routes.templateManagement,
      component: LazyLoadPage({ name: 'TemplateManagement' }),
      exact: true,
    },
  },
  {
    key: routes.templateType,
    routerProps: {
      path: routes.templateType,
      component: LazyLoadPage({ name: 'TemplateType' }),
      exact: true,
    },
  },
  {
    key: routes.templateDetails,
    routerProps: {
      path: routes.templateDetails,
      component: LazyLoadPage({ name: 'TemplateDetails' }),
      exact: true,
    },
  },
  {
    key: routes.clauses,
    requiresSubscription: true,
    routerProps: {
      path: routes.clauses,
      component: LazyLoadPage({ name: 'Clauses' }),
      exact: true,
    },
    featureFlag: {
      name: FeatureFlagKeys.isMyClausesEnabled,
      variation: true,
    },
  },
  {
    key: routes.clauseSharing,
    routerProps: {
      path: routes.clauseSharing,
      component: LazyLoadPage({ name: 'Clauses/ClauseSharing' }),
      exact: true,
    },
  },
  {
    key: routes.brokerageClauses,
    requiresSubscription: true,
    routerProps: {
      path: routes.brokerageClauses,
      component: LazyLoadPage({ name: 'Clauses' }),
      exact: true,
    },
  },
  {
    key: routes.userSettingsProfile,
    routerProps: {
      path: routes.userSettingsProfile,
      component: LazyLoadPage({ name: 'UserSettings' }),
      exact: true,
    },
  },
  {
    key: routes.userSettingsSecurity,
    routerProps: {
      path: routes.userSettingsSecurity,
      component: LazyLoadPage({ name: 'UserSettings/Security' }),
      exact: true,
    },
    featureFlag: {
      name: FeatureFlagKeys.isSecurityTabEnabled,
      variation: true,
    },
  },
  {
    key: routes.userSettingsAssociations,
    routerProps: {
      path: routes.userSettingsAssociations,
      component: LazyLoadPage({ name: 'UserSettings/Associations' }),
      exact: true,
    },
  },
  {
    key: routes.userSettingsAdditionalInfo,
    routerProps: {
      path: routes.userSettingsAdditionalInfo,
      component: LazyLoadPage({ name: 'UserSettings/AdditionalInfo' }),
      exact: true,
    },
  },
  {
    key: routes.userSettingsAuthProfile,
    routerProps: {
      path: routes.userSettingsAuthProfile,
      component: LazyLoadPage({ name: 'UserSettings/AuthProfileUserSettings' }),
      exact: true,
    },
  },
  {
    key: routes.userSettingsMyTeam,
    routerProps: {
      path: routes.userSettingsMyTeam,
      component: LazyLoadPage({ name: 'UserSettings/MyTeam' }),
      exact: true,
    },
  },
  {
    key: routes.userSettingsMyTeamAddPeople,
    routerProps: {
      path: routes.userSettingsMyTeamAddPeople,
      component: LazyLoadPage({
        name: 'UserSettings/AddPeople',
      }),
      exact: true,
    },
  },
  {
    key: routes.userSettingsPreferences,
    routerProps: {
      path: routes.userSettingsPreferences,
      component: LazyLoadPage({
        name: 'UserSettings/UserPreferences',
      }),
      exact: true,
    },
  },
  {
    key: routes.brokerageManagement,
    routerProps: {
      path: routes.brokerageManagement,
      component: LazyLoadPage({
        name: 'BrokerageManagement',
      }),
      exact: true,
    },
    featureFlag: {
      name: FeatureFlagKeys.isBrokerPermissionsActive,
      variation: true,
    },
  },
  {
    key: routes.fileCreate,
    requiresSubscription: true,
    routerProps: {
      path: routes.fileCreate,
      component: LazyLoadPage({ name: 'FileCreate' }),
      exact: true,
    },
  },
  {
    key: routes.fileDetailsDocuments,
    routerProps: {
      path: routes.fileDetailsDocuments,
      component: LazyLoadPage({ name: 'FileForms' }),
      exact: true,
    },
  },
  {
    key: routes.fileDetailsEnvelopes,
    routerProps: {
      path: routes.fileDetailsEnvelopes,
      component: LazyLoadPage({ name: 'FileDetails/EnvelopeList' }),
      exact: true,
    },
  },
  {
    key: routes.fileDetailsDetails,
    routerProps: {
      path: routes.fileDetailsDetails,
      component: LazyLoadPage({ name: 'FileDetails/QuickEntry' }),
      exact: true,
    },
  },
  {
    key: routes.fileDetailsSignedDocuments,
    routerProps: {
      path: routes.fileDetailsSignedDocuments,
      component: LazyLoadPage({ name: 'FileDetails/SignedDocuments' }),
      exact: true,
    },
    featureFlag: {
      name: FeatureFlagKeys.isSignedDocumentsEnabled,
      variation: true,
    },
  },
  {
    key: routes.browseLibraries,
    requiresSubscription: true,
    routerProps: {
      path: routes.browseLibraries,
      component: LazyLoadPage({ name: 'BrowseLibraries' }),
      exact: true,
    },
  },
  {
    key: routes.fillFileForms,
    routerProps: {
      path: routes.fillFileForms,
      component: LazyLoadPage({ name: 'Fill' }),
      exact: true,
    },
  },
  {
    key: routes.fillTemplateForms,
    routerProps: {
      path: routes.fillTemplateForms,
      component: LazyLoadPage({ name: 'Fill' }),
      exact: true,
    },
  },
  {
    key: routes.addFormsToFile,
    requiresSubscription: true,
    routerProps: {
      path: routes.addFormsToFile,
      component: LazyLoadPage({ name: 'AddFormsToFile' }),
      exact: true,
    },
  },
  {
    key: routes.addFormsToEnvelope,
    routerProps: {
      path: routes.addFormsToEnvelope,
      component: LazyLoadPage({ name: 'AddFormsToEnvelope' }),
      exact: true,
    },
  },
  {
    key: routes.addFormsToTemplate,
    routerProps: {
      path: routes.addFormsToTemplate,
      component: LazyLoadPage({ name: 'AddFormsToTemplate' }),
      exact: true,
    },
  },
  {
    key: routes.fillEnvelope,
    routerProps: {
      path: routes.fillEnvelope,
      component: LazyLoadPage({ name: 'Fill' }),
      exact: true,
    },
  },
  {
    key: routes.envelopeHistory,
    routerProps: {
      path: routes.envelopeHistory,
      component: LazyLoadPage({ name: 'EnvelopeHistory' }),
      exact: true,
    },
  },
  {
    key: routes.onboardingEmail,
    routerProps: {
      path: routes.onboardingEmail,
      component: LazyLoadPage({ name: 'UserOnboarding/Email' }),
      exact: true,
    },
  },
  {
    key: routes.onboardingRegion,
    routerProps: {
      path: routes.onboardingRegion,
      component: LazyLoadPage({ name: 'UserOnboarding/Regions' }),
      exact: true,
    },
  },
  {
    key: routes.onboardingAssociations,
    routerProps: {
      path: routes.onboardingAssociations,
      component: LazyLoadPage({ name: 'UserOnboarding/Associations' }),
      exact: true,
    },
  },
  {
    key: routes.onboardingAuthProfiles,
    routerProps: {
      path: routes.onboardingAuthProfiles,
      component: LazyLoadPage({ name: 'UserOnboarding/AuthProfiles' }),
      exact: true,
    },
  },
  {
    key: routes.onboardingNoFormsForState,
    routerProps: {
      path: routes.onboardingNoFormsForState,
      component: LazyLoadPage({ name: 'UserOnboarding/NoFormsForState' }),
      exact: true,
    },
  },
  {
    key: routes.onboardingAdditionalInformation,
    routerProps: {
      path: routes.onboardingAdditionalInformation,
      component: LazyLoadPage({ name: 'UserOnboarding/AdditionalInformation' }),
      exact: true,
    },
  },
  {
    key: routes.widgetOnboardingAssociations,
    routerProps: {
      path: routes.widgetOnboardingAssociations,
      component: LazyLoadPage({ name: 'UserOnboarding/Widget/Associations' }),
      exact: true,
    },
  },
  {
    key: routes.backgroundLoad,
    routerProps: {
      path: routes.backgroundLoad,
      component: LazyLoadPage({ name: 'BackgroundLoad' }),
      exact: true,
    },
  },
  {
    key: routes.verifyOreaHandler,
    routerProps: {
      path: routes.verifyOreaHandler,
      component: LazyLoadPage({ name: 'VerifyOreaHandler' }),
      exact: true,
    },
  },
  {
    key: routes.logout,
    isNaked: true,
    routerProps: {
      path: routes.logout,
      component: Logout,
      exact: true,
    },
  },
  {
    key: routes.login,
    isNaked: true,
    routerProps: {
      path: routes.login,
      component: LazyLoadPage({ name: 'Login' }),
      exact: true,
    },
  },
  {
    key: routes.loggedOut,
    isNaked: true,
    routerProps: {
      path: routes.loggedOut,
      component: Logout,
      exact: true,
    },
  },
  {
    key: routes.sso,
    isNaked: true,
    routerProps: {
      path: routes.sso,
      component: Sso,
      exact: true,
    },
  },
  {
    key: routes.unauthorized,
    isNaked: true,
    routerProps: {
      path: routes.unauthorized,
      component: UnauthorizedPage,
      exact: true,
    },
  },
  {
    key: routes.relinkingError,
    isNaked: true,
    routerProps: {
      path: routes.relinkingError,
      component: RelinkingErrorPage,
      exact: true,
    },
  },
  {
    key: routes.authCallback,
    isNaked: true,
    routerProps: {
      path: routes.authCallback,
      component: LoginCallback,
      exact: true,
    },
  },
  {
    key: routes.primeAuthCallback,
    isNaked: true,
    routerProps: {
      path: routes.primeAuthCallback,
      component: PrimeLoginCallback,
      exact: true,
    },
  },
  {
    key: routes.welcomeSkySlope,
    routerProps: {
      path: routes.welcomeSkySlope,
      component: LazyLoadPage({ name: 'FilesDashboard' }),
      exact: true,
    },
  },
  {
    key: routes.clauseCreate,
    routerProps: {
      path: routes.clauseCreate,
      component: LazyLoadPage({ name: 'Clauses/Create' }),
      exact: true,
    },
  },
  {
    key: routes.clauseEdit,
    routerProps: {
      path: routes.clauseEdit,
      component: LazyLoadPage({ name: 'Clauses/Edit' }),
      exact: true,
    },
  },
  {
    key: routes.docusignLogin,
    isNaked: true,
    routerProps: {
      path: routes.docusignLogin,
      component: LazyLoadPage({ name: 'DocusignLogin' }),
      exact: true,
    },
  },
  {
    key: routes.subscriptions,
    routerProps: {
      path: routes.subscriptions,
      component: LazyLoadPage({ name: 'Subscriptions' }),
      exact: true,
    },
  },
  {
    key: routes.buyerAgreements,
    routerProps: {
      path: routes.buyerAgreements,
      component: LazyLoadPage({ name: 'BuyerAgreementsPage' }),
      exact: true,
    },
  },
  {
    key: routes.fileQuickCreate,
    routerProps: {
      path: routes.fileQuickCreate,
      component: LazyLoadPage({ name: 'FileQuickCreate' }),
      exact: true,
    },
  },
];

const isLocal = window.location.host.includes('localhost');

if (isLocal) {
  appRoutes.push({
    key: routes.sandbox,
    routerProps: {
      path: routes.sandbox,
      component: LazyLoadPage({ name: 'Sandbox' }),
      exact: true,
    },
  });
}
export interface AppRedirect {
  key: string;
  redirectProps: RedirectProps;
  featureFlag?: AppRouteFeatureFlagConfig;
}

export const appRedirects: AppRedirect[] = [
  {
    key: routes.filesDashboard,
    redirectProps: {
      exact: true,
      from: routes.filesDashboard,
      to: routes.homeTownBuffet,
    },
  },
  {
    key: routes.userSettings,
    redirectProps: {
      exact: true,
      from: routes.userSettings,
      to: routes.userSettingsProfile,
    },
  },
  {
    key: routes.fillDocumentDeprecated,
    redirectProps: {
      exact: true,
      from: routes.fillDocumentDeprecated,
      to: routes.fillFileForms,
    },
  },
  {
    key: routes.addFormsToFileDeprecated,
    redirectProps: {
      exact: true,
      from: routes.addFormsToFileDeprecated,
      to: routes.addFormsToFile,
    },
  },
];

export const getRoute = (path: string, routes: AppRoute[] = appRoutes): AppRoute | null => {
  for (const route of routes) {
    if (matchPath(path, route.routerProps)) return route;
  }

  return null;
};
