import type { Action } from 'redux';

import type { BlockType } from 'constants/BlockTypes';
import type EnvelopeStatus from 'constants/EnvelopeStatus';
import type FileType from 'constants/FileType';
import type FormStatus from 'constants/FormStatuses';
import type { LibraryTagKeys } from 'constants/LibraryTagKeys';
import type { FormsLDFlags } from 'hooks/useFeatureFlags';
import type { EnvelopeEventName } from 'pages/EnvelopeHistory/store/types';
import type { TemplateCategory } from 'pages/FilesDashboard/store/types';
import type { FileMetaAccountGroup } from 'resources/types/CreateFileRequest';
import type { Address, FieldAddress } from 'types/address';
import type { ClientType, DocumentDetailsV2 } from 'types/documents';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface AppAction<T = any> extends Action {
  payload?: T;
}

export interface AddressPremises {
  unit: string;
  street: string;
}

export interface Name {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  suffix?: string;
}

export type FormFieldValue =
  | string
  | AddressPremises
  | DomainFieldOption
  | Address
  | Name[]
  | Contact[]
  | number
  | boolean
  | FieldValueMapping
  | null;

export interface FieldValueMapping {
  [fieldName: string]: FormFieldValue;
}

export interface FormFieldDeprecated<T = FormFieldValue> {
  value: T | null;
  xCoordinate: number;
  yCoordinate: number;
  width: number;
  height: number;
  name: string;
  type: BlockType;
  pageNumber: number;
  id: string;
  isTabbable: boolean;
  format?: string;
  dataRef: string;
  order?: number;
  isReadOnly: boolean;
  dataRefIndex: number;
  domainFieldMetaData?: DomainFieldMetaData;
  clientType?: ClientType;
}

export interface DomainFieldMetaData {
  optionSet: DomainFieldOptionSet;
}

export interface DomainFieldOptionSet {
  entityName: string;
  isOverridable: boolean;
  options: DomainFieldOption[];
}

export interface DomainFieldOption {
  display: string;
  value: string;
  type?: OptionSetValueType;
}

export enum OptionSetValueType {
  OPTION = 'option',
  MANUAL = 'manual',
}

export interface DocumentDetails {
  id: number;
  fileId: number;
  formId: number;
  formName: string;
  formURL: string;
  fieldData: FormFieldsData;
  pageCount: number;
  formStatus: FormStatus;
  publishedVersionId?: number;
  sources: FormLibrarySource[];
}

export interface FormFieldsData {
  fields: FormFieldDeprecated[];
  fieldsMetadata: FormFieldsMetadata;
}

export interface FormFieldsMetadata {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [dataRef: string]: any | MultilineMetadata;
}

export interface MultilineMetadata {
  lineLengths: number[];
  fullText: string;
}

export interface Property {
  id?: string;
  streetNumber: string;
  streetName: string;
  unitNumber: string;
  city: string;
  postalCode: string;
  state: string;
  county: string;
  isCompleteAddress?: boolean;
}

export interface FormDocument {
  id: number;
  formId: number; // forms-db formVersion Id
  formName: string;
  formURL: string;
  updatedOn: string;
  thumbnailURL?: string | null;
  theRealFormId?: number; // forms-db form Id
}

export interface DocumentSummary {
  totalRecords: number;
  documents: FormDocument[];
}

export interface BrokerTemplateDocumentDetail {
  totalRecords: number;
  documents: DocumentDetailsV2[];
}

export interface EnvelopeSummary {
  totalRecords: number;
  envelopes: Envelope[];
}

export interface EnvelopeSigner {
  id: number;
  externalId: string;
  contactId: number;
  envelopeId: number;
  dateSigned?: string;
}

export interface Envelope {
  id: number;
  fileId: number;
  name: string;
  dateSent: string;
  status: EnvelopeStatus;
  action?: string;
  externalId: string;
  signers: EnvelopeSigner[];
  documentIds?: number[];
  envelopeUrl?: string;
  isMissingExternalReference?: boolean;
  ownedBy?: string;
}

export interface Ds3SigningGroup {
  id: string;
  name: string;
  order: number;
}

export interface Ds3Recipient {
  id: string;
  email: string;
  firstName: string;
  middleName: string;
  lastName: string;
  group: Ds3SigningGroup;
  status: string;
  role: string;
}

export interface Ds3Envelope {
  envelopeId: string;
  documents: Ds3Document[];
}

export interface Ds3Document {
  documentId: string;
  numberOfPages: number;
}

export interface ContactRoleDisplay {
  key: ContactRole | '';
  displayValue: string;
}

export interface FileMetaRegion {
  country: string;
  region: string;
}

export interface TemplateMeta {
  brokerTemplateStatus: BrokerTemplateStatus;
  brokerTemplateStatusUpdatedOn: string;
  externalId: string;
  regions: FileMetaRegion[];
  accountGroups: FileMetaAccountGroup[];
}

export interface File {
  id: number;
  mlsNumber: string;
  name: string;
  fileType: FileType;
  property: Property;
  acceptanceDateTime: string | null;
  closingDateTime: string | null;
  purchasePrice: string | null;
  commissions: Commission[];
  contacts: Contact[];
  representationType: RepresentationType;
  availableContactRolesDeprecated: ContactRoleDisplay[]; // todo: remove once file-details v1 is removed. This change was made because we were adding data in a saga, bad practice
  documentData: {
    [documentId: number]: {
      [dataRef: string]: FormFieldValue;
    };
  };
  fileData: {
    [dataRef: string]: FormFieldValue;
    mlsMarketName: string;
  };
  isDeleted: boolean;
  isArchived: boolean;
  templateCategory?: TemplateCategory;
  meta?: TemplateMeta;
  tenantScreeningProvider?: 'RentSpree' | 'None';
  tenantScreeningProviderMetadata?: { ShortUrl: string };
  isUtilityConnectEnabled?: boolean;
  ownedBy: string;
  createdBy: string;
}

export interface FormVersionPage {
  totalRecords: number;
  formVersionViewModels: FormVersion[];
}

export interface PageMeta {
  width: number;
  height: number;
}

export interface FormVersion {
  id: number;
  name: string;
  url: string;
  previewUrl: string;
  formId: number;
  thumbnailUrl?: string;
  isSelected?: boolean; // todo remove this once we get rid of old implementation of add form to file
  pageCount: number;
  pageMeta: PageMeta[];
  libraryId?: number;
  sources?: FormLibrarySource[];
}

export interface SignedDocument {
  documentId: number;
  uploadedDocumentId?: number;
  envelopeDocumentId?: number;
  envelopeId?: string;
  digisignDocumentGuid?: string;
  name: string;
  dateSent: string;
  envelopeName: string;
  thumbnailUrl: string;
  documentName: string;
  documentUrl?: string;
  pageCount: number;
}

export enum FormLibrarySource {
  Nrds_profiles = 'NrdsProfiles',
  AzNrds = 'Nrds',
  PRIME = 'Prime',
  TxNrds = 'TxNrds',
  NWMLS = 'Nwmls',
  Oref = 'Oref',
  None = 'None',
  CaNrds = 'CaNrds',
  MdNrds = 'MdNrds',
  OhNrds = 'OhNrds',
  InNrds = 'InNrds',
  MaNrds = 'MaNrds',
  PaNrds = 'PaNrds',
  NSBARNrds = 'NSBARNrds',
  GCAARNrds = 'GCAARNrds',
  Ramco = 'Ramco',
  Orea = 'Orea',
  FilesUser = 'FilesUser',
  AccessCode = 'AccessCode',
  CtNrds = 'CtNrds',
  RiNrds = 'RiNrds',
  NcNrds = 'NcNrds',
  EctNrds = 'EctNrds',
  SctNrds = 'SctNrds',
  ScNrds = 'ScNrds',
  VaNrds = 'VaNrds',
  FlNrds = 'FlNrds',
  LaNrds = 'LaNrds',
  UtNrds = 'UtNrds',
  CanopyNcNrds = 'CanopyNcNrds',
  BEARCaNrds = 'BEARCaNrds',
  MoNrds = 'MoNrds',
  GBREBNrds = 'GBREBNrds',
  ChicagoNrds = 'ChicagoNrds',
  Ca0808Nrds = 'Ca0808Nrds',
  PrinceGeorgeNrds = 'PrinceGeorgeNrds',
  KansasCityNrds = 'KansasCityNrds',
  RSARNrds = 'RSARNrds',
  GLARNrds = 'GLARNrds',
  IllinoisAORNrds = 'IllinoisAORNrds',
  ParkCityUTNrds = 'ParkCityUTNrds',
  OregonOARNrds = 'OregonOARNrds',
  TRREB = 'Trreb',
  NMNrds = 'NMNrds',
  NMRamco = 'NMRamco',
  MsAORNrds = 'MsAORNrds',
  DeAORNrds = 'DeAORNrds',
  KsAORNrds = 'KsAORNrds',
  SpAORNrds = 'SpAORNrds',
  GAR = 'Gar',
  AlAORNrds = 'AlAORNrds',
}

export const NRDS_AUTH_PROFILE_TYPES: { [key: string]: boolean } = {
  [FormLibrarySource.AzNrds]: true,
  [FormLibrarySource.TxNrds]: true,
  [FormLibrarySource.MdNrds]: true,
  [FormLibrarySource.OhNrds]: true,
  [FormLibrarySource.InNrds]: true,
  [FormLibrarySource.MaNrds]: true,
  [FormLibrarySource.PaNrds]: true,
  [FormLibrarySource.NSBARNrds]: true,
  [FormLibrarySource.GCAARNrds]: true,
  [FormLibrarySource.CtNrds]: true,
  [FormLibrarySource.RiNrds]: true,
  [FormLibrarySource.NcNrds]: true,
  [FormLibrarySource.EctNrds]: true,
  [FormLibrarySource.SctNrds]: true,
  [FormLibrarySource.ScNrds]: true,
  [FormLibrarySource.VaNrds]: true,
  [FormLibrarySource.FlNrds]: true,
  [FormLibrarySource.LaNrds]: true,
  [FormLibrarySource.UtNrds]: true,
  [FormLibrarySource.CanopyNcNrds]: true,
  [FormLibrarySource.BEARCaNrds]: true,
  [FormLibrarySource.MoNrds]: true,
  [FormLibrarySource.GBREBNrds]: true,
  [FormLibrarySource.ChicagoNrds]: true,
  [FormLibrarySource.Ca0808Nrds]: true,
  [FormLibrarySource.PrinceGeorgeNrds]: true,
  [FormLibrarySource.KansasCityNrds]: true,
  [FormLibrarySource.RSARNrds]: true,
  [FormLibrarySource.GLARNrds]: true,
  [FormLibrarySource.IllinoisAORNrds]: true,
  [FormLibrarySource.ParkCityUTNrds]: true,
  [FormLibrarySource.OregonOARNrds]: true,
  [FormLibrarySource.NMNrds]: true,
  [FormLibrarySource.MsAORNrds]: true,
  [FormLibrarySource.DeAORNrds]: true,
  [FormLibrarySource.KsAORNrds]: true,
  [FormLibrarySource.SpAORNrds]: true,
  [FormLibrarySource.AlAORNrds]: true,
};

export const REQUIRED_AUTH_PROFILE_TYPES: { [key: string]: boolean } = {
  ...NRDS_AUTH_PROFILE_TYPES,
  [FormLibrarySource.Oref]: true,
  [FormLibrarySource.NWMLS]: true,
  [FormLibrarySource.CaNrds]: true,
  [FormLibrarySource.Ramco]: true,
  [FormLibrarySource.Orea]: true,
  [FormLibrarySource.AccessCode]: true,
  [FormLibrarySource.TRREB]: true,
  [FormLibrarySource.NMRamco]: true,
  [FormLibrarySource.GAR]: true,
};

export const RAMCO_LOCKED_USER_INFORMATION_PROFILE_TYPES: {
  [key: string]: boolean;
} = {
  [FormLibrarySource.NMRamco]: true,
};

export interface AuthProfileTermsInfo {
  associationName: string;
  termsName: string;
}

export interface FormLibraryAuthProfile {
  source: FormLibrarySource;
  externalId: string;
  tooltip?: string;
}

export interface FormLibrary {
  id: number;
  name: string;
  regionCodes?: string[];
  requiredFields: string[];
  authProfiles: FormLibraryAuthProfile[];
  tags: Partial<Record<LibraryTagKeys, string>>;
  source?: FormLibrarySource;
}

export interface Commission {
  id?: number;
  type: string;
  calculationType: string;
  amount: number;
}

export interface Contact {
  id?: number;
  type: ContactRole | '';
  lastName?: string;
  middleName?: string;
  firstName?: string;
  email?: string;
  suffix: string;
  primaryPhoneNumber: string;
  isEntity?: boolean;
  actionAttribute?: ContactActionAttributeType | '';
  lenderLicenseNumber?: string;
  primaryAddress: ContactAddress;
  newContactId?: number;
  isUser?: boolean; // file creator
  isFileOwner?: boolean;

  // Brokerage Info
  companyName?: string;
  agentLicenseNumber?: string;
  agentMLSCode?: string;
  brokerLicenseNumber?: string;
  brokerMLSCode?: string;
  brokerageLicenseNumber?: string;
  brokerageMLSCode?: string;
  faxPhoneNumber?: string;
  brokeragePhoneNumber?: string;
}

export interface ContactAddress {
  streetNumber: string;
  streetName: string;
  city: string;
  state: string;
  postalCode: string;
  unitNumber: string;
  county: string;
  id?: number;
}

export enum ContactRole {
  BUYER = 'Buyer',
  SELLER = 'Seller',
  ESCROW_OFFICER = 'EscrowOfficer',
  TITLE_OFFICER = 'TitleOfficer',
  BUYERS_LAWYER = 'BuyersLawyer',
  SELLERS_LAWYER = 'SellersLawyer',
  LOAN_OFFICER = 'LoanOfficer',
  BUYER_AGENT = 'BuyerAgent',
  SELLER_AGENT = 'SellerAgent',
  OTHER = 'Other',
  BROKER = 'Broker',
}

export const isEntityType = (type: ContactRole | ''): boolean =>
  type === ContactRole.BUYER || type === ContactRole.SELLER;

export enum RepresentationType {
  BUYER = 'Buyer',
  SELLER = 'Seller',
  TENANT = 'Tenant',
  LANDLORD = 'Landlord',
  BROKER = 'BROKER',
}

export enum ContactActionAttributeType {
  ACTION_EMPTY = 'Empty',
  ACTION_NEEDS_TO_SIGN = 'NeedsToSign',
  ACTION_RECEIVES_COPY = 'ReceivesCopy',
  ACTION_NO_ACTION = 'NoAction',
}

export interface FileServiceResult<T> {
  result: T;
}

export interface FileServiceError {
  statusCode: number;
  errors: string[];
}

export enum HttpResponseStatus {
  NOT_FOUND_ERROR = 404,
  OTHER,
}

export interface Pagination {
  page: number;
  pageSize: number;
  totalRecords: number;
}

export interface PendingEnvelope {
  id: number;
  updatedOn: string;
  name: string;
  envelopeStatus: string;
  fileId: number;
  externalId: string;
  dateSent: string;
  documents: EnvelopeDocument[];
  envelopeUrl: string;
}

export interface EnvelopeDocument {
  id: number;
  documentId: number;
  externalId: string;
  thumbnailUrl?: string;
  formName: string;
}

export interface EnvelopeHistoryUser {
  firstName: string;
  lastName: string;
  middleName: string;
  suffix: string;
  email: string;
}

export interface EnvelopeHistorySigner {
  firstName: string;
  lastName: string;
  middleName: string;
  suffix: string;
  email: string;
  externalId?: string;
  contactId?: number;
  dateSigned?: string;
  isEntity: boolean;
  companyName: string;
}

export interface EnvelopeHistoryActivity {
  eventName: EnvelopeEventName;
  timeStamp: string;
  signers: EnvelopeHistorySigner[];
  formattedDate?: string;
  formattedTime?: string;
}

export interface EnvelopeHistory {
  name: string;
  dateSent: string;
  externalId: string;
  sender: EnvelopeHistoryUser;
  owner: EnvelopeHistoryUser;
  signers: EnvelopeHistorySigner[];
  ccRecipients?: EnvelopeHistorySigner[];
  activityLog: EnvelopeHistoryActivity[];
}

export enum Ds3BackPageRedirect {
  ENVELOPES = 'envelopes',
  FILL = 'fill',
}

export interface MLSProperty {
  streetNumber?: string | null;
  streetName?: string | null;
  streetDirection?: string | null;
  streetType?: string | null;
  streetDirSuffix?: string | null;
  lotNumber?: string | null;
  buildingNumber?: string | null;
  unitNumber?: string | null;
  city?: string | null;
  state?: string | null;
  stateProvinceFullname?: string | null;
  zipCode?: string | null;
  county?: string | null;
  countryCode?: string | null;
}

export interface MLSListing {
  uid: string;
  propertyId: string;
  displayMlsNumber: string | null;
  marketName: string | null;
  displayAddress: string | null;
  photoUrl: string | null;
  propertyAddress: MLSProperty;
  listDate: string | null;
  listingPrice: number | null;
  listingStatus: string | null;
  lastUpdateDate: string | null;
  listingAgentSummaryInfo: {
    name: string | null;
  };
}

export interface UploadProgressPayload {
  requestId: number;
  progress: number;
}

export interface UploadProgressAction {
  type: string;
  payload: UploadProgressPayload;
}
export interface Path {
  path: string;
  label: string;
  featureFlag?: keyof FormsLDFlags;
  featureFlagMethod?: () => boolean;
}
export type Paths = Array<Path>;

export interface UserRegion {
  country: string;
  region: string;
}

export interface AuthProfile {
  nrdsId?: string;
  lastName?: string;
  lagNumber?: string;
  memberId?: string;
  accessId?: string;
  mlsId?: string;
  areTermsAccepted?: boolean;
  eqs?: string;
  encryptedVerification?: string;
}

export interface MembershipCache {
  status?: string; // CAR returns 'Valid'
  exitUrl?: string;
  libraryProfiles?: LibraryProfile[];
}

export interface UserAuthProfile {
  id?: string;
  type: FormLibrarySource;
  profile: AuthProfile;
  membershipCache?: MembershipCache;
  membershipCacheExpiration?: string;
  cachedAt?: string;
}

export interface LibraryProfile {
  libraryId: number;
  authorizedAt: Date;
}

export interface TrackingMetadata {
  referringClient?: string;
  referrer?: string;
}

export interface UserSettings {
  id: string;
  createdOn: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  suffix?: string;
  primaryPhoneNumber?: string;
  email: string;
  mlsCode?: string;
  licenseNumber?: string;
  brokerageName?: string;
  brokeragePhone?: string;
  brokerageAddress?: FieldAddress;
  brokerageMLSCode?: string;
  brokerageLicenseNumber?: string;
  regions: UserRegion[];
  libraries: number[];
  authProfiles?: UserAuthProfile[];
  isInitialized: boolean;
  notificationEmail?: string;
  isAutoDeletePreDraftEnvelopeAllowed?: boolean;
  isAutoDeleteFormAllowed?: boolean;
  subscriberId?: string | null;
  trackingMetadata?: TrackingMetadata;
  brokerageFax?: string;
  userPreferences?: UserPreferences;
}

export interface UserPreferences {
  digitalSigningPlatformPreference?: 'DigiSign' | 'DocuSign';
  nhdProviderPreference?: string;
}

export interface OwnerName {
  userId: string;
  name: string;
}

export interface UserMetaMembership {
  authorizedTypes: FormLibrarySource[];
  externalIdAuthorization: { [key: string]: string[] };
  libraryProfiles?: { libraryId: number; authorizedAt: Date }[];
}

export interface UserSettingsMeta {
  memberInfo: UserMetaMembership;
}

export enum BrokerTemplateStatus {
  Draft = 'Draft',
  Published = 'Published',
}

export interface AddAccessCodeAuthProfileRequest {
  userId?: string;
  encryptedVerification?: string;
}

export interface BrokerageClause {
  id: string;
  brokerageSubId?: string;
  libraryId?: number;
  categories: string;
  regions?: string[];
  groups?: string[];
  title: string;
  body: string;
  createdOn?: Date;
  createdBy?: string;
}

export interface BrokerCategory {
  id: string;
  category: string;
  libraryId?: number;
}

export interface OwnerMeta {
  id: string;
  firstName: string;
  middleName: string;
  lastName: string;
  suffix: string;
}

export interface Data {
  groupId: string;
  roleId: string;
}

/**
 * See [here](https://skyslope.atlassian.net/wiki/spaces/DEVGEN/pages/3153690646/Defining+Every+SkySlope+User+Permission)
 * for explanations of the different permissions.
 */
export type AccountPermission =
  | 'forms.*'
  | 'forms.template.*'
  | 'forms.template.write'
  | 'forms.clauses.*'
  | 'forms.clauses.write'
  | 'forms.libraries.*'
  | 'forms.libraries.write'
  | 'forms.buyeragreement.*'
  | 'ds3.*';

export interface GroupPermission {
  groupId: string;
  accountPermission: AccountPermission;
}

export interface Roles {
  roleId: string;
  roleName: string;
  access: Data[];
  userPermissions?: GroupPermission[];
}

export interface ChameleonInfo {
  userId: string;
  email: string;
  isStandaloneUser: boolean;
  hasBrokerTemplates: boolean;
  organizationId?: number;
  regions: string;
  filesCreated: number;
  userCreationDate: string;
  libraryIds: string | string[];
  firstName: string;
  lastName: string;
}

export interface AccountGroup {
  groupId: string;
  name: string;
  userCount: number;
}

export interface AccountGroupListResults {
  pageNumber: number;
  pageSize: number;
  totalCount: number;
  data: AccountGroup[];
}

export interface AccountGroupListResponse {
  errors: string[];
  data: {
    result: AccountGroupListResults;
  };
}

export interface Recipient {
  email: string;
}

export interface RecipientsForm {
  recipients: Recipient[];
}

export interface BuyerAgreementServerResult<T> {
  result: T;
  statusCode: string;
  errors: unknown[];
}

type IsoDate = string;
export interface BuyerAgreement {
  id: string;
  fileId: number;
  formsEnvelopeId: number;
  digiSignEnvelopeId: string;
  buyerAgreementDocumentId: number;
  buyerAgentOktaId: string;
  buyerAgentFullName: string;
  buyerAgentGroupIds: string[];
  buyerFullNames: string[];
  isAccepted: boolean;
  acceptedBy: null | string;
  acceptedAt: IsoDate;
  signedAt: IsoDate;
  createdAt: IsoDate;
  updatedAt: IsoDate;
}

export interface BuyerAgreementLeaderboardItem {
  buyerAgentOktaId: string;
  count: number;
  buyerAgentFullName: string;
}
