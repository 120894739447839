/* eslint-disable @typescript-eslint/no-explicit-any */
export default function trimStringProperties(payload: any): any {
  if (payload === null) {
    return payload;
  }

  if (typeof payload === 'string') {
    return payload.trim();
  }

  if (Array.isArray(payload)) {
    return payload.map((e) => trimStringProperties(e));
  }

  if (typeof payload === 'object') {
    const returnObject: any = {};
    for (const [key, value] of Object.entries(payload)) {
      returnObject[key] = trimStringProperties(value);
    }
    return returnObject;
  }

  return payload;
}
