import { useNHDProvidersOptions } from '../../../hooks/domain/nhd/useNHDProviders';
import useGetUserSettingsQuery from '../../../hooks/domain/user/queries/useGetUserSettingsQuery';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';

export const useShowNHDUserPreferenceMissingDialog = () => {
  const userSettings = useGetUserSettingsQuery();
  const nhdProviders = useNHDProvidersOptions();
  const { formsNhdIntegration } = useFeatureFlags();

  const isLoading = userSettings.isLoading || nhdProviders.isLoading;
  return {
    show:
      !isLoading &&
      userSettings.data &&
      userSettings.data.isInitialized &&
      userSettings.data.userPreferences?.nhdProviderPreference == null &&
      nhdProviders.data &&
      nhdProviders.data.length > 1 &&
      formsNhdIntegration,
    isLoading,
  };
};
