import { useFeatureFlags } from 'hooks/useFeatureFlags';

import { useIsPrimeAuthUser } from '../../../auth/hooks';

const useIsBooksLinkEnabled = (): boolean => {
  const isPrimeUser = useIsPrimeAuthUser();
  const { isBooksLinkEnabled } = useFeatureFlags();

  return !!isPrimeUser && isBooksLinkEnabled;
};

export default useIsBooksLinkEnabled;
