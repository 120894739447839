export enum MessagesToPrime {
  // This message is sent when the Forms onboarding is complete so that
  // integrators can close the iFrame and cleanup/save data as needed.
  FORMS_ONBOARDING_COMPLETE = 'forms-onboarding-complete',
}
/**
 * Prime can host Forms in an iFrame. Currently this is being done
 * to encourage Prime users to go through Forms onboarding without
 * making it obvious that that's what they're doing (i.e. theme=agnostic).
 *
 * This hook provides allows communication with prime when it is
 * hosting our app in the iFrame.
 */
export function postMessageToPrime(message: MessagesToPrime) {
  window.parent.postMessage(message, '*');
  // eslint-disable-next-line no-console
  if (window.environmentKey !== 'prod') console.info('postMessageToPrime: ', message);
}
