import type { UserManager } from '@skyslope/auth-js';
import type { AxiosRequestConfig } from 'axios';

import type { FormsUserManager } from 'auth/userManager';
import { userManager } from 'auth/userManager';
import { RENT_SPREE_URL } from 'constants/environments';

import type { CreateTenantScreeningRequest } from '../types/CreateUserRentSpree';

import Server from './Server';

class RentSpreeServer extends Server {
  public constructor(manager: UserManager, axiosConfig: AxiosRequestConfig = {}) {
    super(manager, axiosConfig, {
      tokenGetter: (userManager) => (userManager as FormsUserManager).getAccessToken(),
      includeBaseHeaders: false,
    });
  }

  public async CreateTenantScreening(request: CreateTenantScreeningRequest): Promise<number> {
    // applylink is the name of the rentspree service we have integrated with
    return (await this.api.post(`${RENT_SPREE_URL}applylink/agent/${request.userId}`, request)).data.result;
  }
}

export default new RentSpreeServer(userManager);
