import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export interface WidgetParams {
  isWidget: boolean;
  fileId: number | null;
  state: string | null;
  libraryIds: number[];
  formVersionIds: number[];
}

const useWidgetQueryParams = (): WidgetParams => {
  const useQuery = (): URLSearchParams => new URLSearchParams(useLocation().search);

  const isWidget = useQuery().get('isWidget') === 'true';
  const state = useQuery().get('state');
  const formVersions = useQuery().get('formVersionIds');
  const libraries = useQuery().get('libraryIds');
  const fileId = useQuery().get('fileId');

  const getIds = useCallback(
    (ids: string | null): number[] =>
      // Ensures we get a unique array of ids so we don't load two of the same document
      // Document Ids can also be blank and this will return an empty array
      Array.from(
        new Set((ids?.split(',').filter((o) => o) || []).map((id) => parseInt(id)).filter((id) => !isNaN(id)))
      ),
    []
  );
  const formVersionIds = useMemo(() => getIds(formVersions), [formVersions, getIds]);
  const libraryIds = useMemo(() => getIds(libraries), [libraries, getIds]);

  return {
    isWidget,
    state,
    formVersionIds,
    libraryIds,
    fileId: fileId !== null ? parseInt(fileId) : null,
  };
};
export default useWidgetQueryParams;
