import update from 'immutability-helper';
import type { RootState } from 'store';

import type { AppAction } from 'store/types';

import { PopUnderActions } from './actions';

export interface PopUnderSettings {
  popUnderUrl: string;
  delayMs: number;
}

export interface PopUnderState {
  popUnderSettings?: PopUnderSettings;
}

export const initialPopUnderState: PopUnderState = {};

export const popUnderSettingsSelector = (state: RootState): PopUnderSettings | undefined =>
  state.globalState.popUnderState.popUnderSettings;

export default (state: PopUnderState = initialPopUnderState, action: AppAction): PopUnderState => {
  switch (action.type) {
    case PopUnderActions.SET_POP_UNDER: {
      const settings: PopUnderSettings = action.payload;
      return update(state, {
        popUnderSettings: {
          $set: settings,
        },
      });
    }
    default:
      return state;
  }
};
