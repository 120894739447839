import React from 'react';

import LinkButton from 'components/LinkButton/LinkButton';

export type AuthProfileTermsLanguageProps = {
  onTermsAndConditionsLinkClicked: () => void;
};
export const OARTermsLanguage: React.FC<AuthProfileTermsLanguageProps> = ({ onTermsAndConditionsLinkClicked }) => (
  <div>
    I have read the{' '}
    <LinkButton id="btnTermsAndConditionsLink" onClick={onTermsAndConditionsLinkClicked}>
      Oregon REALTORS(R) End User License Agreement
    </LinkButton>{' '}
    in its entirety and I understand and agree to its terms.
  </div>
);

export const SCTermsLanguage: React.FC<AuthProfileTermsLanguageProps> = ({ onTermsAndConditionsLinkClicked }) => (
  <div>
    I agree to the SCR{' '}
    <LinkButton id="btnTermsAndConditionsLink" onClick={onTermsAndConditionsLinkClicked}>
      Forms Use Policy
    </LinkButton>
  </div>
);

export const FLTermsLanguage: React.FC<AuthProfileTermsLanguageProps> = ({ onTermsAndConditionsLinkClicked }) => (
  <div>
    I agree to the FL{' '}
    <LinkButton id="btnTermsAndConditionsLink" onClick={onTermsAndConditionsLinkClicked}>
      Statement of Ownership
    </LinkButton>
  </div>
);

export const GCAARTermsLanguage: React.FC<AuthProfileTermsLanguageProps> = ({ onTermsAndConditionsLinkClicked }) => (
  <div>
    I agree to the GCAAR{' '}
    <LinkButton id="btnTermsAndConditionsLink" onClick={onTermsAndConditionsLinkClicked}>
      Realtor Terms
    </LinkButton>
  </div>
);
