import update from 'immutability-helper';

import { ActionTypes } from 'store/actions';
import type { AppAction, Contact, Ds3Recipient } from 'store/types';

import { EnvelopeResendDialogActions } from './actions';

export interface EnvelopeResendDialogState {
  contacts: Contact[];
  recipients: Ds3Recipient[];
  isLoadingContacts: boolean;
  isLoadingRecipients: boolean;
}

export const initialState: EnvelopeResendDialogState = {
  contacts: [],
  isLoadingContacts: false,
  recipients: [],
  isLoadingRecipients: false,
};

const envelopeResendDialogReducer = (
  state: EnvelopeResendDialogState = initialState,
  action: AppAction
): EnvelopeResendDialogState => {
  switch (action.type) {
    case EnvelopeResendDialogActions.GET_CONTACTS: {
      return update(state, {
        isLoadingContacts: { $set: true },
      });
    }
    case EnvelopeResendDialogActions.GET_CONTACTS_DONE: {
      return update(state, {
        contacts: { $set: action.payload },
        isLoadingContacts: { $set: false },
      });
    }
    case EnvelopeResendDialogActions.GET_RECIPIENTS: {
      return update(state, {
        isLoadingRecipients: { $set: true },
      });
    }
    case EnvelopeResendDialogActions.GET_RECIPIENTS_DONE: {
      return update(state, {
        recipients: { $set: action.payload.recipients },
        isLoadingRecipients: { $set: false },
      });
    }
    case ActionTypes.RESET: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default envelopeResendDialogReducer;
