import { action } from 'typesafe-actions';

import type { FileSummary } from '../../FilesDashboard/store/types';

import type { OpenDialogState } from './types';

export enum TemplateActions {
  SET_OPEN_DIALOG = 'SET_OPEN_DIALOG',
  SET_TEMPLATE = 'SET_TEMPLATE',
  ERROR = 'ERROR',
}

export const setTemplate = (
  template?: FileSummary
): {
  type: TemplateActions.SET_TEMPLATE;
  payload: FileSummary | undefined;
} => action(TemplateActions.SET_TEMPLATE, template);

export const setOpenDialog = (
  openDialog: OpenDialogState
): {
  type: TemplateActions.SET_OPEN_DIALOG;
  payload: OpenDialogState;
} => action(TemplateActions.SET_OPEN_DIALOG, openDialog);
