import formatRelative from 'date-fns/formatRelative';
import defaultLocale from 'date-fns/locale/en-US';

// TODO: if we ever do internationalization we'll need to do dynamic locale building here base on user-agent

interface LocaleMapping {
  lastWeek: string;
  yesterday: string;
  today: string;
  tomorrow: string;
  nextWeek: string;
  other: string;
}

type FormatRelativeToken = keyof LocaleMapping;

const localeMapping: LocaleMapping = {
  lastWeek: 'P',
  yesterday: "'Yesterday'",
  today: "'Today'",
  tomorrow: "'Tomorrow'",
  nextWeek: 'P',
  other: 'P',
};

const locale: Locale = {
  ...defaultLocale,
  formatRelative: (token: FormatRelativeToken) => localeMapping[token],
};

export default (date: string): string => formatRelative(new Date(date), new Date(), { locale });
