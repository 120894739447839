import { action } from 'typesafe-actions';

export enum FileCreateActions {
  SET_MLS_IMPORT_UID = 'FileCreateActions/SET_MLS_IMPORT_UID',
}

export const setMlsImportUid = (
  uid: string | undefined
): {
  type: FileCreateActions.SET_MLS_IMPORT_UID;
  payload: string | undefined;
} => action(FileCreateActions.SET_MLS_IMPORT_UID, uid);
