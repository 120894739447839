import { useAuth } from '@skyslope/auth-react';
import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import UserProfileServer from 'resources/server/UserProfileServer';
import type { UserSettingsMeta } from 'store/types';

export const userMetaKey = 'userMeta';

const useGetUserSettingsMetaQuery = (
  options: Omit<
    UseQueryOptions<UserSettingsMeta, unknown, UserSettingsMeta, string[]>,
    'initialData' | 'queryFn' | 'queryKey'
  > = {}
): UseQueryResult<UserSettingsMeta> => {
  const { userContext } = useAuth();
  return useQuery({
    queryKey: [userMetaKey],
    queryFn: async () => {
      if (!userContext) {
        throw new Error('context was not loaded yet');
      }

      return UserProfileServer.GetUserSettingsMeta(userContext.id);
    },
    ...options,
    enabled: !!userContext,
  });
};

export default useGetUserSettingsMetaQuery;
