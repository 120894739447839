import { action } from 'typesafe-actions';

import type { Address } from 'types/address';

import type { Contact } from '../types';

export enum FileActions {
  GET_FILE = 'FILE/GET_FILE',
  GET_FILE_DONE = 'FILE/GET_FILE_DONE',
  GET_FILE_ERROR = 'FILE/GET_FILE_ERROR',
  SET_PROPERTY = 'FILE/SET_PROPERTY',
  SET_PROPERTY_FIELD = 'FILE/SET_PROPERTY_FIELD',
  SET_FILE_FIELD = 'FILE/SET_FILE_FIELD',
  SET_COMMISSION_FIELD = 'FILE/SET_COMMISSION_FIELD',
  UPDATE_FILE_DETAILS = 'FILE/UPDATE_FILE_DETAILS',
  UPDATE_FILE_DETAILS_DONE = 'FILE/UPDATE_FILE_DETAILS_DONE',
  UPDATE_FILE_DETAILS_ERROR = 'FILE/UPDATE_FILE_DETAILS_ERROR',
  TRY_SAVE_FILE_DETAILS = 'FILE/TRY_SAVE_FILE_DETAILS',
  SAVE_FILE_DETAILS_DONE = 'FILE/SAVE_FILE_DETAILS_DONE',
  SAVE_CONTACT = 'FILE/SAVE_CONTACT',
  DELETE_CONTACT = 'FILE/DELETE_CONTACT',
  DELETE_TEMPLATE = 'DELETE_TEMPLATE',
  DELETE_TEMPLATE_DONE = 'DELETE_TEMPLATE_DONE',
  DELETE_TEMPLATE_ERROR = 'DELETE_TEMPLATE_ERROR',
}

export interface SetStringValue {
  fieldName: string;
  value: string | null;
}

export const setPropertyValue = (
  address: Address
): {
  type: FileActions.SET_PROPERTY;
  payload: Address;
} => action(FileActions.SET_PROPERTY, address);

export const setPropertyFieldValue = (
  fieldName: string,
  value: string
): {
  type: FileActions.SET_PROPERTY_FIELD;
  payload: SetStringValue;
} => action(FileActions.SET_PROPERTY_FIELD, { fieldName, value });

export const setFileFieldValue = (
  fieldName: string,
  value: string | null
): {
  type: FileActions.SET_FILE_FIELD;
  payload: SetStringValue;
} => action(FileActions.SET_FILE_FIELD, { fieldName, value });

export const trySaveFileDetails = (): {
  type: FileActions.TRY_SAVE_FILE_DETAILS;
} => action(FileActions.TRY_SAVE_FILE_DETAILS);

export const saveFileDetailsDone = (): {
  type: FileActions.SAVE_FILE_DETAILS_DONE;
} => action(FileActions.SAVE_FILE_DETAILS_DONE);

export const saveContact = (
  payload: Contact
): {
  type: FileActions.SAVE_CONTACT;
  payload: Contact;
} => action(FileActions.SAVE_CONTACT, payload);

export const deleteContact = (
  contactId: number
): {
  type: FileActions.DELETE_CONTACT;
  payload: number;
} => action(FileActions.DELETE_CONTACT, contactId);

export interface SetCommissionFieldValue {
  type: string;
  fieldName: string;
  value: string | number | null;
}
export interface DeleteTemplateRequest {
  templateId: number;
}

export interface RenameFileRequest {
  id: number;
  name: string;
  type?: string;
  onBehalfOf?: string;
}

export interface DuplicateFileRequest {
  fileId: number;
  name: string;
  type?: string;
  onBehalfOf?: string;
}

export interface SaveFileAsTemplateRequest {
  fileId: number;
  name: string;
  type?: string;
  documentIds?: number[];
}
