import React from 'react';
import Box from '@mui/material/Box';
import { css } from '@mui/material/styles';
import Stack from '@mui/system/Stack';
import clsx from 'classnames';

import LookItsAPaper from 'assets/images/LookItsAPaper.svg';

import { css as emotionCss } from '@emotion/css';

export interface LoadingSplashPageProps {
  titleText?: string | React.ReactNode;
  bodyText?: string | React.ReactNode;
}

const LoadingSplashPage: React.FC<LoadingSplashPageProps> = ({
  titleText = 'Loading...',
  bodyText = "Please wait, it'll just be a moment.",
}) => (
  <Box component="div" sx={{ height: '100vh', width: '100vw' }}>
    <Stack component={'div'} spacing={2} className={clsx('loaderContent', emotionCss(css({ display: 'block' })))}>
      <img alt="loading Image" aria-hidden src={LookItsAPaper} style={{ display: 'initial' }} />
      <h4>{titleText}</h4>
      <h6>{bodyText}</h6>
    </Stack>
  </Box>
);

export default LoadingSplashPage;
