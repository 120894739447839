import type { FormsLDFlags } from 'hooks/useFeatureFlags';

const FeatureFlagKeys: { [key: string]: keyof FormsLDFlags } = {
  isSecurityTabEnabled: 'isSecurityTabEnabled',
  isTemplateCreationWizardEnabled: 'isTemplateCreationWizardEnabled',
  isBrokerPermissionsActive: 'isBrokerPermissionsActive',
  brokerPermissionsAdminAccess: 'brokerPermissionsAdminAccess',
  isUseFormsLandingPageActive: 'isUseFormsLandingPageActive', // not in LD, all environments, not even archived/deprecated
  isDraftEnvelopeEnabled: 'isDraftEnvelopeEnabled',
  isOffersTabEnabled: 'isOffersTabEnabled',
  allowedOffersTabIdps: 'allowedOffersTabIdps',
  isTcAccessOffersTabEnabled: 'isTcAccessOffersTabEnabled',
  isTcAccessOwnerFilterAndColumnEnabled: 'isTcAccessOwnerFilterAndColumnEnabled',
  isLibraryOnboardingStepSkipable: 'isLibraryOnboardingStepSkipable',
  isOneToOneTCTeamManagementEnabled: 'isOneToOneTCTeamManagementEnabled',
  isCorrectEnvelopeEnabled: 'isCorrectEnvelopeEnabled',
  isMyTeamsEnabled: 'isMyTeamsEnabled',
  isMyTeamsEnabledV2: 'isMyTeamsEnabledV2',
  isSignedDocumentsEnabled: 'isSignedDocumentsEnabled',
  isMyClausesEnabled: 'isMyClausesEnabled',
  ssoAmp: 'ssoAmp', //planned to be removed quickly
  showBrokerageOptionOnClauseCreate: 'showBrokerageOptionOnClauseCreate',
  isFormSuggestionsEnabled: 'isFormSuggestionsEnabled',
};

export default FeatureFlagKeys;
