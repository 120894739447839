import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Menu from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { SrOnly } from '@skyslope/mache';

import { routes } from 'constants/routes';
import { ellipsisJss } from 'jss/sharedJss';

import SkyslopeImg from '../../../../../assets/images/ss-apps/SkySlope.svg';
import type { MobileMenuProps } from '../MobileMenu';

import MobileMenuBreadcrumbs from './MobileMenuBreadcrumbs';

export interface MobileMenuToolbarProps extends MobileMenuProps {
  handleDrawerOpen: () => void;
}

const MobileMenuToolbar: React.FC<MobileMenuToolbarProps> = (props) => {
  const { title, smallHeader = false, breadcrumb = [], handleDrawerOpen, tabs } = props;
  const theme = useTheme();

  const formattedTitle = useMemo(() => {
    if (title === '') {
      return (
        <Skeleton
          variant="rectangular"
          width={270}
          sx={{
            fontSize: '24px',
            marginLeft: theme.spacing(3),
            height: theme.spacing(4),
            lineHeight: theme.spacing(4), // https://github.com/mui-org/material-ui/issues/18014,
            ...ellipsisJss,
          }}
        />
      );
    }
    if (smallHeader && breadcrumb.length && title) {
      return <MobileMenuBreadcrumbs breadcrumbs={breadcrumb} title={title} />;
    }
    return (
      <Typography
        variant="h4"
        component="h1"
        sx={{
          fontSize: '24px',
          marginLeft: theme.spacing(3),
          height: theme.spacing(4),
          lineHeight: theme.spacing(4), // https://github.com/mui-org/material-ui/issues/18014,
          ...ellipsisJss,
        }}
      >
        {title}
      </Typography>
    );
  }, [breadcrumb, smallHeader, theme, title]);

  let marginTopBottom = theme.spacing(3);
  if (breadcrumb?.length) {
    marginTopBottom = theme.spacing(1);
  }
  if (title?.length) {
    marginTopBottom = theme.spacing(2);
  }

  return (
    <Toolbar
      sx={{
        '& .MuiToolbar-root': {
          padding: `0 ${theme.spacing(3)}`,
          marginTop: marginTopBottom,
          marginBottom: marginTopBottom,
          display: 'flex',
          alignItems: 'center',
          minHeight: smallHeader ? theme.spacing(4) : theme.spacing(6),
        },
      }}
    >
      {smallHeader && !tabs.length && !breadcrumb?.length && (
        <Box
          component="div"
          sx={{
            position: 'relative',
            width: theme.spacing(18.5),
            marginLeft: theme.spacing(1),
            marginBottom: theme.spacing(1),
            '& a': {
              position: 'absolute',
              left: 0,
              transition: 'top ease-in-out 0.25s',
              top: smallHeader ? theme.spacing(0.0) : theme.spacing(-1.5),
            },
          }}
        >
          <Link to={routes.homeTownBuffet} id="files-dashboard-link">
            <SrOnly>Go to files dashboard</SrOnly>
            <img alt="SkySlope Forms" src={SkyslopeImg} width={120} height={30} />
          </Link>
        </Box>
      )}
      {!smallHeader && (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          id="drawer-open-btn"
          size="large"
        >
          <Menu />
        </IconButton>
      )}
      {formattedTitle}
    </Toolbar>
  );
};

export default MobileMenuToolbar;
