import React from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { colors } from '@skyslope/mache';

import { ReactComponent as SadCloud } from 'assets/images/SadCloud.svg';
import { logger } from 'utils/logger';

import DefaultAppBar from './components/DefaultAppBar';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
export interface ErrorBoundaryState {
  error?: Error;
  errorInfo?: React.ErrorInfo;
  hasError: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class ErrorBoundary extends React.Component<any, ErrorBoundaryState> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(props: any) {
    super(props);
    this.state = {
      error: undefined,
      errorInfo: undefined,
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    datadogLogs.logger.error(`Error Boundary reached: ${error}`, {
      errorInfo: JSON.stringify(errorInfo),
    });
    logger.error(error);
    logger.error(errorInfo);
  }

  onClick = (): void => {
    window.location.reload();
  };

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <>
          <DefaultAppBar />
          <Container id="error-boundary" sx={{ marginTop: 30, textAlign: 'center', maxWidth: 500 }}>
            <SadCloud />
            <Typography variant="h4" sx={{ marginTop: 3, color: colors.grey[800] }}>
              That&apos;s not supposed to happen!
            </Typography>
            <Typography sx={{ marginTop: 3, marginBottom: 3 }}>
              Sorry, the server encountered a temporary error and could not complete the request. Please try again.
            </Typography>
            <Button onClick={this.onClick} color="primary" variant="contained" autoFocus aria-label="Reload error">
              Reload Page
            </Button>
          </Container>
        </>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
