import { call, fork, put, takeEvery } from 'redux-saga/effects';
import { createAsyncAction } from 'typesafe-actions';

import { addAppNotification, addErrorNotification } from 'containers/Notifier/actions';
import documentServer from 'resources/server/DocumentServer';
import type {
  UploadDocumentFailed,
  UploadDocumentPayload,
  UploadDocumentResponse,
} from 'resources/types/UploadDocuments';
import { createProgressChannel, watchUploadProgress } from 'store/sagaUtils';

import FileType from '../../../constants/FileType';
import BrokerTemplateServer from '../../../resources/server/BrokerTemplateServer';

import { uploadDocumentProgress, UploadDocumentsActions } from './actions';

const getFile = createAsyncAction(
  UploadDocumentsActions.UPLOAD_DOCUMENT,
  UploadDocumentsActions.UPLOAD_DOCUMENT_DONE,
  UploadDocumentsActions.UPLOAD_DOCUMENT_ERROR
)<UploadDocumentPayload, UploadDocumentResponse, UploadDocumentFailed>();

export function* handleUploadDocument(action: ReturnType<typeof getFile.request>): any {
  const requestId = action.payload.requestId;
  const documentName = action.payload.documentName;
  try {
    const [channel, onProgress] = createProgressChannel(requestId);

    const responsePromise: Promise<number> =
      action.payload?.fileType == FileType.BrokerTemplate
        ? BrokerTemplateServer.UploadDocumentToBrokerTemplate({
            ...action.payload,
            onProgress,
          })
        : documentServer.UploadDocumentToFile({
            ...action.payload,
            onProgress,
          });

    yield fork(watchUploadProgress, channel, uploadDocumentProgress);

    const response = yield call(() => responsePromise);

    yield put(
      addAppNotification({
        key: new Date().getTime(),
        message: `${documentName} successfully uploaded.`,
        options: { variant: 'success' },
      })
    );

    const successResult: UploadDocumentResponse = { requestId, id: response };
    yield put(getFile.success(successResult));
  } catch (e) {
    yield put(
      addErrorNotification({
        message: `${documentName} had a problem uploading.`,
      })
    );
    yield put(getFile.failure({ requestId }));
  }
}

export default (): any => [takeEvery(UploadDocumentsActions.UPLOAD_DOCUMENT, handleUploadDocument)];
