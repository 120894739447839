let isGlobalLoading = false;

interface useGlobalLoadingScreenResults {
  isGlobalLoading: boolean;
  showGlobalLoadingScreen: (show: boolean) => boolean;
}

export function showGlobalLoadingScreen(show: boolean): boolean {
  // These elements exist outside react to prevent flash of un-styled content
  const loadingScreen: HTMLElement | null = document.querySelector('#loader');
  const root: HTMLElement | null = document.querySelector('#root');
  const loadingScreenStyle = loadingScreen?.style;
  const rootStyle = root?.style;

  if (show && loadingScreenStyle && rootStyle) {
    loadingScreenStyle.display = 'block';
    rootStyle.display = 'none';
    isGlobalLoading = true;
  } else if (loadingScreenStyle && rootStyle) {
    loadingScreenStyle.display = 'none';
    rootStyle.display = 'block';
    isGlobalLoading = false;
  }
  return isGlobalLoading;
}

const useGlobalLoadingScreen = (): useGlobalLoadingScreenResults => ({
  isGlobalLoading,
  showGlobalLoadingScreen,
});

export default useGlobalLoadingScreen;
