import { saveAs } from 'file-saver';
import { put, takeEvery } from 'redux-saga/effects';
import { createAsyncAction } from 'typesafe-actions';

import { DIGISIGN_API_URL } from 'constants/environments';
import envelopeServer from 'resources/server/EnvelopeServer';
import { ActionTypes } from 'store/actions';
import type { EnvelopeHistory } from 'store/types';
import { apiCall } from 'utils/apiCall';

import { EnvelopeHistoryActions } from './actions';

const getEnvelopeHistory = createAsyncAction(
  EnvelopeHistoryActions.GET_ENVELOPE_HISTORY,
  EnvelopeHistoryActions.GET_ENVELOPE_HISTORY_DONE,
  ActionTypes.ERROR
)<number, EnvelopeHistory, string>();

const downloadEnvelopeCertificate = createAsyncAction(
  EnvelopeHistoryActions.DOWNLOAD_ENVELOPE_CERTIFICATE,
  EnvelopeHistoryActions.DOWNLOAD_ENVELOPE_CERTIFICATE_DONE,
  ActionTypes.ERROR
)<string, undefined, string>();

export function* handleDownloadEnvelopeCertificate(
  action: ReturnType<typeof downloadEnvelopeCertificate.request>
): any {
  try {
    const url = `${DIGISIGN_API_URL}/envelopes/${action.payload}/auditCertificate`;
    saveAs(url);

    yield put(downloadEnvelopeCertificate.success());
  } catch (e) {
    yield put(downloadEnvelopeCertificate.failure(e as string));
  }
}

export function* handleGetEnvelopeHistory(action: ReturnType<typeof getEnvelopeHistory.request>): any {
  try {
    const res: EnvelopeHistory = yield apiCall({
      resources: [envelopeServer, envelopeServer.GetEnvelopeHistory],
      payload: action.payload,
    });
    yield put(getEnvelopeHistory.success(res));
  } catch (e) {
    yield put(getEnvelopeHistory.failure(e as string));
  }
}

export default (): any => [
  takeEvery(EnvelopeHistoryActions.GET_ENVELOPE_HISTORY, handleGetEnvelopeHistory),
  takeEvery(EnvelopeHistoryActions.DOWNLOAD_ENVELOPE_CERTIFICATE, handleDownloadEnvelopeCertificate),
];
