import { action } from 'typesafe-actions';

import type { AddAuthProfileErrorCode, VerifyNRDSIdPayload } from './types';

export enum NRDSProfileActions {
  VERIFY_NRDS_ID = 'NRDSProfile/VERIFY_NRDS_ID',
  VERIFY_NRDS_ID_DONE = 'NRDSProfile/VERIFY_NRDS_ID_DONE',
  VERIFY_NRDS_ID_ERROR = 'NRDSProfile/VERIFY_NRDS_ID_ERROR',
  SHOW_SEARCH = 'NRDSProfile/SHOW_SEARCH',
  SET_NRDS_LAST_NAME = 'NRDSProfile/SET_NRDS_LAST_NAME',
  SET_NRDS_ID = 'NRDSProfile/SET_NRDS_ID',
  SET_ARE_TERMS_ACCEPTED = 'NRDSProfile/SET_ARE_TERMS_ACCEPTED',
  ADD_NRDS_AUTH_PROFILE_ERROR = 'NRDSProfile/ADD_NRDS_AUTH_PROFILE_ERROR',
}

export const verifyNRDSId = (
  nrdsId: string,
  lastName: string,
  source: string,
  areTermsAccepted: boolean
): {
  type: NRDSProfileActions.VERIFY_NRDS_ID;
  payload: VerifyNRDSIdPayload;
} =>
  action(NRDSProfileActions.VERIFY_NRDS_ID, {
    nrdsId,
    lastName,
    source,
    areTermsAccepted,
  });

export const showNRDSSearch = (): {
  type: NRDSProfileActions.SHOW_SEARCH;
} => action(NRDSProfileActions.SHOW_SEARCH);

export const setNRDSLastName = (
  val: string
): {
  type: NRDSProfileActions.SET_NRDS_LAST_NAME;
  payload: string;
} => action(NRDSProfileActions.SET_NRDS_LAST_NAME, val);

export const setNRDSId = (
  val: string
): {
  type: NRDSProfileActions.SET_NRDS_ID;
  payload: string;
} => action(NRDSProfileActions.SET_NRDS_ID, val);

export const setAreTermsAccepted = (
  val: boolean
): {
  type: NRDSProfileActions.SET_ARE_TERMS_ACCEPTED;
  payload: boolean;
} => action(NRDSProfileActions.SET_ARE_TERMS_ACCEPTED, val);

export const addNrdsAuthProfileError = (
  payload: AddAuthProfileErrorCode
): {
  type: NRDSProfileActions.ADD_NRDS_AUTH_PROFILE_ERROR;
  payload: AddAuthProfileErrorCode;
} => action(NRDSProfileActions.ADD_NRDS_AUTH_PROFILE_ERROR, payload);
