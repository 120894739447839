import type { UserManager } from '@skyslope/auth-js';

import { userManager } from 'auth/userManager';
import { FORM_API_URL } from 'constants/environments';
import type { PaginationOptions } from 'resources/types/PaginationOptions';
import type { FileServiceResult, FormVersion, FormVersionPage } from 'store/types';
import SieveBuilder from 'utils/sieve';

import type {
  DownloadFormVersionResponse,
  FormVersionsFilterRequest,
  InsightsByFormVersion,
} from '../types/FormVersionServer';

import Server from './Server';

export class BaseFormVersionServer extends Server {
  public constructor(manager: UserManager) {
    super(manager);
  }

  public async GetFormVersions(
    request: FormVersionsFilterRequest,
    pagination?: PaginationOptions | undefined
  ): Promise<FormVersionPage> {
    const url = `${FORM_API_URL}/form-versions?api-version=2.0`;

    const sieveBuilder: SieveBuilder = new SieveBuilder();
    const nonZeroSelectedLibraryIds =
      request.selectedLibraryIds && request.selectedLibraryIds.length > 0
        ? request.selectedLibraryIds.filter((id) => id > 0)
        : [];

    const nonZeroOptinLibraryIds =
      request.optinLibraryIds && request.optinLibraryIds.length > 0
        ? request.optinLibraryIds.filter((id) => id > 0)
        : [];

    if (nonZeroSelectedLibraryIds.length > 0) {
      sieveBuilder.equals('LibraryId', SieveBuilder.or(...nonZeroSelectedLibraryIds));
    }

    if (nonZeroOptinLibraryIds.length > 0) {
      sieveBuilder.equals('OptIns', SieveBuilder.or(...nonZeroOptinLibraryIds));
    }

    if (request.name) {
      sieveBuilder.containsIgnoreCase('Name', request.name);
    }

    sieveBuilder.page(pagination?.page ?? 1).pageSize(pagination?.pageSize ?? 50);

    if (request.formVersionIds) {
      sieveBuilder.equals('Id', SieveBuilder.or(...request.formVersionIds));
    }

    if (request.formTypes) {
      const nonEmptyFormTypes = request.formTypes.filter((formType) => formType.length > 0);
      sieveBuilder.equals('formType', SieveBuilder.or(...nonEmptyFormTypes));
    }

    let params: URLSearchParams | undefined = sieveBuilder.build();

    if (request.shouldIncludeUnpublished) {
      if (params) {
        params.append('shouldIncludeUnpublished', 'true');
      } else {
        params = new URLSearchParams({ shouldIncludeUnpublished: 'true' });
      }
    }

    if (request.onBehalfOf) {
      if (params) {
        params.append('onBehalfOf', request.onBehalfOf);
      } else {
        params = new URLSearchParams({ onBehalfOf: request.onBehalfOf });
      }
    }

    return (await this.api.get<FileServiceResult<FormVersionPage>>(url, { params })).data.result;
  }

  public async GetFormVersion(formVersionId: number): Promise<FormVersion> {
    const url = `${FORM_API_URL}/form-versions/${formVersionId}?api-version=2.0`;

    return (await this.api.get<FileServiceResult<FormVersion>>(url)).data.result;
  }

  public async DownloadFormVersion(formVersionId: number, onBehalfOf?: string): Promise<DownloadFormVersionResponse> {
    let headers;

    if (onBehalfOf) {
      headers = { 'x-on-behalf-of': onBehalfOf };
    }

    const response = await this.api.get<Blob>(`${FORM_API_URL}/form-versions/${formVersionId}/download`, {
      responseType: 'arraybuffer',
      headers,
    });

    const formVersion: DownloadFormVersionResponse = {
      data: response.data,
      contentType: response.headers['content-type'],
    };

    return formVersion;
  }

  public async DownloadFormVersionList(
    versionIds: number[],
    isSinglePdf: boolean,
    isFinalized: boolean
  ): Promise<DownloadFormVersionResponse> {
    let query = '';
    versionIds.forEach((id, i) => (i === 0 ? (query += `versionIds=${id}`) : (query += `&versionIds=${id}`)));

    if (!isSinglePdf) {
      query ? (query += '&isSinglePdf=false') : (query = 'isSinglePdf=false');
    }

    query += `&finalize=${isFinalized}`;

    const response = await this.api.get<Blob>(`${FORM_API_URL}/form-versions/download?${query}`, {
      responseType: 'arraybuffer',
    });
    const formVersion: DownloadFormVersionResponse = {
      data: response.data,
      contentType: response.headers['content-type'],
      fileName: response.headers['content-disposition'].match(/".*"/)[0].replace(/"/g, ''),
    };

    return formVersion;
  }

  public async GetInsightsByFormVersionIds(formVersionIds: number[]): Promise<InsightsByFormVersion> {
    const url = `${FORM_API_URL}/insights?formVersionIds=${formVersionIds.join(',')}`;

    return (await this.api.get<FileServiceResult<InsightsByFormVersion>>(url)).data.result;
  }
}

export default new BaseFormVersionServer(userManager);
