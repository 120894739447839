import { useEffect } from 'react';

/**
 * A helper hook that may be useful for writing unit tests and making code readable
 * @param handleKeyPress callback function for handling key press event
 */
const useAddKeyDownEvent = (handleKeyPress: (e: KeyboardEvent) => void): void => {
  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);
};

export default useAddKeyDownEvent;
