import useDerivedUserData from 'hooks/domain/user/useDerivedUserData';

const useLibraryRegionsString = (): string | undefined => {
  const { isLoading, regionLibraries } = useDerivedUserData();
  return isLoading
    ? undefined
    : Array.from(
        regionLibraries.reduce((regions: Set<string>, regionLibrary) => {
          regionLibrary.regionCodes?.forEach((value) => {
            const strippedValue = value.split('-')?.[1]; // region codes are unique in US/CA
            if (strippedValue != null) {
              regions.add(strippedValue);
            }
          });
          return regions;
        }, new Set<string>())
      ).join(',');
};

export default useLibraryRegionsString;
