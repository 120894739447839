import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import { useNHDProvidersOptions } from '../../../hooks/domain/nhd/useNHDProviders';
import useUserSettingsMutation from '../../../hooks/domain/user/mutations/useUserSettingsMutation';
import useGetUserSettingsQuery from '../../../hooks/domain/user/queries/useGetUserSettingsQuery';
import useIsMobile from '../../../hooks/useIsMobile';

type FormType = { selectedNHDProvider: string };
export const NHDUserPreferenceMissingDialog = () => {
  const isMobile = useIsMobile();
  const { data: userSettings } = useGetUserSettingsQuery();
  const nhdProviders = useNHDProvidersOptions();
  const { mutate: saveNHDProvider } = useUserSettingsMutation();
  const {
    handleSubmit,
    control,
    errors,
    formState: { isDirty },
  } = useForm<FormType>({ defaultValues: { selectedNHDProvider: '' } });

  const onSubmit = (data: FormType) => {
    saveNHDProvider({
      userPreferences: { ...userSettings?.userPreferences, nhdProviderPreference: data.selectedNHDProvider },
    });
  };

  return (
    <Dialog open fullScreen={isMobile} fullWidth maxWidth="sm" classes={{ paper: 'p-5' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          <Typography variant="h3">{"Who's your favorite NHD Provider?"}</Typography>
        </DialogTitle>
        <DialogContent className={'pb-6'}>
          <Typography variant="body1" className="pb-4">
            {
              "In order to save you time and extra clicks, we'll use your selection to automatically Order NHD reports as soon as you've gotten a signed listing agreement."
            }
          </Typography>
          <FormControl fullWidth error={!!errors.selectedNHDProvider?.message}>
            <InputLabel shrink>Select NHD Provider</InputLabel>
            <Controller
              control={control}
              name="selectedNHDProvider"
              render={(props) => (
                <Select {...props} variant="filled" fullWidth displayEmpty id="nhd-provider-select">
                  {nhdProviders.data?.map((p) => (
                    <MenuItem key={p.value} value={p.value}>
                      {p.displayName}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            {errors.selectedNHDProvider?.message && (
              <FormHelperText>{errors.selectedNHDProvider?.message}</FormHelperText>
            )}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" disabled={!isDirty} type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
