export default class SieveBuilder {
  private _filters: string[] = [];
  private _sorts: string[] = [];
  private _page: number | null = null;
  private _pageSize: number | null = null;

  private addFilter(key: string, operator: string, value: string): SieveBuilder {
    this._filters.push(`${key}${operator}${value}`);
    return this;
  }

  public static escapeCharacters(value: string): string {
    return value.replace(/[,<>|]/g, '\\$&');
  }

  public static or(...args: string[] | number[]): string {
    return args.join('|');
  }

  public equals(key: string, value: string): SieveBuilder {
    return this.addFilter(key, '==', value);
  }

  public contains(key: string, value: string): SieveBuilder {
    return this.addFilter(key, '@=', value);
  }

  public containsIgnoreCase(key: string, value: string): SieveBuilder {
    return this.addFilter(key, '@=*', value);
  }

  public startsWith(key: string, value: string): SieveBuilder {
    return this.addFilter(key, '_=', value);
  }

  public startsWithIgnoreCase(key: string, value: string): SieveBuilder {
    return this.addFilter(key, '_=*', value);
  }

  public greater(key: string, value: number): SieveBuilder {
    return this.addFilter(key, '>', value.toString());
  }

  private addSort(operator: string, key: string): SieveBuilder {
    this._sorts.push(`${operator}${key}`);
    return this;
  }

  public ascendBy(key: string): SieveBuilder {
    return this.addSort('', key);
  }

  public descendBy(key: string): SieveBuilder {
    return this.addSort('-', key);
  }

  public page(page: number): SieveBuilder {
    this._page = page;
    return this;
  }

  public pageSize(pageSize: number): SieveBuilder {
    this._pageSize = pageSize;
    return this;
  }

  public build(): URLSearchParams | undefined {
    if (this._filters.length <= 0 && this._sorts.length <= 0 && !this._pageSize) {
      return undefined;
    }
    const paramBuilder: URLSearchParams = new URLSearchParams();
    paramBuilder.append('filters', this._filters.join(','));
    paramBuilder.append('sorts', this._sorts.join(','));
    if (this._page) {
      paramBuilder.append('page', String(this._page));
    }
    paramBuilder.append('pageSize', String(this._pageSize));
    return paramBuilder;
  }
}
