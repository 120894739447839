/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Middleware, Store } from 'redux';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import type { Action } from 'typesafe-actions';

import LocalStorageItem from 'constants/LocalStorageItem';
import type { FormsListDrawerState } from 'containers/BrowseLibraries/store';
import formsListDrawerReducer from 'containers/BrowseLibraries/store';
import type { EnvelopeResendDialogState } from 'containers/EnvelopeResendDialog/store';
import envelopeResendDialogReducer from 'containers/EnvelopeResendDialog/store';
import type { SelectFormsState } from 'containers/SelectForms/store';
import selectFormsReducer from 'containers/SelectForms/store';
import type { UploadDocumentsState } from 'containers/UploadDocuments/store';
import uploadDocumentsReducer from 'containers/UploadDocuments/store';
import type { EnvelopeHistoryState } from 'pages/EnvelopeHistory/store';
import envelopeHistoryReducer from 'pages/EnvelopeHistory/store';
import type { FileCreatePageState } from 'pages/FileCreate/store';
import fileCreatePageReducer from 'pages/FileCreate/store';
import type { FileDetailsPageState } from 'pages/FileDetails/store';
import fileDetailsReducer from 'pages/FileDetails/store';
import type { FileFormsContainerState } from 'pages/FileForms/store';
import FileFormsReducer from 'pages/FileForms/store';
import type { FileDashboardPageState } from 'pages/FilesDashboard/store';
import fileDashboardPageReducer from 'pages/FilesDashboard/store';
import type { TemplateManagementPageState } from 'pages/TemplateManagement/store';
import templateManagementReducer from 'pages/TemplateManagement/store';
import type { UserOnboardingPageState } from 'pages/UserOnboarding/store';
import userOnboardingPageReducer from 'pages/UserOnboarding/store';
import type { NRDSProfileState } from 'pages/UserSettings/AuthProfileUserSettings/store';
import nrdsProfileReducer from 'pages/UserSettings/AuthProfileUserSettings/store';

import type { SignedDocumentsDrawerState } from '../pages/FileDetails/SignedDocuments/store';
import signedDocumentsDrawerReducer from '../pages/FileDetails/SignedDocuments/store';

import { FileActions } from './domain/actions';
import { ActionTypes } from './actions';
import type { GlobalState } from './globalState';
import globalStateReducer from './globalState';
import sagas from './sagas';
import type { AppAction } from './types';

function* rootSaga(): any {
  yield all([fork(sagas)]);
}

const appReducer = combineReducers({
  fileCreatePageState: fileCreatePageReducer,
  fileDetailsPageState: fileDetailsReducer,
  FileFormsState: FileFormsReducer,
  fileDashboardPageState: fileDashboardPageReducer,
  globalState: globalStateReducer,
  envelopeHistoryPageState: envelopeHistoryReducer,
  selectFormsState: selectFormsReducer,
  envelopeResendDialogState: envelopeResendDialogReducer,
  nrdsProfileState: nrdsProfileReducer,
  userOnboardingPageState: userOnboardingPageReducer,
  templateManagementPageState: templateManagementReducer,
  uploadDocumentsContainerState: uploadDocumentsReducer,
  formsListDrawerState: formsListDrawerReducer,
  signedDocumentsDrawerState: signedDocumentsDrawerReducer,
});

const rootReducer = (state: RootState | any, action: AppAction): RootState => appReducer(state, action);

export interface RootState {
  fileCreatePageState: FileCreatePageState;
  fileDetailsPageState: FileDetailsPageState;
  FileFormsState: FileFormsContainerState;
  fileDashboardPageState: FileDashboardPageState;
  selectFormsState: SelectFormsState;
  envelopeHistoryPageState: EnvelopeHistoryState;
  globalState: GlobalState;
  envelopeResendDialogState: EnvelopeResendDialogState;
  nrdsProfileState: NRDSProfileState;
  userOnboardingPageState: UserOnboardingPageState;
  templateManagementPageState: TemplateManagementPageState;
  uploadDocumentsContainerState: UploadDocumentsState;
  formsListDrawerState: FormsListDrawerState;
  signedDocumentsDrawerState: SignedDocumentsDrawerState;
}

const sagaMiddleware = createSagaMiddleware();

const makeStore = (): Store<RootState, Action<string>> => {
  const middleware: Middleware[] = [sagaMiddleware];
  if (process.env.NODE_ENV !== 'production') {
    const filteredLogger = createLogger({
      predicate: (_, action) => {
        if (process.env.REACT_APP_SHOW_REDUX_LOGS === 'FALSE') {
          for (const type of Object.values(ActionTypes)) {
            if (action.type === type) return false;
          }

          for (const type of Object.values(FileActions)) {
            if (action.type === type) return false;
          }
        }

        return true;
      },
      collapsed: (_, __, logEntry) => !logEntry?.error,
    });

    middleware.push(filteredLogger);
  }
  const enhancers = [applyMiddleware(...middleware)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  const isReduxDebuggingEnabled =
    process.env.NODE_ENV !== 'production' || window.localStorage.getItem(LocalStorageItem.ReduxDebugging) === 'true';
  const composeEnhancers =
    isReduxDebuggingEnabled && typeof window === 'object' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;

  const store = createStore(rootReducer, {} as RootState, composeEnhancers(...enhancers));

  sagaMiddleware.run(rootSaga);
  return store;
};

const store = makeStore();

export default store;
