import type { UserManager } from '@skyslope/auth-js';
import { createUserManager, TokenDomain } from '@skyslope/auth-js';

import { routes } from 'constants/routes';
import history from 'utils/history';

import { isAppRunningInIframe } from '../utils/isAppRunningInIframe';

const doesIframeParentSupport3rdPartyCookies = (): boolean => {
  // Set a cookie in the iframe, test if it exists, then delete it
  document.cookie = 'thirdparty=yes; Max-Age=3600; SameSite=None; Secure';
  const thirdPartyCookiesSupported = /thirdparty=yes/.test(document.cookie);
  document.cookie = 'thirdparty=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  return thirdPartyCookiesSupported;
};

// new substype to encapsule common implementations
export type FormsUserManager = UserManager;

const idpMappings: Map<string, (userManager: UserManager) => string> = new Map([
  ['primeAuth', (userManager) => userManager.idps.primeAuth],
  ['ure', (userManager) => userManager.idps.associations.ure],
  ['glar', (userManager) => userManager.idps.associations.glar],
  ['flex_mls', (userManager) => userManager.idps.platforms.flex_mls],
  ['realmls', (userManager) => userManager.idps.associations.realmls],
  ['trreb', (userManager) => userManager.idps.associations.trreb],
  ['stellar', (userManager) => userManager.idps.associations.stellar],
  ['sfaor', (userManager) => userManager.idps.associations.sfaor],
  ['gaar', (userManager) => userManager.idps.associations.gaar],
  ['weichert', (userManager) => userManager.idps.associations.weichert],
  ['nmmls', (userManager) => userManager.idps.associations.nmmls],
  ['recolorado', (userManager) => userManager.idps.associations.recolorado],
  ['paragon', (userManager) => userManager.idps.associations.paragon],
  ['trreb_amp', (userManager) => userManager.idps.associations.trreb_amp],
  ['brightmls', (userManager) => userManager.idps.associations.bright],
  ['silvercity', (userManager) => userManager.idps.associations.silvercity],
  ['alamogordo', (userManager) => userManager.idps.associations.alamogordo],
  ['newmexico', (userManager) => userManager.idps.associations.newmexico],
  ['taosmls', (userManager) => userManager.idps.associations.taosmls],
]);

export const getIdpByKey = (idpKey: string, userManager: UserManager): string | null => {
  const idpKeyLowerCase = idpKey.toLowerCase();
  const idpMapper = idpMappings.get(idpKeyLowerCase);
  return idpMapper ? idpMapper(userManager) : idpKey;
};

/**
 * this function will be cleaned up once the ssoAmp feature flag is removed and Trreb is migrated to Amp
 * @param idp
 * @param ssoAmp
 * @param userManager
 * @returns {string}
 */
export const computeTrebbAmpMigrationIdp = (idp: string, ssoAmp: boolean, userManager: UserManager): string => {
  if (!ssoAmp) return idp; //if ssoAmp is false, return the idp as is
  if (idp === userManager.idps.associations.trreb) return userManager.idps.associations.trreb_amp;
  return idp; //optherwise return the idp as is
};

const _userManager: FormsUserManager = createUserManager({
  environment: window.auth.okta.env,
  tokenDomain: TokenDomain.Global,
  providers: {
    okta: {
      clientId: window.auth.okta.clientId,
      redirectPath: routes.authCallback,
      postLogoutRedirectPath: routes.loggedOut,
      scopes: [
        'openid',
        'email',
        'profile',
        'com.skyslope.prime.subscriber',
        'com.skyslope.groups',
        'com.skyslope.forms.api',
        'com.skyslope.digisign.userid',
        'com.skyslope.forms.accountid',
        'com.skyslope.marketplace.api',
      ],
      devMode: false,
    },
    primeAuth: {
      clientId: window.auth.primeAuth.clientId,
      redirectPath: routes.primeAuthCallback,
      postLogoutRedirectPath: routes.loggedOut,
    },
  },
  onAuthRequired: async (userManager: UserManager) => {
    if (isAppRunningInIframe()) {
      if (!doesIframeParentSupport3rdPartyCookies()) {
        // eslint-disable-next-line no-console
        console.error('third party cookies are not supported');
        const loader = document.querySelector('#loader') as HTMLElement;
        if (loader) loader.style.display = 'block';
        return; // do not redirect to login page, instead show loading error message
      }
    }

    let idpMappedFromKey;
    const params = new URLSearchParams(location.search);
    const idpKey: string = params.get('idp') ?? '';

    if (idpKey) {
      idpMappedFromKey = getIdpByKey(idpKey, userManager);
    }

    if (idpMappedFromKey) {
      await userManager.login({ idp: computeTrebbAmpMigrationIdp(idpMappedFromKey, true, userManager) });
    } else {
      if (window.auth.externalLoginUrl) window.location.assign(window.auth.externalLoginUrl);
      else history.push(routes.login);
    }
  },
});

// make subtype members required by casting
// Liskov Substitution Principle: objects of a superclass shall be replaceable
// with objects of its subclasses without breaking the application
// This allows UserManager to continue to be the predominantly referenced type
// in the app. Subtype references can cast UserManager to FormsUserManager accordingly
export const userManager: FormsUserManager = _userManager as FormsUserManager;
