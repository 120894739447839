import React, { useCallback, useState } from 'react';
import { Toolbar } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import GlobalStyles from '@mui/material/GlobalStyles';
import { useTheme } from '@mui/material/styles';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

import SkyslopeDefaultImg from 'assets/images/ss-apps/SkySlopeLogoDefault.svg';
import { TrialBanner } from 'components/TrialBanner/TrialBanner';
import { useThemeQueryParam } from 'hooks/useThemeQueryParam';
import type { Paths } from 'store/types';

import FormsTabs from '../Tabs/FormsTabs';

import MobileMenuMainContent from './components/MobileMenuMainContent';
import MobileMenuToolbar from './components/MobileMenuToolbar';

const debounceWaitMs = 300;
const minScrollToOpenClose = 100;
const openIfAbove = 5;

export interface MobileMenuProps {
  title?: string;
  tabs: Paths;
  smallHeader?: boolean;
  breadcrumb?: Paths;
}

const MobileMenu: React.FC<MobileMenuProps> = (props) => {
  const { tabs = [], smallHeader = false } = props;
  const theme = useTheme();
  const themeQueryParam = useThemeQueryParam();

  const [open, setOpen] = useState(false);
  const [isHideOnScroll, setIsHideOnScroll] = useState(true);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      let isShow = isHideOnScroll;
      if (currPos.y < openIfAbove) {
        isShow = true;
      } else if (currPos.y > prevPos.y + minScrollToOpenClose) {
        isShow = false;
      } else if (currPos.y < prevPos.y - minScrollToOpenClose) {
        isShow = true;
      }
      if (isShow !== isHideOnScroll) setIsHideOnScroll(isShow);
    },
    [isHideOnScroll],
    undefined,
    true,
    debounceWaitMs
  );

  const handleDrawerOpen = useCallback((): void => {
    setOpen(true);
  }, []);
  const handleDrawerClose = useCallback((): void => {
    setOpen(false);
  }, []);

  if (themeQueryParam === 'agnostic') {
    return (
      <AppBar
        elevation={4}
        color="inherit"
        position="sticky"
        id="mobile-main-menu-nav-bar"
        sx={{ marginBottom: theme.spacing(4) }}
        className="m-0"
      >
        <Toolbar className="pl-0 flex items-center justify-center min-h-[64px]">
          <img alt="SkySlope" src={SkyslopeDefaultImg} width={146} height={30} />
        </Toolbar>
      </AppBar>
    );
  }

  return (
    <>
      <GlobalStyles
        styles={{
          body: {
            // NOTE: in the long run both of these can be removed for ResizeObserver
            '--header-height': theme.spacing(10),
            '--header-height-with-fallback': theme.spacing(24),
          },
          a: {
            textDecoration: 'none',
          },
        }}
      />
      <AppBar elevation={4} color="inherit" position="sticky" id="mobile-main-menu-nav-bar">
        <Collapse in={isHideOnScroll} sx={{ backgroundColor: '#fff' }}>
          <MobileMenuToolbar {...props} handleDrawerOpen={handleDrawerOpen} />
        </Collapse>
        {!!tabs.length && (
          <Box
            component="div"
            sx={{
              paddingTop: theme.spacing(3),
              '& div': {
                display: 'flex',
                justifyContent: 'space-around',
                flexFlow: 'row wrap',
                alignItems: 'stretch',
                marginLeft: 0,
                '& button': {
                  backgroundColor: 'transparent',
                  flexGrow: 1,
                  width: '1px',
                  margin: 0,
                  textAlign: 'center',
                  '& p': {
                    margin: 0,
                    width: 'auto!important', // important because overriding of what is on the item from desktop
                  },
                },
              },
            }}
          >
            <FormsTabs paths={tabs} />
          </Box>
        )}
        <TrialBanner />
      </AppBar>
      {!smallHeader && (
        <SwipeableDrawer
          id="sidebar-drawer"
          anchor="left"
          open={open}
          onClose={handleDrawerClose}
          onOpen={handleDrawerOpen}
          sx={{
            '& .MuiSwipeableDrawer-paper': {
              width: '100%',
              maxWidth: 320,
            },
          }}
        >
          <MobileMenuMainContent handleDrawerClose={handleDrawerClose} />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default MobileMenu;
