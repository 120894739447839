import React from 'react';
import Box from '@mui/material/Box';
import type { ButtonProps } from '@mui/material/Button';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import { colors } from '@skyslope/mache';
import classNames from 'classnames';

const PREFIX = 'LoaderButton';

const classes = {
  wrapper: `${PREFIX}-wrapper`,
  loadingProgress: `${PREFIX}-loadingProgress`,
  fullWidth: `${PREFIX}-fullWidth`,
};

const StyledBox = styled(Box)(() => ({
  [`&.${classes.wrapper}`]: {
    position: 'relative',
    display: 'flex',
  },

  [`& .${classes.loadingProgress}`]: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
    color: colors.blue[900],
  },

  [`&.${classes.fullWidth}`]: {
    width: '100%',
  },
}));

export interface LoaderButtonProps extends ButtonProps {
  // ButtonProps from MaterialUI
  isLoading?: boolean;
  buttonClassName?: string;
}
const LoaderButton: React.FC<LoaderButtonProps> = ({
  isLoading = false,
  buttonClassName,
  children,
  disabled,
  ...other
}) => (
  <StyledBox
    component={other.fullWidth ? 'div' : 'span'}
    className={classNames(
      {
        [classes.wrapper]: other.fullWidth,
        [classes.fullWidth]: other.fullWidth,
      },
      other.className
    )}
  >
    <Button
      disabled={disabled || isLoading}
      fullWidth={other.fullWidth}
      className={classNames(buttonClassName, other.className)}
      {...other}
    >
      {children}
      {isLoading && <CircularProgress className={classes.loadingProgress} size={24} />}
    </Button>
  </StyledBox>
);

export default LoaderButton;
