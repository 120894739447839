import React from 'react';
import { Redirect } from 'react-router-dom';
import { datadogLogs } from '@datadog/browser-logs';
import { LoginCallback as AuthLoginCallback, useAuth } from '@skyslope/auth-react';

import { routes } from 'constants/routes';
import DefaultAppBar from 'pages/ErrorBoundary/components/DefaultAppBar';

import LoginCallbackPlaceholderMessage from './LoginCallbackPlaceholderMessage';

const LoginCallback: React.FC = (props) => {
  const { authState } = useAuth();

  const shouldUseWorkaround =
    !authState.isAuthenticated &&
    authState.error &&
    authState.error.message === 'The redirectUri passed to /authorize must also be passed to /token';
  if (shouldUseWorkaround) {
    datadogLogs.logger.debug('client redirect due to okta workaround');
  }

  return (
    <>
      <DefaultAppBar />
      {shouldUseWorkaround && <Redirect to={routes.homeTownBuffet} />}
      <LoginCallbackPlaceholderMessage />
      <AuthLoginCallback {...props} />
    </>
  );
};

export default LoginCallback;
