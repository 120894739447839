import React, { createContext, useState } from 'react';

import type { ConfirmationDialogProps } from 'components/ConfirmationDialog';
import ConfirmationDialog from 'components/ConfirmationDialog';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ConfirmationDialogOptions
  extends Omit<ConfirmationDialogProps, 'confirmCallback' | 'cancelCallback' | 'checkboxCallBack'> {}

interface ConfirmationDialogPayload {
  dialogOptions: ConfirmationDialogOptions;
  confirmCallback: () => void;
  cancelCallback?: () => void;
  checkboxCallBack?: () => void;
}

export interface ConfirmationServiceContextType {
  confirm: (
    dialogContent: ConfirmationDialogOptions | string,
    confirmCallback: () => void,
    cancelCallback?: () => void,
    checkboxCallBack?: () => void
  ) => void;
  closeDialog: () => void;
}
export const ConfirmationServiceContext = createContext<ConfirmationServiceContextType>({
  confirm: () => {},
  closeDialog: () => {},
});

/**
 * This component acts as a placeholder for a dialog in the location the provider is placed.
 * A context consumer can modify the contents of the dialog. see useConfirmation hook
 */
export const ConfirmationDialogServiceProvider: React.FC = ({ children }) => {
  const [payload, setPayload] = useState<ConfirmationDialogPayload | null>(null);

  return (
    <>
      <ConfirmationServiceContext.Provider
        value={{
          confirm: (
            dialogContent: ConfirmationDialogOptions | string,
            confirmCallback: () => void,
            cancelCallback?: () => void,
            checkboxCallBack?: () => void
          ) => {
            const dialogOptions: ConfirmationDialogOptions =
              typeof dialogContent === 'string' ? { description: dialogContent } : dialogContent;
            setPayload({
              dialogOptions,
              confirmCallback,
              cancelCallback,
              checkboxCallBack,
            });
          },
          closeDialog: () => {
            setPayload(null);
          },
        }}
      >
        {children}
      </ConfirmationServiceContext.Provider>

      {payload && (
        <ConfirmationDialog
          {...payload.dialogOptions}
          confirmCallback={() => {
            payload.confirmCallback();
            setPayload(null);
          }}
          cancelCallback={() => {
            setPayload(null);
            if (payload.cancelCallback) payload.cancelCallback();
          }}
          checkboxCallBack={() => {
            if (payload.checkboxCallBack) payload.checkboxCallBack();
          }}
        />
      )}
    </>
  );
};
