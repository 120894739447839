import type { Theme } from '@mui/material/styles';
import { typography } from '@skyslope/mache';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useMenuItemStyles = (theme: Theme) => ({
  link: {
    marginLeft: theme.spacing(3),
    border: 0,
    padding: 0,
    marginTop: 0,
    cursor: 'pointer',
    backgroundColor: '#fff',
  },
  menuItem: {
    textDecoration: 'none',
    '&:hover': {
      ...typography.body2,
    },
  },
  menuItemSelected: {
    ...typography.body2,
  },
});

export default useMenuItemStyles;
