import type { RootState } from 'store';

import type { FormLibrary } from 'store/types';

export const targetOptInLibrariesSelector = (state: RootState): FormLibrary[] =>
  state.userOnboardingPageState.targetOptInLibraries;

//TODO move this ?
export enum StampingFields {
  AgentName = 'agentName',
  BrokerageName = 'brokerageName',
  BrokerageAddress = 'brokerageAddress',
  BrokeragePhone = 'brokeragePhone',
}
