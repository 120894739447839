import { all, put, select, takeEvery } from 'redux-saga/effects';
import { createAsyncAction } from 'typesafe-actions';

import contactsServer from 'resources/server/ContactsServer';
import digiSignServer from 'resources/server/DigiSignServer';
import type { GetRecipientsRequest, GetRecipientsResponse } from 'resources/types/GetRecipients';
import type { UpdateRecipientPayload } from 'resources/types/UpdateRecipient';
import { ActionTypes } from 'store/actions';
import type { Contact, Ds3Recipient } from 'store/types';
import { apiCall } from 'utils/apiCall';
import nameFormatter from 'utils/nameFormatter';

import { addErrorNotification } from '../../Notifier/actions';

import { EnvelopeResendDialogActions, getContacts, getRecipients } from './actions';
import { contactsSelector, signersWhoHaveNotSignedSelector } from './selectors';

const getRecipientsAsync = createAsyncAction(
  EnvelopeResendDialogActions.GET_RECIPIENTS,
  EnvelopeResendDialogActions.GET_RECIPIENTS_DONE,
  ActionTypes.ERROR
)<GetRecipientsRequest, GetRecipientsResponse, string>();

const updateRecipientAsync = createAsyncAction(
  EnvelopeResendDialogActions.UPDATE_RECIPIENT,
  EnvelopeResendDialogActions.UPDATE_RECIPIENT_DONE,
  EnvelopeResendDialogActions.UPDATE_RECIPIENT_ERROR
)<UpdateRecipientPayload, number, string>();

const getFileContacts = createAsyncAction(
  EnvelopeResendDialogActions.GET_CONTACTS,
  EnvelopeResendDialogActions.GET_CONTACTS_DONE,
  EnvelopeResendDialogActions.GET_CONTACTS_ERROR
)<number, Contact[], string>();

export function* handleGetFileContacts(action: ReturnType<typeof getFileContacts.request>): any {
  try {
    const fileId: number = action.payload;
    const contacts = yield apiCall({
      resources: [contactsServer, contactsServer.GetContacts],
      payload: fileId,
    });
    yield put(getFileContacts.success(contacts));
  } catch (e) {
    yield put(getFileContacts.failure('Unable to get file contacts'));
  }
}

export function* handleGetRecipients(action: ReturnType<typeof getRecipientsAsync.request>): any {
  try {
    const request: GetRecipientsRequest = {
      ...action.payload,
    };
    const response: GetRecipientsResponse = yield apiCall({
      resources: [digiSignServer, digiSignServer.getRecipients],
      payload: request,
    });
    yield put(getRecipientsAsync.success(response));
  } catch (error) {
    yield put(getRecipientsAsync.failure(error as string));
  }
}

export function* handleUpdateRecipient(action: ReturnType<typeof updateRecipientAsync.request>): any {
  const currentContacts: Contact[] = yield select(contactsSelector);
  const currentRecipients: Ds3Recipient[] = yield select(signersWhoHaveNotSignedSelector);
  try {
    if (action.payload.updatedContact) {
      const request: UpdateRecipientPayload = {
        ...action.payload,
      };

      if (action.payload.updatedContact.id) {
        yield all([
          apiCall({
            resources: [digiSignServer, digiSignServer.updateRecipient],
            payload: request,
          }),
          apiCall({
            resources: [contactsServer, contactsServer.UpdateContact],
            payload: action.payload.updatedContact,
          }),
        ]);
      } else {
        yield apiCall({
          resources: [digiSignServer, digiSignServer.updateRecipient],
          payload: request,
        });
      }
    }
  } catch (error) {
    const contactToRollback = currentContacts.find((c) => c.id === action.payload.updatedContact?.id);
    const recipientToRollback = currentRecipients.find((r) => r.id === action.payload.recipientId);
    const request: UpdateRecipientPayload = {
      ...action.payload,
      updatedContact: {
        ...contactToRollback,
        email: recipientToRollback?.email,
      } as Contact,
    };

    if (contactToRollback?.id) {
      yield all([
        apiCall({
          resources: [digiSignServer, digiSignServer.updateRecipient],
          payload: request,
        }),
        apiCall({
          resources: [contactsServer, contactsServer.UpdateContact],
          payload: contactToRollback,
        }),
      ]);
    } else {
      yield apiCall({
        resources: [digiSignServer, digiSignServer.updateRecipient],
        payload: request,
      });
    }

    yield put(
      addErrorNotification({
        message: `Failed to update ${nameFormatter({
          firstName: action.payload.updatedContact?.firstName || '',
          middleName: action.payload.updatedContact?.middleName,
          lastName: action.payload.updatedContact?.lastName || '',
        })}'s email address, please try again.`,
      })
    );
    yield put(updateRecipientAsync.failure(error as string));
    return;
  }

  try {
    if (action.payload.shouldNotifyRecipient) {
      const request: UpdateRecipientPayload = {
        ...action.payload,
      };

      const response: number = yield apiCall({
        resources: [digiSignServer, digiSignServer.createEmail],
        payload: request,
      });
      yield put(updateRecipientAsync.success(response));
    } else {
      yield put(updateRecipientAsync.success(1));
    }
  } catch (error) {
    const recipient = currentRecipients.find((r) => r.id === action.payload.recipientId);
    yield put(
      addErrorNotification({
        message: `Failed to send a reminder to ${nameFormatter({
          firstName: recipient?.firstName || '',
          middleName: recipient?.middleName,
          lastName: recipient?.lastName || '',
        })}, please try again.`,
      })
    );
    yield put(updateRecipientAsync.failure(error as string));
  }

  if (action.payload.updatedContact) {
    yield put(getContacts(action.payload.fileId));
    yield put(getRecipients({ externalId: action.payload.envelopeId }));
  }
}

export default (): any => [
  takeEvery(EnvelopeResendDialogActions.GET_RECIPIENTS, handleGetRecipients),
  takeEvery(EnvelopeResendDialogActions.UPDATE_RECIPIENT, handleUpdateRecipient),
  takeEvery(EnvelopeResendDialogActions.GET_CONTACTS, handleGetFileContacts),
];
