import React from 'react';
import AppBar from '@mui/material/AppBar';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { SrOnly } from '@skyslope/mache';

import SkyslopeImg from 'assets/images/ss-apps/SkySlope.svg';
import { routes } from 'constants/routes';

const DefaultAppBar: React.FC = () => {
  const theme = useTheme();
  return (
    <AppBar
      elevation={4}
      color="inherit"
      position="sticky"
      sx={{
        width: '100vw',
        height: theme.spacing(10),
      }}
    >
      <Toolbar>
        <a href={routes.homeTownBuffet} style={{ marginTop: theme.spacing(2) }}>
          <SrOnly>Go to files dashboard</SrOnly>
          <img alt="SkySlope Forms" src={SkyslopeImg} width={120} height={30} />
        </a>
      </Toolbar>
    </AppBar>
  );
};

export default DefaultAppBar;
