import React from 'react';
import { Link } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { default as MuiBreadcrumbs } from '@mui/material/Breadcrumbs';
import Skeleton from '@mui/material/Skeleton';
import { css, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import type { Paths } from 'store/types';

import useMenuItemStyles from './MenuItemStyles';

import { css as emotionCss } from '@emotion/css';

interface BreadcrumbsProps {
  breadcrumbs?: Paths;
}

const MainMenuBreadcrumbs: React.FC<BreadcrumbsProps> = ({ breadcrumbs }) => {
  const theme = useTheme();
  const menuItemClasses = useMenuItemStyles(theme);

  if (breadcrumbs === undefined) return null;

  if (!breadcrumbs.length) {
    return <Skeleton sx={{ marginLeft: theme.spacing(4) }} animation="wave" width={124} height={20} />;
  }

  return (
    <MuiBreadcrumbs>
      {breadcrumbs.map((breadcrumb, i) => {
        if (!breadcrumb.label) {
          return <Skeleton key={i} sx={{ marginLeft: theme.spacing(4) }} animation="wave" width={124} height={30} />;
        }
        return (
          <button key={breadcrumb.path} color="#fff" tabIndex={-1} className={emotionCss(css(menuItemClasses.link))}>
            <Link tabIndex={0} to={breadcrumb.path}>
              <Typography
                variant="body1"
                sx={{
                  ...menuItemClasses.menuItem,
                  paddingLeft: theme.spacing(1),
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <ArrowBackIosIcon sx={{ width: 16, height: 24, color: theme.palette.grey[500] }} />
                {breadcrumb.label}
              </Typography>
            </Link>
          </button>
        );
      })}
    </MuiBreadcrumbs>
  );
};

export default MainMenuBreadcrumbs;
