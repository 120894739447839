import type { UseQueryOptions } from '@tanstack/react-query';

import { useFeatureFlags } from 'hooks/useFeatureFlags';

import type { SubscriptionExpiration } from '../../../../resources/types/GetUserSubscriptionSettings';

import useQueryParams from './../../../../hooks/useQueryParams';
import useGetUserSubscriptionQuery from './useGetUserSubscriptionQuery';
import useUpdateUserSubscriptionQuery from './useUpdateUserSubscriptionQuery';

const useGetUserSubscription = (options: UseQueryOptions<{ enabled: boolean }>): SubscriptionExpiration => {
  const queryParams = useQueryParams();
  const paymentSuccess = queryParams.get('paymentSuccess');
  const { isFetched } = useUpdateUserSubscriptionQuery({ enabled: true });
  const { data: userSubscription } = useGetUserSubscriptionQuery({
    enabled: ((isFetched || !paymentSuccess) && options.enabled) ?? false,
  });
  const { isSubscriptionRequired } = useFeatureFlags();

  const isCurrentDatePastExpDate = (expirationDate: string): boolean => {
    const currentDate = new Date().toISOString().slice(0, 10);
    return currentDate > expirationDate;
  };

  const daysLeftInTrial = userSubscription?.trial?.daysLeftInTrial ?? 0;
  const subscriptionExpired =
    isSubscriptionRequired && !!userSubscription && new Date(userSubscription?.subscription.validUntil) < new Date();
  const trialExpired =
    isSubscriptionRequired &&
    !!userSubscription &&
    userSubscription?.trial?.endDate &&
    new Date(userSubscription?.trial.endDate) < new Date();
  const validSubscriptionExpired =
    isSubscriptionRequired &&
    !!userSubscription &&
    new Date(userSubscription.subscription.validUntil) > new Date('1970-01-01') &&
    isCurrentDatePastExpDate(userSubscription.subscription.validUntil as unknown as string);

  return {
    subscriptionExpired,
    trialExpired,
    daysLeftInTrial,
    validSubscriptionExpired,
  };
};

export default useGetUserSubscription;
