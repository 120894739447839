import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import type * as H from 'history';

export interface UseLocationHistoryRestult {
  currentLocation: H.Location<H.LocationState>; // Can't find the types
  locationHistory: H.Location<H.LocationState>[];
}

const useLocationHistory = (): UseLocationHistoryRestult => {
  const location = useLocation();
  const [locationHistory, setLocationHistory] = useState<H.Location<H.LocationState>[]>([location]);

  useEffect(() => {
    if (location !== locationHistory[locationHistory.length - 1]) {
      setLocationHistory((p) => {
        const previous = [...p];
        previous.push(location);
        return previous;
      });
    }
  }, [location, locationHistory]);

  return {
    locationHistory,
    currentLocation: location,
  };
};

export default useLocationHistory;
