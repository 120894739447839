import React from 'react';
import Box from '@mui/material/Box';
import { css, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { getTermsSx } from '../store/types';

import { css as emotionCss } from '@emotion/css';

export interface OreaTermsAndConditionsViewProps {
  title: string;
  inlineView?: boolean;
}

const OreaTermsAndConditionsTermsView: React.FC<OreaTermsAndConditionsViewProps> = ({ title, inlineView = false }) => {
  const theme = useTheme();
  const termsSx = getTermsSx(theme);
  return (
    <Box right="0" sx={termsSx.contentWrapper} p={inlineView ? 2 : 8}>
      <Box mb={4}>
        <Typography id="typTitle" variant={inlineView ? 'h6' : 'h4'} sx={termsSx.title}>
          {title}
        </Typography>
        <Typography variant="caption">Effective Date: June 1, 2021</Typography>
      </Box>
      <Typography variant="body1">
        This End User Agreement (“<b>Agreement</b>”) is a binding legal Agreement between you (“<b>You</b>”) and
        SkySlope (“<b>Licensee</b>”) and states the terms and conditions under which You may access and use the SkySlope
        Forms Application (“<b>Application</b>”) and the Forms and Clauses (“<b>Forms and Clauses</b>”) accessible
        through the Application.
      </Typography>
      <Typography variant="body1">
        By clicking “I Agree” You are indicating Your agreement to be bound by comply with the terms and conditions of
        this Agreement.
      </Typography>

      <ol>
        <li>
          <Typography variant="h6">LICENSE</Typography>{' '}
          <Typography component="span" variant="body1">
            Subject to the terms and conditions of this Agreement, Licensee grants You the following personal,
            non-exclusive, non-transferable licenses:
            <ol className={emotionCss(css(termsSx.lowercaseList))}>
              <li> a license to access and use the Application;</li>
              <li>
                {' '}
                a license to download and reproduce the Forms and Clauses and to Use the Forms and Clauses in
                Transactions;
              </li>
            </ol>{' '}
            Apart from the licenses expressly granted herein, no other license, right, title, or interest in and to the
            Application or Forms and Clauses is granted to You under this Agreement.
          </Typography>
        </li>
        <li>
          {' '}
          <Typography variant="h6">RESTRICTIONS</Typography>
          <Typography component="span" variant="body1">
            <ol className={emotionCss(css(termsSx.lowercaseList))}>
              <li>
                {' '}
                You agree that You shall only use the Application, the Forms and Clauses and any User-created Forms in
                accordance with the terms and conditions of this Agreement and only for Use by You in Transactions. Any
                Use or other exploitation of the Application, the Forms and Clauses or any User-created Forms which is
                not expressly permitted under this Agreement is strictly prohibited;
              </li>
              <li>
                {' '}
                Without limiting the generality of the foregoing, You agree that You shall not:
                <ol className={emotionCss(css(termsSx.romanSmallList))}>
                  <li>
                    {' '}
                    Use the Forms and Clauses (or any User-created Forms) for any purpose other than in Transactions;
                  </li>
                  <li>
                    {' '}
                    distribute or provide access to any Forms and Clauses (or any User-created Forms) to any Person who
                    is not party to a Transaction for which You have entered into an agreement with a buyer or seller;
                  </li>
                  <li>
                    {' '}
                    permit any Person who is not a member of the Ontario Real Estate Association (“OREA”) to access, or
                    use the Application or to access and Use the Forms and Clauses (or any User-created Forms) except
                    for the provision of any Form or User-created Form which is provided to any Person who is a party to
                    a Transaction for which You have entered into an agreement with a buyer or seller and which is Used
                    in said Transaction;
                  </li>
                  <li>
                    {' '}
                    remove, obscure or amend any trademarks or any copyright or proprietary rights notices which appear
                    in or on any Forms and Clauses;
                  </li>
                  <li>
                    {' '}
                    attempt to gain access to the software or code comprising the Application or attempt to disassemble,
                    decrypt, extract or reverse engineer such software or code (or assist any third party in doing so).
                  </li>
                </ol>
              </li>
            </ol>
          </Typography>
        </li>
        <li>
          {' '}
          <Typography variant="h6">USER-CREATED FORMS</Typography>
          <Typography component="span" variant="body1">
            {' '}
            You are entitled to change, modify and amend the Forms and Clauses by (i) completing the customizable
            portions of such Forms, (ii) adding one or more Clauses (in whole or in part) or other language to any Form,
            (iii) changing, amending or deleting the preset or non-customizable portion of any Form or clause provided
            that in respect of any change or alteration to any pre-set portion of any Form such change is clearly
            identified as a change to the original Form or Clause provided to You by Licensee. For the purpose of
            clarity, a change in which is in hand written form, or which is highlighted, underlined or black-lined shall
            be considered clearly identified as a change.
          </Typography>
        </li>
        <li>
          {' '}
          <Typography variant="h6">UPDATES</Typography>
          <Typography component="span" variant="body1">
            {' '}
            You acknowledge and agree that Licensee may on instruction from OREA, from time-to-time and at any time,
            without notice, update, change or amend the Forms and Clauses (including discontinuing publication of the
            Forms and Clauses in whole or in part). Once a Form or Clause has been updated or amended You shall
            discontinue use of any previous version of such Form or Clause and use only the updated Form or Clause. In
            the event that You are advised by Licensee or OREA that any Forms or Clauses must be updated or removed from
            usage on an urgent basis (whether due to statutory amendment or otherwise) You shall immediately cease use
            of the affected Form(s) or Clause(s) as directed by Licensee or OREA You acknowledge and agree that neither
            Licensee nor OREA has any obligation to update the Forms and Clauses and shall have no liability to You in
            respect of any change or amendment to the Forms and Clauses (including for any discontinuance thereof);
          </Typography>
        </li>
        <li>
          {' '}
          <Typography variant="h6">PROPRIETARY RIGHTS</Typography>
          <Typography component="span" variant="body1">
            <ol className={emotionCss(css(termsSx.lowercaseList))}>
              <li>
                {' '}
                The Application and the Forms and Clauses and the Use thereof are protected by copyright and other laws.
                You acknowledge and agree that:
                <ol className={emotionCss(css(termsSx.romanSmallList))}>
                  <li>
                    {' '}
                    the Application (and all rights therein including all Intellectual Property Rights therein) is owned
                    by Licensee (and its licensors) and shall remain the sole and exclusive property of Licensee at all
                    times;
                  </li>
                  <li>
                    {' '}
                    the Forms and Clauses (and all rights therein including all Intellectual Property Rights therein),
                    are owned by OREA and shall remain the sole and exclusive property of OREA at all times; and
                  </li>
                  <li>
                    {' '}
                    Your rights in any User-created Form(s), are strictly limited to ownership of the text of any
                    changes, amendments or modifications You make to any Forms or Clauses and remain subject, at all
                    times, to OREA’s ownership of the Forms and Clauses (and all elements thereof including all elements
                    of the Forms and Clauses contained or included in any User-created Form).
                  </li>
                </ol>
              </li>
              <li>
                {' '}
                You agree that You shall not do anything inconsistent with Licensee’s ownership of the Application or
                OREA’s ownership of the Forms and Clauses during or subsequent to the Term of this Agreement.
              </li>
            </ol>
          </Typography>
        </li>
        <li>
          {' '}
          <Typography variant="h6">REGISTRATION</Typography>
          <Typography component="span" variant="body1">
            <ol className={emotionCss(css(termsSx.lowercaseList))}>
              <li>
                {' '}
                You agree to provide accurate, current and complete information about yourself as prompted during the
                registration process to access and Use the Licensee Application and to maintain and promptly update such
                information to keep it accurate, current and complete. If Licensee has reasonable grounds to suspect
                that any such information you provide is inaccurate, not current or incomplete, it has the right to
                terminate this Agreement (including Your access to and Use of the Application and the Forms and
                Clauses). Licensee’s use of any personally identifiable information (“Your Information”) you provide as
                part of the registration process is governed by the terms of Licensee’s Privacy Policy. You acknowledge
                and agree that Licensee is entitled to share Your Information with OREA for purposes of administration
                of the Forms and Clauses and the enforcement of OREA’s related rights
              </li>
              <li>
                {' '}
                Your account is personal to You. You must not share Your account with others or allow others to access
                and Use the Application or the Forms and Clauses using Your account. You are responsible for maintaining
                the confidentiality of Your password and account information, and are responsible for all activities
                that occur under Your password or account. You agree to notify Licensee immediately of any unauthorized
                use of Your password or account or any other breach of security, and to ensure that You exit Your
                account at the end of each session. Licensee may terminate this Agreement if it has reason to believe
                that You are allowing others to access and Use the Application and the Forms and Clauses and any
                User-created Forms using Your account. Licensee cannot and will not be liable for any loss or damage
                arising from Licensee’s or Your failure to protect your password or account information.
              </li>
            </ol>
          </Typography>
        </li>
        <li>
          {' '}
          <Typography variant="h6">REPRESENTATIONS, WARRANTIES AND DISCLAIMER</Typography>
          <Typography component="span" variant="body1">
            <ol>
              <li className={emotionCss(css(termsSx.lowercaseList))}>
                {' '}
                You represent, warrant and covenant to Licensee that You are an OREA Member in good standing;
              </li>
              <li>
                {' '}
                Neither Licensee nor OREA makes any (and there are no) representations, warranties, conditions or
                covenants of any kind, whether express, implied, statutory or arising by custom or usage or otherwise,
                regarding the Application, the Forms and Clauses (or the Use thereof) including, without limitation, any
                representation, warranty, condition or covenant:
                <ol className={emotionCss(css(termsSx.romanSmallList))}>
                  <li>
                    {' '}
                    regarding the accuracy or reliability of the Forms and Clauses or that the Forms and Clauses are
                    complete, correct, current and free from errors, defects, omissions or deficiencies of any kind;
                  </li>
                  <li>
                    {' '}
                    that errors, defects, omissions or deficiencies in the Forms and Clauses (if any) can or will be
                    corrected;
                  </li>
                  <li>
                    {' '}
                    that the Forms and Clauses are applicable, adequate or suitable for use in any particular
                    Transaction or will meet the requirements of any Licensee Party for use in any Transaction;
                  </li>
                  <li>
                    {' '}
                    regarding the merchantability of fitness of the Forms and Clauses for a particular purpose, and;
                  </li>
                  <li>
                    {' '}
                    regarding the non-infringement of any third party rights (including Intellectual Property Rights) by
                    virtue of use or other exploitation of any Forms and Clauses by any Licensee Party.
                  </li>
                </ol>
              </li>
              <li>
                {' '}
                LICENSEE AND OREA HEREBY DISCLAIM ALL REPRESENTATIONS, WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER
                EXPRESS, IMPLIED, STATUTORY OR ARISING BY CUSTOM OF USAGE OR OTHERWISE REGARDING THE FORMS AND CLAUSES
                AND THE USE THEREOF TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW.
              </li>
            </ol>
          </Typography>
        </li>
        <li>
          {' '}
          <Typography variant="h6">ACKNOWLEDGEMENT</Typography>
          <Typography component="span" variant="body1">
            {' '}
            You acknowledge and agree as follows:
            <ol className={emotionCss(css(termsSx.lowercaseList))}>
              <li>
                {' '}
                the Application and the Forms and Clauses are provided “as is” and that the Use or the reliance upon the
                Application or the Forms or Clauses (including by You or any other Person, in any Transaction) is at
                Your sole risk;
              </li>
              <li>
                {' '}
                You are solely responsible for Your Use, misuse or inability to Use the Application, the Forms and
                Clauses or any User-created Forms and for any results (or lack thereof) achieved through the Use thereof
                or reliance thereon (including in any Transaction);
              </li>
              <li>
                {' '}
                You are solely responsible for any changes, amendments or modifications made by You (in whole or in
                part, or jointly or severally with others) to any Forms or Clauses and for any User-created Form and for
                Your Use, misuse or inability to Use any User-created Forms and for any results (or lack thereof)
                achieved through the Use thereof or reliance thereon (including in any Transaction);
              </li>
              <li>
                {' '}
                the Forms and Clauses have been created for general usage in the Province of Ontario only and, as such,
                may not be applicable, adequate or suitable for use in every Transaction or in any specific Transaction
                (including Transactions not governed by the laws of the Province of Ontario, in Transactions in which
                the Parties to the Transaction are not domiciled or a resident in the Province of Ontario) or in
                Transactions where the real property subject of the Transaction is not located in the Province of
                Ontario); and
              </li>
              <li>
                {' '}
                the Forms and Clauses (and the provision or licensing thereof by Licensee and OREA) do not constitute
                legal, accounting or other professional advice of any nature and that neither Licensee nor OREA shall
                have any liability to You whatsoever in respect of Your Use, misuse, inability to Use or reliance upon
                the Forms and Clauses or any User-created Forms (including in any Transaction).
              </li>
            </ol>
          </Typography>
        </li>
        <li>
          {' '}
          <Typography variant="h6">LIABILITY AND INDEMNIFICATION</Typography>
          <Typography component="span" variant="body1">
            <ol className={emotionCss(css(termsSx.lowercaseList))}>
              <li>
                {' '}
                Neither Licensee nor OREA shall have any liability to You or any third Person in respect of any Claims
                or Losses arising directly or indirectly from, out of, or relating to any of the following:
                <ol className={emotionCss(css(termsSx.romanSmallList))}>
                  <li>
                    {' '}
                    Your Use of or reliance upon the Forms and Clauses or any User-created Forms (including in any
                    Transaction);
                  </li>
                  <li>
                    {' '}
                    Your misuse or inability to use any Forms and Clauses or any User-created Forms (including in any
                    Transaction);
                  </li>
                  <li>
                    {' '}
                    any failure of the Forms and Clauses or any User-created Forms to be accurate, complete, correct or
                    current; or
                  </li>
                  <li>
                    {' '}
                    any error, defect, omission or deficiency in the Forms and Clauses or any User-created Forms.
                  </li>
                </ol>
              </li>
              <li>
                {' '}
                You agree to indemnify, defend and hold harmless Licensee and OREA (including their respective officers,
                directors, employees and/or agents) from and against any and all Claims and Losses arising directly or
                indirectly from, out of or relating to:
                <ol className={emotionCss(css(termsSx.romanSmallList))}>
                  <li> Your breach of any term, condition, representation or warranty of this Agreement;</li>
                  <li>
                    {' '}
                    any wrongful, negligent or unlawful act or omission committed by You in the exercise of the rights
                    granted herein, or in any Transaction;
                  </li>
                  <li>
                    {' '}
                    any failure by You to comply with any Applicable Law applicable to Your exercise of the rights
                    granted herein, or in any Transaction; or
                  </li>
                  <li>
                    {' '}
                    any infringement, violation or misappropriation of any third party Intellectual Property (including
                    any Intellectual Property Rights therein) arising from or in respect of the creation, use, or other
                    exploitation of any User-created Form.
                  </li>
                </ol>
              </li>
            </ol>
          </Typography>
        </li>
        <li>
          {' '}
          <Typography variant="h6">LIMITATION OF LIABILITY</Typography>
          <Typography component="span" variant="body1">
            <ol className={emotionCss(css(termsSx.lowercaseList))}>
              <li>
                {' '}
                Under no circumstances will Licensee or OREA (including their respective officers, directors, employees
                or agents) be liable to You or any other Licensee, Authorized User or other Person for any Losses
                whatsoever including without limitation any Losses of the following nature, whether arising in contract,
                (including fundamental breach), tort (including negligence), strict liability, or under any other legal
                or equitable theory, whether or not OREA was advised of the possibility of such Losses whether or not
                such Losses were foreseeable:
                <ol className={emotionCss(css(termsSx.romanSmallList))}>
                  <li>
                    {' '}
                    direct damages, in any amount whatsoever (including direct damages in the nature of lost or
                    anticipated profits); and
                  </li>
                  <li>
                    {' '}
                    any indirect, special, incidental or consequential damages (including, without limitation, damages
                    in the nature of lost or anticipated profits).
                  </li>
                </ol>
              </li>
            </ol>
          </Typography>
        </li>
        <li>
          {' '}
          <Typography variant="h6">TERM AND TERMINATION</Typography>
          <Typography component="span" variant="body1">
            <ol className={emotionCss(css(termsSx.lowercaseList))}>
              <li>
                {' '}
                This Agreement shall enter into effect upon Your accepting the terms and conditions set out herein by
                clicking “I Agree” and shall continue in force and effect until terminated in accordance with the terms
                and conditions set out herein (the “Term”).
              </li>
              <li>
                {' '}
                This Agreement shall terminate immediately if at any point you cease to be an OREA Member in good
                standing.
              </li>
              <li>
                {' '}
                Licensee shall have the right to terminate this Agreement effective immediately if You breach of any
                term or condition of this Agreement.
              </li>
              <li>
                {' '}
                Licensee shall have the right to terminate this Agreement without cause upon ten (10) days’ notice to
                You in accordance with the terms and conditions of this Agreement.
              </li>
              <li>
                {' '}
                You shall be entitled to terminate this Agreement without cause upon ten (10) days’ notice to Licensee
                in accordance with the terms and conditions of this Agreement.
              </li>
              <li>
                {' '}
                In the event of termination of this Agreement the following shall apply:
                <ol>
                  <li>
                    {' '}
                    If Licensee terminates this Agreement for breach, You must immediately cease all further access to
                    and Use of the Application, the Forms and Clauses and User-created Forms;
                  </li>
                  <li>
                    {' '}
                    If Licensee terminates the Agreement without cause, You shall have ten (10) days to wind down Your
                    Use of the Application, the Forms and Clauses and any User-created Forms, following which all
                    licences granted herein shall terminate and You shall cease all further use of the Application, the
                    Forms and Clauses and User-created Forms;
                  </li>
                  <li>
                    {' '}
                    Neither You nor Licensee shall be released from any liability which, at the time of termination, has
                    already accrued to the other party or which is attributable, prior to such termination, nor will any
                    party be prohibited from pursuing any rights and remedies it may have hereunder at law or in equity
                    with respect to any breach of this Agreement prior to termination; and
                  </li>
                  <li>
                    {' '}
                    Neither You nor Licensee shall be liable to the other for any compensation, reimbursement or damages
                    for the mere reason of termination of this Agreement.
                  </li>
                </ol>
              </li>
              <li>
                {' '}
                <Typography variant="h6">GENERAL</Typography>
                <Typography component="span" variant="body1">
                  <ol className={emotionCss(css(termsSx.lowercaseList))}>
                    <li>
                      {' '}
                      This Agreement does not create any relationship between You and Licensee (or between You and OREA)
                      other than as licensee and licensor. You shall not act in a manner which expresses or implies any
                      other relationship, nor bind or purport to bind either Licensee or OREA in any way.
                    </li>
                    <li>
                      {' '}
                      You acknowledge that a breach of this Agreement may cause Licensee or OREA irreparable harm, for
                      which an award of damages may not be adequate compensation. You acknowledge and agree that in the
                      event of Your breach of this Agreement, Licensee and OREA (as appropriate) shall be entitled to an
                      injunction enjoining any further breach or threatened breach (in addition to any other relief to
                      which Licensee or OREA may be entitled at law or in equity).
                    </li>
                    <li>
                      {' '}
                      Any notice or other communication to be given to You in connection with this Agreement shall be
                      sent by email to the address You provide in the registration process or by registered mail to the
                      physical address you provide during the registration process. Licensee may also provide notice to
                      You by way of message posted or otherwise made available to You in the Application. Notification
                      by email shall be deemed to have been received the Business Day following transmission of the
                      notice. Notification by posting or message through the Application shall be deemed to have been
                      received the Business Day following Your first access to the Application after the notice is
                      posted or transmitted. If mailed at any time other than during a general discontinuance of postal
                      service due to strike, lock-out or otherwise, notice sent by registered mail shall be deemed to
                      have been received on the fifth (5th) Business Day after mailing. Notice can be sent to Licensee
                      by email to the following address{' '}
                      <a className={emotionCss(css(termsSx.link))} href="mailto:support@skyslope.com">
                        support@skyslope.com
                      </a>{' '}
                      or by registered mail sent to SkySlope 825 K Street, 2nd Floor, Sacramento, CA 95814.
                    </li>
                    <li>
                      {' '}
                      This Agreement is personal to You. You may not assign or transfer this Agreement (or Your rights
                      and obligations hereunder) without the prior written consent of Licensee. Licensee shall be free
                      to assign this Agreement (including, its rights and obligations hereunder). The rights and
                      obligations of the parties under this Agreement will bind and enure to the benefit of the parties’
                      respective and permitted successors and assigns.
                    </li>
                    <li>
                      {' '}
                      Licensee may amend, modify or update the terms and conditions of this Agreement from time to time
                      by providing You notice of new, amended, modified or updated terms and conditions (including by
                      providing notice of such amendments or modifications through the Application). Your continued
                      access and Use of the Application, the Forms and Clauses or any User-created Forms after notice of
                      such amendments or modifications are deemed to be received by You shall constitute Your acceptance
                      of such new, amended, modified or updated terms and conditions. If You do not agree with any new,
                      modified, amended or updated terms or conditions, Your sole remedy shall be to terminate this
                      Agreement for Your convenience pursuant to Subsection 11(c) hereof. You agree that neither
                      Licensee nor OREA shall have any liability to You in respect of any amendments or modifications to
                      the terms and conditions of this Agreement.
                    </li>
                    <li>
                      {' '}
                      If, for any reason, a court of competent jurisdiction finds any term or provision of this
                      Agreement to be unenforceable, that provision of the Agreement will be enforced to the maximum
                      extent permissible so as to affect the intent of the parties, and the remainder of this Agreement
                      will continue in full force and effect unaffected.
                    </li>
                    <li>
                      {' '}
                      This Agreement, and any action related thereto, shall be governed, controlled, interpreted and
                      defined by and under the laws of the Province of Ontario and the federal laws of Canada applicable
                      therein. The Parties specifically disclaim the UN Convention on Contracts for the International
                      Sale of Goods. You attorn to the jurisdiction of the courts located in the City of Toronto.
                    </li>
                    <li>
                      {' '}
                      This Agreement constitutes the entire agreement between You and Licensee with respect to the
                      subject matter hereof, and supersedes and replaces all prior and contemporaneous understandings or
                      agreements, written or oral, regarding such subject matter.
                    </li>
                    <li>
                      {' '}
                      Notwithstanding the termination of this Agreement for any reason, the following terms shall
                      survive the termination: 2(b), 5(b), 9(a) and (b), 10, 11(f)), 12(b), 12(g) and 12(k).
                    </li>
                    <li> You shall comply with all Applicable Law while exercising the rights granted herein.</li>
                    <li>
                      {' '}
                      OREA shall be considered to be a third party beneficiary to this Agreement for the purposes of the
                      following sections: 2(b), 5(a), 5(b), 7(a)- (c), 8, 9(a), 9(b), 10, 12(b) and 12(j);
                    </li>
                    <li>
                      {' '}
                      In this Agreement, capitalized terms shall have the meanings set forth below.
                      <ol className={emotionCss(css(termsSx.romanSmallList))}>
                        <li>
                          {' '}
                          “Applicable Law” means any and all applicable laws including all federal, provincial and
                          municipal laws, statutes, rules, regulations, by-laws, judgments, orders, decisions, rulings
                          or awards, policies, guidelines, and general principles of common and civil law and equity,
                          binding on or affecting the Person or matter(s) referred to in the context in which the word
                          is used;
                        </li>
                        <li>
                          {' '}
                          “Business Day” means any day other than a Saturday, Sunday or a mandatory statutory holiday
                          observed in Toronto, Ontario, Canada;
                        </li>
                        <li>
                          {' '}
                          “Claims” means any and all actions, causes of actions, liabilities, claims, demands, suits, or
                          injuries;
                        </li>
                        <li>
                          {' '}
                          “Clauses” means the OREA standard clauses made available through the Application (or any of
                          them). For the purpose of this Agreement, Clauses shall be deemed to include all Updates
                          thereof;
                        </li>
                        <li>
                          {' '}
                          “Forms” means the OREA Standard Forms made available through the application (or any of them).
                          For the purpose of this Agreement, Forms shall be deemed to include all Updates thereof;
                        </li>
                        <li> “Forms and Clauses” means the Forms and Clauses (in whole or in part) or any of them;</li>
                        <li>
                          {' '}
                          “Intellectual Property” means (i) all inventions, processes, discoveries, developments, or
                          improvements, whether or not patentable, or whether subject of patent(s) or application(s)
                          therefore; (ii) all software, computer programs and code of all types (in source code and
                          object code format and including all source materials, data, data structures, and databases),
                          (iii) all copyright-protected works (including, without limitation, all literary, artistic,
                          dramatic, and musical works); (iv) all brand names, logos, slogans, product names, services
                          names, trade-marks, trade dress, corporate names, business names, trade names, and domain
                          names, all whether or not registerable or subject to registrations or applications for
                          registration; (v) all designs and industrial designs, whether or not patentable or
                          registerable, patented or registered, or the subject of application(s) for registration; (vi)
                          all formulae, confidential information, proprietary information, trade secrets, know-how, and
                          any information having commercial value; (vii) all other intellectual and industrial property
                          whether or not registered subject of application(s) for registration;
                        </li>
                        <li>
                          {' '}
                          “Intellectual Property Rights” means any and all worldwide rights in Intellectual Property
                          including any and all such rights existing under patent law, copyright law, trademark law,
                          trade secret law, unfair competition law, moral rights law, publicity rights law, privacy
                          rights law, and any and all other similar proprietary rights including (i) any patents,
                          pending patent applications and rights to file applications for inventions, including all
                          rights of priority and all rights in continuations, continuations-in-part, divisions,
                          re-examinations, re-issues and other derivative applications and patents; (ii) all copyright
                          and all rights of authorship, including all registrations and applications for registration of
                          copyright and all rights to file applications for registration thereof; (iii) all
                          registrations and applications for registration of trademarks and service marks; (iv) all
                          industrial designs, design patents, design registrations, pending patent and design
                          applications and rights to file applications therefore including all rights of priority and
                          rights in continuations, continuations in part, divisions, re-examinations, re-issues and
                          other derivative applications, registrations and patents;
                        </li>
                        <li>
                          {' '}
                          “Losses” means all damages, losses, liability (whether accrued, actual contingent, latent or
                          otherwise), costs, fees and expenses (including interest, court costs and reasonable fees and
                          expenses of lawyers, accountants and other experts and professionals);
                        </li>
                        <li> “OREA Member” means a member of any class of membership of OREA;</li>
                        <li>
                          {' '}
                          “Person” means an individual, partnership, corporation, trust, incorporated association, joint
                          venture, governmental authority or other entity;
                        </li>
                        <li> “Term” shall have the meaning ascribed in Section 11(a) hereof;</li>
                        <li>
                          {' '}
                          “Transaction” means a disposition or acquisition or transaction in real estate by sale,
                          purchase, agreement for purchase and sale, exchange, option, lease, rental or otherwise, any
                          offer or attempt to list real estate for the purpose of such disposition, acquisition or
                          transaction and any act, advertisement, conduct or negotiation, directly or indirectly in
                          furtherance of any such disposition, acquisition, transaction, offer or attempt;
                        </li>
                        <li>
                          {' '}
                          “Update” means a release of the Forms and Clauses (in whole or in part) which contains
                          updates, changes, corrections, additions, deletions or enhancements to the Forms and Clauses;
                        </li>
                        <li>
                          {' '}
                          “Use” means in respect of Forms and Clauses and User-created Forms the right to produce,
                          reproduce, distribute, modify and amend such forms, solely for the purposes of, and in the
                          course of, Transactions;
                        </li>
                        <li>
                          {' '}
                          “User-Created Form” means any form, document or agreement created by You (in whole or in part
                          and whether jointly or severally with others) which includes (in whole or in part) the Forms
                          and Clauses. For the purpose of clarity, User-created Forms shall include any such forms
                          created by the amendment, modification or completion by You of any Forms and Clauses.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </Typography>
              </li>
            </ol>
          </Typography>
        </li>
      </ol>
    </Box>
  );
};

export default OreaTermsAndConditionsTermsView;
