import { useQuery } from '@tanstack/react-query';

import AccountUserServer from 'resources/server/AccountUserServer';

import type { AccountGroup } from '../../../../store/types';

export const getSubscriberGroupKey = 'get-group-by-subscriberid';

const useGetGroupBySubscriberId = (subscriberId?: string) => {
  const { isInitialLoading, data } = useQuery({
    queryKey: [getSubscriberGroupKey, subscriberId],
    queryFn: async () => {
      if (subscriberId !== undefined) {
        return AccountUserServer.GetSubscriberGroup(subscriberId);
      } else {
        return [] as AccountGroup[];
      }
    },
    enabled: !!subscriberId,
    staleTime: 1000 * 60 * 10,
  });

  return { isLoading: isInitialLoading, data };
};

export default useGetGroupBySubscriberId;
