export enum MarkupType {
  Strike = 'strike',
  Redact = 'redact-rectangle',
  Highlight = 'highlight-rectangle',
  Text = 'text',
}
export interface MarkupBlock {
  key: string;
  x: number;
  y: number;
  width: number;
  height: number;
  type: MarkupType;
  pageNumber: number;
  documentKey: number | string;
  bgColor?: string;
  color?: string;
  opacity?: number;
  value?: string;
  fontSize?: number;
}

type BlockSize = {
  width: number;
  height: number;
};

export interface BlockPosition extends BlockSize {
  x: number;
  y: number;
}
export interface MarkupConfig {
  key: MarkupType;
  size: {
    default: BlockSize;
    min: BlockSize;
    max: BlockSize;
  };
  fontSize: number | null;
}
