import React from 'react';
import { KeyboardArrowDown } from '@mui/icons-material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import type { ButtonProps } from '@mui/material';
import { Button, Menu, MenuItem, useTheme } from '@mui/material';
import { useAnchorEl } from '@skyslope/mache';

import { BROKER_MANAGEMENT_URL } from 'constants/environments';
import LocalStorageItem from 'constants/LocalStorageItem';
import { routes } from 'constants/routes';
import useIsBrokerageManagementRole from 'hooks/domain/Roles/queries/useIsBrokerageManagementRole';
import useGetUserSettingsQuery from 'hooks/domain/user/queries/useGetUserSettingsQuery';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import useLocalStorage from 'hooks/useLocalStorage';

import { isAppRunningInIframe } from '../../../../utils/isAppRunningInIframe';

import useMenuItemStyles from './MenuItemStyles';

const MainMenuUserDropdown: React.FC<ButtonProps> = (props) => {
  const theme = useTheme();
  const menuItemClasses = useMenuItemStyles(theme);
  const { data: userProfile } = useGetUserSettingsQuery();

  const name = `${userProfile?.firstName ?? ''} ${userProfile?.lastName ?? ''}`;
  const { removeItem } = useLocalStorage();

  const { handleClick, menuProps } = useAnchorEl();

  const { isSecurityTabEnabled, isBrokerPermissionsActive, brokerPermissionsAdminAccess, isMyTeamsEnabled } =
    useFeatureFlags();

  const isBrokerageManagementRole = useIsBrokerageManagementRole();
  const isInIframe = isAppRunningInIframe();

  return (
    <>
      <Button
        variant="text"
        color="primary"
        size="small"
        sx={menuItemClasses.link}
        {...props}
        onClick={handleClick}
        data-spec="user-menu-btn"
        aria-controls="user-menu"
        aria-haspopup="true"
        startIcon={<FiberManualRecordIcon sx={{ fontSize: '30px' }} />}
        endIcon={<KeyboardArrowDown />}
      >
        {name}
      </Button>
      <Menu
        data-spec="user-menu"
        id="user-menu"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        disableScrollLock
        {...menuProps}
      >
        <MenuItem data-spec="user" id="user" component="a" href={routes.userSettings}>
          User Profile
        </MenuItem>
        {isSecurityTabEnabled && (
          <MenuItem data-spec="security" id="security" component="a" href={routes.userSettingsSecurity}>
            Security
          </MenuItem>
        )}
        <MenuItem data-spec="associations" id="associations" component="a" href={routes.userSettingsAssociations}>
          Libraries
        </MenuItem>
        {isMyTeamsEnabled && (
          <MenuItem data-spec="myteam" id="user" component="a" href={routes.userSettingsMyTeam}>
            My Team
          </MenuItem>
        )}
        {isBrokerPermissionsActive && brokerPermissionsAdminAccess && isBrokerageManagementRole && !isInIframe && (
          <MenuItem
            data-spec="brokerage-management"
            id="brokerage-management"
            component="a"
            href={`${BROKER_MANAGEMENT_URL}?returnUrl=${encodeURIComponent(window.location.href)}`}
          >
            Brokerage Management
          </MenuItem>
        )}
        <MenuItem
          data-spec="user-preferences"
          id="user-preferences"
          component="a"
          href={routes.userSettingsPreferences}
        >
          Preferences
        </MenuItem>
        <MenuItem
          data-spec="logout"
          id="logout"
          component="a"
          href={routes.logout}
          onClick={() => {
            removeItem(LocalStorageItem.TrrebRedirectUrl);
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default MainMenuUserDropdown;
