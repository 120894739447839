import useDerivedUserData from 'hooks/domain/user/useDerivedUserData';
import type { FormLibrarySource } from 'store/types';

const useAreTermsAccepted = (source: FormLibrarySource): boolean => {
  const { userSettings } = useDerivedUserData();
  const authProfile = userSettings?.authProfiles?.find((p) => p.type === source);

  if (authProfile == null) return false;
  return !!authProfile.profile.areTermsAccepted;
};

export default useAreTermsAccepted;
