import { useCallback } from 'react';

const useAssignLocation = (): ((href: string) => void) =>
  useCallback((href: string) => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Location/assign
    // The Location.assign() method causes the window to load and display the document at the URL specified.
    // After the navigation occurs, the user can navigate back to the page that called Location.assign() by pressing the "back" button.
    window.location.assign(href);
  }, []);

export default useAssignLocation;
