import { useFeatureFlags } from 'hooks/useFeatureFlags';

import { useHasPermission } from './useHasPermission';

/**
 * Does this user have permission to view the BuyerAgreements page
 * where brokers go to approve the agreements between their agents
 * and those agent's clients?
 */
export function useCanApproveBuyerAgreements() {
  const flags = useFeatureFlags();
  const hasPermission = useHasPermission('forms.buyeragreement.*');
  return flags.isBuyerAgreementApprovalPageEnabled && hasPermission;
}
