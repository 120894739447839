import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

type TabsContextProps = { onChange?: (path: string) => void };
const TabsContext = React.createContext<TabsContextProps>({
  onChange: () => {},
});

export const useTabsContext = (): TabsContextProps => useContext(TabsContext);

export interface TabsProps {
  onChange?: (path: string) => void;
  className?: string;
}

/**
 * Generic tabs component built without a router library so it can be used across React, Next, etc.
 *
 * TODO - This component is being moved to Mache. When we upgrade to MUI V5,
 * please replace this component with Mache version.
 *
 * @param onChange optional onChange callback called upon item click
 * @param className {string} class override for wrapper
 */
const Tabs: React.FC<TabsProps> = ({ onChange, className, children }) => {
  const theme = useTheme();
  return (
    <TabsContext.Provider value={{ onChange }}>
      <Box
        component="div"
        className={className}
        sx={{
          marginLeft: theme.spacing(4),
          display: 'flex',
          flexWrap: 'nowrap',
        }}
      >
        {children}
      </Box>
    </TabsContext.Provider>
  );
};

export default Tabs;
