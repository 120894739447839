import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import useGetUserSettingsQuery from '../../../hooks/domain/user/queries/useGetUserSettingsQuery';
import { targetOptInLibrariesSelector } from '../../../pages/UserOnboarding/store/selectors';
import type { RootState } from '../../../store';

export const useMissingLibraryNames = () => {
  const { data: user } = useGetUserSettingsQuery();
  const targetLibraries = useSelector((state: RootState) => targetOptInLibrariesSelector(state));

  return useMemo(() => {
    const optInLibraryIds = user?.libraries ?? [];
    return targetLibraries.filter((l) => !optInLibraryIds.includes(l.id)).map((l) => l.name);
  }, [targetLibraries, user]);
};
