/* eslint-disable @typescript-eslint/no-explicit-any*/
import { call } from 'redux-saga/effects';

import trimStringProperties from './trimStringProperties';

interface ApiCallOptions {
  resources: any;
  payload?: any;
}

export function* apiCall({ resources, payload }: ApiCallOptions): any {
  return yield call(resources, trimStringProperties(payload));
}
