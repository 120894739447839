import { useQuery, type UseQueryResult } from '@tanstack/react-query';

import FileServer from 'resources/server/FileServer';
import type { File } from 'store/types';

export const getFileQueryKey = 'getFile';

const useGetFileQuery = (fileId: number, onError?: () => void): UseQueryResult<File> =>
  useQuery({
    queryKey: [getFileQueryKey, fileId],
    queryFn: () => FileServer.GetFile(fileId),
    enabled: !isNaN(fileId) && fileId !== -1,
    onError: onError,
  });

export default useGetFileQuery;
