import update from 'immutability-helper';
import { combineReducers } from 'redux';

import type { DownloadFormsResult } from 'hooks/domain/document/mutations/useDownloadForms';
import { ActionTypes } from 'store/actions';
import type { AppAction, FormVersion } from 'store/types';

import type { FilterState } from '../Filter/store';
import filterReducer from '../Filter/store';

import { SelectFormsActions } from './actions';

export interface SelectFormsState {
  formsState: FormsState;
  filterState: FilterState;
}

export interface FormsState {
  selectedDisplayDocuments: DownloadFormsResult;
  selectedForms: FormVersion[];
  isAddingFormsInProgress: boolean;
}

export const defaultState: FormsState = {
  selectedForms: [],
  isAddingFormsInProgress: false,
  selectedDisplayDocuments: { url: undefined, blob: undefined },
};

export const reducer = (state: FormsState = defaultState, action: AppAction): FormsState => {
  switch (action.type) {
    case SelectFormsActions.ADD_FORM_TO_SELECTION: {
      const i = state.selectedForms.findIndex((form) => form.id === action.payload.id);
      if (i !== -1) {
        return state;
      }

      return update(state, {
        selectedForms: {
          $push: [action.payload],
        },
      });
    }
    case SelectFormsActions.REMOVE_FORM_FROM_SELECTION: {
      const i = state.selectedForms.findIndex((form) => form.id === action.payload);
      if (i === -1) {
        return state;
      }

      return update(state, {
        selectedForms: {
          $splice: [[i, 1]],
        },
      });
    }
    case SelectFormsActions.RESET: {
      return update(state, {
        selectedForms: { $set: [] },
      });
    }
    case ActionTypes.RESET: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
};

const selectFormsReducer = combineReducers({
  formsState: reducer,
  filterState: filterReducer,
});

export default selectFormsReducer;
