import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Check,
  ContactSupport,
  Description,
  FolderShared,
  Label,
  LibraryBooks,
  People,
  Person,
} from '@mui/icons-material';
import { Box, List, useTheme } from '@mui/material';
import { SrOnly } from '@skyslope/mache';

import SkyslopeImg from 'assets/images/ss-apps/SkySlope.svg';
import { BROKER_MANAGEMENT_URL } from 'constants/environments';
import { routes } from 'constants/routes';
import { useCanApproveBuyerAgreements } from 'hooks/domain/Roles/queries/useCanApproveBuyerAgreements';
import useIsBrokerageManagementRole from 'hooks/domain/Roles/queries/useIsBrokerageManagementRole';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import useAssignLocation from 'hooks/window/useAssignLocation';

import { isAppRunningInIframe } from '../../../../../utils/isAppRunningInIframe';
import DialogCloseButton from '../../../../DialogCloseButton';
import { AppHeaderLinkNames } from '../../AppHeader';

import MobileMenuAppRows from './MobileMenuAppRows';
import MobileMenuLinkMenuItem from './MobileMenuLinkMenuItem';
import MobileMenuLogoutButton from './MobileMenuLogoutButton';
import MobileMenuRow from './MobileMenuRow';
import MobileMenuSubMenu from './MobileMenuSubMenu';

export interface MobileMenuMainContentProps {
  handleDrawerClose: () => void;
}

const MobileMenuMainContent: React.FC<MobileMenuMainContentProps> = ({ handleDrawerClose }) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const assignLocation = useAssignLocation();
  const flags = useFeatureFlags();
  const isInIframe = isAppRunningInIframe();

  const isBrokerageManagementRole = useIsBrokerageManagementRole();
  const canApproveBuyerAgreements = useCanApproveBuyerAgreements();

  const [subMenu, setSubMenu] = React.useState<JSX.Element | undefined>(undefined);
  const handleBackClick = React.useCallback(() => setSubMenu(undefined), []);

  if (subMenu) return subMenu;
  return (
    <>
      <Box
        component="div"
        sx={{
          display: 'flex',
          padding: theme.spacing(4),
          justifyContent: 'space-between',
        }}
      >
        <Link to={routes.homeTownBuffet}>
          <SrOnly>Go to files dashboard</SrOnly>
          <img alt="SkySlope Forms" src={SkyslopeImg} width={104} height={25} />
        </Link>
        <DialogCloseButton absolutePosition={false} onClick={handleDrawerClose} />
      </Box>
      <List>
        <MobileMenuLinkMenuItem
          data-spec="files"
          name={AppHeaderLinkNames.Files}
          path={routes.homeTownBuffet}
          isSelected={/file-details/.test(pathname)}
          icon={<FolderShared />}
          handleDrawerClose={handleDrawerClose}
          id="files-mobile-nav-link"
        />
        <MobileMenuLinkMenuItem
          data-spec="templates"
          name={AppHeaderLinkNames.Templates}
          path={routes.templateManagement}
          icon={<LibraryBooks />}
          isSelected={/templates/.test(pathname)}
          handleDrawerClose={handleDrawerClose}
          id="templates-mobile-nav-link"
        />
        {flags.isViewFormsWithNoFileEnabled && (
          <MobileMenuLinkMenuItem
            data-spec="forms"
            name={AppHeaderLinkNames.BrowseLibraries}
            path={routes.browseLibraries}
            icon={<Description />}
            isSelected={/forms/.test(pathname)}
            handleDrawerClose={handleDrawerClose}
            id="forms-mobile-nav-link"
          />
        )}
        {flags.isMyClausesEnabled && (
          <MobileMenuLinkMenuItem
            data-spec="clauses"
            name={AppHeaderLinkNames.Clauses}
            path={routes.clauses}
            icon={<Label />}
            isSelected={/clauses/.test(pathname)}
            handleDrawerClose={handleDrawerClose}
            id="clauses-mobile-nav-link"
          />
        )}
        <MobileMenuRow
          data-spec="user-settings"
          name={AppHeaderLinkNames.UserSettings}
          icon={<Person />}
          isSelected={/settings/.test(pathname)}
          id="user-settings-menu-nav-link"
          onClick={() =>
            setSubMenu(
              <MobileMenuSubMenu
                id="user-settings-sub-menu"
                title={AppHeaderLinkNames.UserSettings}
                menuLinks={[
                  {
                    name: 'Profile',
                    link: routes.userSettingsProfile,
                    isSelected: pathname === routes.userSettingsProfile,
                  },
                  ...(flags.isSecurityTabEnabled
                    ? [
                        {
                          name: 'Security',
                          link: routes.userSettingsSecurity,
                          isSelected: pathname === routes.userSettingsSecurity,
                        },
                      ]
                    : []),
                  {
                    name: 'Libraries',
                    link: routes.userSettingsAssociations,
                    isSelected: pathname === routes.userSettingsAssociations,
                  },
                ]}
                handleDrawerClose={handleDrawerClose}
                handleBackClick={handleBackClick}
              />
            )
          }
        />
        {flags.isBrokerPermissionsActive &&
          flags.brokerPermissionsAdminAccess &&
          isBrokerageManagementRole &&
          !isInIframe && (
            <MobileMenuRow
              data-spec="broker-management"
              id="broker-management-templates-mobile-nav-link"
              name={AppHeaderLinkNames.BrokerManagement}
              icon={<People />}
              onClick={() =>
                assignLocation(`${BROKER_MANAGEMENT_URL}?returnUrl=${encodeURIComponent(window.location.href)}`)
              }
              ariaLabel={`Navigate to ${AppHeaderLinkNames.BrokerManagement} page`}
            />
          )}
        {canApproveBuyerAgreements && (
          <MobileMenuLinkMenuItem
            data-spec="buyer-agreements"
            name={AppHeaderLinkNames.BuyerAgreements}
            path={routes.buyerAgreements}
            icon={<Check />}
            isSelected={/buyer-agreements/.test(pathname)}
            handleDrawerClose={handleDrawerClose}
            id="buyer-agreements-mobile-nav-link"
          />
        )}
        <MobileMenuAppRows
          setSubMenu={setSubMenu}
          handleDrawerClose={handleDrawerClose}
          handleBackClick={handleBackClick}
        />
        <MobileMenuRow
          data-spec="help"
          name={AppHeaderLinkNames.Help}
          icon={<ContactSupport />}
          id="help-settings-menu-nav-link"
          onClick={() =>
            setSubMenu(
              <MobileMenuSubMenu
                id="apps-help-menu"
                title={AppHeaderLinkNames.Help}
                menuLinks={[
                  {
                    name: 'Help Documentation',
                    link: 'https://support.skyslope.com/hc/en-us/categories/4550787407387-SkySlope-Forms',
                  },
                  {
                    name: 'Support',
                    link: 'https://support.skyslope.com/hc/en-us',
                  },
                  {
                    name: 'Terms of Use',
                    link: 'https://skyslope.com/terms-conditions/',
                    openInNewTab: true,
                  },
                  {
                    name: 'Privacy Policy',
                    link: 'https://skyslope.com/privacy-policy/',
                    openInNewTab: true,
                  },
                ]}
                handleDrawerClose={handleDrawerClose}
                handleBackClick={handleBackClick}
              />
            )
          }
        />
      </List>
      <MobileMenuLogoutButton />
    </>
  );
};

export default MobileMenuMainContent;
