import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useLayout } from 'components/Layout/Provider';
import DefaultAppBar from 'pages/ErrorBoundary/components/DefaultAppBar';

const RelinkingErrorPage: React.FC = () => {
  useLayout({ header: { title: '', hidden: true } });
  const theme = useTheme();
  return (
    <>
      <DefaultAppBar />
      <Container>
        <Box
          mt={10}
          mb={10}
          textAlign="center"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: '125px',
            }}
          >
            Oops!
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              paddingTop: theme.spacing(4),
            }}
          >
            500 - Relinking Error
          </Typography>
          <Typography
            variant="body1"
            sx={{
              maxWidth: '500px',
            }}
          >
            Oops! Your user had issues relinking email. Please try again or contact support for assistance.
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default RelinkingErrorPage;
