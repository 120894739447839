import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import type { Theme } from '@mui/material/styles';
import type { SxProps } from '@mui/system/styleFunctionSx';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

export interface CenteredLoaderProps {
  isFlex?: boolean;
  isFixed?: boolean;
  className?: string;
  size?: number;
  sx?: SxProps<Theme>;
}

const CenteredLoader = ({
  isFlex = false,
  isFixed = false,
  className = '',
  size = 40,
  sx,
}: CenteredLoaderProps): JSX.Element => {
  const halfOfSize = size / 2;
  const absoluteSx: SxProps<Theme> = {
    top: `calc(50% - ${halfOfSize}px)`,
    left: `calc(50% - ${halfOfSize}px)`,
    position: isFixed ? 'fixed' : 'absolute',
  };
  const flexSx: SxProps<Theme> = {
    display: 'flex',
    margin: 'auto',
  };
  const defaultSx = isFlex ? { ...flexSx } : { ...absoluteSx };

  return (
    <CircularProgress
      size={size}
      className={className}
      sx={{
        ...defaultSx,
        ...sx,
      }}
    />
  );
};

export default CenteredLoader;
