import React from 'react';
import { useHistory } from 'react-router-dom';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { colors } from '@skyslope/mache';

import { routes } from 'constants/routes';

const MobileMenuLogoutButton: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();
  return (
    <Box component="div" sx={{ position: 'fixed', left: 0, bottom: 0, width: '100%', height: 54 }}>
      <Divider />
      <Button
        data-spec="logout"
        sx={{ marginTop: theme.spacing(1), marginLeft: theme.spacing(3) }}
        variant="text"
        onClick={() => history.push(routes.logout)}
      >
        <ExitToAppIcon sx={{ color: colors.grey[800] }} />
        <Typography variant="body1" sx={{ marginLeft: theme.spacing(2) }}>
          Logout
        </Typography>
      </Button>
    </Box>
  );
};

export default MobileMenuLogoutButton;
