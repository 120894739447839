import { useQuery } from '@tanstack/react-query';

import UtilityConnectServer from '../../../../resources/server/UtilityConnectServer';
import useGetUserSettingsQuery from '../../user/queries/useGetUserSettingsQuery';

export const SubscriberEnablementKey = 'utility-connect-subscriber-enablement';
export const useSubscriberUCEnablement = () => {
  const subId = useGetUserSettingsQuery().data?.subscriberId;
  return useQuery({
    queryKey: [SubscriberEnablementKey, subId],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: async () => UtilityConnectServer.GetSubscriberEnablement(subId!),
    enabled: !!subId,
    refetchOnWindowFocus: false,
  });
};
