import type { LogLevel } from 'utils/logger';

export interface EnvironmentConfig {
  dev?: string;
  integ: string;
  staging: string;
  prod: string;
}

const launchDarklyClientId: EnvironmentConfig = {
  integ: '5e2b7990f201f408ebc65db5',
  staging: '5e2b79b8f201f408ebc65dbb',
  prod: '5e2b78fef201f408ebc65da5',
};

const clarityProjectId: EnvironmentConfig = {
  integ: 'kis4bz45zq',
  staging: 'kivrp06m5r',
  prod: 'kivtmih6we',
};

const digiSignApiUrls: EnvironmentConfig = {
  integ: 'https://integ-digisign3.skyslope.com/api',
  staging: 'https://staging-digisign3.skyslope.com/api',
  prod: 'https://digisign3.skyslope.com/api',
};

const rentSpreeApiUrls: EnvironmentConfig = {
  integ: 'https://integ-rentspree.skyslope.com/api/',
  staging: 'https://staging-rentspree.skyslope.com/api/',
  prod: 'https://rentspree.skyslope.com/api/',
};

const utilityConnectApiUrls: EnvironmentConfig = {
  integ: 'https://integ-updater.skyslope.com/api',
  staging: 'https://staging-updater.skyslope.com/api',
  prod: 'https://updater.skyslope.com/api',
};

const primeUrls: EnvironmentConfig = {
  integ: 'https://integ.skyslope.com',
  staging: 'https://staging.skyslope.com',
  prod: 'https://app.skyslope.com',
};

const primeCDNUrls: EnvironmentConfig = {
  integ: 'https://integ-cdn.skyslope.com',
  staging: 'https://staging-cdn.skyslope.com',
  prod: 'https://cdn.skyslope.com',
};

const primeAuthUrls: EnvironmentConfig = {
  integ: 'https://integ-auth.skyslope.com',
  staging: 'https://staging-auth.skyslope.com',
  prod: 'https://auth.skyslope.com',
};

const fileUrls: EnvironmentConfig = {
  integ: 'https://integ-forms.skyslope.com/api',
  staging: `https://${window.location.hostname}/api`,
  prod: `https://${window.location.hostname}/api`,
};

const formUrls: EnvironmentConfig = {
  integ: 'https://integ-forms.skyslope.com/library/api',
  staging: `https://${window.location.hostname}/library/api`,
  prod: `https://${window.location.hostname}/library/api`,
};

const userUrls: EnvironmentConfig = {
  integ: 'https://integ-forms.skyslope.com/users/api',
  staging: `https://${window.location.hostname}/users/api`,
  prod: `https://${window.location.hostname}/users/api`,
};

const clauseUrls: EnvironmentConfig = {
  integ: 'https://integ-forms.skyslope.com/clauses/api',
  staging: `https://${window.location.hostname}/clauses/api`,
  prod: `https://${window.location.hostname}/clauses/api`,
};

const metadataUrls: EnvironmentConfig = {
  integ: 'https://integ-forms.skyslope.com/metadata/api',
  staging: `https://${window.location.hostname}/metadata/api`,
  prod: `https://${window.location.hostname}/metadata/api`,
};

const accountUserUrls: EnvironmentConfig = {
  integ: 'https://accounts-integ.skyslope.com/api',
  staging: 'https://accounts-staging.skyslope.com/api',
  prod: 'https://accounts.skyslope.com/api',
};

const offerManagementUrls: EnvironmentConfig = {
  integ: 'https://integ-offers.skyslope.com',
  staging: 'https://staging-offers.skyslope.com',
  prod: 'https://offers.skyslope.com',
};

const breezeUrls: EnvironmentConfig = {
  integ: 'https://integ-breeze.skyslope.com',
  staging: 'https://staging-breeze.skyslope.com',
  prod: 'https://breeze.skyslope.com',
};

const ds3Urls: EnvironmentConfig = {
  integ: 'https://integ-send.skyslope.com/envelopes',
  staging: 'https://staging-send.skyslope.com/envelopes',
  prod: 'https://send.skyslope.com/envelopes',
};

const brokerManagementUrls: EnvironmentConfig = {
  integ: 'https://integ-admin.skyslope.com/people',
  staging: 'https://staging-admin.skyslope.com/people',
  prod: 'https://admin.skyslope.com/people',
};

const ds2Urls: EnvironmentConfig = {
  integ: 'https://integ-digisign.skyslope.com',
  staging: 'https://staging-digisign.skyslope.com',
  prod: 'https://digisign.skyslope.com',
};

const complianceUrls: EnvironmentConfig = {
  integ: 'https://integ-agent.skyslope.com',
  staging: 'https://staging-agent.skyslope.com',
  prod: 'https://agent.skyslope.com',
};

const booksUrls: EnvironmentConfig = {
  integ: 'https://zipi-dev.skyslope.com/default-page',
  staging: 'https://zipi-dev.skyslope.com/default-page',
  prod: 'https://books.skyslope.com/default-page',
};

const heapIds: EnvironmentConfig = {
  integ: '355210786',
  staging: '1691345378',
  prod: '3954488189',
};

const gtmAuth: EnvironmentConfig = {
  integ: 'AC2GoMnqdtM_fTnRg-Fg_g',
  staging: '9OVXeDhfb1ILj5G0lpxTtw',
  prod: '8WAA4RAALtwpjBysEvBZig',
};

const gtmPreview: EnvironmentConfig = {
  integ: 'env-5',
  staging: 'env-4',
  prod: 'env-1',
};

const docusignAccountUrl: EnvironmentConfig = {
  integ: 'https://account-d.docusign.com',
  staging: 'https://account.docusign.com',
  prod: 'https://account.docusign.com',
};

const stripeSubscriptionProduct: EnvironmentConfig = {
  integ: 'prod_OSOHLyGG3eqxnt',
  staging: 'prod_OSOHLyGG3eqxnt',
  prod: 'prod_OUbhEGmYhHkDGR',
};

const stripeMonthlyPriceId: EnvironmentConfig = {
  integ: 'price_1NhwfWLFv96cntgD5NLEvG2a',
  staging: 'price_1NhwfWLFv96cntgD5NLEvG2a',
  prod: 'price_1NhcUNLFv96cntgDXuJ1HhQR',
};

const stripeYearlyPriceId: EnvironmentConfig = {
  integ: 'price_1NfTV9LFv96cntgD5D0r0Fx5',
  staging: 'price_1NfTV9LFv96cntgD5D0r0Fx5',
  prod: 'price_1NhcUNLFv96cntgDRNgd0Gwr',
};

const contactsUrl: EnvironmentConfig = {
  integ: 'https://contacts-integ.skyslope.com/api',
  staging: 'https://contacts-staging.skyslope.com/api',
  prod: 'https://contacts.skyslope.com/api',
};

const nhdUrl: EnvironmentConfig = {
  integ: 'https://integ-nhd.skyslope.com',
  staging: 'https://staging-nhd.skyslope.com',
  prod: 'https://nhd.skyslope.com',
};

const getConfig = (config: EnvironmentConfig, localEnvKeyOverride?: string): string => {
  if (window.environmentKey === 'dev' && localEnvKeyOverride && process.env[localEnvKeyOverride]) {
    return process.env[localEnvKeyOverride] || config.integ;
  }
  return config[window.environmentKey] || config.integ;
};

export const HEAP_ID = getConfig(heapIds, 'REACT_APP_HEAP_ID');
export const LAUNCHDARKLY_CLIENT_ID = getConfig(launchDarklyClientId);
export const CLARITY_PROJECT_ID = getConfig(clarityProjectId, 'REACT_APP_CLARITY_PROJECT_ID');

export const DIGISIGN_API_URL = getConfig(digiSignApiUrls);
export const FILE_API_URL = getConfig(fileUrls, 'REACT_APP_FILE_API_URL');
export const FORM_API_URL = getConfig(formUrls, 'REACT_APP_FORM_API_URL');
export const USER_API_URL = getConfig(userUrls, 'REACT_APP_USER_API_URL');
export const CLAUSE_API_URL = getConfig(clauseUrls, 'REACT_APP_CLAUSE_API_URL');
export const METADATA_API_URL = getConfig(metadataUrls, 'REACT_APP_METADATA_API_URL');
export const ACCOUNT_API_URL = getConfig(accountUserUrls, 'REACT_APP_ACCOUNT_API_URL');

export const PRIME_AUTH_URL = getConfig(primeAuthUrls);

export const PRIME_URL = getConfig(primeUrls);
export const PRIME_CDN_URL = getConfig(primeCDNUrls);
export const BREEZE_URL = getConfig(breezeUrls);
export const OFFERS_URL = getConfig(offerManagementUrls);
export const DS3_URL = getConfig(ds3Urls);
export const DS2_URL = getConfig(ds2Urls);
export const BROKER_MANAGEMENT_URL = getConfig(brokerManagementUrls);
export const COMPLIANCE_URL = getConfig(complianceUrls);
export const BOOKS_URL = getConfig(booksUrls);

export const PRIME_MARKETING_URL = 'https://resources.skyslope.com/make-the-switch';

export const GOOGLE_TAG_MANAGER_ID = 'GTM-56H38PR';
export const GOOGLE_TAG_MANAGER_AUTH = getConfig(gtmAuth);
export const GOOGLE_TAG_MANAGER_PREVIEW = getConfig(gtmPreview);
export const RENT_SPREE_URL = getConfig(rentSpreeApiUrls);
export const UTILITY_CONNECT_URL = getConfig(utilityConnectApiUrls, 'REACT_APP_UTILITY_CONNECT_URL');
export const DOCUSIGN_INTEGRATION_KEY = 'a9383d0c-0634-4c6e-8c3e-cf2b01f323b3';
export const DOCUSIGN_ACCOUNT_URL = getConfig(docusignAccountUrl);
export const STRIPE_SUBSCRIPTION_PRODUCT = getConfig(stripeSubscriptionProduct);
export const STRIPE_MONTHLY_PRICE_ID = getConfig(stripeMonthlyPriceId);
export const STRIPE_YEARLY_PRICE_ID = getConfig(stripeYearlyPriceId);
export const CONTACTS_URL = getConfig(contactsUrl);
export const NHD_URL = getConfig(nhdUrl);

export const LOG_LEVEL: LogLevel = window.environmentKey === 'prod' ? 'warn' : 'log';
