import update from 'immutability-helper';

import type { NotifierState } from 'containers/Notifier/store';
import notifierReducer, { initialNotifierState } from 'containers/Notifier/store';
import type { PopUnderState } from 'containers/PopUnder/store';
import popUnderReducer, { initialPopUnderState } from 'containers/PopUnder/store';

import type { OreaDialogStatus } from './actions';
import { ActionTypes } from './actions';
import type { DomainState } from './domain';
import domainReducer, { initialDomainState } from './domain';
import type { AppAction } from './types';

export interface GlobalState {
  domainState: DomainState;
  notifierState: NotifierState;
  popUnderState: PopUnderState;
  oreaDialogStatus: OreaDialogStatus;
}

export const defaultState: GlobalState = {
  domainState: initialDomainState,
  notifierState: initialNotifierState,
  popUnderState: initialPopUnderState,
  oreaDialogStatus: 'closed',
};

const reducer = (state: GlobalState = defaultState, action: AppAction): GlobalState => {
  switch (action.type) {
    case ActionTypes.RESET: {
      return update(state, {
        // we only want to partially reset global state
        domainState: { $set: initialDomainState },
      });
    }
    case ActionTypes.SET_OREA_DIALOG_STATUS: {
      return update(state, {
        oreaDialogStatus: {
          $set: action.payload,
        },
      });
    }
    default: {
      return update(state, {
        domainState: {
          $set: domainReducer(state.domainState, action),
        },
        notifierState: {
          $set: notifierReducer(state.notifierState, action),
        },
        popUnderState: {
          $set: popUnderReducer(state.popUnderState, action),
        },
      });
    }
  }
};

export default reducer;
