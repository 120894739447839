import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { getRoute } from 'appRoutes';
import { routes } from 'constants/routes';
import useGetUserSubscriptionQuery, {
  userSubscriptionKey,
} from 'hooks/domain/user/queries/useGetUserSubscriptionQuery';
import useUpdateUserSubscriptionQuery from 'hooks/domain/user/queries/useUpdateUserSubscriptionQuery';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import useIsOnboarding from 'hooks/useIsOnboarding';
import useQueryParams from 'hooks/useQueryParams';

import useGetUserSubscription from '../../../hooks/domain/user/queries/useGetUserSubscription';

const useNavigateToSubscriptionPage = (): void => {
  const queryParams = useQueryParams();
  const queryClient = useQueryClient();
  const paymentSuccess = queryParams.get('paymentSuccess');
  const history = useHistory();
  const location = useLocation();
  const currentRoute = getRoute(location.pathname);
  const pageRequiresSubscription = currentRoute?.requiresSubscription;
  const subscription = useGetUserSubscription({ enabled: !useIsOnboarding() });
  const { isFetched, refetch } = useUpdateUserSubscriptionQuery({ enabled: !useIsOnboarding() });

  const { isSubscriptionRequired } = useFeatureFlags();

  const { data: userSubscription } = useGetUserSubscriptionQuery({
    enabled: isFetched || !paymentSuccess,
  });

  const redirectToSubscription =
    pageRequiresSubscription && isSubscriptionRequired && subscription.subscriptionExpired && subscription.trialExpired;
  useEffect(() => {
    const handleNavigateToSubscriptionPage = async (): Promise<void> => {
      if (subscription.validSubscriptionExpired) {
        const updatedUserSubscription = await refetch();
        if (
          updatedUserSubscription &&
          updatedUserSubscription?.data?.validUntil &&
          updatedUserSubscription?.data?.validUntil > new Date().toISOString()
        ) {
          queryClient.invalidateQueries([userSubscriptionKey]);
          return;
        }
      }
      if (redirectToSubscription) {
        history.replace(routes.subscriptions, {
          prevLocation: location.pathname,
        });
      }
    };
    if (redirectToSubscription) {
      handleNavigateToSubscriptionPage();
    }
  }, [
    redirectToSubscription,
    history,
    location.pathname,
    userSubscription,
    paymentSuccess,
    subscription,
    refetch,
    queryClient,
  ]);
};

export default useNavigateToSubscriptionPage;
