import React from 'react';
import { useSnackbar } from '@skyslope/mache';
import { useAtom } from 'jotai';
import Lottie from 'lottie-react';
import { type CustomContentProps, SnackbarContent } from 'notistack';

import { onboardingJustCompletedAtom } from 'pages/UserOnboarding/hooks/useCompleteOnboarding';

import animationData from '../../assets/animations/rocket.json';

export type WelcomeToFormsSnackbarProps = CustomContentProps;

/**
 * Custom Notistack Snackbar that shows a welcome message to the user.
 */
export const WelcomeToFormsSnackbar = React.forwardRef(function WelcomeToFormsSnackbar(
  props: WelcomeToFormsSnackbarProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const { id, ...rest } = props;
  return (
    <SnackbarContent
      ref={ref}
      id={id as never}
      role="alert"
      {...rest}
      className="bg-grey-900 flex items-center gap-4 p-4 rounded-lg"
    >
      <Lottie animationData={animationData} style={{ height: 44 }} loop={false} />
      <span className="text-white font-bold font-base">Welcome to Forms!</span>
    </SnackbarContent>
  );
});

/**
 * Shows the "Welcome to Forms" snackbar if the user had just
 * finished completing Forms onboarding.
 */
export function useWelcomeToFormsSnackbar(args: { enabled?: boolean } = {}) {
  const { enabled = true } = args;
  const snackbar = useSnackbar();
  const [happenedJustNow, setHappenedJustNow] = useAtom(onboardingJustCompletedAtom);

  React.useEffect(() => {
    if (enabled && happenedJustNow) {
      snackbar.custom('welcomeToForms', {
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        autoHideDuration: 5000,
        preventDuplicate: true,
      });
      setHappenedJustNow(false);
    }
  }, [enabled, happenedJustNow, snackbar, setHappenedJustNow]);
}
