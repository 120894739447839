import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useAuth } from '@skyslope/auth-react';

import { getRoute } from 'appRoutes';
import LocalStorageItem from 'constants/LocalStorageItem';
import { getPath, routes } from 'constants/routes';
import useGetUserSettingsQuery from 'hooks/domain/user/queries/useGetUserSettingsQuery';
import useIsOnboarding from 'hooks/useIsOnboarding';
import useIsPermanentEmailNeeded from 'hooks/useIsPermanentEmailNeeded';
import useLocalStorage from 'hooks/useLocalStorage';
import useWidgetQueryParams from 'hooks/useWidgetQueryParams';

const useNavigateToOnboardingStep = (): void => {
  const history = useHistory();
  const location = useLocation();
  const currentRoute = getRoute(location.pathname);
  const currentPath = currentRoute?.routerProps.path;
  const isNakedRoute = currentRoute?.isNaked;
  const isOnOnboardingPage = useIsOnboarding();
  const widgetParams = useWidgetQueryParams();
  const { addItem, getItem, removeItem } = useLocalStorage();
  const isPermanentEmailNeeded = useIsPermanentEmailNeeded();

  const isAuthenticated = useAuth().authState.isAuthenticated;
  const { data: user } = useGetUserSettingsQuery();

  const isInitialized = user?.isInitialized;

  const shouldRedirect =
    isAuthenticated && !isNakedRoute && isInitialized != null && !currentPath?.includes('/verify/');

  useEffect(() => {
    if (isInitialized && shouldRedirect && !isPermanentEmailNeeded && isOnOnboardingPage) {
      const redirectUrl = getItem(LocalStorageItem.OnboardingRedirectUrl);
      if (redirectUrl) {
        removeItem(LocalStorageItem.OnboardingRedirectUrl);
        history.push(redirectUrl);
      } else if (widgetParams.fileId) {
        history.push(getPath(routes.fileDetailsDocuments, { id: widgetParams.fileId }));
      } else {
        history.push(routes.homeTownBuffet);
      }
    }
  }, [
    isInitialized,
    shouldRedirect,
    isOnOnboardingPage,
    history,
    widgetParams.fileId,
    getItem,
    removeItem,
    isPermanentEmailNeeded,
  ]);

  useEffect(() => {
    if (!isInitialized && shouldRedirect && !isOnOnboardingPage) {
      addItem(LocalStorageItem.OnboardingRedirectUrl, `${location.pathname}${location.search}`);
      history.push(routes.onboardingEmail);
    }
  }, [isInitialized, shouldRedirect, isOnOnboardingPage, history, addItem, location.pathname, location.search]);
};

export default useNavigateToOnboardingStep;
