export const base64ToUrl: (base64string: string) => Promise<string> = (base64string: string) =>
  new Promise((resolve, reject) => {
    try {
      // decode base64 string, remove space for IE compatibility
      const binary = atob(base64string.replace(/\s/g, ''));
      const len = binary.length;
      const buffer = new ArrayBuffer(len);
      const view = new Uint8Array(buffer);
      for (let i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
      }

      const blob = new Blob([view], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      resolve(url);
    } catch (error) {
      reject(error);
    }
  });

export const fileToBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });

export const base64ToBlob = (b64Url: string): Promise<Blob> => fetch(b64Url).then((res) => res.blob());

export const printFile = (url: string): void => {
  const iframe = document.createElement('iframe');
  iframe.src = url;
  iframe.style.display = 'none';
  document.body.append(iframe);
  iframe.onload = () => {
    iframe.contentWindow?.print();
  };
};

export const blobToBase64 = (blob: Blob): Promise<string> => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result as string);
    };
  });
};
