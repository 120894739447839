import { useMemo } from 'react';

import type { FormLibrary, UserSettings } from 'store/types';
import { FormLibrarySource } from 'store/types';
import { buildRequiredFields } from 'utils/userSettings';

import useGetDelegatedUserSettingsQuery from './queries/useGetDelegatedUserSettingsQuery';
import useRegionLibraries from './useRegionLibraries';

export const derivedDataKey = 'derivedData';

export interface DelegatedDerivedUserData {
  userSettings?: UserSettings;
  isLoading: boolean;
  completedAuthProfileTypes: FormLibrarySource[];
  regionLibraries: FormLibrary[];
  librariesAvailableForOptIn: FormLibrary[];
  requiredFields: string[];
  optInLibraries: FormLibrary[];
  availableLibraries: FormLibrary[];
  onBehalfOf: string | undefined;
}

const useDelegatedDerivedUserData = (
  onBehalfOf: string,
  regionLibrariesOverride?: FormLibrary[]
): DelegatedDerivedUserData => {
  const { data: userSettings, isInitialLoading: isUserLoading } = useGetDelegatedUserSettingsQuery(onBehalfOf);

  const { data: originalRegionsLibraries, isInitialLoading: isLibrariesLoading } = useRegionLibraries(
    userSettings?.regions,
    onBehalfOf
  );

  const regionLibraries = regionLibrariesOverride ?? originalRegionsLibraries;

  const librariesAvailableForOptin = useMemo(
    () =>
      regionLibraries?.filter(
        (l) => l.authProfiles[0].source !== FormLibrarySource.PRIME && !userSettings?.libraries.includes(l.id)
      ) ?? [],
    [userSettings, regionLibraries]
  );

  const optInLibraries = useMemo(
    () =>
      regionLibraries?.filter(
        (l) => l.authProfiles[0].source !== FormLibrarySource.PRIME && userSettings?.libraries.includes(l.id)
      ) ?? [],
    [userSettings, regionLibraries]
  );

  const availableLibraries = useMemo(
    () =>
      regionLibraries?.filter(
        (l) => l.authProfiles[0].source === FormLibrarySource.PRIME || optInLibraries.find((optin) => optin.id === l.id)
      ) ?? [],
    [regionLibraries, optInLibraries]
  );

  // If this becomes expensive we would probably want to memoize it globally. Be careful if copying this pattern.
  return useMemo(
    () => ({
      isLoading: isUserLoading || isLibrariesLoading,
      userSettings,
      completedAuthProfileTypes: userSettings?.authProfiles?.map((p) => p.type) ?? [],
      regionLibraries: regionLibraries ?? [],
      librariesAvailableForOptIn: librariesAvailableForOptin,
      requiredFields: buildRequiredFields(optInLibraries),
      optInLibraries: optInLibraries ?? [],
      availableLibraries,
      onBehalfOf,
    }),
    [
      userSettings,
      regionLibraries,
      optInLibraries,
      librariesAvailableForOptin,
      isUserLoading,
      isLibrariesLoading,
      availableLibraries,
      onBehalfOf,
    ]
  );
};

export default useDelegatedDerivedUserData;
