import { useEffect, useState } from 'react';
import { useAuth } from '@skyslope/auth-react';

import LocalStorageItem from 'constants/LocalStorageItem';

export interface DeviceTrackingInfo {
  lastAuthenticated?: string;
}

export default (): string | null => {
  const [hasTracked, setHasTracked] = useState<boolean>(false);
  const {
    authState: { isAuthenticated },
  } = useAuth();

  useEffect(() => {
    if (isAuthenticated && !hasTracked) {
      localStorage.setItem(LocalStorageItem.DeviceLastAuthenticated, Date());
      setHasTracked(true);
    }
  }, [isAuthenticated, hasTracked]);

  return localStorage.getItem(LocalStorageItem.DeviceLastAuthenticated);
};
