import type { UseMutationResult } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from 'axios';

import DelegationServer from 'resources/server/DelegationServer';

const useProcessAccessDelayMutation = (): UseMutationResult<string[], AxiosError, string, unknown> =>
  useMutation({
    mutationFn: DelegationServer.ProcessAccessDelay.bind(DelegationServer),
    onSettled: (data: string[] | undefined) => Promise.resolve(data),
  });

export default useProcessAccessDelayMutation;
