import { action } from 'typesafe-actions';

import type { DeleteFileDocumentRequest } from 'resources/types/DeleteFileDocument';
import type DeleteFileDocumentsRequest from 'resources/types/DeleteFileDocuments';
import type { GetFileDocumentsRequest } from 'resources/types/GetFileDocuments';

import type { FormDocument } from './types';

export enum ActionTypes {
  RESET = 'RESET',
  ERROR = 'ERROR',

  GET_FILE_DOCUMENTS = 'GET_FILE_DOCUMENTS',
  GET_FILE_DOCUMENTS_DONE = 'GET_FILE_DOCUMENTS_DONE',
  RESET_FILE_DOCUMENTS = 'RESET_FILE_DOCUMENTS',
  DELETE_FILE_DOCUMENT = 'DELETE_FILE_DOCUMENT',
  DELETE_FILE_DOCUMENT_DONE = 'DELETE_FILE_DOCUMENT_DONE',
  DELETE_FILE_DOCUMENTS = 'DELETE_FILE_DOCUMENTS',
  DELETE_FILE_DOCUMENTS_DONE = 'DELETE_FILE_DOCUMENTS_DONE',
  CREATE_FILE_DOCUMENT_THUMBNAIL = 'CREATE_FILE_DOCUMENT_THUMBNAIL',
  CREATE_FILE_DOCUMENT_THUMBNAIL_DONE = 'CREATE_FILE_DOCUMENT_THUMBNAIL_DONE',
  CREATE_FILE_DOCUMENT_THUMBNAIL_ERROR = 'CREATE_FILE_DOCUMENT_THUMBNAIL_ERROR',
  SET_FILE_DOCUMENT_ORDER = 'SET_FILE_DOCUMENT_ORDER',

  //one-off global state
  SET_OREA_DIALOG_STATUS = 'GLOBAL/SET_OREA_DIALOG_STATUS',
}

// Action Creators
// TODO work towards getting rid of this reset
export const reset = (): { type: ActionTypes.RESET } => action(ActionTypes.RESET);

export const getFileDocuments = (
  getFileDocumentsRequest: GetFileDocumentsRequest
): {
  type: ActionTypes.GET_FILE_DOCUMENTS;
  payload: GetFileDocumentsRequest;
} => action(ActionTypes.GET_FILE_DOCUMENTS, getFileDocumentsRequest);

export const deleteFileDocument = (
  deleteFileDocumentRequest: DeleteFileDocumentRequest
): {
  type: ActionTypes.DELETE_FILE_DOCUMENT;
  payload: DeleteFileDocumentRequest;
} => action(ActionTypes.DELETE_FILE_DOCUMENT, deleteFileDocumentRequest);

export const deleteFilesDocuments = (
  deleteFileDocumentsRequest: DeleteFileDocumentsRequest
): {
  type: ActionTypes.DELETE_FILE_DOCUMENTS;
  payload: DeleteFileDocumentsRequest;
} => action(ActionTypes.DELETE_FILE_DOCUMENTS, deleteFileDocumentsRequest);

export const createThumbnail = (
  document: FormDocument
): {
  type: ActionTypes.CREATE_FILE_DOCUMENT_THUMBNAIL;
  payload: FormDocument;
} => action(ActionTypes.CREATE_FILE_DOCUMENT_THUMBNAIL, document);

export type OreaDialogStatus = 'closed' | 'pending' | 'success';
export const setOreaDialogStatus = (
  status: OreaDialogStatus
): {
  type: ActionTypes.SET_OREA_DIALOG_STATUS;
  payload: OreaDialogStatus;
} => action(ActionTypes.SET_OREA_DIALOG_STATUS, status);

export const setFileDocumentOrder = (
  newDocumentOrder: FormDocument[]
): {
  type: ActionTypes.SET_FILE_DOCUMENT_ORDER;
  payload: FormDocument[];
} => action(ActionTypes.SET_FILE_DOCUMENT_ORDER, newDocumentOrder);
