/* eslint-disable simple-import-sort/imports */
import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';

// Polyfill for Android web and pdfjs
import 'core-js/features/array/at';
import { pdfjs } from 'react-pdf';

import 'what-input';
import './utils/loadenv';
import './utils/console-logger-listener';

import AppProviders from './components/AppProviders/AppProviders';
import { GOOGLE_TAG_MANAGER_AUTH, GOOGLE_TAG_MANAGER_ID, GOOGLE_TAG_MANAGER_PREVIEW } from './constants/environments';
import App from './App';

import './styles/preflight.css';
import './styles/reset.css';
import './styles/globals.css';

if (process.env.REACT_APP_RERENDER_CHECK === 'TRUE') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    trackAllPureComponents: true,
    collapseGroups: true,
  });
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

TagManager.initialize({
  gtmId: GOOGLE_TAG_MANAGER_ID,
  auth: GOOGLE_TAG_MANAGER_AUTH,
  preview: GOOGLE_TAG_MANAGER_PREVIEW,
});

ReactDOM.render(
  <AppProviders>
    <App />
  </AppProviders>,
  document.getElementById('root')
);
