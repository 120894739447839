import React, { useCallback, useEffect } from 'react';
import { PartnershipsProvider } from '@skyslope/partnerships-sdk/react';

import { userManager } from '../../auth';
import useDerivedUserData from '../../hooks/domain/user/useDerivedUserData';

let authResolver: () => void;
const isAuthed = new Promise<void>((resolve) => {
  authResolver = resolve;
});

const FormsAdsProvider = ({ children }: { children: React.ReactNode }) => {
  const { regions, userSettings } = useDerivedUserData();
  const isUserLoaded = userSettings != null;

  useEffect(() => {
    if (isUserLoaded) authResolver();
  }, [isUserLoaded]);

  const getToken = useCallback(async () => {
    await isAuthed;
    return await userManager.getAccessToken();
  }, []);

  return (
    <PartnershipsProvider environment={window.environmentKey} tokenGetter={getToken} unauthed={false} regions={regions}>
      {children}
    </PartnershipsProvider>
  );
};

export default FormsAdsProvider;
