import { action } from 'typesafe-actions';

import type { GetEnvelopesRequest } from 'resources/types/GetEnvelopes';
import type { UpdateFileDetailsPayload } from 'resources/types/UpdateFileDetails';
import { FileActions } from 'store/domain/actions';

export enum EnvelopeActions {
  UPDATE_ENVELOPE_TO_CANCELLED = 'EnvelopeActions/UPDATE_ENVELOPE_TO_CANCELLED',
  UPDATE_ENVELOPE_TO_CANCELLED_DONE = 'EnvelopeActions/UPDATE_ENVELOPE_TO_CANCELLED_DONE',
  GET_ENVELOPES = 'EnvelopeActions/GET_ENVELOPES',
  GET_ENVELOPES_DONE = 'EnvelopeActions/GET_ENVELOPES_DONE',
}

export const getFile = (
  fileId: number
): {
  type: FileActions.GET_FILE;
  payload: number;
} => action(FileActions.GET_FILE, fileId);

export const updateFileDetails = (
  payload: UpdateFileDetailsPayload
): {
  type: FileActions.UPDATE_FILE_DETAILS;
  payload: UpdateFileDetailsPayload;
} => action(FileActions.UPDATE_FILE_DETAILS, payload);

export const getEnvelopes = (
  payload: GetEnvelopesRequest
): {
  type: EnvelopeActions.GET_ENVELOPES;
  payload: GetEnvelopesRequest;
} => action(EnvelopeActions.GET_ENVELOPES, payload);

export const updateEnvelopeToCancelled = (
  envelopeId: number
): {
  type: EnvelopeActions.UPDATE_ENVELOPE_TO_CANCELLED;
  payload: number;
} => action(EnvelopeActions.UPDATE_ENVELOPE_TO_CANCELLED, envelopeId);
