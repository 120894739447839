import { put, takeEvery } from 'redux-saga/effects';
import { createAsyncAction } from 'typesafe-actions';

import nrdsServer from 'resources/server/AuthProfileVerificationServer';
import { apiCall } from 'utils/apiCall';

import { NRDSProfileActions } from './actions';
import type { NRDSVerificationError, VerifyNRDSIdPayload, VerifyNRDSIdResult } from './types';

const verifyNRDSIdAsync = createAsyncAction(
  NRDSProfileActions.VERIFY_NRDS_ID,
  NRDSProfileActions.VERIFY_NRDS_ID_DONE,
  NRDSProfileActions.VERIFY_NRDS_ID_ERROR
)<VerifyNRDSIdPayload, VerifyNRDSIdResult, NRDSVerificationError>();

export function* handleVerifyNRDSId(action: ReturnType<typeof verifyNRDSIdAsync.request>): any {
  try {
    const res = yield apiCall({
      resources: [nrdsServer, nrdsServer.VerifyNRDSMember],
      payload: action.payload,
    });
    if (res.memberData) {
      yield put(verifyNRDSIdAsync.success(res));
    } else {
      yield put(verifyNRDSIdAsync.failure({}));
    }
  } catch (e) {
    yield put(
      verifyNRDSIdAsync.failure({
        title: 'Connection Failed',
        message: 'We’re sorry, something went wrong. Please try again.',
      })
    );
  }
}

export default (): any => [takeEvery(NRDSProfileActions.VERIFY_NRDS_ID, handleVerifyNRDSId)];
