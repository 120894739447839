import { action } from 'typesafe-actions';

import type { SignedDocument } from '../../../../store/types';

export enum SelectSignedDocumentActions {
  SET_SELECTED_DISPLAY_SIGNED_DOCUMENTS = 'SignedDocuments/SET_SELECTED_DISPLAY_SIGNED_DOCUMENTS',
  ADD_SIGNED_DOCUMENT_TO_SELECTION = 'SignedDocuments/ADD_SIGNED_DOCUMENT_TO_SELECTION',
  ADD_ALL_SIGNED_DOCUMENTS_TO_SELECTION = 'SignedDocuments/ADD_ALL_SIGNED_DOCUMENTS_TO_SELECTION',
  REMOVE_SIGNED_DOCUMENT_FROM_SELECTION = 'SignedDocuments/REMOVE_SIGNED_DOCUMENT_FROM_SELECTION',
  REMOVE_ALL_SIGNED_DOCUMENTS_FROM_SELECTION = 'SignedDocuments/REMOVE_ALL_SIGNED_DOCUMENTS_FROM_SELECTION',
}

export interface SelectedSignedDocument {
  url?: string;
  blob?: Blob;
  pageCount?: number;
  documentName?: string;
  signedDocuments?: SignedDocument[];
}

export const setSelectedDisplaySignedDocuments = (
  selectedSignedDocument: SelectedSignedDocument | undefined
): {
  type: SelectSignedDocumentActions.SET_SELECTED_DISPLAY_SIGNED_DOCUMENTS;
  payload: SelectedSignedDocument | undefined;
} => action(SelectSignedDocumentActions.SET_SELECTED_DISPLAY_SIGNED_DOCUMENTS, selectedSignedDocument);

export const addSignedDocumentToSelection = (
  document: SignedDocument
): {
  type: SelectSignedDocumentActions.ADD_SIGNED_DOCUMENT_TO_SELECTION;
  payload: SignedDocument;
} => action(SelectSignedDocumentActions.ADD_SIGNED_DOCUMENT_TO_SELECTION, document);

export const addAllSignedDocumentToSelection = (
  documents: SignedDocument[]
): {
  type: SelectSignedDocumentActions.ADD_ALL_SIGNED_DOCUMENTS_TO_SELECTION;
  payload: SignedDocument[];
} => action(SelectSignedDocumentActions.ADD_ALL_SIGNED_DOCUMENTS_TO_SELECTION, documents);

export const removeSignedDocumentFromSelection = (
  document: SignedDocument
): {
  type: SelectSignedDocumentActions.REMOVE_SIGNED_DOCUMENT_FROM_SELECTION;
  payload: SignedDocument;
} => action(SelectSignedDocumentActions.REMOVE_SIGNED_DOCUMENT_FROM_SELECTION, document);

export const removeAllSignedDocumentFromSelection = (): {
  type: SelectSignedDocumentActions.REMOVE_ALL_SIGNED_DOCUMENTS_FROM_SELECTION;
} => action(SelectSignedDocumentActions.REMOVE_ALL_SIGNED_DOCUMENTS_FROM_SELECTION);
