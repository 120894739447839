/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

/** Barebones throttle function taken from Stackoverflow rather than import lodash or some other library
 * when its only needed currently for event throttling on the activityMonitor */
export const throttle = (fn: Function, wait = 300) => {
  let inThrottle: boolean, lastFn: ReturnType<typeof setTimeout>, lastTime: number;
  return function (this: void) {
    const context = this,
      args = arguments;
    if (!inThrottle) {
      fn.apply(context, args);
      lastTime = performance.now();
      inThrottle = true;
    } else {
      clearTimeout(lastFn);
      lastFn = setTimeout(
        () => {
          if (performance.now() - lastTime >= wait) {
            fn.apply(context, args);
            lastTime = performance.now();
          }
        },
        Math.max(wait - (performance.now() - lastTime), 0)
      );
    }
  };
};
