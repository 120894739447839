import React from 'react';
import { atom, useAtom } from 'jotai';

import useUserSettingsMutation from 'hooks/domain/user/mutations/useUserSettingsMutation';
import { MessagesToPrime, postMessageToPrime } from 'utils/postMessageToPrime';

type CompleteOnboarding = Omit<ReturnType<typeof useUserSettingsMutation>, 'mutate' | 'mutateAsync'> & {
  mutateAsync: () => Promise<void>;
};

export const onboardingJustCompletedAtom = atom(false);

export function useCompleteOnboarding(): CompleteOnboarding {
  const saveUserSettings = useUserSettingsMutation();
  const [, setHappenedJustNow] = useAtom(onboardingJustCompletedAtom);

  return React.useMemo(() => {
    async function mutateAsync() {
      await saveUserSettings.mutateAsync({ isInitialized: true });
      postMessageToPrime(MessagesToPrime.FORMS_ONBOARDING_COMPLETE);
      setHappenedJustNow(true);
    }

    const r = { ...saveUserSettings, mutateAsync } as CompleteOnboarding;
    // @ts-expect-error
    delete r.mutate;
    return r;
  }, [saveUserSettings, setHappenedJustNow]);
}
