import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '@skyslope/auth-react';

import isTrrebIdp from 'auth/hooks/helpers/isTrrebIdp';
import useGetUserIDP from 'auth/hooks/useGetUserIDP';
import LocalStorageItem from 'constants/LocalStorageItem';
import { getPath, routes } from 'constants/routes';
import useAddAuthProfilesMutation from 'hooks/domain/user/mutations/useAddAuthProfilesMutation';
import useLinkOktaMutation from 'hooks/domain/user/mutations/useLinkOktaMutation';
import useGetUserSettingsQuery from 'hooks/domain/user/queries/useGetUserSettingsQuery';
import useGlobalLoadingScreen from 'hooks/useGlobalLoadingScreen';
import useIsOnboarding from 'hooks/useIsOnboarding';
import useIsPermanentEmailNeeded from 'hooks/useIsPermanentEmailNeeded';
import useLocalStorage from 'hooks/useLocalStorage';
import useGetTrrebMemberByMemberId from 'pages/UserOnboarding/hooks/useGetTrrebMemberByMemberId';
import useGetUserLoginId from 'pages/UserOnboarding/hooks/useGetUserLoginId';

import useIsLoginProcessComplete from '../../../auth/hooks/useIsLoginProcessComplete';

const useTrrebMemberOnboarding = (): void => {
  const userIdp = useGetUserIDP();
  const loginId = useGetUserLoginId();
  const { data: userSettings } = useGetUserSettingsQuery();
  const { mutate: addAuthProfiles } = useAddAuthProfilesMutation();
  const { mutateAsync: linkOktaMutation } = useLinkOktaMutation();
  const { addItem, updateItem } = useLocalStorage();
  const { userManager, userContext } = useAuth();
  const { showGlobalLoadingScreen } = useGlobalLoadingScreen();
  const history = useHistory();
  const isPermanentEmailNeeded = useIsPermanentEmailNeeded();
  const isOnOnboardingPage = useIsOnboarding();
  const isLoggedIn = useIsLoginProcessComplete();

  const isTrreb = isTrrebIdp(userIdp);

  const needsAuthProfileAdded =
    isTrreb &&
    !userSettings?.email?.endsWith('@trreb.invalid') &&
    !userSettings?.authProfiles?.find((p) => p.type == 'Trreb');

  // User's with existing okta accounts need to be relogged in
  // This will ensure they land on their intended page
  if (window?.location?.href.toLowerCase().indexOf('trreb') > -1) {
    updateItem(LocalStorageItem.TrrebRedirectUrl, window?.location?.href);
  }

  // This is a retroactive fix.  Some users with spoofed emails have already been initialized
  // Onboarding will now prevent new users from initializing with spoofed emails
  // This useEffect should be sunset and removed after a grace period.
  useEffect(() => {
    if (isPermanentEmailNeeded && !isOnOnboardingPage) {
      addItem(LocalStorageItem.OnboardingRedirectUrl, `${location.pathname}${location.search}`);
      history.push(routes.onboardingEmail);
    }
  }, [isPermanentEmailNeeded, isOnOnboardingPage, history, addItem]);

  const { data: trrebMember } = useGetTrrebMemberByMemberId(needsAuthProfileAdded ? loginId : undefined);

  const isULA = trrebMember?.memberId.startsWith('5') && trrebMember?.title.toLowerCase() == 'unlicensed assistant';

  useEffect(() => {
    const addTrrebAuthProfile = (): void => {
      if (trrebMember && userSettings) {
        addAuthProfiles([
          {
            userId: userSettings.id,
            type: `Trreb`,
            profile: { memberId: trrebMember?.memberId },
          },
        ]);
      }
    };

    if (needsAuthProfileAdded && !isULA) {
      addTrrebAuthProfile();
    }
  }, [addAuthProfiles, userSettings, needsAuthProfileAdded, trrebMember, userManager, isULA]);

  const [isRelinked, setIsRelinked] = useState(false);

  const linkOktaAccount = useCallback(
    async (loginId: string, userId: string): Promise<void> => {
      try {
        const oktaId = await linkOktaMutation({ memberId: loginId });
        if (oktaId !== userId) {
          userManager?.startLogout();
        } else {
          showGlobalLoadingScreen(false);
        }
      } catch (e) {
        history.push(getPath(routes.relinkingError));
        showGlobalLoadingScreen(false);
      }
    },
    [linkOktaMutation, showGlobalLoadingScreen, userManager, history]
  );

  const hasInvalidEmail = userContext?.email?.endsWith('@trreb.invalid') ?? false;

  useEffect(() => {
    try {
      if (userContext == null || !isLoggedIn) return;
      // We only need to go through this flow the first time a trreb user logins in
      if (isTrreb && !isRelinked && hasInvalidEmail && loginId != null) {
        showGlobalLoadingScreen(true);
        setIsRelinked(true);
        linkOktaAccount(loginId, userContext.id);
      }
    } catch (e) {
      showGlobalLoadingScreen(false);
      history.push(getPath(routes.relinkingError));
    }
  }, [
    showGlobalLoadingScreen,
    history,
    isTrreb,
    loginId,
    userContext,
    isRelinked,
    linkOktaAccount,
    hasInvalidEmail,
    isLoggedIn,
  ]);
};

export default useTrrebMemberOnboarding;
