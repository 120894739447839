import type { MeSubscription } from '../resources/types/GetUserSubscriptionSettings';

import initialize from './chameleon-initialize';

const adminKey = 'adminPreview';
const chameleonProperties = 'setup identify alias track clear set show on off custom help _data'.split(' ');

export default {
  initialize: (key: string): void => {
    initialize(key, adminKey, chameleonProperties);
  },
  setupUser: (params: {
    userId: string;
    email: string;
    isStandaloneUser?: boolean;
    hasBrokerTemplates?: boolean;
    organizationId?: number;
    regions?: string;
    filesCreated?: number;
    userCreationDate?: string;
    libraryRegions?: string;
    meSubscriptions?: MeSubscription;
    libraryIds?: string | string[];
    firstName?: string;
    lastName?: string;
    fileRepresentationType?: string;
    orefExpiration?: number;
    isWidgetUser?: boolean;
    isDocusignEnabled?: boolean;
  }): void => {
    window.chmln.identify(params.userId, {
      email: params.email,
      senderEmail: params.email, // this one is not necessary and is only in for backwards compatibility, we should not use it in the chameleon app and eventually remove it from here
      forms_standalone: params.isStandaloneUser,
      organization_id: params.organizationId,
      subscriber_id: params.organizationId,
      agent_has_template_shared: params.hasBrokerTemplates,
      forms_onboarding_region: params.regions,
      forms_files_created: params.filesCreated,
      forms_user_creation_date: params.userCreationDate,
      forms_library_states: params.libraryRegions,
      forms_libraries: params.libraryIds,
      subscription_properties: params.meSubscriptions,
      first_name: params.firstName,
      last_name: params.lastName,
      fileRepresentationType: params.fileRepresentationType,
      orefExpiration: params.orefExpiration,
      isWidgetUser: params.isWidgetUser,
      isDocusignEnabled: params.isDocusignEnabled,
    });
  },
};
