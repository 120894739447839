import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import AuthProfileVerificationServer from 'resources/server/AuthProfileVerificationServer';
import type { OreaVerificationResponse } from 'resources/types/AuthProfileVerificationResponses';
import type { UserAuthProfile } from 'store/types';
import { FormLibrarySource } from 'store/types';

import useOreaAuthFlow from './useOreaAuthFlow';

interface OreaVerificationState {
  authTab?: Window | null;
  eqs?: string;
  isSuccess?: boolean;
}

export interface UseOreaAuthLoginResult {
  isInvalidOreaMember?: boolean;
  isLoading: boolean;
  isLoggingIn: boolean;
  isLoggingOut: boolean;
  isSuccess?: boolean;
  onContinue: () => void;
  onLoginClick: () => void;
  onLogoutClick: () => void;
}

const useOreaAuthLogin = (onSubmit: (profile: UserAuthProfile) => void): UseOreaAuthLoginResult => {
  const { login, logout, baseUrl, isLoggingOut } = useOreaAuthFlow();
  const [{ eqs, authTab, isSuccess }, setVerificationState] = useState<OreaVerificationState>({});

  const { data: verificationResult, isInitialLoading } = useQuery<OreaVerificationResponse>({
    queryKey: ['verify-orea', eqs],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: () => AuthProfileVerificationServer.VerifyOreaMember(eqs!),
    enabled: eqs != null,
  });

  const isInvalidOreaMember = verificationResult
    ? !verificationResult?.isVerified && verificationResult?.memberDataRaw.reply === 'NOT_A_MEMBER'
    : undefined;

  useEffect(() => {
    const handleMessage = (event: MessageEvent): void => {
      const sourceAsWindow = event.source as Window;
      if (
        event.origin !== baseUrl ||
        sourceAsWindow == null ||
        !sourceAsWindow.location?.pathname.includes('orea-handler') ||
        typeof event.data !== 'string'
      ) {
        return;
      }

      sourceAsWindow.close();
      if (event.data) {
        setVerificationState((curState) => ({
          ...curState,
          eqs: event.data,
          authTab: undefined,
        }));
      }
    };

    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);
  }, [baseUrl]);

  useEffect(() => {
    if (verificationResult?.isVerified) {
      setVerificationState((curState) => ({
        ...curState,
        isSuccess: true,
      }));
    }
  }, [eqs, onSubmit, verificationResult]);

  const onLoginClick = (): void => {
    setVerificationState((curState) => ({
      ...curState,
      authTab: login(),
    }));
  };

  const onLogoutClick = (): void => {
    logout();
    setVerificationState({});
  };

  const onContinue = (): void => {
    if (isSuccess) {
      onSubmit({
        type: FormLibrarySource.Orea,
        profile: {
          eqs: eqs,
          areTermsAccepted: true, // can't get here without accepting terms
        },
      });
    }
  };

  const isLoggingIn = authTab != null && eqs == null;
  return {
    isInvalidOreaMember,
    isLoading: isInitialLoading,
    isLoggingIn,
    isLoggingOut,
    isSuccess,
    onContinue,
    onLoginClick,
    onLogoutClick,
  };
};

export default useOreaAuthLogin;
