import type { UseMutationResult } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from 'axios';

import type { UpdateAuthProfileCacheRequest } from 'resources/server/UserProfileServer';
import UserProfileServer from 'resources/server/UserProfileServer';

const useUpdateAuthProfileCacheMutation = (): UseMutationResult<
  string | undefined,
  AxiosError,
  UpdateAuthProfileCacheRequest,
  unknown
> => useMutation(UserProfileServer.UpdateAuthProfileCache.bind(UserProfileServer));

export default useUpdateAuthProfileCacheMutation;
