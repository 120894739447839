import React from 'react';
import Box from '@mui/material/Box';
import { css, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import type { TermsAndConditionsViewProps } from './store/types';
import { getTermsSx } from './store/types';

import { css as emotionCss } from '@emotion/css';

const FlNrdsTermsAndConditionsView: React.FC<TermsAndConditionsViewProps> = ({ title, inlineView = false }) => {
  const theme = useTheme();
  const termsSx = getTermsSx(theme);
  return (
    <Box right="0" sx={termsSx.contentWrapper} p={inlineView ? 2 : 8}>
      <Box mb={4}>
        <Typography id="typTitle" variant="h4" sx={termsSx.title}>
          {title}
        </Typography>
        <Typography variant="caption">Effective Date: November 1, 2021</Typography>
      </Box>

      <Typography variant="body1">OWNERSHIP, IDENTIFICATION, AND ALTERATION OF FORMS</Typography>

      <ol>
        <li>
          <Typography variant="h6">FORMS STATEMENT OF OWNERSHIP & IDENTIFICATION</Typography>
          <Typography component="span" variant="body1">
            <ol className={emotionCss(css(termsSx.uppercaseList))}>
              <li>
                {' '}
                <Typography variant="h6">Statement of Ownership</Typography>
                “Florida Realtors and Florida Realtors/Florida Bar forms ©2021. All rights reserved. The Florida
                Realtors and Florida Realtors/Florida Bar forms included in this software are reproduced under a license
                agreement from Florida Realtors. This license agreement does not constitute an endorsement or
                recommendation of this software by Florida Realtors. Blank forms may not be resold, repackaged or
                redistributed to any third party. Read your terms of service carefully.”
              </li>
            </ol>
          </Typography>
        </li>
      </ol>
    </Box>
  );
};

export default FlNrdsTermsAndConditionsView;
