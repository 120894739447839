import { useEffect } from 'react';

export default (): void => {
  useEffect(() => {
    const handleMessage = (event: MessageEvent): void => {
      if (event.isTrusted && event.data === 'reload') {
        window.location.reload();
      }
    };

    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);
  }, []);
};
