import React, { isValidElement } from 'react';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import type { DialogClasses } from '@mui/material/Dialog';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import { twMerge } from 'tailwind-merge';

import useIsMobile from 'hooks/useIsMobile';

export interface ConfirmationDialogProps {
  title?: string; // Use title only for important alerts. See guidelines here: https://material-ui.com/components/dialogs/#alerts
  description?: string | string[] | React.ReactNode;
  confirmCallback: () => void;
  cancelCallback?: () => void;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  clickOutCallbackOverride?: () => void;
  hasCheckBox?: boolean;
  checkboxCallBack?: () => void;
  classes?: Partial<DialogClasses> & {
    title?: string;
    description?: string;
    actions?: string;
    cancelButton?: string;
    confirmButton?: string;
  };
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  title,
  description,
  confirmButtonLabel,
  confirmCallback,
  cancelButtonLabel,
  cancelCallback,
  clickOutCallbackOverride,
  hasCheckBox,
  checkboxCallBack,
  classes,
}) => {
  const isMobile = useIsMobile();

  return (
    <Dialog
      id="confirmation-dialog"
      open
      onClose={clickOutCallbackOverride ?? cancelCallback}
      aria-labelledby={title ? 'confirmation-dialog-title' : undefined}
      aria-describedby={description ? 'confirmation-dialog-title' : undefined}
      classes={{
        paper: twMerge('w-[600px]', classes?.paper),
        ...classes,
      }}
      fullScreen={isMobile}
      className={classes?.root}
    >
      {title && (
        <DialogTitle
          id="confirmation-dialog-title"
          component="h2"
          variant="h3"
          className={twMerge('pt-8 px-8 pb-6', classes?.title)}
        >
          {title}
        </DialogTitle>
      )}
      {description && (
        <DialogContent className={twMerge('px-8 py-0', classes?.description)}>
          {isValidElement(description) && description}
          <DialogContentText color="initial" id="confirmation-dialog-description">
            {!isValidElement(description) &&
              (typeof description === 'string'
                ? description
                : (description as string[]).map((d, row) => (
                    <>
                      {d} {row !== (description as string[])?.length - 1 ? <br /> : ''}
                    </>
                  )))}
          </DialogContentText>
          {hasCheckBox && (
            <FormControlLabel
              control={<Checkbox id="do-not-display-again-checkbox" color="primary" onChange={checkboxCallBack} />}
              label="Do not display again"
            />
          )}
        </DialogContent>
      )}
      <DialogActions className={twMerge('p-8', classes?.actions)}>
        <Button
          onClick={cancelCallback}
          color="primary"
          variant="text"
          id="confirmation-dialog-cancel-btn"
          aria-label="Cancel the action"
          className={classes?.cancelButton}
        >
          {cancelButtonLabel ? cancelButtonLabel : 'Cancel'}
        </Button>
        <Button
          onClick={confirmCallback}
          color="primary"
          type="submit"
          variant="contained"
          id="confirmation-dialog-ok-btn"
          aria-label="Confirm the action"
          className={classes?.confirmButton}
        >
          {confirmButtonLabel ? confirmButtonLabel : 'OK'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
