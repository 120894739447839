import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import AccountUserServer from 'resources/server/AccountUserServer';
import type { MeSubscription } from 'resources/types/GetUserSubscriptionSettings';

import useGetUserSettingsQuery from './useGetUserSettingsQuery';

export const meSubscriptionKey = 'me-subscription';

const useGetMeSubscriptionQuery = (
  options: Omit<
    UseQueryOptions<
      { subscription: MeSubscription },
      unknown,
      { subscription: MeSubscription } | undefined,
      (string | undefined)[]
    >,
    'initialData' | 'queryFn' | 'queryKey'
  > = { enabled: true }
) => {
  const { data: userSettings } = useGetUserSettingsQuery();

  return useQuery({
    queryKey: [meSubscriptionKey, userSettings?.id],
    queryFn: async () => await AccountUserServer.getMeSubscriptions(),
    enabled: !!userSettings?.id && !!options.enabled,
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export default useGetMeSubscriptionQuery;
