import update from 'immutability-helper';

import { ActionTypes } from 'store/actions';
import type { AppAction } from 'store/types';

import { FileCreateActions } from './actions';

export interface FileCreatePageState {
  mlsImportUid?: string;
}

export const defaultState: FileCreatePageState = {};

export const createFilePageReducer = (
  state: FileCreatePageState = defaultState,
  action: AppAction
): FileCreatePageState => {
  switch (action.type) {
    case FileCreateActions.SET_MLS_IMPORT_UID: {
      return update(state, { mlsImportUid: { $set: action.payload } });
    }
    case ActionTypes.RESET: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
};

export default createFilePageReducer;
