import { useParams } from 'react-router-dom';

import useGetFilteredFormVersions from 'containers/SelectForms/hooks/useGetFilteredFormVersions';
import useGetFileQuery from 'hooks/domain/file/queries/useGetFileQuery';
import useDelegateUser from 'hooks/domain/user/useDelegateUser';
import type { AddFormsToFileContainerRouterProps } from 'pages/AddFormsToFile/AddFormsToFile';
import useEnvelopeForAddForms from 'pages/AddFormsToFile/Hooks/useEnvelopeForAddForms';

/**
 * Determines if the current user, or the owner of the file currently being accessed has
 * access to any Forms Libraries.
 *
 * Reads fileId and envelopeId from the url if they exist.
 */
export function useHasLibraries() {
  const urlParams: AddFormsToFileContainerRouterProps = useParams();
  const envelope = useEnvelopeForAddForms();

  const fileId = envelope.isEnvelope ? envelope.fileId : parseInt(urlParams.fileId || '');
  const { data: file } = useGetFileQuery(fileId);
  const onBehalfOf = useDelegateUser(file?.ownedBy) || '';
  const { hasLibraries } = useGetFilteredFormVersions(onBehalfOf);

  return hasLibraries;
}
