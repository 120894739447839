import type { UseQueryResult } from '@tanstack/react-query';

import type { FormLibrary, UserRegion } from 'store/types';
import SieveBuilder from 'utils/sieve';

import useGetLibrariesQuery from '../library/queries/useGetLibrariesQuery';

const useRegionLibraries = (regions: UserRegion[] | undefined, onBehalfOf?: string): UseQueryResult<FormLibrary[]> => {
  const sieveBuilder = new SieveBuilder();
  sieveBuilder.pageSize(10000);
  sieveBuilder.page(1);
  sieveBuilder.ascendBy('Name');
  if (regions && regions.length > 0) {
    sieveBuilder.contains('Regions', SieveBuilder.or(...regions.map((r) => `${r.country}-${r.region}`)));
  }

  const params = sieveBuilder.build();
  const searchParams = params ?? new URLSearchParams();
  if (onBehalfOf) {
    searchParams.append('onBehalfOf', onBehalfOf);
  }

  return useGetLibrariesQuery({
    params: searchParams,
    extendedKey: 'user-regions',
    enabled: (regions && regions.length > 0) ?? false,
  });
};

export default useRegionLibraries;
