import type { AuthState, UserContext } from '@skyslope/auth-js';
import type { AuthContext } from '@skyslope/auth-react';

import type { FormsUserManager } from 'auth/userManager';
import EnvelopeStatus from 'constants/EnvelopeStatus';
import FileType from 'constants/FileType';
import FormStatuses from 'constants/FormStatuses';
import type { FileSummary } from 'pages/FilesDashboard/store/types';
import type { MarkupBlock } from 'pages/Fill/components/Controls/markup/types';
import { MarkupType } from 'pages/Fill/components/Controls/markup/types';
import type { ClauseViewModel } from 'resources/types/GetClause';
import type { EnvelopeResponse } from 'resources/types/GetPendingEnvelope';
import type {
  AccountGroupListResponse,
  BrokerageClause,
  BuyerAgreement,
  BuyerAgreementLeaderboardItem,
  Contact,
  DocumentDetails,
  Envelope,
  File,
  FormDocument,
  FormLibrary,
  MLSListing,
  PendingEnvelope,
  Roles,
  UserSettings,
} from 'store/types';
import { BrokerTemplateStatus, ContactRole, FormLibrarySource, RepresentationType } from 'store/types';
import type { ContactFieldType } from 'types/contacts';
import type { DocumentDetailsV2, DomainFieldMetaData, FormField } from 'types/documents';

import type { PersonalClause } from '../../resources/types/Clause/PersonalClause';
import type { UserSubscription, UserSubscriptionSettings } from '../../resources/types/GetUserSubscriptionSettings';

export const createDocumentDetails = ({
  id = 1,
  fileId = 1,
  formId = 1,
  formName = 'test form',
  pageCount = 1,
  formURL = 'test-url',
  fieldData = {
    fieldsMetadata: {},
    fields: [],
  },
  formStatus = FormStatuses.Current,
  publishedVersionId = 1,
  sources = [FormLibrarySource.AzNrds],
}: Partial<DocumentDetails> = {}): DocumentDetails => ({
  id,
  fileId,
  formId,
  formName,
  pageCount,
  formURL,
  fieldData,
  formStatus,
  publishedVersionId,
  sources,
});

export const createMockFile = ({
  id = 1,
  mlsNumber = '123',
  name = 'file name',
  fileType = FileType.File,
  property = {
    state: 'ca',
    streetName: 'some st',
    streetNumber: '123',
    city: 'sac',
    postalCode: '1234123',
    county: 'some county',
    unitNumber: 'A',
    isCompleteAddress: true,
  },
  acceptanceDateTime = null,
  closingDateTime = null,
  purchasePrice = null,
  isDeleted = false,
  isArchived = false,
  commissions = [],
  contacts = [],
  representationType = RepresentationType.BUYER,
  availableContactRolesDeprecated = [],
  fileData = {
    mlsMarketName: 'test',
  },
  documentData = {},
  meta = {
    externalId: '1',
    regions: [],
    brokerTemplateStatus: BrokerTemplateStatus.Draft,
    brokerTemplateStatusUpdatedOn: '',
    accountGroups: [],
  },
  ownedBy = 'me',
  createdBy = 'me',
}: Partial<File> = {}): File => ({
  id,
  mlsNumber,
  name,
  fileType,
  property,
  acceptanceDateTime,
  closingDateTime,
  purchasePrice,
  isDeleted,
  isArchived,
  commissions,
  contacts,
  representationType,
  availableContactRolesDeprecated,
  documentData,
  fileData,
  meta,
  ownedBy,
  createdBy,
});

export const createDocumentDetailsV2 = ({
  id = 1,
  fileId = 1,
  formId = 1,
  formName = 'test form',
  pageCount = 1,
  formURL = 'test-url',
  fields = [],
  isPremisesAddressRequiredToDownload = false,
  formStatus = FormStatuses.Current,
  publishedVersionId = 1,
  source = FormLibrarySource.AzNrds,
  documentData = {},
  pages = [],
  meta = {
    groups: [],
  },
  addendumFormId = undefined,
  addendumFormName,
  numAddendums = undefined,
  mlsType = undefined,
  inputSheetType = undefined,
}: Partial<DocumentDetailsV2> = {}): DocumentDetailsV2 => ({
  id,
  fileId,
  formId,
  formName,
  pageCount,
  formURL,
  fields,
  isPremisesAddressRequiredToDownload,
  formStatus,
  publishedVersionId,
  source,
  documentData,
  pages,
  meta,
  addendumFormId,
  addendumFormName,
  numAddendums,
  mlsType,
  inputSheetType,
});

export const createMockFormDocument = ({
  id = 1,
  formId = 2,
  formName = 'form',
  formURL = 'some form url',
  updatedOn = 'today',
  thumbnailURL = 'some thumbnail url',
  theRealFormId = 4,
}: Partial<FormDocument> = {}): FormDocument => ({
  id,
  formId,
  formName,
  formURL,
  updatedOn,
  thumbnailURL,
  theRealFormId,
});

export const createMockFormLibrary = ({
  id = 1,
  name = 'Test Lib',
  requiredFields = [],
  authProfiles = [
    {
      externalId: '123',
      source: FormLibrarySource.AzNrds,
    },
  ],
  tags = {},
  source = FormLibrarySource.AzNrds,
  ...other
}: Partial<FormLibrary> = {}): FormLibrary => ({
  id,
  name,
  requiredFields,
  authProfiles,
  tags,
  source,
  ...other,
});

export const createMockMlsListing = ({
  uid = '1234',
  propertyId = 'propertyId',
  displayMlsNumber = '000000000',
  marketName = 'marketName',
  displayAddress = '123 Street Road',
  photoUrl = 'photo.jpg',
  propertyAddress = {
    streetNumber: '123',
    streetName: 'Street Road',
    unitNumber: 'X',
    city: 'Blitz',
    state: 'CA',
    zipCode: '44444',
  },
  listDate = '01/01/2000',
  listingPrice = 200000,
  listingStatus = 'Open',
  lastUpdateDate = '02/02/2000',
  listingAgentSummaryInfo = {
    name: 'Listing Agent',
  },
}: Partial<MLSListing> = {}): MLSListing => ({
  uid,
  propertyId,
  displayMlsNumber,
  marketName,
  displayAddress,
  photoUrl,
  propertyAddress,
  listDate,
  listingPrice,
  listingStatus,
  lastUpdateDate,
  listingAgentSummaryInfo,
});

export const createMockFileSummary = ({
  id = 1,
  name = 'name',
  type = FileType.File,
  documentCount = 1,
  lastUpdated = '2020-12-31 23:59:59',
  envelopeCount = 1,
  representationType = RepresentationType.TENANT,
  isDeleted = false,
  isArchived = false,
  isDelegateUser = false,
  templateCategory = undefined,
  createdDate = '2020-12-30 23:59:59',
  meta = undefined,
  ownedBy = '',
}: Partial<FileSummary> = {}): FileSummary => ({
  id,
  name,
  type,
  documentCount,
  lastUpdated,
  envelopeCount,
  representationType,
  isDeleted,
  isArchived,
  isDelegateUser,
  templateCategory,
  createdDate,
  meta,
  ownedBy,
});

export const createMockEnvelope = ({
  id = 1,
  fileId = 2,
  name = 'envelope1',
  status = EnvelopeStatus.Sent,
  externalId = '123456',
  dateSent = '01/01/2020',
  signers = [],
  documentIds = [],
  isMissingExternalReference = false,
}: Partial<Envelope> = {}): Envelope => ({
  id,
  fileId,
  name,
  status,
  externalId,
  dateSent,
  signers,
  documentIds,
  isMissingExternalReference,
});

export const createMockContact = ({
  id = 1,
  type = ContactRole.BUYER,
  lastName = 'last name',
  middleName = 'middle name',
  firstName = 'first name',
  suffix = 'III',
  primaryPhoneNumber = '1231231234',
  primaryAddress = {
    streetNumber: '123',
    streetName: 'Street Road',
    unitNumber: 'X',
    city: 'Blitz',
    state: 'CA',
    postalCode: '44444',
    county: '123',
  },
  ...other
}: Partial<Contact> = {}): Contact => ({
  id,
  type,
  lastName,
  middleName,
  firstName,
  suffix,
  primaryPhoneNumber,
  primaryAddress,
  ...other,
});

export const createMockContactFieldType = ({
  id = 1,
  type = ContactRole.BUYER,
  ...other
}: Partial<ContactFieldType> = {}): ContactFieldType =>
  ({
    id,
    type,
    ...other,
  }) as ContactFieldType;

export const createMockPendingEnvelope = ({
  id = 1,
  updatedOn = 'some time',
  name = 'env name',
  envelopeStatus = 'DRAFT',
  fileId = 1,
  externalId = 'external',
  dateSent = 'some date',
  documents = [],
  envelopeUrl = 'some-url',
}: Partial<PendingEnvelope> = {}): PendingEnvelope => ({
  id,
  updatedOn,
  name,
  envelopeUrl,
  envelopeStatus,
  fileId,
  externalId,
  dateSent,
  documents,
});

export const createMockEnvelopeResponse = ({
  id = 1,
  updatedOn = 'some time',
  name = 'env name',
  envelopeStatus = EnvelopeStatus.Draft,
  fileId = 1,
  externalId = 'external',
  dateSent = 'some date',
}: Partial<EnvelopeResponse> = {}): EnvelopeResponse => ({
  id,
  updatedOn,
  name,
  envelopeStatus,
  fileId,
  externalId,
  dateSent,
});

export const createMockAuthState = ({ isAuthenticated = false }: Partial<AuthState> = {}): AuthState => ({
  isAuthenticated,
});

export const createMockUserManager = ({
  login = async () => {},
  startLogout = async () => {},
  endLogout = async () => {},
  getAccessToken = async () => 'abc',
  getAuthState = async () => createMockAuthState(),
  getUser = async () => null,
  impersonateUser = async () => {},
  forgotPassword = async () => true,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options = {} as any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  idps = {} as any,
  primeAuth = {
    isAuthenticated: async () => false,
    startBackgroundLogin: async () => {},
    endBackgroundLogin: async () => {},
    getAccessToken: async () => null,
    getSessionStatus: async () => null,
  },
  _oktaAuth = {
    oktaAuth: {
      authStateManager: {
        getAuthState: () => createMockAuthState(),
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any,
    onAuthRequired: async () => {},
  },
}: Partial<FormsUserManager> = {}): FormsUserManager => ({
  login,
  startLogout,
  endLogout,
  getAccessToken,
  getAuthState,
  getUser,
  impersonateUser,
  forgotPassword,
  options,
  idps,
  _oktaAuth,
  primeAuth,
});

export const createMockUserContext = ({
  id = '123',
  firstName = 'yoo',
  lastName = 'lul',
  email = 'chiefs@suck.com',
  primaryPhone = '1234567890',
  loginId = undefined,
  nrdsId = undefined,
  prime = {
    userId: '123',
    subscriberId: 123,
  },
}: Partial<UserContext> = {}): UserContext => ({
  id,
  firstName,
  lastName,
  email,
  primaryPhone,
  loginId,
  nrdsId,
  prime,
});

export const createMockAuthContext = ({
  userManager,
  authState,
  userContext,
}: Partial<AuthContext> = {}): AuthContext => ({
  userManager: createMockUserManager(userManager),
  authState: createMockAuthState(authState),
  userContext: createMockUserContext(userContext ?? undefined),
});

export const createMockUserSubscriptionsSettings = ({
  subscription = {
    created: new Date('2020-10-19T20:58:52.4017211Z'),
    subscriptionId: 'subId-123',
    type: 'Individual',
    validUntil: new Date().toISOString(),
    customerId: 'custId-123',
  },
  trial = {
    daysLeftInTrial: 0,
    endDate: new Date(),
  },
}: Partial<UserSubscriptionSettings> = {}): UserSubscriptionSettings => ({
  subscription,
  trial,
});

export const createMockUserSubscriptions = ({
  created = new Date('2020-10-19T20:58:52.4017211Z'),
  subscriptionId = 'subId-123',
  type = 'Individual',
  validUntil = new Date().toISOString(),
  customerId = 'custId-123',
  ...other
}: Partial<UserSubscription> = {}): UserSubscription => ({
  created,
  subscriptionId,
  type,
  validUntil,
  customerId,
  ...other,
});

export const createMockUserSettings = ({
  id = 'user-id',
  firstName = 'first',
  middleName = 'mid',
  lastName = 'last',
  suffix = '',
  primaryPhoneNumber = '',
  email = 'user@email.com',
  brokerageName = '',
  brokeragePhone = '',
  regions = [],
  libraries = [],
  authProfiles = [],
  isInitialized = true,
  notificationEmail = '',
  licenseNumber = 'license number',
  mlsCode = 'mls code',
  brokerageLicenseNumber = 'broker license number',
  brokerageMLSCode = 'broker mls code',
  isAutoDeletePreDraftEnvelopeAllowed = false,
  createdOn = '2020-10-19T20:58:52.4017211Z',
  ...other
}: Partial<UserSettings> = {}): UserSettings => ({
  id,
  firstName,
  middleName,
  lastName,
  suffix,
  primaryPhoneNumber,
  email,
  brokerageName,
  brokeragePhone,
  regions,
  libraries,
  authProfiles,
  isInitialized,
  notificationEmail,
  licenseNumber,
  mlsCode,
  brokerageMLSCode,
  brokerageLicenseNumber,
  isAutoDeletePreDraftEnvelopeAllowed,
  createdOn,
  ...other,
});

export const createMockDomainFieldMetaData = ({
  optionSet = {
    isOverridable: true,
    entityName: 'entityName',
    options: [
      {
        display: 'display',
        value: 'value',
      },
    ],
  },
}: Partial<DomainFieldMetaData> = {}): DomainFieldMetaData => ({
  optionSet,
});

export const mockFile = createMockFile({
  acceptanceDateTime: '',
  closingDateTime: '',
  commissions: [],
  contacts: [],
  fileType: FileType.File,
  id: 1,
  mlsNumber: '',
  name: '',
  property: {
    streetNumber: '1300X',
    streetName: 'random_street_name',
    unitNumber: '1',
    city: 'Sacramento',
    state: 'CA',
    county: 'Sacramento',
    postalCode: '95814',
  },
  purchasePrice: '',
  representationType: RepresentationType.BUYER,
  availableContactRolesDeprecated: [],
});

export const createMockClause = ({
  id = '1',
  brokerageSubId = undefined,
  libraryId = undefined,
  regions = undefined,
  categories = 'categories',
  title = 'title',
  body = 'body',
}: Partial<BrokerageClause> = {}): BrokerageClause => ({
  id,
  brokerageSubId,
  libraryId,
  regions,
  categories,
  title,
  body,
});

export const createMockClauseViewModel = ({
  id = '1',
  brokerageSubId = undefined,
  libraryId = undefined,
  regions = undefined,
  category = 'category',
  title = 'title',
  clause = 'clause',
  source = 'source',
}: Partial<ClauseViewModel> = {}): ClauseViewModel => ({
  id,
  brokerageSubId,
  libraryId,
  regions,
  category,
  title,
  clause,
  source,
});

export const createMockPersonalClause = ({
  id = 1,
  brokerageSubId = undefined,
  regions = undefined,
  categories = undefined,
  title = 'title',
  body = 'clause',
  createdBy = 'creator',
  updatedBy = 'updater',
  ownedBy = 'owner',
}: Partial<PersonalClause> = {}): PersonalClause => ({
  id,
  brokerageSubId,
  regions,
  categories,
  title,
  body,
  createdBy,
  updatedBy,
  ownedBy,
});

export const createMockFormField = ({
  isGlobal = false,
  isOverridden = false,
  dataRef = 'dataRef',
  format = 'format',
  type = 'text',
  id = 'id',
  pageNumber = 1,
  width = 10,
  height = 10,
  xCoordinate = 1,
  yCoordinate = 1,
  isTabbable = true,
  isReadOnly = false,
  name = 'name',
  order = 1,
  group = undefined,
  clientType = undefined,
  domainFieldMetaData = undefined,
  associatedDataRefs = [],
}: Partial<FormField> = {}): FormField => ({
  order,
  group,
  name,
  isReadOnly,
  isTabbable,
  yCoordinate,
  height,
  width,
  pageNumber,
  clientType,
  id,
  type,
  format,
  dataRef,
  isGlobal,
  isOverridden,
  domainFieldMetaData,
  xCoordinate,
  associatedDataRefs,
});

export const createMockRole = ({
  roleId = 'abc123',
  roleName = 'Broker Admin',
  access = [
    {
      groupId: '123',
      roleId: 'abc123',
    },
  ],
}: Partial<Roles> = {}): Roles => ({
  roleId,
  roleName,
  access,
});

export const createMockMarkupBlock = ({
  key = '2c5ea4c0-4067-11e9-8bad-9b1deb4d3b7d',
  x = 1,
  y = 1,
  height = 1,
  width = 1,
  pageNumber = 0,
  documentKey = 1,
  type = MarkupType.Strike,
}: Partial<MarkupBlock> = {}): MarkupBlock => ({
  key,
  x,
  y,
  height,
  width,
  pageNumber,
  documentKey,
  type,
});

export const createMockAccountGroupsListResponse = ({
  errors = [],
  data = {
    result: {
      pageNumber: 1,
      pageSize: 3,
      totalCount: 3,
      data: [
        {
          groupId: '1',
          name: 'group1',
          userCount: 10,
        },
        {
          groupId: '2',
          name: 'group2',
          userCount: 5,
        },
        {
          groupId: '3',
          name: 'group3',
          userCount: 15,
        },
      ],
    },
  },
}: Partial<AccountGroupListResponse> = {}): AccountGroupListResponse => ({
  errors,
  data,
});

export const createMockEmptyAccountGroupsListResponse = ({
  errors = [],
  data = {
    result: {
      pageNumber: 1,
      pageSize: 1,
      totalCount: 0,
      data: [],
    },
  },
}: Partial<AccountGroupListResponse> = {}): AccountGroupListResponse => ({
  errors,
  data,
});

export const createMockBuyerAgreementsLeaderboardItem = ({
  buyerAgentOktaId = 'happy-agent',
  count = 24,
  buyerAgentFullName = 'Devvvy Devvvy',
}: Partial<BuyerAgreementLeaderboardItem> = {}): BuyerAgreementLeaderboardItem => ({
  buyerAgentOktaId,
  count,
  buyerAgentFullName,
});

export const createMockBuyerAgreement = ({
  id = 'sad-agent-1',
  fileId = 273019,
  buyerAgreementDocumentId = 0,
  formsEnvelopeId = 102004,
  digiSignEnvelopeId = 'c3577fa4-8219-9940-90a1370a09c2',
  buyerAgentOktaId = 'sad-agent-okta-id',
  buyerAgentFullName = 'Devvvy Devvvy',
  buyerAgentGroupIds = ['767f96ad-094c-96c4'],
  buyerFullNames = ['Excited Buyer'],
  isAccepted = true,
  acceptedBy = 'someones-okta-id',
  acceptedAt = '2024-04-16T19:49:23Z',
  signedAt = '2024-04-02T14:39:56Z',
  createdAt = '2024-04-02T14:39:56Z',
  updatedAt = '2024-04-02T14:39:56Z',
}: Partial<BuyerAgreement> = {}): BuyerAgreement => ({
  id,
  fileId,
  buyerAgreementDocumentId,
  formsEnvelopeId,
  digiSignEnvelopeId,
  buyerAgentOktaId,
  buyerAgentFullName,
  buyerAgentGroupIds,
  buyerFullNames,
  isAccepted,
  acceptedBy,
  acceptedAt,
  signedAt,
  createdAt,
  updatedAt,
});
