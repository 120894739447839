import { createContext } from 'react';

import type { LayoutConfig } from './Provider';

interface LayoutContextProps {
  layout: LayoutConfig;
  updateLayout: (updates: LayoutConfig, fresh?: boolean) => void;
}

const LayoutContext = createContext<Partial<LayoutContextProps>>({});

export default LayoutContext;
