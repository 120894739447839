import React from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Button, type ButtonProps, Menu, MenuItem } from '@mui/material';
import { openNewTabProps, useAnchorEl } from '@skyslope/mache';

const MainMenuHelpDropdown: React.FC<ButtonProps> = (props) => {
  const { handleClick, menuProps } = useAnchorEl();

  return (
    <>
      <Button
        variant="text"
        color="secondary"
        size="small"
        {...props}
        onClick={handleClick}
        data-spec="help-menu-btn"
        aria-controls="help-menu"
        aria-haspopup="true"
        startIcon={<HelpOutlineIcon />}
      >
        Help
      </Button>
      <Menu
        data-spec="help-menu"
        id="help-menu"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        disableScrollLock
        {...menuProps}
      >
        <MenuItem
          data-spec="help"
          component="a"
          href="https://support.skyslope.com/hc/en-us/categories/4550787407387-SkySlope-Forms"
          {...openNewTabProps()}
        >
          Help Documentation
        </MenuItem>
        <MenuItem data-spec="support" component="a" href="https://support.skyslope.com/hc/en-us" {...openNewTabProps()}>
          Support
        </MenuItem>
        <MenuItem data-spec="privacy" href="https://skyslope.com/privacy-policy" component="a" {...openNewTabProps()}>
          Privacy Policy
        </MenuItem>
        <MenuItem
          data-spec="terms-and-conditions"
          href="https://skyslope.com/terms-conditions"
          component="a"
          {...openNewTabProps()}
        >
          Terms of Use
        </MenuItem>
      </Menu>
    </>
  );
};

export default MainMenuHelpDropdown;
