import { useAuth } from '@skyslope/auth-react';

import useIsLoginProcessComplete from './useIsLoginProcessComplete';

const useIsPrimeAuthUser = (): boolean | undefined => {
  const isLoginProcessComplete = useIsLoginProcessComplete();

  // when we look to switch all Prime users away from PrimeAuth down the road, this will need to be changed
  const { userContext } = useAuth();
  const isPrimeAuthUser = !!userContext?.prime?.userId;

  return !isLoginProcessComplete ? undefined : isPrimeAuthUser;
};

export default useIsPrimeAuthUser;
