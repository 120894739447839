import { isAppRunningInIframe } from './isAppRunningInIframe';
import { getBrowser } from './userAgent';

/* eslint-disable no-console */
const root = document.querySelector('#root') as HTMLElement;
const loader = document.querySelector('#loader') as HTMLElement;
const loaderContent = document.querySelector('.loaderContent') as HTMLElement;
const observer = new MutationObserver(() => {
  const backgrounds = root?.getElementsByClassName('background');

  if (backgrounds?.length !== 0) {
    loader.style.display = 'none';
    observer.disconnect();
  }
});
const config = { attributes: true, childList: true, characterData: true };
observer.observe(root, config);

setTimeout(() => {
  loaderContent.style.display = 'block';
  if (isAppRunningInIframe()) {
    const loader3p = document.getElementById('loader-3p-cookies');
    if (loader3p) loader3p.style.display = 'flex';
    document
      .getElementById('loader-3p-cookies-btn')
      ?.addEventListener('click', () =>
        window.open(`https://support.skyslope.com/hc/en-us/articles/15329634720539#${getBrowser()}`, '_blank')
      );
  }
}, 1000);

if (!window.location.host.includes('localhost')) {
  /*
    What this function does is hook into all the console loggers and store them into the console.everything array.
    This array will be used to ingress the logs into the CustomerSupportReportingTool.
    We do this so the customer-reported issues can be tracked down.
    https://stackoverflow.com/questions/19846078/how-to-read-from-chromes-console-in-javascript
  **/

  if (console.everything === undefined) {
    console.everything = [];

    window.onerror = function (error, url, line) {
      if (console.everything) {
        console.everything.push({
          type: 'exception',
          timeStamp: new Date().toISOString(),
          value: { error, url, line },
        });

        return false;
      }
    };

    window.onunhandledrejection = function (e) {
      if (console.everything) {
        console.everything.push({
          type: 'promiseRejection',
          timeStamp: new Date().toISOString(),
          value: e.reason,
        });
      }
    };

    const hookLogType = (logType: string): (() => void) => {
      const original = console[logType].bind(console);
      return function (...args: unknown[]) {
        if (console.everything) {
          console.everything.push({
            type: logType,
            timeStamp: new Date().toISOString(),
            value: Array.from(args),
          });

          original.apply(console, args);
        }
      };
    };

    ['log', 'error', 'warn', 'debug'].forEach((logType) => {
      console[logType] = hookLogType(logType);
    });
  }
}

export {};
