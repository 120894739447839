import React from 'react';

import useGetUserSettingsQuery from 'hooks/domain/user/queries/useGetUserSettingsQuery';
import type { AccountPermission } from 'store/types';

import useGetGroupBySubscriberId from './useGetGroupBySubscriberId';
import useGetRoleByUserId from './useGetRoleByUserId';

// You can add debug values for permissions here for testing purposes.
// (don't forget to delete them before making your PR)
const debugPermissions: AccountPermission[] = [];
// Note: This will be false in integ (where the app is built for production),
// it will only be true when running locally.
const IS_DEBUG = process.env.NODE_ENV === 'development';

/**
 * Checks if the current user has a specific permission in a specific group.
 * If no group is specified, the first of that user's subscriber groups is used.
 * @returns null if data is loading, true if the user has the permission, false otherwise.
 */
export function useHasPermission(
  permission: AccountPermission,
  groupId: string | undefined = undefined
): boolean | null {
  const userSettings = useGetUserSettingsQuery();
  const userId = userSettings.data?.id;
  const subscriberId = userSettings.data?.subscriberId;

  const userRoles = useGetRoleByUserId(userId);
  const userPermissions = userRoles.data?.userPermissions;
  const subscriberGroup = useGetGroupBySubscriberId(subscriberId ?? '');
  const finalGroupId = groupId || subscriberGroup.data?.[0]?.groupId;

  const isLoading = userSettings.isInitialLoading || subscriberGroup.isLoading || userRoles.isInitialLoading;

  return React.useMemo(() => {
    if (isLoading) return null;
    if (!userPermissions?.length || finalGroupId == null) {
      return IS_DEBUG ? debugPermissions.includes(permission) : false;
    }

    const groupPermissions = userPermissions.filter((p) => p.groupId === finalGroupId).map((p) => p.accountPermission);

    const expandedGroupPermissions = groupPermissions.flatMap(expandPermission);
    if (IS_DEBUG) expandedGroupPermissions.push(...debugPermissions);

    return expandedGroupPermissions.includes(permission);
  }, [isLoading, finalGroupId, permission, userPermissions]);
}

/**
 * See [here](https://skyslope.atlassian.net/wiki/spaces/DEVGEN/pages/3153690646/Defining+Every+SkySlope+User+Permission)
 * for explanations of the different permissions.
 */
const permissionHierarchy: { [key in AccountPermission]?: AccountPermission[] } = {
  'forms.*': ['forms.template.*', 'forms.clauses.*', 'forms.libraries.*', 'forms.buyeragreement.*'],
  'forms.template.*': ['forms.template.write'],
  'forms.clauses.*': ['forms.clauses.write'],
  'forms.libraries.*': ['forms.libraries.write'],
};

/**
 * Permissions are defined in a hierarchy, where root.* will also imply
 * root.foo, root.bar, and root.child.baz.
 *
 * This function expands he hierarchy to include all implied permissions.
 */
function expandPermission(permission: AccountPermission): AccountPermission[] {
  if (!permissionHierarchy[permission]) return [permission];
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return [permission, ...permissionHierarchy[permission]!.flatMap(expandPermission)];
}
