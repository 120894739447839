import React from 'react';
import type { OrUndefined } from '@skyslope/utils';
// eslint-disable-next-line no-restricted-imports
import { useFlags } from 'launchdarkly-react-client-sdk';

/**
 * Define feature flags here. The key is the name of the flag, and the value
 * is the date that the flag was created (YYYY-MM-DD).
 *
 * All flags default to a boolean type. If your flag is not a boolean, you must
 * define the shape of your flag in `NonBooleanFlagTypes` below.
 */
type FormsLDFlagDefs = {
  isSecurityTabEnabled: '2020-12-14';
  isTemplateCreationWizardEnabled: '2021-03-18';
  brokerTemplateAdminAccess: '2021-03-31';
  isQuickEntryV2Enabled: '2021-04-05';
  smartDisclosureTest: '2021-04-20';
  brokerPermissionsAdminAccess: '2021-05-06';
  isBrokerPermissionsActive: '2021-05-06';
  isMyTeamsEnabled: '2021-05-06';
  isFirstFileCreateFileBannerEnabled: '2021-06-15';
  isDraftEnvelopeEnabled: '2021-09-14';
  isUseFormsLandingPageActive: '2021-09-16';
  enablePopUnder: '2021-11-23';
  isOffersTabEnabled: '2022-04-19';
  isFileDashboardSearchEnabled: '2022-06-22';
  isTcAccessOwnerFilterAndColumnEnabled: '2022-06-22';
  isNewDashboardFileOwnerFilterEnabled: '2022-06-22';
  isLibraryOnboardingStepSkipable: '2022-06-27';
  isTcAccessAddFormsEnabled: '2022-07-12';
  isTcAccessOffersTabEnabled: '2022-08-03';
  isOneToOneTCTeamManagementEnabled: '2022-09-15';
  idleTimeoutAmount: '2022-09-28';
  isIdleTimeOutEnabled: '2022-09-28';
  isRegionalMlsFilterEnabled: '2022-10-20';
  isCorrectEnvelopeEnabled: '2022-11-14';
  isViewFormsWithNoFileEnabled: '2022-11-21';
  isInsertingClausesEnabled: '2022-11-23';
  isAddFormsToFileEnabled: '2023-01-23';
  isFileCreateOnBehalfOfEnabled: '2023-01-24';
  maxRenderAmount: '2023-02-14';
  useAccountingFormatForCalcBlocks: '2023-03-01';
  ct2SyncEnvelopesWithPrime: '2023-03-01';
  isMyTeamsEnabledV2: '2023-03-06';
  isBrokerTemplateGroupAccessEnabled: '2023-03-07';
  allowedOmLinkIdps: '2023-03-07';
  isNonSpoofedEmailRequired: '2023-03-09';
  isSignedDocumentsEnabled: '2023-03-27';
  isDtmEmailEditEnabled: '2023-03-31';
  isNewTemplateEditActive: '2023-04-04';
  isEditDeleteBrokerTemplateEnabled: '2023-04-11';
  isMyClausesEnabled: '2023-04-21';
  isSignedDocumentsUploadEnabled: '2023-05-09';
  isSignedDocumentsBannerEnabled: '2023-06-13';
  isBulkFillImportExportButtonEnabled: '2023-06-15';
  isTrrebDisabled: '2023-06-28';
  isShareByEmailEnabled: '2023-07-13';
  isSmartBannerEnabled: '2023-07-14';
  isNewNrdsVerificationEnabled: '2023-07-20';
  useNewHeapProps: '2023-07-26';
  docusignIntegration: '2023-08-08';
  isFullEnvelopeExternalEndpointUsageEnabled: '2023-08-08';
  isBooksLinkEnabled: '2023-08-11';
  isDigiSignMenuButtonEnabled: '2023-08-11';
  allowedOffersTabIdps: '2023-08-14';
  isOfferManagementLinkEnabled: '2023-08-14';
  isSubscriptionRequired: '2023-08-18';
  isBreezeMenuButtonEnabledV2: '2023-09-14';
  showBrokerageOptionOnClauseCreate: '2023-09-14';
  hideAddendaContinuationTextByRegion: '2023-09-15';
  hideAddendaContinuationTextByUser: '2023-09-15';
  sharedContactsSearchEnabled: '2023-09-15';
  unhideAddendaContinuationTextByUser: '2023-09-15';
  areInsightsEnabled: '2023-09-27';
  isFormsHighlightEnabled: '2023-10-03';
  isDateTextOverrideEnabled: '2023-10-13';
  isFormsRedactionEnabled: '2023-10-19';
  isFormsStrikethroughEnabled: '2023-10-19';
  isFormsTextBlockEnabled: '2023-10-24';
  isMobileBulkFillActionBarEnabled: '2023-10-31';
  isTemplateMarkupToolsEnabled: '2023-11-03';
  showInteractiveForm: '2023-11-09';
  isTenantScreeningToggleEnabled: '2023-12-05';
  isClarityEnabled: '2024-01-07';
  ssoAmp: '2023-12-08';
  sendEnvelopeTokenToDs3: '2024-02-05';
  isBulkFillEmailEnabled: '2024-02-22';
  allowedSignedDocumentsBannerIdps: '2024-02-23';
  isFormSuggestionsEnabled: '2024-02-22';
  formsNhdIntegration: '2024-03-11';
  isBuyerAgreementShortcutStage1Enabled: '2024-03-21';
  isBuyerAgreementShortcutStage2Enabled: '2024-03-22';
  isBuyerAgreementApprovalPageEnabled: '2024-03-28';
  isTenantScreeningProviderOnBackendEnabled: '2024-04-30';
  isTenantScreeningCopySectionEnabled: '2024-05-20';
  isUtilityConnectEnabled: '2024-06-25';
  isBuyerAgreementBrokerLeaderboardEnabled: '2024-08-22';
};

type NonBooleanFlagTypes = OrUndefined<{
  smartDisclosureTest: { isTestEnabled: boolean; hhda: number };
  isDigiSignMenuButtonEnabled: 'OFF' | 'DS3' | 'DS2';
  hideAddendaContinuationTextByRegion: string[];
  isBreezeMenuButtonEnabledV2: { regions: string[] | undefined };
  maxRenderAmount: number;
  allowedOmLinkIdps: string[];
  allowedOffersTabIdps: string[];
  allowedSignedDocumentsBannerIdps: string[];
  idleTimeoutAmount: number;
}>;

export type FormsLDFlags = {
  [K in keyof FormsLDFlagDefs]: K extends keyof NonBooleanFlagTypes ? NonBooleanFlagTypes[K] : boolean;
};

// You can add debug values for flags here for testing purposes.
// (don't forget to delete them before making your PR)
const debugFlags: Partial<FormsLDFlags> = {};

/** A strongly typed version of `useFlags` from launchdarkly-react-client-sdk */
export function useFeatureFlags() {
  const flags = useFlags<FormsLDFlags>();
  return React.useMemo(() => (process.env.NODE_ENV === 'development' ? { ...flags, ...debugFlags } : flags), [flags]);
}

//
// If any of these types error, it indicates that feature flags have not
// been defined properly above. These are kind of type unit tests.
//
type YearMonthDay = `${number}${number}${number}${number}-${number}${number}-${number}${number}`;
type EveryFlagHasADateTest<T extends Record<string, YearMonthDay>> = T;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type DoesEveryFlagHaveADate = EveryFlagHasADateTest<FormsLDFlagDefs>;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type OverridesWereAlsoDefinedWithDate = { [K in keyof NonBooleanFlagTypes]: FormsLDFlagDefs[K] };
