import { useEffect } from 'react';

import useGetFileData from 'components/SecurityDependentEffects/hooks/useSetupChameleon/hooks/useGetFileData';
import useGetDelegatedUserSettingsQuery from 'hooks/domain/user/queries/useGetDelegatedUserSettingsQuery';
import type { CreateTenantScreeningRequest } from 'resources/types/CreateUserRentSpree';

import useDeprecatedCreateRentSpreeTenantScreeningMutation from '../../../hooks/domain/file/mutations/useDeprecatedCreateRentSpreeTenantScreeningMutation';

const useSendToRentSpree = (): void => {
  const file = useGetFileData();
  const { data: userSettings } = useGetDelegatedUserSettingsQuery(file?.ownedBy || '');
  const { mutate: createTenantScreening } = useDeprecatedCreateRentSpreeTenantScreeningMutation();

  useEffect(() => {
    if (file == undefined || userSettings == undefined) {
      window.sendToRentSpree = () => {};
      return;
    }
    const street = `${file.property.streetNumber} ${file.property.streetName}`;
    const email = userSettings.email;

    window.sendToRentSpree = () => {
      const request = {
        userId: userSettings.id,
        email: email,
        property: {
          propertyID: `${file.id}`,
          street: street,
          unitNumber: file.property.unitNumber,
          city: file.property.city,
          state: file.property.state,
          zip: file.property.postalCode,
        },
      } as CreateTenantScreeningRequest;

      window?.heap?.track('RentSpreeScreeningCreated - Chameleon Popup', { fileId: file.id, request });

      // No validation before sending.  Forms will stay dumb to prevent coupling
      // RentSpree will handle validation
      createTenantScreening(request);
    };
  }, [file, createTenantScreening, userSettings]);
};
export default useSendToRentSpree;
