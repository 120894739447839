import React, { useMemo } from 'react';
import AppsIcon from '@mui/icons-material/Apps';

import { ReactComponent as BooksIcon } from 'assets/images/ss-apps/BooksIcon.svg';
import { ReactComponent as BreezeIcon } from 'assets/images/ss-apps/BreezeIcon.svg';
import { ReactComponent as DigisignIcon } from 'assets/images/ss-apps/DigisignIcon.svg';
import { ReactComponent as OffersIcon } from 'assets/images/ss-apps/OffersIcon.svg';
import { ReactComponent as SkyslopeIcon } from 'assets/images/ss-apps/SkyslopeIcon.svg';
import { useIsPrimeAuthUser } from 'auth/hooks';
import {
  BOOKS_URL,
  BREEZE_URL,
  DS2_URL,
  DS3_URL,
  OFFERS_URL,
  PRIME_MARKETING_URL,
  PRIME_URL,
} from 'constants/environments';
import useIsBreezeButtonEnabled from 'hooks/domain/user/useIsBreezeButtonEnabled';
import useIsOffersLinkEnabled from 'hooks/domain/user/useIsOffersLinkEnabled';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

import { LibraryTagKeys } from '../../../../../constants/LibraryTagKeys';
import useDerivedUserData from '../../../../../hooks/domain/user/useDerivedUserData';
import useIsBooksLinkEnabled from '../../../../../hooks/domain/user/useIsBooksLinkEnabled';
import { AppHeaderLinkNames } from '../../AppHeader';

import MobileMenuRow from './MobileMenuRow';
import type { SubMenuItem } from './MobileMenuSubMenu';
import MobileMenuSubMenu from './MobileMenuSubMenu';

export interface MobileMenuAppRowsProps {
  setSubMenu: (elm: JSX.Element | undefined) => void;
  handleDrawerClose: () => void;
  handleBackClick: () => void;
}

const MobileMenuAppRows: React.FC<MobileMenuAppRowsProps> = ({ setSubMenu, handleBackClick, handleDrawerClose }) => {
  const isBreezeButtonEnabled = useIsBreezeButtonEnabled();
  const showOffersLink = useIsOffersLinkEnabled();
  const showBooksLink = useIsBooksLinkEnabled();
  const isPrimeUser = useIsPrimeAuthUser();
  const { isDigiSignMenuButtonEnabled } = useFeatureFlags();

  const { optInLibraries } = useDerivedUserData();
  const hasDs3AccessBasedOnLibrary = useMemo(
    () => !isPrimeUser && optInLibraries.some((l) => l.tags[LibraryTagKeys.givesDs3Access]?.toLowerCase() === 'true'),
    [isPrimeUser, optInLibraries]
  );

  const menuLinks: SubMenuItem[] = useMemo(() => {
    const links: SubMenuItem[] = [
      {
        name: 'SkySlope',
        link: isPrimeUser ? PRIME_URL : PRIME_MARKETING_URL,
        icon: <SkyslopeIcon />,
      },
    ];

    if (
      (isPrimeUser && (isDigiSignMenuButtonEnabled === 'DS3' || isDigiSignMenuButtonEnabled === 'DS2')) ||
      hasDs3AccessBasedOnLibrary
    ) {
      links.push({
        name: 'DigiSign',
        link: hasDs3AccessBasedOnLibrary
          ? DS3_URL
          : isDigiSignMenuButtonEnabled === 'DS3'
          ? DS3_URL
          : isDigiSignMenuButtonEnabled === 'DS2'
          ? DS2_URL
          : DS3_URL,
        icon: <DigisignIcon />,
      });
    }

    if (showOffersLink) {
      links.push({
        name: 'Offers',
        link: OFFERS_URL,
        icon: <OffersIcon />,
      });
    }

    if (showBooksLink) {
      links.push({
        name: 'Books',
        link: BOOKS_URL,
        icon: <BooksIcon />,
      });
    }

    if (isBreezeButtonEnabled) {
      links.push({
        name: 'Breeze',
        link: BREEZE_URL,
        icon: <BreezeIcon />,
      });
    }

    return links;
  }, [
    isPrimeUser,
    isDigiSignMenuButtonEnabled,
    hasDs3AccessBasedOnLibrary,
    showOffersLink,
    showBooksLink,
    isBreezeButtonEnabled,
  ]);

  return (
    <MobileMenuRow
      data-spec="apps"
      name={AppHeaderLinkNames.Apps}
      icon={<AppsIcon />}
      id="apps-settings-menu-nav-link"
      onClick={() =>
        setSubMenu(
          <MobileMenuSubMenu
            id="apps-sub-menu"
            title={AppHeaderLinkNames.Apps}
            menuLinks={menuLinks}
            handleDrawerClose={handleDrawerClose}
            handleBackClick={handleBackClick}
          />
        )
      }
    />
  );
};

export default MobileMenuAppRows;
