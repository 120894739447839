import { useEffect, useState } from 'react';
import { useAuth } from '@skyslope/auth-react';

import useGetUserSettingsQuery from 'hooks/domain/user/queries/useGetUserSettingsQuery';

import useProcessAccessDelayMutation from './useProcessAccessDelayMutation';

/**
 * new users may have an awaiting 1:1 invitation, called "delayed delgated access" (in shared services lingo)
 * send their email address & complete the access invitation seamlessly while they are onboarding (user?.isInitialized === false)
 */
const useProcessAccessDelay = (): void => {
  const [isProcessed, setIsProcessed] = useState(false);
  const { mutate: processAccessDelay } = useProcessAccessDelayMutation();
  const { data: user } = useGetUserSettingsQuery();
  const { userContext } = useAuth();

  useEffect(() => {
    if (!isProcessed && user?.isInitialized === false && userContext?.email) {
      processAccessDelay(userContext?.email);
      setIsProcessed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext, user, isProcessed]);
};

export default useProcessAccessDelay;
