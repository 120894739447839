import { useEffect } from 'react';
import { useAuth } from '@skyslope/auth-react';

import { CLARITY_PROJECT_ID } from '../constants/environments';
import { useFeatureFlags } from '../hooks/useFeatureFlags';

export const useSetupClarity = (): void => {
  const { isClarityEnabled } = useFeatureFlags();
  const { userContext } = useAuth();

  useEffect(() => {
    if (isClarityEnabled) {
      if (!window.clarity) {
        window.clarityInit(window, document, 'clarity', 'script', CLARITY_PROJECT_ID);
      }

      if (userContext && window.clarity) {
        window.clarity('set', 'email', userContext?.email);
        window.clarity('set', 'sub', userContext?.prime?.subscriberId?.toString() ?? '');
        window.clarity('set', 'prime_user_guid', userContext?.prime?.userId ?? '');
        window.clarity('set', 'okta_id', userContext?.id);

        window.clarity('set', 'pathname', window.location.pathname);
        const digitRegex = /[\d]+/g;
        window.clarity('set', 'app_pathname', window.location.pathname.replaceAll(digitRegex, '<id>'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext, isClarityEnabled, window.location.pathname]);
};
