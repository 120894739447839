import { useCallback } from 'react';

const dateDiffInDays = (a: Date, b: Date): number => {
  const msPerDay = 1000 * 60 * 60 * 24;
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / msPerDay);
};

const useGetOrefDaysUntilCacheExpiration = (): ((membershipCacheExpiration?: string) => number) =>
  useCallback((membershipCacheExpiration?: string) => {
    if (!membershipCacheExpiration) return 0;
    return dateDiffInDays(new Date(), new Date(membershipCacheExpiration));
  }, []);

export default useGetOrefDaysUntilCacheExpiration;
