import React, { useState } from 'react';
import ChevronDown from '@mui/icons-material/KeyboardArrowDown';
import ChevronUp from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { colors } from '@skyslope/mache';

import LinkButton from 'components/LinkButton/LinkButton';
import OreaTermsAndConditionsTermsView from 'pages/TermsAndConditions/Orea/OreaTermsAndConditionsTermsView';

export interface OreaAuthDialogCheckboxProps {
  value: boolean;
  onChange: (isChecked: boolean) => void;
}

const OreaAuthDialogTermsView: React.FC<OreaAuthDialogCheckboxProps> = ({ value, onChange }) => {
  const theme = useTheme();
  const [isTermsSectionOpen, setIsTermsSectionOpen] = useState<boolean>(false);

  return (
    <>
      <Box
        component="label"
        sx={{
          textAlign: 'center',
          paddingTop: theme.spacing(3),
          paddingBottom: theme.spacing(1),
        }}
      >
        <Checkbox
          id="checkBoxTermsAndConditions"
          color="primary"
          checked={value}
          onChange={(e) => {
            onChange(e.target.checked);
          }}
        />
        <Typography variant="body1" component="label" htmlFor="checkBoxTermsAndConditions">
          {'I have read and agree to the license '}
          <LinkButton
            id="btnTermsAndConditionsLink"
            onClick={() => {
              setIsTermsSectionOpen((isChecked) => !isChecked);
            }}
          >
            terms and conditions
            {isTermsSectionOpen ? (
              <ChevronUp
                sx={{
                  fontSize: 16,
                  top: 2,
                  position: 'relative',
                  paddingLeft: 3,
                }}
              />
            ) : (
              <ChevronDown
                sx={{
                  fontSize: 16,
                  top: 2,
                  position: 'relative',
                  paddingLeft: 3,
                }}
              />
            )}
          </LinkButton>
        </Typography>
      </Box>
      {isTermsSectionOpen && (
        <Box
          component="div"
          sx={{
            padding: 0,
            height: 266,
            textAlign: 'left',
            overflow: 'auto',
            border: `2px solid ${colors.grey[400]}`,
            marginBottom: theme.spacing(2),
          }}
        >
          <OreaTermsAndConditionsTermsView title="License Terms & Conditions" inlineView />
        </Box>
      )}
    </>
  );
};

export default OreaAuthDialogTermsView;
