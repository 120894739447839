import { useAuth } from '@skyslope/auth-react';
import type { UseMutationResult } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from 'axios';

import useCustomSnackbar from 'hooks/useCustomSnackbar';
import UserServer from 'resources/server/UserServer';
import type { FileServiceError } from 'store/types';

export interface ChangePasswordRequest {
  oldPassword: string;
  newPassword: string;
}

const useChangePasswordMutation = (): UseMutationResult<
  string,
  AxiosError<FileServiceError>,
  ChangePasswordRequest,
  unknown
> => {
  const { userContext } = useAuth();
  const { addSuccessSnackbar } = useCustomSnackbar();

  return useMutation({
    mutationFn: async (request: ChangePasswordRequest) => await UserServer.ChangePassword(userContext?.id, request),
    onSuccess: () => {
      addSuccessSnackbar('Password updated!');
    },
  });
};

export default useChangePasswordMutation;
