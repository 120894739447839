import React from 'react';
import { css, useTheme } from '@mui/material/styles';
import { ReactQueryDevtools as ReactQueryDevToolsInner } from '@tanstack/react-query-devtools';

import { css as emotionCss } from '@emotion/css';

/**
 * React query devtools, shows in bottom left and only in localhost
 */
const ReactQueryDevtools: React.FC = () => {
  const theme = useTheme();
  return (
    <ReactQueryDevToolsInner
      initialIsOpen={false}
      panelProps={{
        className: emotionCss(css({ maxWidth: 'fit-content', left: 0 })),
      }}
      toggleButtonProps={{
        className: emotionCss(
          css({
            opacity: 0.15,
            transition: theme.transitions.create('opacity', {
              duration: theme.transitions.duration.shortest,
            }),
            '& svg': {
              width: 30,
              height: 30,
            },
            '&:hover': {
              opacity: 1,
            },
            [theme.breakpoints.down('lg')]: {
              display: 'none !important',
            },
          })
        ),
      }}
    />
  );
};

export default ReactQueryDevtools;
