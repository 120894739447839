import { useEffect, useState } from 'react';
import { useAuth } from '@skyslope/auth-react';

import isTrrebIdp from 'auth/hooks/helpers/isTrrebIdp';
import useGetUserIDP from 'auth/hooks/useGetUserIDP';
import useGetMeSubscriptionQuery from 'hooks/domain/user/queries/useGetMeSubscriptionQuery';
import useGetUserSettingsQuery from 'hooks/domain/user/queries/useGetUserSettingsQuery';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import useGetChameleonInfoQuery from 'hooks/useGetChameleonInfoQuery';
import useGetOrefDaysUntilCacheExpiration from 'pages/UserSettings/AuthProfileUserSettings/hooks/useGetOrefDaysUntilCacheExpiration';
import { FormLibrarySource } from 'store/types';
import { default as chameleon } from 'utils/chameleon';

import { isAppRunningInIframe } from '../../../../utils/isAppRunningInIframe';

import useGetFileData from './hooks/useGetFileData';
import useLibraryRegionsString from './hooks/useLibraryRegionsString';

const useSetupChameleon = (): void => {
  const { userContext } = useAuth();
  const [isChameleonInitialized, setIsChameleonInitialized] = useState(false);
  const [isChameleonUserSetup, setIsChameleonUserSetup] = useState(false);
  const { data: userProfile } = useGetUserSettingsQuery();
  const userIdp = useGetUserIDP();
  const isTrreb = isTrrebIdp(userIdp);

  const isTrrebUserFirstLogin = isTrreb && userContext?.email?.endsWith('@trreb.invalid');

  const { data: chameleonInfo } = useGetChameleonInfoQuery({
    enabled: !!userContext && !isChameleonUserSetup && !isTrrebUserFirstLogin,
  });
  const libraryRegions = useLibraryRegionsString();
  const fileData = useGetFileData();

  const oreaAuthProfile = userProfile?.authProfiles?.find((p) => p.type === FormLibrarySource.Oref);

  const orefDaysUntilCacheExpiration = useGetOrefDaysUntilCacheExpiration()(oreaAuthProfile?.membershipCacheExpiration);

  const { docusignIntegration } = useFeatureFlags();
  const isDocusignEnabled =
    userProfile?.userPreferences?.digitalSigningPlatformPreference === 'DocuSign' && docusignIntegration;

  const { data: meSubscriptions } = useGetMeSubscriptionQuery();

  useEffect(() => {
    if (!isChameleonInitialized) {
      chameleon.initialize(window.skySlopeCKey);
      setIsChameleonInitialized(true);
    } else {
      if (chameleonInfo && libraryRegions !== undefined && meSubscriptions !== undefined) {
        if (!isChameleonUserSetup) {
          chameleon.setupUser({
            ...chameleonInfo,
            libraryRegions,
            isWidgetUser: isAppRunningInIframe(),
            isDocusignEnabled,
            meSubscriptions,
          });
          setIsChameleonUserSetup(true);
        }
      }
      // if user has navigated to a file specific page, send the representation type for a specific tour
      if (userContext && userContext.email && fileData) {
        chameleon.setupUser({
          userId: userContext.id,
          email: userContext.email,
          organizationId: userContext.prime?.subscriberId,
          fileRepresentationType: fileData.representationType,
          orefExpiration: orefDaysUntilCacheExpiration,
        });
      }
    }
  }, [
    chameleonInfo,
    userContext,
    fileData,
    isChameleonInitialized,
    isChameleonUserSetup,
    isDocusignEnabled,
    libraryRegions,
    meSubscriptions,
    orefDaysUntilCacheExpiration,
  ]);
};

export default useSetupChameleon;
