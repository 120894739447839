import { useFeatureFlags } from 'hooks/useFeatureFlags';

import useGetUserSettingsQuery from './domain/user/queries/useGetUserSettingsQuery';

const useIsPermanentEmailNeeded = (): boolean => {
  const { data: userSettings } = useGetUserSettingsQuery();
  const { isNonSpoofedEmailRequired } = useFeatureFlags();

  // Retroactive fix.  This check can be removed after all existing trreb.invalid emails are fixed
  const isInvalidEmail = userSettings?.email.endsWith('@trreb.invalid') ?? false;

  // Keep this.  @trreb.temp is the designated email format after SSO establishment
  const isEmailSpoofed = userSettings?.email.endsWith('@trreb.temp') ?? false;

  return isNonSpoofedEmailRequired && (isInvalidEmail || isEmailSpoofed);
};

export default useIsPermanentEmailNeeded;
