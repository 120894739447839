import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import UserProfileServer from 'resources/server/UserProfileServer';
import type { GetDelegatedUsersResponse } from 'resources/types/GetFileOwners';

export const userFileOwnersMetaKey = 'userFileOwnersMeta';

const useGetDelegatedUsers = (isDelegate = false): UseQueryResult<GetDelegatedUsersResponse> =>
  useQuery({
    queryKey: [userFileOwnersMetaKey, isDelegate],
    queryFn: () => UserProfileServer.GetDelegatedUsers(),
    enabled: isDelegate,
  });

export default useGetDelegatedUsers;
