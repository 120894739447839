import type { UserManager } from '@skyslope/auth-js';
import type { AxiosRequestConfig } from 'axios';

import type { FormsUserManager } from 'auth/userManager';
import { userManager } from 'auth/userManager';
import { UTILITY_CONNECT_URL } from 'constants/environments';

import Server from './Server';

export type EnablementResponse = { subscribed: boolean; claimUrl?: string };

class UtilityConnectServer extends Server {
  public constructor(manager: UserManager, axiosConfig: AxiosRequestConfig = {}) {
    super(manager, axiosConfig, {
      tokenGetter: (userManager) => (userManager as FormsUserManager).getAccessToken(),
      includeBaseHeaders: false,
      throwErrorOnInvalidToken: true,
    });
  }

  public async GetAgentEnablement(primeUserId: string | number): Promise<EnablementResponse> {
    return (await this.api.get(`${UTILITY_CONNECT_URL}/uc/agent/${primeUserId}/subscription`)).data;
  }

  public async GetSubscriberEnablement(subId: string | number): Promise<EnablementResponse> {
    return (await this.api.get(`${UTILITY_CONNECT_URL}/uc/subscriber/${subId}/subscription`)).data;
  }
}

export default new UtilityConnectServer(userManager);
