import { useDispatch } from 'react-redux';
import type { UseMutationResult } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { AxiosError } from 'axios';

import { addNrdsAuthProfileError } from 'pages/UserSettings/AuthProfileUserSettings/store/actions';
import { AddAuthProfileErrorCode } from 'pages/UserSettings/AuthProfileUserSettings/store/types';
import type { AddAuthProfileRequest } from 'resources/server/UserProfileServer';
import UserProfileServer from 'resources/server/UserProfileServer';

import { userMetaKey } from '../queries/useGetUserSettingsMetaQuery';
import { userSettingsKey } from '../queries/useGetUserSettingsQuery';

export const isDuplicatedAuthProfile = (e: AxiosError): boolean =>
  e.isAxiosError &&
  e.response?.data.errors.some((error: string) => error.toLowerCase().includes('profile.nrdsid: already in use'));

const useAddAuthProfilesMutation = (): UseMutationResult<
  string | undefined,
  AxiosError,
  AddAuthProfileRequest[],
  unknown
> => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: UserProfileServer.AddAuthProfiles.bind(UserProfileServer),
    onSuccess: () => {
      queryClient.invalidateQueries([userSettingsKey]);
      // auth profiles drive a lot of logic in the app and we should not use cached versions after updates
      // so removeQueries instead of invalidate
      queryClient.removeQueries([userMetaKey]);
    },
    onError: (e) => {
      if (isDuplicatedAuthProfile(e as AxiosError)) {
        // TODO: we should move away from using redux to report this error
        dispatch(
          // TODO remove when we convert nrds to use a similar process as other sources
          addNrdsAuthProfileError(AddAuthProfileErrorCode.NRDS_ID_IN_USE)
        );
      } else {
        // TODO: we should move away from using redux to report this error
        dispatch(addNrdsAuthProfileError(AddAuthProfileErrorCode.OTHER));
      }
    },
  });
};

export default useAddAuthProfilesMutation;
