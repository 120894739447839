import React, { useCallback, useEffect, useState } from 'react';

import { useFeatureFlags } from 'hooks/useFeatureFlags';
import useIsSkySlopeMobileApp from 'hooks/useIsSkySlopeMobileApp';

import { throttle } from './throttle';
import TimeoutModal from './TImeoutModal';

const documentEvents = [
  'mousemove',
  'mousedown',
  'keypress',
  'DOMMouseScroll',
  'mousewheel',
  'touchmove',
  'MSPointerMove',
];

const IdleActivityMonitorInner = (): React.ReactElement | null => {
  const { idleTimeoutAmount = Number.NaN } = useFeatureFlags();
  const [showModal, setShowModal] = useState(false);
  const default30minutes = 30 * 60 * 1000;
  const timeout = isNaN(idleTimeoutAmount) || idleTimeoutAmount <= 0 ? default30minutes : idleTimeoutAmount * 60 * 1000;
  const updateExpiredTime = useCallback(
    (force?: boolean) => {
      if (force || !showModal) {
        localStorage.setItem('_expiredTime', (Date.now() + timeout).toString());
        /**
         * Force a storage event so current window/tab gets an update
         * (will cause 2 events for non active tabs but should be a non issue) */
        window.dispatchEvent(new Event('storage'));
      }
    },
    [timeout, showModal]
  );

  /**
   * Listen for storage changes
   * Check if latest expiredTime has expired already
   * if not set new/update setTimeout with difference
   * if timeout is ever exceeded show TimeoutModal
   * Use of Date.now vs performance.now is because performance.now is on a per tab instance */
  useEffect(() => {
    let activityTimeOut = setTimeout(() => null, Infinity);
    const handleStorageChange = (): void => {
      const expiredTimeStr = localStorage.getItem('_expiredTime') || Infinity;
      const expiredTime = +expiredTimeStr;

      clearTimeout(activityTimeOut);

      if (expiredTime < Date.now()) {
        setShowModal(true);
      } else if (expiredTime > Date.now()) {
        setShowModal(false);
        activityTimeOut = setTimeout(() => {
          setShowModal(true);
        }, expiredTime - Date.now());
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      clearTimeout(activityTimeOut);
    };
  }, []);

  useEffect(() => {
    const handleUserActivity = throttle(() => updateExpiredTime(), 5000);

    documentEvents.forEach((v) => document.addEventListener(v, handleUserActivity));

    // Force an update (Need this in case the user is auto logged in but doesn't make any input(s))
    updateExpiredTime();

    return () => {
      documentEvents.forEach((v) => document.removeEventListener(v, handleUserActivity));
    };
  }, [updateExpiredTime]);

  return showModal ? <TimeoutModal onStaySignedIn={() => updateExpiredTime(true)}></TimeoutModal> : null;
};

const IdleActivityMonitor = (): React.ReactElement | null => {
  const { isIdleTimeOutEnabled } = useFeatureFlags();
  const isLocal = window.environmentKey === 'dev';
  const isMobileApp = useIsSkySlopeMobileApp();
  return isIdleTimeOutEnabled && !isLocal && !isMobileApp ? <IdleActivityMonitorInner /> : null;
};

export default IdleActivityMonitor;
