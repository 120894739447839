import { useCallback, useMemo } from 'react';
import type { OptionsObject, SnackbarKey, SnackbarMessage, VariantType } from 'notistack';
import { useSnackbar as useNotistackSnackbar } from 'notistack';

export interface UseSnackbarResponse {
  addErrorSnackbar: (message?: SnackbarMessage, options?: OptionsObject) => void;
  addSuccessSnackbar: (message: SnackbarMessage, options?: OptionsObject) => void;
  addInfoSnackbar: (message: SnackbarMessage, options?: OptionsObject) => void;
  closeSnackbar: (key?: SnackbarKey) => void;
}

const useCustomSnackbar = (): UseSnackbarResponse => {
  const { enqueueSnackbar, closeSnackbar } = useNotistackSnackbar();

  const addSnackbar = useCallback(
    (message: SnackbarMessage, variant: VariantType, options?: OptionsObject) => {
      enqueueSnackbar(message, {
        ...options,
        variant,
      });
    },
    [enqueueSnackbar]
  );

  const addErrorSnackbar = useCallback(
    (message: SnackbarMessage = 'Oops something went wrong! Please try again.', options?: OptionsObject) => {
      addSnackbar(message, 'error', {
        autoHideDuration: 6000,
        ...options,
      });
    },
    [addSnackbar]
  );

  const addSuccessSnackbar = useCallback(
    (message: SnackbarMessage, options?: OptionsObject) => {
      addSnackbar(message, 'success', options);
    },
    [addSnackbar]
  );

  const addInfoSnackbar = useCallback(
    (message: SnackbarMessage, options?: OptionsObject) => {
      addSnackbar(message, 'info', options);
    },
    [addSnackbar]
  );

  return useMemo(
    () => ({
      addErrorSnackbar,
      addSuccessSnackbar,
      addInfoSnackbar,
      closeSnackbar,
    }),
    [addErrorSnackbar, addInfoSnackbar, addSuccessSnackbar, closeSnackbar]
  );
};

export default useCustomSnackbar;
