import { useLocation } from 'react-router-dom';

import { useMissingLibraryNames } from './useMissingLibraryNames';

export const useShowMissingOnboardingLibrariesDialog = () => {
  const isWidget = new URLSearchParams(useLocation().search).get('isWidget');
  const missingLibraryNames = useMissingLibraryNames();

  return { show: !isWidget && missingLibraryNames.length > 0, isLoading: false };
};
