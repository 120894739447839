import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import FormLibraryServer from 'resources/server/FormLibraryServer';
import type { FormLibrary } from 'store/types';

export const librariesQueryKey = 'libraries';

export interface UseGetLibrariesOptions {
  extendedKey?: string | unknown[];
  params?: URLSearchParams;
  enabled?: boolean;
}

const useGetLibrariesQuery = (
  options: UseGetLibrariesOptions = {
    enabled: true,
  }
): UseQueryResult<FormLibrary[]> => {
  const parameterizedKey = [librariesQueryKey, options.params?.toString(), options.extendedKey];
  return useQuery({
    queryKey: parameterizedKey,
    queryFn: async () => await FormLibraryServer.GetFormLibraries(options.params),
    enabled: options.enabled ?? false,
  });
};

export default useGetLibrariesQuery;
