import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import UserSummaryServer from 'resources/server/UserSummaryServer';
import type { ChameleonInfo } from 'store/types';

export const getChameleonInfoQueryKey = 'get-chameleon-info';

const useGetChameleonInfoQuery = (
  options: Omit<
    UseQueryOptions<ChameleonInfo, unknown, ChameleonInfo, string[]>,
    'initialData' | 'queryFn' | 'queryKey'
  > = {}
): UseQueryResult<ChameleonInfo> =>
  useQuery({
    queryKey: [getChameleonInfoQueryKey],
    queryFn: async (): Promise<ChameleonInfo> => UserSummaryServer.GetChameleonInfo(),
    ...options,
  });

export default useGetChameleonInfoQuery;
