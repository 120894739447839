import update from 'immutability-helper';

import { ActionTypes } from 'store/actions';
import type { AppAction } from 'store/types';

import type { FileSummary } from '../../FilesDashboard/store/types';

import { TemplateActions } from './actions';
import { OpenDialogState } from './types';

export interface TemplateManagementPageState {
  template?: FileSummary;
  openDialog: OpenDialogState;
  isSaving: boolean;
}

export const defaultTemplateManagementState: TemplateManagementPageState = {
  isSaving: false,
  openDialog: OpenDialogState.none,
};

const templateManagementReducer = (
  state: TemplateManagementPageState = defaultTemplateManagementState,
  action: AppAction
): TemplateManagementPageState => {
  switch (action.type) {
    case TemplateActions.SET_TEMPLATE: {
      return update(state, {
        template: { $set: action.payload },
      });
    }
    case TemplateActions.SET_OPEN_DIALOG: {
      return update(state, {
        openDialog: { $set: action.payload },
      });
    }
    case ActionTypes.DELETE_FILE_DOCUMENTS: {
      return update(state, {
        isSaving: { $set: true },
      });
    }
    case ActionTypes.DELETE_FILE_DOCUMENTS_DONE: {
      return update(state, {
        isSaving: { $set: false },
        openDialog: { $set: OpenDialogState.none },
      });
    }
  }
  return state;
};

export default templateManagementReducer;
