export function batch<ElementType>(array: ElementType[], batchSize = 5): ElementType[][] {
  const localArray: ElementType[] = [...array];
  const result: ElementType[][] = [];

  while (localArray.length > 0) {
    result.push(localArray.splice(0, batchSize));
  }

  return result;
}

/**
 * Returns the index of the last element in the array where predicate is true, and -1
 * otherwise.
 * @param array The source array to search in
 * @param predicate find calls predicate once for each element of the array, in descending
 * order, until it finds one where predicate returns true. If such an element is found,
 * findLastIndex immediately returns that element index. Otherwise, findLastIndex returns -1.
 */
export function findLastIndex<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number {
  let l = array.length;
  while (l--) {
    if (predicate(array[l], l, array)) return l;
  }
  return -1;
}

export const stringArrayToReadableList = (arr: string[]): string =>
  arr.reduce((prev, curr, i) => prev + curr + (i === arr.length - 2 ? ' and ' : ', '), '').slice(0, -2);

export const distinct = <T>(array: T[]): T[] => Array.from(new Set(array));

export const getNextIndexInArray = (items: unknown[], currentIndex: number): number =>
  (currentIndex + 1) % items.length;

export const previousSums = <TInput>(items: TInput[], valueGetter: (input: TInput) => number): number[] =>
  items.reduce(
    (accumulator, current) => accumulator.concat(valueGetter(current) + accumulator[accumulator.length - 1]),
    [0]
  );

export const createAndFill2DArray = <T>(length: number): T[][] =>
  // fill with empty arrays in each index
  new Array(length).fill(0).map(() => []);

export interface ArrayGrouping<ValueType> {
  [key: number]: ValueType[] | undefined;
  [key: string]: ValueType[] | undefined;
}
