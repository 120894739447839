import React from 'react';
import GlobalStyles from '@mui/material/GlobalStyles';
import { useTheme } from '@mui/material/styles';

const Background: React.FC = () => {
  const theme = useTheme();

  return (
    <>
      <GlobalStyles
        styles={{
          '.background': {
            backgroundColor: theme.palette.grey[100],
            position: 'fixed',
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
            zIndex: -1,
          },
        }}
      />
      <div id="background" className="background" />
    </>
  );
};
export default Background;
