import React, { useMemo } from 'react';
import type { ButtonProps } from '@mui/material';
import { SkyslopeAppsButton } from '@skyslope/mache';

import { useIsPrimeAuthUser } from 'auth/hooks';
import { LibraryTagKeys } from 'constants/LibraryTagKeys';
import useDerivedUserData from 'hooks/domain/user/useDerivedUserData';
import useIsBreezeButtonEnabled from 'hooks/domain/user/useIsBreezeButtonEnabled';
import useIsOffersLinkEnabled from 'hooks/domain/user/useIsOffersLinkEnabled';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

import useIsBooksLinkEnabled from '../../../../hooks/domain/user/useIsBooksLinkEnabled';

const MainMenuAppDropdown: React.FC<ButtonProps> = (props) => {
  const isPrimeUser = useIsPrimeAuthUser() ?? false;

  const { optInLibraries } = useDerivedUserData();
  const hasDs3AccessBasedOnLibrary = useMemo(
    () => !isPrimeUser && optInLibraries.some((l) => l.tags[LibraryTagKeys.givesDs3Access]?.toLowerCase() === 'true'),
    [isPrimeUser, optInLibraries]
  );

  const showBreezeLink = useIsBreezeButtonEnabled();
  const showOffersLink = useIsOffersLinkEnabled();
  const showBooksLink = useIsBooksLinkEnabled();

  const { isDigiSignMenuButtonEnabled } = useFeatureFlags();
  const showDs3Link =
    (isPrimeUser && (isDigiSignMenuButtonEnabled === 'DS3' || isDigiSignMenuButtonEnabled === 'DS2')) ||
    hasDs3AccessBasedOnLibrary;

  return (
    <SkyslopeAppsButton
      variant="text"
      color="secondary"
      size="small"
      {...props}
      linkEnv={window.environmentKey === 'dev' ? 'integ' : window.environmentKey}
      show={{
        books: showBooksLink,
        breeze: showBreezeLink,
        ds3: showDs3Link,
        offers: showOffersLink,
        prime: isPrimeUser ? true : 'marketing',
      }}
    />
  );
};

export default MainMenuAppDropdown;
