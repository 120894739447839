import type { AxiosError } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { createAsyncAction } from 'typesafe-actions';

import { addErrorNotification } from 'containers/Notifier/actions';
import EnvelopeServer from 'resources/server/EnvelopeServer';
import fileServer from 'resources/server/FileServer';
import type { GetEnvelopesRequest, GetEnvelopesResult } from 'resources/types/GetEnvelopes';
import type { UpdateFileDetailsPayload, UpdateFileDetailsResponse } from 'resources/types/UpdateFileDetails';
import { ActionTypes } from 'store/actions';
import { FileActions } from 'store/domain/actions';
import type { Envelope, File, FileServiceError } from 'store/types';
import { HttpResponseStatus } from 'store/types';
import { apiCall } from 'utils/apiCall';

import { availableContactRolesForRepresentationType } from '../utils/contactUtils';

import { EnvelopeActions, getFile as getFileCreator } from './actions';

const getFile = createAsyncAction(FileActions.GET_FILE, FileActions.GET_FILE_DONE, FileActions.GET_FILE_ERROR)<
  number,
  File,
  HttpResponseStatus
>();

const updateFileDetails = createAsyncAction(
  FileActions.UPDATE_FILE_DETAILS,
  FileActions.UPDATE_FILE_DETAILS_DONE,
  FileActions.UPDATE_FILE_DETAILS_ERROR
)<UpdateFileDetailsPayload, UpdateFileDetailsResponse, string>();

const getEnvelopes = createAsyncAction(
  EnvelopeActions.GET_ENVELOPES,
  EnvelopeActions.GET_ENVELOPES_DONE,
  ActionTypes.ERROR
)<GetEnvelopesRequest, GetEnvelopesResult, string>();

const updateEnvelopeToCancelled = createAsyncAction(
  EnvelopeActions.UPDATE_ENVELOPE_TO_CANCELLED,
  EnvelopeActions.UPDATE_ENVELOPE_TO_CANCELLED_DONE,
  ActionTypes.ERROR
)<number, Envelope, string>();

export function* handleGetFile(action: ReturnType<typeof getFile.request>): any {
  try {
    const file: File = yield apiCall({
      resources: [fileServer, fileServer.GetFile],
      payload: action.payload,
    });

    file.availableContactRolesDeprecated = availableContactRolesForRepresentationType(file.representationType);

    yield put(getFile.success(file));
  } catch (error) {
    if (
      (error as AxiosError<FileServiceError>)?.isAxiosError &&
      (error as AxiosError<FileServiceError>)?.response?.status === HttpResponseStatus.NOT_FOUND_ERROR
    ) {
      yield put(getFile.failure(HttpResponseStatus.NOT_FOUND_ERROR));
    } else {
      yield put(getFile.failure(HttpResponseStatus.OTHER));
    }
  }
}

export function* handleGetEnvelopes(action: ReturnType<typeof getEnvelopes.request>): any {
  try {
    const payload = action.payload || [];
    if (payload.sentEnvelopeId) {
      yield apiCall({
        resources: [EnvelopeServer, EnvelopeServer.RefreshEnvelopeStatus],
        payload: action.payload.sentEnvelopeId,
      });
    }

    const envelopeSummary: GetEnvelopesResult = yield apiCall({
      resources: [fileServer, fileServer.GetFileEnvelopes],
      payload: {
        fileId: payload.fileId,
        page: payload.page,
        pageSize: payload.pageSize,
      },
    });

    yield put(getEnvelopes.success(envelopeSummary));
  } catch (error) {
    yield put(getEnvelopes.failure(error as string));
  }
}

export function* handleUpdateFileDetails(action: ReturnType<typeof updateFileDetails.request>): any {
  try {
    const response: UpdateFileDetailsResponse = yield apiCall({
      resources: [fileServer, fileServer.UpdateFileDetails],
      payload: action.payload,
    });

    yield put(updateFileDetails.success(response));
  } catch (error) {
    yield put(
      addErrorNotification({
        message: 'Oops! Something went wrong, please try again.',
      })
    );
    yield put(updateFileDetails.failure(error as string));
  } finally {
    yield put(getFileCreator(action.payload.fileId));
  }
}

export function* handleUpdateEnvelopeToCancelled(action: ReturnType<typeof updateEnvelopeToCancelled.request>): any {
  try {
    const response: Envelope = yield apiCall({
      resources: [EnvelopeServer, EnvelopeServer.UpdateEnvelopeStatusToCancelled],
      payload: action.payload,
    });
    yield put(updateEnvelopeToCancelled.success(response));
  } catch (e) {
    yield put(updateEnvelopeToCancelled.failure(e as string));
  }
}

export default (): any => [
  takeEvery(FileActions.GET_FILE, handleGetFile),
  takeEvery(FileActions.UPDATE_FILE_DETAILS, handleUpdateFileDetails),
  takeEvery(EnvelopeActions.GET_ENVELOPES, handleGetEnvelopes),
  takeEvery(EnvelopeActions.UPDATE_ENVELOPE_TO_CANCELLED, handleUpdateEnvelopeToCancelled),
];
