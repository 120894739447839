import { useWelcomeToFormsSnackbar } from 'components/Snackbar';

import { MissingOnboardingLibraries } from './components/MissingOnboardingLibraries';
import { NHDUserPreferenceMissingDialog } from './components/NHDUserPreferenceMissingDialog';
import { UtilityConnectDialog } from './components/UtilityConnectDialog';
import { OreaAuthDialogContainer } from './OreaAuthDialog/OreaAuthDialogContainer';
import {
  useIsInitialized,
  useShowMissingOnboardingLibrariesDialog,
  useShowNHDUserPreferenceMissingDialog,
  useShowOreaAuthDialog,
  useShowUtilityConnectUI,
} from './hooks';

/**
 * AppSplashDialogs
 * @description Component that displays splash dialogs for the app, only allowing one to be displayed at a time.
 * @returns {JSX.Element|null} The splash dialog to be displayed, or null if none should be shown.
 */
export const AppSplashDialogs = (): JSX.Element | null => {
  const isInitialized = useIsInitialized();

  const onboardingLibsMissing = useShowMissingOnboardingLibrariesDialog();
  const nhdPrefsMissing = useShowNHDUserPreferenceMissingDialog();
  const oreaAuth = useShowOreaAuthDialog();
  const utilityConnect = useShowUtilityConnectUI();

  // Hey, you! If you add something 👆, please make sure it returns the same format
  // and that you also add it here 👇
  const allDialogs = [onboardingLibsMissing, nhdPrefsMissing, oreaAuth, utilityConnect];

  const isLoading = reduceOR('isLoading', allDialogs);
  const showAnySplashDialog = reduceOR('show', allDialogs);
  useWelcomeToFormsSnackbar({ enabled: isInitialized && !isLoading && !showAnySplashDialog });

  if (oreaAuth.show) return <OreaAuthDialogContainer />;

  if (!isInitialized) return null;
  if (onboardingLibsMissing.show) return <MissingOnboardingLibraries />;
  if (nhdPrefsMissing.show) return <NHDUserPreferenceMissingDialog />;
  if (utilityConnect.show) return <UtilityConnectDialog />;
  return null;
};

function reduceOR<T>(keyName: keyof T, objs: T[]): boolean {
  return objs.reduce((acc, val) => acc || Boolean(val[keyName]), false);
}
