import type { UserManager } from '@skyslope/auth-js';

import { userManager } from 'auth/userManager';
import { FILE_API_URL } from 'constants/environments';
import type { Contact, FileServiceResult } from 'store/types';

import Server from './Server';

export interface CreateContactRequest extends Contact {
  fileId: number;
  onBehalfOf?: string;
}

export interface UpdateContactRequest extends Contact {
  onBehalfOf?: string;
}

export interface CreateMultipleContactsRequest {
  fileId: number;
  onBehalfOf?: string;
  contacts: Contact[];
}

export interface DeleteContactRequest {
  fileId: number;
  contactId: number;
}

class ContactsServer extends Server {
  public constructor(manager: UserManager) {
    super(manager);
  }

  public async CreateContact(request: CreateContactRequest): Promise<number> {
    return (await this.api.post<FileServiceResult<number>>(`${FILE_API_URL}/files/${request.fileId}/contacts`, request))
      .data.result;
  }

  public async CreateContacts(request: CreateMultipleContactsRequest): Promise<number> {
    return (
      await this.api.post<FileServiceResult<number>>(`${FILE_API_URL}/files/${request.fileId}/contacts/bulk`, request)
    ).data.result;
  }

  public async UpdateContact(request: Contact): Promise<number> {
    return (await this.api.put<FileServiceResult<number>>(`${FILE_API_URL}/contacts/${request.id}`, request)).data
      .result;
  }

  public async GetContacts(fileId: number): Promise<Contact[]> {
    return (await this.api.get<FileServiceResult<Contact[]>>(`${FILE_API_URL}/files/${fileId}/contacts`)).data.result;
  }

  public async DeleteContact({ fileId, contactId }: DeleteContactRequest): Promise<number> {
    return (await this.api.delete<FileServiceResult<number>>(`${FILE_API_URL}/files/${fileId}/contacts/${contactId}`))
      .data.result;
  }
}

export default new ContactsServer(userManager);
