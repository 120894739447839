import type { Theme } from '@mui/material/styles';
import { colors } from '@skyslope/mache';

export interface TermsAndConditionsViewProps {
  title: string;
  inlineView?: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getTermsSx = (theme: Theme) => ({
  wrapper: {
    display: 'flex',
  },
  mobileWrapper: {
    display: 'block',
  },
  tabs: {
    borderRight: `1px solid`,
    minWidth: theme.spacing(25),
  },
  indicator: {
    left: 0,
    width: '3px',
    backgroundColor: colors.blue[800],
  },
  contentWrapper: {
    padding: theme.spacing(8),
    overflow: 'auto',
    '& > .MuiTypography-root': {
      marginBottom: theme.spacing(4),
    },
  },
  title: {
    marginBottom: 0,
  },
  link: {
    color: colors.blue[800],
    textDecoration: 'none',
    cursor: 'pointer',
  },
  lowercaseList: {
    listStyleType: 'lower-alpha',
  },
  uppercaseList: {
    listStyleType: 'upper-alpha',
  },
  romanSmallList: {
    listStyleType: 'lower-roman',
  },
  underline: {
    textDecoration: 'underline',
  },
});
