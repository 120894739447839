import type { UserManager } from '@skyslope/auth-js';

import { userManager } from 'auth';
import { USER_API_URL } from 'constants/environments';
import type { TrrebMember } from 'pages/UserSettings/AuthProfileUserSettings/store/types';

import Server from './Server';

class TrrebServer extends Server {
  public constructor(manager: UserManager) {
    super(manager);
  }
  public async GetTrrebMember(trrebMemberId: string): Promise<TrrebMember> {
    return (await this.api.get(`${USER_API_URL}/trreb/member/${trrebMemberId}`)).data.result;
  }
}
export default new TrrebServer(userManager);
