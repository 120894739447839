import type { UserManager } from '@skyslope/auth-js';

import type { ChangePasswordRequest } from 'auth/hooks/useChangePasswordMutation';
import { userManager } from 'auth/userManager';
import { USER_API_URL } from 'constants/environments';

import Server from './Server';

class UserServer extends Server {
  public constructor(userManager: UserManager) {
    super(userManager, {}, { isGlobalUnauthorizedEnabled: false });
  }

  public async ChangePassword(
    userId: string | undefined,
    changePasswordRequest: ChangePasswordRequest
  ): Promise<string> {
    return (await this.api.put(`${USER_API_URL}/users/${userId}/password`, changePasswordRequest)).data.result;
  }

  public async IsValidLogin(login: string): Promise<boolean> {
    return (await this.api.get(`${USER_API_URL}/users/${login}/valid`)).data.result;
  }
}

export default new UserServer(userManager);
