import { action } from 'typesafe-actions';

import type { GetRecipientsRequest } from 'resources/types/GetRecipients';
import type { UpdateRecipientPayload } from 'resources/types/UpdateRecipient';

export enum EnvelopeResendDialogActions {
  GET_CONTACTS = 'EnvelopeResendDialogActions/GET_CONTACTS',
  GET_CONTACTS_DONE = 'EnvelopeResendDialogActions/GET_CONTACTS_DONE',
  GET_CONTACTS_ERROR = 'EnvelopeResendDialogActions/GET_CONTACTS_ERROR',
  GET_RECIPIENTS = 'EnvelopeResendDialogActions/GET_RECIPIENTS',
  GET_RECIPIENTS_DONE = 'EnvelopeResendDialogActions/GET_RECIPIENTS_DONE',
  UPDATE_RECIPIENT = 'EnvelopeResendDialogActions/UPDATE_RECIPIENT',
  UPDATE_RECIPIENT_DONE = 'EnvelopeResendDialogActions/UPDATE_RECIPIENT_DONE',
  UPDATE_RECIPIENT_ERROR = 'EnvelopeResendDialogActions/UPDATE_RECIPIENT_ERROR',
}

export const getContacts = (
  payload: number
): {
  type: EnvelopeResendDialogActions.GET_CONTACTS;
} => action(EnvelopeResendDialogActions.GET_CONTACTS, payload);

export const getRecipients = (
  request: GetRecipientsRequest
): {
  type: EnvelopeResendDialogActions.GET_RECIPIENTS;
} => action(EnvelopeResendDialogActions.GET_RECIPIENTS, request);

export const updateRecipient = (
  request: UpdateRecipientPayload
): {
  type: EnvelopeResendDialogActions.UPDATE_RECIPIENT;
} => action(EnvelopeResendDialogActions.UPDATE_RECIPIENT, request);
