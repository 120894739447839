import type { RootState } from 'store';

import type { Pagination } from 'store/types';

export const selectedLibraryIdsSelector = (state: RootState): number[] | undefined =>
  state.selectFormsState.filterState.selectedLibraryIds;

export const selectedFormTypesSelector = (state: RootState): string[] | undefined =>
  state.selectFormsState.filterState.selectedFormTypes;

export const searchQuerySelector = (state: RootState): string =>
  state.selectFormsState.filterState.searchQuery.toLowerCase();

export const isFilterActiveSelector = (state: RootState): boolean => state.selectFormsState.filterState.isFilterActive;

export const selectFormsPaginationSelector = (state: RootState): Pagination =>
  state.selectFormsState.filterState.pagination;
