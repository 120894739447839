import update from 'immutability-helper';
import { combineReducers } from 'redux';

import { ActionTypes } from 'store/actions';
import type { AppAction, DocumentSummary, FormDocument } from 'store/types';

import type { CreateThumbnailResult } from '../../FileDetails/store/types';

export interface FileFormsContainerState {
  forms: FileFormsState;
}

export interface FileFormsState {
  documentSummary?: DocumentSummary;
}

export const BaseFileFormsReducer = (state: FileFormsState = {}, action: AppAction): FileFormsState => {
  switch (action.type) {
    case ActionTypes.GET_FILE_DOCUMENTS_DONE: {
      const payload: DocumentSummary = action.payload;
      return update(state, {
        documentSummary: {
          $set: payload,
        },
      });
    }
    case ActionTypes.DELETE_FILE_DOCUMENT_DONE: {
      const payload: number = action.payload;
      return update(state, {
        documentSummary: {
          documents: (documents) => documents.filter((document) => document.id !== payload),
        },
      });
    }
    case ActionTypes.DELETE_FILE_DOCUMENTS_DONE: {
      const payload: number[] = action.payload.documentIds;
      return update(state, {
        documentSummary: {
          documents: (documents) => documents.filter((document) => !payload.includes(document.id)),
        },
      });
    }
    case ActionTypes.CREATE_FILE_DOCUMENT_THUMBNAIL_ERROR:
    case ActionTypes.CREATE_FILE_DOCUMENT_THUMBNAIL_DONE: {
      const payload: CreateThumbnailResult = action.payload;
      if (state.documentSummary?.documents) {
        return update(state, {
          documentSummary: {
            documents: (documents: FormDocument[]): FormDocument[] =>
              documents.map((document) => {
                if (document.id === payload.documentId) {
                  document.thumbnailURL = payload.thumbnailDataUrl;
                }
                return document;
              }),
          },
        });
      }
      return state;
    }
    case ActionTypes.RESET_FILE_DOCUMENTS: {
      return update(state, {
        documentSummary: { $set: undefined },
      });
    }
    case ActionTypes.SET_FILE_DOCUMENT_ORDER: {
      const payload: FormDocument[] = action.payload;
      return update(state, {
        documentSummary: {
          documents: {
            $set: payload,
          },
        },
      });
    }
    case ActionTypes.RESET: {
      return {};
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({
  forms: BaseFileFormsReducer,
});
