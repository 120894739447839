import { useMemo } from 'react';
import type { UserContext } from '@skyslope/auth-js';
import type { Schema } from 'yup';
import { object, string } from 'yup';

import { StampingFields } from 'pages/UserOnboarding/store/selectors';
import type { FormLibrary, UserSettings } from 'store/types';

import { distinct } from './array';

export const sanitizeUserContext = (userContext: UserContext): UserContext => {
  const isPhoneNumberValid =
    !!userContext.primaryPhone && !isNaN(Number(userContext.primaryPhone)) && userContext.primaryPhone.length === 10;

  // later if we import more data from okta/prime we might need more sanitization, everything else we import is
  // guaranteed to be correct currently since email is required to be valid by okta
  return {
    ...userContext,
    primaryPhone: isPhoneNumberValid ? userContext.primaryPhone : undefined,
  };
};

export const useUserSettingsSchema = (requiredFields: string[]): Schema<Partial<UserSettings>> =>
  useMemo(() => {
    const requiredSchemaFields: string[] = requiredFields.flatMap((field) => {
      if (field === StampingFields.AgentName) {
        return ['firstName', 'lastName'];
      }

      return [field];
    });
    const schemaFields: { [key: string]: Schema<unknown> } = {
      firstName: string().trim().required().label('First Name'),
      lastName: string().trim().required().label('Last Name'),
      brokerageName: string().trim().required().label('Brokerage Name'),
      brokeragePhone: string().required().length(10).label('Brokerage Phone'),
      brokerageAddress: object().shape<{
        streetAddress: string;
        city: string;
        state: string;
        postalCode: string;
      }>({
        streetAddress: string().trim().required().label('Brokerage Address'),
        city: string().trim().required().label('City'),
        state: string().trim().required().label('State'),
        postalCode: string().trim().required().label('Postal Code'),
      }),
    };
    return object().shape(
      Object.assign(
        {},
        ...requiredSchemaFields.map((field) => ({
          [field]: schemaFields[field],
        }))
      )
    );
  }, [requiredFields]);

export const buildRequiredFields = (libraries: FormLibrary[]): string[] =>
  distinct(libraries.flatMap((library) => library.requiredFields));
