import update from 'immutability-helper';

import type { AppAction, Pagination } from 'store/types';

import { SelectFormsActions } from '../../store/actions';

export interface FilterState {
  selectedLibraryIds?: number[];
  selectedFormTypes?: string[];
  isFilterActive: boolean;
  searchQuery: string;
  pagination: Pagination;
}

export const defaultState: FilterState = {
  isFilterActive: false,
  searchQuery: '',
  pagination: { page: 1, pageSize: 10, totalRecords: 0 },
};

const setTotalPages = (state: FilterState, totalRecords: number): FilterState =>
  update(state, {
    pagination: {
      totalRecords: { $set: totalRecords },
    },
  });

const setSelectedLibraryIds = (state: FilterState, libraryIds: number[] | undefined): FilterState =>
  update(state, {
    selectedLibraryIds: {
      $set: libraryIds,
    },
    pagination: {
      page: {
        $set: 1,
      },
    },
  });

const setSelectedFormTypes = (state: FilterState, formTypes: string[] | undefined): FilterState =>
  update(state, {
    selectedFormTypes: {
      $set: formTypes,
    },
    pagination: {
      page: {
        $set: 1,
      },
    },
  });

const toggleFilters = (state: FilterState): FilterState =>
  update(state, {
    isFilterActive: { $set: !state.isFilterActive },
  });

const setSearchQuery = (state: FilterState, query: string): FilterState =>
  update(state, {
    searchQuery: { $set: query },
    pagination: {
      page: { $set: defaultState.pagination.page },
    },
  });

const reducer = (state: FilterState = defaultState, action: AppAction): FilterState => {
  switch (action.type) {
    case SelectFormsActions.SET_SELECTED_LIBRARY_IDS: {
      return setSelectedLibraryIds(state, action.payload.length > 0 ? action.payload : undefined);
    }
    case SelectFormsActions.SET_SELECTED_FORM_TYPES: {
      return setSelectedFormTypes(state, action.payload.length > 0 ? action.payload : undefined);
    }
    case SelectFormsActions.TOGGLE_FILTERS: {
      return toggleFilters(state);
    }
    case SelectFormsActions.SET_SEARCH_QUERY: {
      return setSearchQuery(state, action.payload);
    }
    case SelectFormsActions.SET_TOTAL_PAGES: {
      return setTotalPages(state, action.payload);
    }
    default: {
      return state;
    }
  }
};

export default reducer;
