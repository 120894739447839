import { action } from 'typesafe-actions';

import type { UploadDocumentPayload } from 'resources/types/UploadDocuments';
import type { UploadProgressPayload } from 'store/types';

export enum UploadDocumentsActions {
  UPLOAD_DOCUMENT = 'UploadDocumentsActions/UPLOAD_DOCUMENT',
  UPLOAD_DOCUMENT_PROGRESS = 'UploadDocumentsActions/UPLOAD_DOCUMENT_PROGRESS',
  UPLOAD_DOCUMENT_DONE = 'UploadDocumentsActions/UPLOAD_DOCUMENT_DONE',
  UPLOAD_DOCUMENT_ERROR = 'UploadDocumentsActions/UPLOAD_DOCUMENT_ERROR',
  CLEAR_DOCUMENTS = 'UploadDocumentsActions/CLEAR_DOCUMENTS',
  REMOVE_DOCUMENT = 'UploadDocumentsActions/REMOVE_DOCUMENTS',
}

export const clearUploadedDocuments = (): {
  type: UploadDocumentsActions.CLEAR_DOCUMENTS;
} => action(UploadDocumentsActions.CLEAR_DOCUMENTS);

export const uploadDocument = (
  payload: UploadDocumentPayload
): {
  type: UploadDocumentsActions.UPLOAD_DOCUMENT;
  payload: UploadDocumentPayload;
} => action(UploadDocumentsActions.UPLOAD_DOCUMENT, payload);

export const uploadDocumentProgress = (
  progress: number,
  requestId: number
): {
  type: UploadDocumentsActions.UPLOAD_DOCUMENT_PROGRESS;
  payload: UploadProgressPayload;
} =>
  action(UploadDocumentsActions.UPLOAD_DOCUMENT_PROGRESS, {
    progress,
    requestId,
  });

export const removeUploadDocument = (
  requestId: number
): {
  type: UploadDocumentsActions.REMOVE_DOCUMENT;
  payload: number;
} => action(UploadDocumentsActions.REMOVE_DOCUMENT, requestId);
