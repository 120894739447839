import type { UserManager } from '@skyslope/auth-js';

import { userManager } from 'auth/userManager';
import { ACCOUNT_API_URL, FILE_API_URL } from 'constants/environments';
import type { MeSubscription, UserSubscription } from 'resources/types/GetUserSubscriptionSettings';
import type { SendToStripeRequest } from 'resources/types/SendToStripeRequest';
import type { AccountGroup, Roles } from 'store/types';

import Server from './Server';

class AccountUserServer extends Server {
  public constructor(manager: UserManager) {
    super(manager);
  }

  public async GetRolebyUserId(userId: string): Promise<Roles> {
    return (await this.api.get(`${ACCOUNT_API_URL}/v3/user/id?Id=${userId}`)).data.result;
  }

  public async GetSubscriberGroup(subscriberId: string): Promise<AccountGroup[]> {
    const results = (
      await this.api.post(`${ACCOUNT_API_URL}/v3/groups/filter`, {
        GroupFilters: [
          {
            MetadataFilters: [{ SubscriberId: subscriberId }],
          },
        ],
      })
    ).data.result;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return results.map((group: any) => ({
      groupId: group._id,
      name: group.name,
      userCount: group.userCount,
    }));
  }

  public async GetGroups(page: number, pageSize: number): Promise<AccountGroup[]> {
    return (await this.api.get(`${FILE_API_URL}/groups?page=${page ?? 1}&pageSize=${pageSize ?? 250}`))?.data?.result
      ?.data;
  }

  public async getUsersInGroup(
    groupId?: string
  ): Promise<Array<{ email: string; firstName: string; lastName: string }>> {
    if (groupId == undefined) return Promise.resolve([]);

    return (await this.api.get(`${FILE_API_URL}/groups/${groupId}/users`))?.data?.result;
  }

  public async getUserSubscriptionSettings(oktaId: string): Promise<UserSubscription> {
    const response = await this.api.get(`${ACCOUNT_API_URL}/v3/users/${oktaId}/subscription`);
    return response.data;
  }

  public async getMeSubscriptions(): Promise<MeSubscription> {
    const response = await this.api.get(`${ACCOUNT_API_URL}/v3/users/me/subscription`);
    return response.data?.result;
  }

  public async sendToStripe(args: SendToStripeRequest): Promise<string> {
    const { priceId, redirectUrl, oktaGuidId } = args;
    const response = await this.api.post(`${ACCOUNT_API_URL}/v3/users/subscription/checkout`, {
      priceId,
      redirectUrl,
      userId: oktaGuidId,
    });
    return response.data.url;
  }

  public async updateUserSubscription(id: string): Promise<UserSubscription> {
    const res = await this.api.post(`${ACCOUNT_API_URL}/v3/users/${id}/subscription/`);
    return res.data;
  }
}

export default new AccountUserServer(userManager);
