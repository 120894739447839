import type { ContactRoleDisplay } from 'store/types';
import { ContactRole, RepresentationType } from 'store/types';

export const availableContactRolesForRepresentationType = (
  representationType: RepresentationType
): ContactRoleDisplay[] => {
  const availableContactRoles = [];

  if (representationType === RepresentationType.LANDLORD || representationType === RepresentationType.TENANT) {
    availableContactRoles.push({
      key: ContactRole.BUYER,
      displayValue: 'Tenant',
    });
    availableContactRoles.push({
      key: ContactRole.SELLER,
      displayValue: 'Landlord',
    });
  } else {
    availableContactRoles.push({
      key: ContactRole.BUYER,
      displayValue: 'Buyer',
    });
    availableContactRoles.push({
      key: ContactRole.SELLER,
      displayValue: 'Seller',
    });
  }

  availableContactRoles.push({
    key: ContactRole.ESCROW_OFFICER,
    displayValue: 'Escrow Officer',
  });
  availableContactRoles.push({
    key: ContactRole.TITLE_OFFICER,
    displayValue: 'Title Officer',
  });
  availableContactRoles.push({
    key: ContactRole.LOAN_OFFICER,
    displayValue: 'Loan Officer',
  });
  availableContactRoles.push({
    key: ContactRole.BUYER_AGENT,
    displayValue: 'Buyer Agent',
  });
  availableContactRoles.push({
    key: ContactRole.SELLER_AGENT,
    displayValue: 'Seller Agent',
  });
  availableContactRoles.push({
    key: ContactRole.OTHER,
    displayValue: 'Other',
  });

  return availableContactRoles;
};

export const displayTypeFormatter = (
  type: ContactRole | '',
  representationType?: RepresentationType | '',
  isUser?: boolean, // file creator
  isFileOwner?: boolean,
  fileCreatorUserId?: string,
  fileOwnerUserId?: string,
  loggedInUserId?: string
): string => {
  if (representationType === RepresentationType.TENANT || representationType === RepresentationType.LANDLORD) {
    if (type === ContactRole.BUYER) return 'Tenant';
    if (type === ContactRole.SELLER) return 'Landlord';
  }
  if (type === ContactRole.BUYERS_LAWYER) return "Buyer's Lawyer";
  if (type === ContactRole.SELLERS_LAWYER) return "Seller's Lawyer";

  const role = type.replace(/([a-z\xE0-\xFF])([A-Z\xC0\xDF])/g, '$1 $2'); //includes diacritic support

  // retain old behavior for quick entry v1
  if (!loggedInUserId) return isUser ? `${role} (Me)` : role;

  if ((fileCreatorUserId === loggedInUserId && isUser) || (fileOwnerUserId === loggedInUserId && isFileOwner))
    return `${role} (Me)`;

  if (isFileOwner) return `${role} (Owner)`;

  return role;
};
