import { useLocation } from 'react-router-dom';

import useIsLoginProcessComplete from 'auth/hooks/useIsLoginProcessComplete';
import useGetFileQuery from 'hooks/domain/file/queries/useGetFileQuery';
import type { File } from 'store/types';

const useGetFileData = (): File | undefined => {
  const isLoggedIn = useIsLoginProcessComplete();

  // This is a temporary solution.
  // This should be using useParams instead of manually parsing the fileId.
  // But useParams always returns undefined in this hook.  This requires some investigation
  const { pathname } = useLocation();

  const paths = pathname.split('/');
  const fileId = pathname.startsWith('/file-details/') ? parseInt(paths[2] ?? '') : NaN;

  const { data: file } = useGetFileQuery(isLoggedIn ? fileId : NaN);

  return file;
};

export default useGetFileData;
