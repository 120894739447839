import React, { useCallback, useState } from 'react';

import useAreTermsAccepted from 'components/AuthProfileSetup/hooks/useAreTermsAccepted';
import useAddAuthProfilesMutation from 'hooks/domain/user/mutations/useAddAuthProfilesMutation';
import useUpdateAuthProfileMutation from 'hooks/domain/user/mutations/useUpdateAuthProfileMutation';
import useGetUserSettingsQuery from 'hooks/domain/user/queries/useGetUserSettingsQuery';
import useOreaAuthLogin from 'hooks/useOreaAuthLogin';
import type { OreaDialogStatus } from 'store/actions';
import type { UserAuthProfile } from 'store/types';
import { FormLibrarySource } from 'store/types';

import OreaAuthDialogView from './OreaAuthDialogView';

export interface OreaAuthDialogProps {
  setDialogStatus(status: OreaDialogStatus): void;
}

const OreaAuthDialog: React.FC<OreaAuthDialogProps> = ({ setDialogStatus }) => {
  const { mutate: addAuthProfiles } = useAddAuthProfilesMutation();
  const { mutate: updateAuthProfile } = useUpdateAuthProfileMutation();
  const { data: userSettings } = useGetUserSettingsQuery();
  const userId = userSettings?.id;
  const oreaAuthProfile = userSettings?.authProfiles?.find((p) => p.type === FormLibrarySource.Orea);
  const [isLoading, setIsLoading] = useState(false);
  const onClose = useCallback(() => {
    setDialogStatus('closed');
  }, [setDialogStatus]);

  const onSuccess = useCallback(() => {
    setDialogStatus('success');
    setIsLoading(false);
  }, [setDialogStatus]);

  const onSubmit = useCallback(
    (profile: UserAuthProfile) => {
      setIsLoading(true);
      if (!userId) return;
      if (oreaAuthProfile == null) {
        addAuthProfiles([{ ...profile, userId }]);
      } else {
        updateAuthProfile({
          profile: profile.profile,
          authProfileId: oreaAuthProfile.id ?? '',
          type: oreaAuthProfile.type,
          userId,
        });
      }
      onSuccess();
    },
    [userId, oreaAuthProfile, onSuccess, addAuthProfiles, updateAuthProfile]
  );
  const areTermsAlreadyAccepted = useAreTermsAccepted(FormLibrarySource.Orea);

  const {
    isInvalidOreaMember,
    isLoading: isOreaLoading,
    isLoggingIn,
    isLoggingOut,
    isSuccess,
    onContinue,
    onLoginClick,
    onLogoutClick,
  } = useOreaAuthLogin(onSubmit);

  return (
    <OreaAuthDialogView
      areTermsAlreadyAccepted={areTermsAlreadyAccepted}
      is8HourUpdate={oreaAuthProfile != null}
      isInvalidOreaMember={isInvalidOreaMember}
      isLoading={isLoading || isOreaLoading}
      isLoggingIn={isLoggingIn}
      isLoggingOut={isLoggingOut}
      isSuccess={isSuccess}
      onClose={onClose}
      onContinue={onContinue}
      onLoginClick={onLoginClick}
      onLogoutClick={onLogoutClick}
    />
  );
};

export default OreaAuthDialog;
