import { put, takeEvery } from 'redux-saga/effects';
import { createAsyncAction } from 'typesafe-actions';

import fileServer from 'resources/server/FileServer';
import { apiCall } from 'utils/apiCall';

import { DashboardActions } from './actions';
import type { FileSummary } from './types';

const getFiles = createAsyncAction(
  DashboardActions.GET_FILES,
  DashboardActions.GET_FILES_DONE,
  DashboardActions.DASHBOARD_ERROR
)<undefined, FileSummary[], string>();

function* handleGetFiles(): any {
  try {
    const res: FileSummary[] = yield apiCall({
      resources: [fileServer, fileServer.GetFiles],
    });
    yield put(getFiles.success(res));
  } catch (e) {
    yield put(getFiles.failure(e as string));
  }
}
export default (): any => [takeEvery(DashboardActions.GET_FILES, handleGetFiles)];
