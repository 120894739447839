import React, { useCallback } from 'react';
import Box from '@mui/material/Box';
import type { Theme } from '@mui/material/styles/';
import type { SxProps } from '@mui/system/styleFunctionSx';
import { colors } from '@skyslope/mache';
import { Key as KeyBoard } from 'ts-keycode-enum';

export interface LinkButtonProps {
  id?: string;
  className?: string;
  sx?: SxProps<Theme>;
  onClick: (event: React.MouseEvent) => void;
  'aria-label'?: string;
}

const LinkButton: React.FC<LinkButtonProps> = (props) => {
  const { id, sx, onClick, children } = props;

  const onKeyDown = useCallback(
    (e) => {
      if (e.which === KeyBoard.Enter) onClick(e);
    },
    [onClick]
  );

  return (
    <Box
      component="a"
      tabIndex={0}
      id={id}
      sx={[
        {
          color: colors.blue[800],
          cursor: 'pointer',
          '&:hover': {
            textDecoration: 'underline',
          },
          '&:focus': {
            textDecoration: 'underline',
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      onClick={onClick}
      onKeyDown={onKeyDown}
      aria-label={props['aria-label']}
    >
      {children}
    </Box>
  );
};

export default LinkButton;
