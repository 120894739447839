import React, { useEffect, useState } from 'react';
import type { RouteProps as ReactRouterProps } from 'react-router';
import { datadogLogs } from '@datadog/browser-logs';

import LayoutContext from 'components/Layout/Context';
import type { LayoutConfig } from 'components/Layout/Provider';

interface LazyProps {
  name: string;
  routerProps?: ReactRouterProps;
}

interface Page {
  layoutLoaded: boolean;
  layoutConfig: LayoutConfig;
  default?: React.FC;
}

interface UpdateLayoutProps {
  updateLayout?: (updates: LayoutConfig, fresh: boolean) => void;
  page: Page;
  setPage: (page: Page) => void;
}

const UpdateLayout: React.FC<UpdateLayoutProps> = ({ updateLayout, page, setPage }) => {
  useEffect(() => {
    if (updateLayout) {
      updateLayout({ header: { ...(page.layoutConfig?.header || { title: '' }) } }, true);
      setPage({ ...page, ...{ layoutLoaded: true } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

const Lazy: React.FC<LazyProps> = (props) => {
  const { name } = props;
  const defaultPage: Page = { layoutLoaded: false, layoutConfig: {} };
  const [page, setPage] = useState(defaultPage);

  if (page.default) {
    // page is loaded
    const Component = page.default;

    return (
      <LayoutContext.Consumer>
        {({ updateLayout }) => (
          <>
            {!page.layoutLoaded && <UpdateLayout updateLayout={updateLayout} page={page} setPage={setPage} />}
            {page.layoutLoaded && <Component {...props.routerProps} />}
          </>
        )}
      </LayoutContext.Consumer>
    );
  }
  // load the page
  import(
    /* webpackChunkName: "[request]" */
    /* webpackExclude: /(\.test\.tsx?$|__snapshots__)/ */
    `pages/${name}`
  )
    .then((loadedComponent) => {
      // set the loaded page
      setPage((p) => ({
        ...p,
        default: loadedComponent.default,
        layoutConfig: loadedComponent.layoutConfig,
      }));
    })
    .catch((e) => {
      datadogLogs.logger.error(`Lazy load chunk error: ${e}`);
      window.location.reload();
    });
  return null;
};

const LazyLoadPage = (props: LazyProps): React.FC => {
  const { name } = props;
  const Wrap: React.FC<ReactRouterProps> = ({ ...props }) => <Lazy name={name} routerProps={props} />;
  return Wrap;
};

export default LazyLoadPage;
