import React from 'react';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled, useTheme } from '@mui/material/styles';
import { colors } from '@skyslope/mache';

import { rippleStyles } from 'utils/rippleStylesUtils';

const CustomListItemButton = styled(ListItemButton)(rippleStyles);

export interface MobileMenuRowProps {
  name: string;
  icon?: JSX.Element;
  isSelected?: boolean;
  onClick?: () => void;
  ariaLabel?: string;
  id: string;
}

const MobileMenuRow: React.FC<MobileMenuRowProps> = ({ name, icon, isSelected, onClick, ariaLabel, id }) => {
  const theme = useTheme();
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        height: theme.spacing(5),
        paddingRight: theme.spacing(2),
      }}
    >
      <Box
        component="span"
        sx={{
          height: theme.spacing(5),
          width: 3,
          borderRadius: '0 3px 3px 0',
          backgroundColor: colors.blue[800],
          ...(!isSelected ? { visibility: 'hidden' } : undefined),
        }}
      />
      <CustomListItemButton
        id={id}
        data-spec="list-item"
        selected={isSelected}
        sx={{
          '& .MuiListItemButton-root': {
            marginLeft: theme.spacing(2),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            borderRadius: 3,
            marginBottom: 2,
          },
          '& .MuiListItemButton-selected': {
            backgroundColor: `${colors.blue[50]} !important`,
          },
        }}
        disableGutters
        onClick={onClick}
        aria-label={ariaLabel ? ariaLabel : `Navigate to ${name} sub menu`}
      >
        {icon && (
          <ListItemIcon
            sx={{
              color: colors.blue[800],
              minWidth: 27,
              marginRight: theme.spacing(2),
            }}
          >
            {icon}
          </ListItemIcon>
        )}
        <ListItemText
          primaryTypographyProps={{
            variant: isSelected ? 'body2' : 'body1',
            color: isSelected ? 'primary' : 'initial',
          }}
        >
          {name}
        </ListItemText>
      </CustomListItemButton>
    </Box>
  );
};

export default MobileMenuRow;
