import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import useGetUserSettingsQuery from 'hooks/domain/user/queries/useGetUserSettingsQuery';

import { PRIME_CDN_URL, PRIME_URL } from '../../../constants/environments';
import LocalStorageItem from '../../../constants/LocalStorageItem';
import { customHeapEvent } from '../../../utils/heap';

export const UtilityConnectDialog = () => {
  const userId = useGetUserSettingsQuery().data?.id;

  const [dismissed, setDismissed] = useState(false);

  useEffect(() => {
    customHeapEvent('Forms - Onboard Modal - Utility Connect Shown');
  }, []);

  const enableUser = () => {
    customHeapEvent('Forms - Onboard Modal - Utility Connect Enabled');
    window.location.href = `${PRIME_URL}/Integrations.aspx?enableUC=true`;
  };

  const notNow = () => {
    customHeapEvent('Forms - Onboard Modal - Utility Connect Dismissed');
    localStorage.setItem(`${LocalStorageItem.UtilityConnectModalLastDismissed}-${userId}`, new Date().toISOString());
    setDismissed(true);
  };

  return (
    <Dialog open={!dismissed} maxWidth={'sm'} classes={{ paper: 'rounded-xl px-10 pt-10 pb-5' }}>
      <div className="flex justify-start items-center p-2">
        <a
          href="https://support.skyslope.com/hc/en-us/articles/26495446741787-Utility-Connect-SkySlope-FAQ"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={`${PRIME_CDN_URL}/prime/images/UtilityConnectGLogo.svg`}
            id="utilityConnectLogo"
            className="updater-logo w-[70%] max-w-[200px] justify-center pl-2"
            alt="Utility Connect Logo"
            width="auto"
            height={50}
          />
        </a>
        <Typography variant="h5" component="span" className="mx-2 font-bold text-2xl">
          +
        </Typography>
        <img
          src={`${PRIME_CDN_URL}/prime/images/logo-skyslope-2x.svg`}
          id="skyslopeLogo"
          className="skyslope-logo w-[70%] max-w-[200px] justify-center pl-2"
          alt="SkySlope Logo"
          width="auto"
          height={50}
        />
      </div>
      <DialogTitle>
        <Typography variant={'h5'}>Activate Utility Connect</Typography>
      </DialogTitle>
      <DialogContent className="mb-4">
        <DialogContentText className="text-grey-800 mb-8">Now with SkySlope!</DialogContentText>
        <DialogContentText className="text-grey-800">
          Make it easy for your clients to set up utilities and home services with Utility Connect. With one call, their
          concierge handles everything so your clients can focus on moving in, stress-free.{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://support.skyslope.com/hc/en-us/articles/26495446741787-Utility-Connect-SkySlope-FAQ"
          >
            Learn More
          </a>
        </DialogContentText>
      </DialogContent>
      <Divider className="mx-[-2.5rem]" />
      <DialogActions className="px-0 pt-5 pb-0">
        <Button
          id="utility-connect-onboarding-dismiss-btn"
          variant="contained"
          color="secondary"
          onClick={() => notNow()}
        >
          Do this later
        </Button>
        <Button
          className="ml-2"
          id="utility-connect-onboarding-save-btn"
          variant="contained"
          color="primary"
          href={`${PRIME_URL}/Integrations.aspx?enableUC=true`}
          onClick={() => enableUser()}
          disabled={false}
        >
          Activate
        </Button>
      </DialogActions>
    </Dialog>
  );
};
