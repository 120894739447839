import type { UserManager } from '@skyslope/auth-js';

import { userManager } from 'auth';
import { FILE_API_URL } from 'constants/environments';
import type { ChameleonInfo, FileServiceResult } from 'store/types';

import Server from './Server';

class UserSummaryServer extends Server {
  public constructor(manager: UserManager) {
    super(manager);
  }

  public async GetChameleonInfo(): Promise<ChameleonInfo> {
    return (await this.api.get<FileServiceResult<ChameleonInfo>>(`${FILE_API_URL}/user-summary/chameleon`)).data.result;
  }
}

export default new UserSummaryServer(userManager);
