export const getBrowser = (): 'chrome' | 'firefox' | 'safari' | 'edge' | 'unknown' => {
  // https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
  const userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.includes('chrome/')) {
    return 'chrome';
  } else if (userAgent.includes('firefox/')) {
    return 'firefox';
  } else if (userAgent.includes('safari')) {
    return 'safari';
  } else if (userAgent.includes('edg/')) {
    return 'edge';
  } else {
    return 'unknown';
  }
};
