import { datadogLogs } from '@datadog/browser-logs';
import type { UserManager } from '@skyslope/auth-js';
import type { AxiosInstance, AxiosRequestConfig } from 'axios';
import axios from 'axios';

import { getPath, routes } from 'constants/routes';
import history from 'utils/history';
import { createMockUserManager } from 'utils/test/types';

export const ACCOUNTS_API_AUTH_HEADER = 'x-accounts-authorization';
export const X_TRANSACTION_SOURCE = 'x-transaction-source';

export interface ServerOptions {
  isGlobalUnauthorizedEnabled: boolean;
  tokenGetter: (userManager: UserManager) => Promise<string>;
  customRequestConfigMutation?: (userManager: UserManager, config: AxiosRequestConfig) => Promise<void>;
  includeBaseHeaders?: boolean;
  throwErrorOnInvalidToken?: boolean;
}

const defaultServerOptions: ServerOptions = {
  isGlobalUnauthorizedEnabled: true,
  tokenGetter: (userManager) => userManager.getAccessToken(),
};

export default abstract class Server {
  protected api: AxiosInstance;
  protected userManager: UserManager;
  protected serverOptions: ServerOptions;

  protected constructor(
    manager: UserManager,
    axiosConfig: AxiosRequestConfig = {},
    serverOptions: Partial<ServerOptions> = {}
  ) {
    this.serverOptions = {
      ...defaultServerOptions,
      ...serverOptions,
    };
    this.api = axios.create(axiosConfig);

    // Jest makes the NODE_ENV = test
    if (process.env.NODE_ENV.toLowerCase() === 'test') {
      import('axios/lib/adapters/http').then((adapter) => (this.api.defaults.adapter = adapter.default));
      this.userManager = createMockUserManager();
    } else {
      this.userManager = manager;
    }

    this.api.interceptors.request.use(async (config: AxiosRequestConfig) => {
      const token = await this.serverOptions.tokenGetter(this.userManager);
      if (!token && this.serverOptions.throwErrorOnInvalidToken) {
        throw new Error('Error retrieving token');
      }
      config.headers.Authorization = `Bearer ${token}`;

      if (serverOptions.includeBaseHeaders ?? true) {
        // TODO: Remove accounts API auth header when Forms APIs are refactored away from it
        config.headers[ACCOUNTS_API_AUTH_HEADER] = token;
        config.headers[X_TRANSACTION_SOURCE] = 'forms';
      }

      if (this.serverOptions.customRequestConfigMutation) {
        await this.serverOptions.customRequestConfigMutation(this.userManager, config);
      }

      if (window.fetchErrors === undefined) {
        window.fetchErrors = [];
      }

      return config;
    });

    this.api.interceptors.response.use(
      (res) => res,
      (e) => {
        window.fetchErrors.push({
          status: e?.response?.status,
          url: e?.config?.url,
          method: e?.config?.method?.toUpperCase(),
          data: e?.response?.data,
          headers: e?.response?.headers,
          request: e?.request?.request,
        });

        datadogLogs.logger.error(`${e?.config?.method?.toUpperCase()} ${e?.response?.status} ${e?.config?.url}`, {
          data: e?.response?.data,
          headers: e?.response?.headers,
          request: e?.request?.request,
        });

        if (e?.response?.status === 401) {
          // push to secure route to re-initiate login
          history.push(routes.homeTownBuffet);
        }

        const formsUrlRegex = /.*forms\.skyslope\.com.*/g;
        if (
          this.serverOptions.isGlobalUnauthorizedEnabled &&
          e?.response?.status === 403 &&
          formsUrlRegex.test(e?.response?.url)
        ) {
          history.push(getPath(routes.unauthorized));
        } else {
          throw e;
        }
      }
    );
  }
}
