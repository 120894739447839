import type { UserManager } from '@skyslope/auth-js';

import { userManager } from '../../auth';
import { NHD_URL } from '../../constants/environments';

import Server from './Server';

interface Provider {
  id: string;
  name: string;
  logoUrl: string;
  options: Option[];
}

interface Option {
  id: number;
  name: string;
  price: {
    min: number;
    max: number;
  };
}

interface ProvidersResponse {
  providers: Provider[];
  page: number;
  limit: number;
  has_more: boolean;
}

class NHDServerImpl extends Server {
  public constructor(manager: UserManager) {
    super(manager, undefined, { includeBaseHeaders: false, throwErrorOnInvalidToken: true });
  }

  public async GetProviders() {
    return (await this.api.get<ProvidersResponse>(`${NHD_URL}/api/providers`)).data.providers;
  }
}

export const NHDServer = new NHDServerImpl(userManager);
