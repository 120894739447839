import type React from 'react';
import { usePrimeAuthSession } from '@skyslope/auth-react';

import useDataDogLogs from 'components/SecurityDependentEffects/hooks/useDataDogLogs';
import useSendToRentSpree from 'components/SecurityDependentEffects/hooks/useSendToRentSpree';
import useHeap from 'hooks/useHeap';

import { useSetupClarity } from '../../utils/clarity';

import useCarExitUrl from './hooks/useCarExitUrl';
import useNavigateToOnboardingStep from './hooks/useNavigateToOnboardingStep';
import useNavigateToSubscriptionPage from './hooks/useNavigateToSubscriptionPage';
import usePopUnder from './hooks/usePopUnder';
import useProcessAccessDelay from './hooks/useProcessAccessDelay';
import useRefreshAuthCache from './hooks/useRefreshAuthCache';
import useSetupChameleon from './hooks/useSetupChameleon/useSetupChameleon';
import useSetupLaunchDarkly from './hooks/useSetupLaunchDarkly';
import useTrackDevice from './hooks/useTrackDevice';
import useTrrebMemberOnboarding from './hooks/useTrrebMemberOnboarding';
import useWidgetControls from './hooks/useWidgetControls';

const SecurityDependentEffects: React.FC = () => {
  useSetupLaunchDarkly();
  usePrimeAuthSession();
  useTrackDevice();
  useDataDogLogs();
  useTrrebMemberOnboarding();
  useNavigateToOnboardingStep();
  useNavigateToSubscriptionPage();
  useSendToRentSpree();
  useProcessAccessDelay();
  useRefreshAuthCache();
  useWidgetControls();
  useSetupClarity();

  if (!window.location.host.includes('localhost')) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useHeap();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useSetupChameleon();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    usePopUnder();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useCarExitUrl();
  }

  return null;
};

export default SecurityDependentEffects;
