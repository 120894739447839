import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '@skyslope/auth-react';

import { getRoute } from 'appRoutes';
import useGetUserSettingsQuery from 'hooks/domain/user/queries/useGetUserSettingsQuery';

const useIsLoginProcessComplete = (): boolean => {
  const {
    authState: { isAuthenticated },
  } = useAuth();
  const { pathname } = useLocation();

  // Workaround to make sure user login process is complete
  const isUserLoaded = useGetUserSettingsQuery().data != null;

  // Might not need this, but just in case, doesn't hurt
  const isOnAuthRoute = useMemo(() => {
    const currentRoute = getRoute(pathname);
    const currentPath = currentRoute?.routerProps.path;
    return currentPath?.includes('/auth');
  }, [pathname]);

  return !!isAuthenticated && isUserLoaded && !isOnAuthRoute;
};

export default useIsLoginProcessComplete;
