import update from 'immutability-helper';

import type { AppAction, SignedDocument } from 'store/types';

import type { DownloadSignedDocumentResult } from '../../../../hooks/domain/document/mutations/useDownloadSignedDocuments';

import { SelectSignedDocumentActions } from './actions';

export interface SignedDocumentsDrawerState {
  selectedDisplaySignedDocuments: DownloadSignedDocumentResult;
  selectedSignedDocuments: SignedDocument[];
}

export const defaultState: SignedDocumentsDrawerState = {
  selectedDisplaySignedDocuments: { url: undefined, blob: undefined },
  selectedSignedDocuments: [],
};

const signedDocumentsDrawerReducer = (
  state: SignedDocumentsDrawerState = defaultState,
  action: AppAction
): SignedDocumentsDrawerState => {
  switch (action.type) {
    case SelectSignedDocumentActions.SET_SELECTED_DISPLAY_SIGNED_DOCUMENTS: {
      return update(state, {
        selectedDisplaySignedDocuments: { $set: action.payload },
      });
    }
    case SelectSignedDocumentActions.ADD_SIGNED_DOCUMENT_TO_SELECTION: {
      const i = state.selectedSignedDocuments.findIndex(
        (document) =>
          document.uploadedDocumentId === action.payload.uploadedDocumentId &&
          document.envelopeDocumentId === action.payload.envelopeDocumentId
      );
      if (i !== -1) {
        return state;
      }

      return update(state, {
        selectedSignedDocuments: {
          $push: [action.payload],
        },
      });
    }
    case SelectSignedDocumentActions.ADD_ALL_SIGNED_DOCUMENTS_TO_SELECTION: {
      return update(state, {
        selectedSignedDocuments: {
          $set: action.payload,
        },
      });
    }
    case SelectSignedDocumentActions.REMOVE_ALL_SIGNED_DOCUMENTS_FROM_SELECTION: {
      return update(state, {
        selectedSignedDocuments: {
          $set: [],
        },
      });
    }
    case SelectSignedDocumentActions.REMOVE_SIGNED_DOCUMENT_FROM_SELECTION: {
      const i = state.selectedSignedDocuments.findIndex(
        (document) =>
          document.uploadedDocumentId === action.payload.uploadedDocumentId &&
          document.envelopeDocumentId === action.payload.envelopeDocumentId
      );
      if (i === -1) {
        return state;
      }

      return update(state, {
        selectedSignedDocuments: {
          $splice: [[i, 1]],
        },
      });
    }
    default: {
      return state;
    }
  }
};

export default signedDocumentsDrawerReducer;
