import type { UserManager } from '@skyslope/auth-js';

import { userManager } from 'auth/userManager';
import { USER_API_URL } from 'constants/environments';
import type {
  CancelAccessRequest,
  RevokeAccessRequest,
  UserPermissionsRequests,
  UserProfilesForDelegation,
} from 'resources/types/DelegateAccess';

import Server from './Server';

export const DELEGATE_ACCESS = 'delegate-access';
export const REVOKE_ACCESS = 'revoke-access';
export const CANCEL_ACCESS = 'cancel-access';
export const GET_USER_PROFILES = 'user-profiles';
export const FULL_ACCESS_DELEGATE_REQUESTS = 'delegation-requests';
export const ASSIGN_ONE_TO_ONE = 'assign-one-to-one';
export const PROCESS_DELAYED_ACCESS = 'process-delegated-access';
class DelegationServer extends Server {
  public constructor(manager: UserManager) {
    super(manager);
  }

  public GetUserDelegationRequests = async (): Promise<UserPermissionsRequests> => {
    const url = `${USER_API_URL}/${FULL_ACCESS_DELEGATE_REQUESTS}/${DELEGATE_ACCESS}`;
    const res = await this.api.get<{ result: UserPermissionsRequests }>(url);
    return res.data.result;
  };

  public GetUserProfilesForDelegation = async (): Promise<UserProfilesForDelegation> => {
    const url = `${USER_API_URL}/${FULL_ACCESS_DELEGATE_REQUESTS}/${GET_USER_PROFILES}`;
    const res = await this.api.get<{ result: UserProfilesForDelegation }>(url);
    return res.data.result;
  };

  public AssignOneToOneAccess = async (request: string[]): Promise<string[]> => {
    const url = `${USER_API_URL}/${FULL_ACCESS_DELEGATE_REQUESTS}/${ASSIGN_ONE_TO_ONE}`;
    const res = await this.api.post(url, {
      RecipientEmails: request,
    });
    return res.data.errors;
  };

  public ProcessAccessDelay = async (email: string): Promise<string[]> => {
    const url = `${USER_API_URL}/${FULL_ACCESS_DELEGATE_REQUESTS}/${PROCESS_DELAYED_ACCESS}`;
    const res = await this.api.post(url, {
      Email: email,
    });
    return res.data.errors;
  };

  public RevokeAccess = async (request: RevokeAccessRequest): Promise<string> => {
    const url = `${USER_API_URL}/${FULL_ACCESS_DELEGATE_REQUESTS}/${REVOKE_ACCESS}`;
    const res = await this.api.patch<string>(url, request);
    return res.data;
  };

  public CancelAccess = async (request: CancelAccessRequest): Promise<string> => {
    const url = `${USER_API_URL}/${FULL_ACCESS_DELEGATE_REQUESTS}/${CANCEL_ACCESS}`;
    const res = await this.api.patch<string>(url, request);
    return res.data;
  };
}

export default new DelegationServer(userManager);
