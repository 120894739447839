import type React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import type { CloseReason, SnackbarKey } from 'notistack';
import { useSnackbar } from 'notistack';
import type { RootState } from 'store';

import { removeAppNotification } from './actions';
import type { AppNotification } from './store';
import { appNotificationsSelector } from './store';

export interface NotifierProps {
  notifications: AppNotification[];
  onRemove: (key: SnackbarKey) => void;
}

/*
    Wrapper around notistack's useSnackbar to make it so that we can open snackbar notifications from redux and sagas.
    usage:
        - open notification: dispatch(addAppNotification(appNotification))
        - close notification: dispatch(closeAppNotification(notificationKey))
 */
export const BaseNotifier: React.FC<NotifierProps> = ({ notifications, onRemove }) => {
  const [displayedNotifications, setDisplayedNotifications] = useState<{
    [key: string]: boolean;
  }>({});

  const addToDisplayed = useCallback(
    (notification: AppNotification): void => {
      setDisplayedNotifications((d) => ({
        ...d,
        [notification.key]: true,
      }));
    },
    [setDisplayedNotifications]
  );

  const removeFromDisplayed = useCallback(
    (notification: AppNotification): void => {
      setDisplayedNotifications((d) => ({
        ...d,
        [notification.key]: false,
      }));
    },
    [setDisplayedNotifications]
  );

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    for (const notification of notifications) {
      if (notification.isDismissed) {
        closeSnackbar(notification.key);
      }

      if (displayedNotifications[notification.key]) {
        return;
      }

      enqueueSnackbar(notification.message, {
        key: notification.key,
        ...notification.options,
        onClose: (
          // eslint-disable-next-line  @typescript-eslint/no-explicit-any
          event: React.SyntheticEvent<any> | null,
          reason: CloseReason,
          key: SnackbarKey | undefined
        ) => {
          if (notification.options?.onClose) {
            notification.options.onClose(event, reason, key);
          }
        },
        onExited: (node: HTMLElement, key: SnackbarKey) => {
          onRemove(key);
          removeFromDisplayed(notification);
        },
      });

      addToDisplayed(notification);
    }
  }, [
    notifications,
    displayedNotifications,
    enqueueSnackbar,
    onRemove,
    removeFromDisplayed,
    addToDisplayed,
    closeSnackbar,
  ]);

  return null;
};

export default connect(
  (state: RootState) => ({
    notifications: appNotificationsSelector(state),
  }),
  {
    onRemove: removeAppNotification,
  }
)(BaseNotifier);
