import { useMemo } from 'react';

import useDerivedUserData from 'hooks/domain/user/useDerivedUserData';
import type { UserRegion } from 'store/types';

const useDoesUserHaveRegion = (regions: string[]): boolean => {
  const { userSettings: userProfile, optInLibraries } = useDerivedUserData();

  return useMemo(
    () =>
      userProfile?.regions.findIndex((userRegion: UserRegion) => {
        if (!Array.isArray(regions)) return false;

        const userHasRegion = regions.includes(userRegion.region);
        const optInLibrariesHaveRegion =
          optInLibraries.filter(
            (library) =>
              regions.filter((region: string) => {
                // region codes look like this: US-AZ, we want just AZ to compare to (regions are unique)
                const parsedRegionCodes = library.regionCodes?.map((rc) => rc.split('-')[1]);
                return parsedRegionCodes?.includes(region);
              }).length > 0
          ).length > 0;
        return userHasRegion || optInLibrariesHaveRegion;
      }) !== -1,
    [optInLibraries, regions, userProfile]
  );
};

export default useDoesUserHaveRegion;
