import React, { useMemo } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { SecureRoute } from '@skyslope/auth-react';
import { VisibleProvider } from '@skyslope/mache';
import type { AppRedirect, AppRoute } from 'appRoutes';
// eslint-disable-next-line no-restricted-imports
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import queryString from 'query-string';

import { appRedirects, appRoutes } from 'appRoutes';
import Background from 'components/Background';
import IdleActivityMonitor from 'components/IdleActivityMonitor/IdleActivityMonitor';
import SmartAppBanner from 'components/SmartAppBanner';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import useLocalStorage from 'hooks/useLocalStorage';
import ErrorBoundary from 'pages/ErrorBoundary/ErrorBoundary';
import history from 'utils/history';

import CustomerSupportReportingTool from './components/CustomerSupportReportingTool/CustomerSupportReportingTool';
import LayoutProvider from './components/Layout/Provider';
import ReactQueryDevtools from './components/ReactQueryDevtools';
import SecurityDependentEffects from './components/SecurityDependentEffects/SecurityDependentEffects';
import { LAUNCHDARKLY_CLIENT_ID } from './constants/environments';
import LocalStorageItem from './constants/LocalStorageItem';
import { AppSplashDialogs } from './containers/AppSplashDialogs/AppSplashDialogs';
import Notifier from './containers/Notifier';
import GlobalCss from './GlobalCss';
import MobileAppFunctions from './MobileAppFunctions';

const App: React.FC = () => {
  const flags = useFeatureFlags();

  const parsed = queryString.parse(location.search);
  const referringClient = parsed.referringClient as string;
  const ls = useLocalStorage();

  ls.addItem(LocalStorageItem.HttpReferrer, document.referrer);
  if (referringClient) ls.addItem(LocalStorageItem.ReferringClient, referringClient);

  const mappedRoutes = useMemo(
    () => (
      <>
        {appRoutes
          .filter((route) => !route.featureFlag || flags[route.featureFlag.name] === route.featureFlag.variation)
          .map((appRoute: AppRoute) => (
            <React.Fragment key={appRoute.key}>
              <Route {...appRoute.routerProps} component={() => <Background />} />
              {appRoute.isNaked ? <Route {...appRoute.routerProps} /> : <SecureRoute {...appRoute.routerProps} />}
            </React.Fragment>
          ))}
        {appRedirects.map((appRedirect: AppRedirect) => (
          <Switch key={appRedirect.key}>
            <Redirect {...appRedirect.redirectProps} />
          </Switch>
        ))}
      </>
    ),
    [flags]
  );

  return (
    <>
      <SmartAppBanner />
      <ErrorBoundary>
        <Router history={history}>
          <CssBaseline />
          <GlobalCss />
          <Notifier />

          <AppSplashDialogs />

          <SecurityDependentEffects />
          <VisibleProvider>
            <LayoutProvider>{mappedRoutes}</LayoutProvider>
          </VisibleProvider>
          <ReactQueryDevtools />
          <CustomerSupportReportingTool />
          <MobileAppFunctions />
        </Router>
        <IdleActivityMonitor />
      </ErrorBoundary>
    </>
  );
};

export default withLDProvider({
  clientSideID: LAUNCHDARKLY_CLIENT_ID,
})(App);
