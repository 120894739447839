import { useEffect, useState } from 'react';
import { useAuth } from '@skyslope/auth-react';

import { HEAP_ID } from 'constants/environments';
import { isAppRunningInIframe } from 'utils/isAppRunningInIframe';

import { useWidgetTracking } from './useWidgetTracking';

const load: (HEAP_ID: string) => Promise<boolean> = (HEAP_ID: string) =>
  new Promise<boolean>((resolve) => {
    window.heap = window.heap || [];
    window.heap.appid = HEAP_ID;
    window.heap.config = {
      secureCookie: true,
    };
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://cdn.heapanalytics.com/js/heap-${HEAP_ID}.js`;
    const pageScripts = document.getElementsByTagName('script')[0];
    if (pageScripts && pageScripts.parentNode) {
      pageScripts.parentNode.insertBefore(script, pageScripts);
    }
    script.onload = () => {
      resolve(true);
    };
  });

const useHeap = (): void => {
  const { userContext } = useAuth();
  const [loaded, setLoaded] = useState(false);
  const [identified, setIdentified] = useState(false);
  useWidgetTracking(identified);

  useEffect(() => {
    load(HEAP_ID).then((isLoaded) => setLoaded(isLoaded));
  }, []);

  useEffect(() => {
    if (loaded) {
      window.heap.identify(userContext?.id ?? '');

      window.heap.addUserProperties({
        email: userContext?.email,
        SubscriberId: userContext?.prime?.subscriberId,
        isWidgetUser: isAppRunningInIframe(),
      });
      setIdentified(true);
    }
  }, [loaded, userContext]);
};

export default useHeap;
