enum LocalStorageItem {
  ReduxDebugging = 'reduxDebugging',
  CarLastPopUnder = 'CAR-last-popunder',
  DeviceLastAuthenticated = 'device.lastAuthenticated',
  OreaLastSession = `OREA-last-session`,
  UseLoginLandingPage = 'use-login-landing-page',
  HttpReferrer = 'http-referrer',
  ReferringClient = 'referring-client',
  TrrebRedirectUrl = 'trreb-redirect-url',
  OnboardingRedirectUrl = 'onboarding-redirect-url',
  HasRefreshedAuthCache = 'hasRefreshedAuthCache',
  FilesDashViewMode = 'files-dash-view-mode',
  UtilityConnectModalLastDismissed = 'utility-connect-modal-last-dismissed',
}

export default LocalStorageItem;
