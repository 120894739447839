import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import type { IconButtonProps } from '@mui/material/IconButton';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';

export interface DialogCloseButtonProps extends IconButtonProps {
  'aria-label'?: string;
  absolutePosition?: boolean;
}

const DialogCloseButton: React.FC<DialogCloseButtonProps> = ({ sx, absolutePosition = true, ...otherProps }) => {
  const theme = useTheme();

  return (
    <IconButton
      id="close-icon"
      aria-label="close the dialog"
      {...otherProps}
      sx={[
        {
          color: theme.palette.grey[500],
          padding: 0,
          ...(absolutePosition
            ? {
                position: 'absolute',
                right: theme.spacing(1.5),
                top: theme.spacing(1.5),
              }
            : undefined),
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      size="large"
    >
      <CloseIcon aria-hidden="true" />
    </IconButton>
  );
};

export default DialogCloseButton;
