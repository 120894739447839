import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useLayout } from 'components/Layout/Provider';
import DefaultAppBar from 'pages/ErrorBoundary/components/DefaultAppBar';

const UnauthorizedPageContainer: React.FC = () => {
  const theme = useTheme();
  useLayout({ header: { title: '', hidden: true } });
  return (
    <>
      <DefaultAppBar />
      <Container>
        <Box
          mt={10}
          mb={10}
          textAlign="center"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h1" sx={{ fontSize: '125px' }}>
            Oops!
          </Typography>
          <Typography variant="subtitle1" sx={{ paddingTop: theme.spacing(4) }}>
            403 - Unauthorized Access
          </Typography>
          <Typography variant="body1" sx={{ maxWidth: '500px' }}>
            Oops! Your session may have expired or you may have tried to access a resource that you do not have access
            to. Please try again or contact support for assistance.
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default UnauthorizedPageContainer;
