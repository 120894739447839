import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useAuth } from '@skyslope/auth-react';

import PaperAirplane from 'assets/images/PaperAirplane.svg';
import isTrrebIdp from 'auth/hooks/helpers/isTrrebIdp';
import useGetUserIDP from 'auth/hooks/useGetUserIDP';
import { useLayout } from 'components/Layout/Provider';
import LocalStorageItem from 'constants/LocalStorageItem';
import { routes } from 'constants/routes';
import useLocalStorage from 'hooks/useLocalStorage';
import DefaultAppBar from 'pages/ErrorBoundary/components/DefaultAppBar';

const Logout: React.FC = () => {
  useLayout({ header: { title: '', hidden: true } });
  const userIdp = useGetUserIDP();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const { userManager, authState } = useAuth();
  const theme = useTheme();
  const { getItem, removeItem } = useLocalStorage();
  const isTrreb = isTrrebIdp(userIdp);

  useEffect(() => {
    if (isLoggingOut) {
      return;
    }
    if (authState.isAuthenticated && !isLoggingOut) {
      setIsLoggingOut(true);
      userManager?.startLogout();
      return;
    }
    removeItem(LocalStorageItem.HasRefreshedAuthCache);
    userManager?.endLogout();
    setTimeout(() => {
      if (isTrreb) {
        //Trreb users may need to login a second time.  This will redirect them to their intended page
        const redirectUrl = getItem(LocalStorageItem.TrrebRedirectUrl) || routes.homeTownBuffet;
        window.location.assign(redirectUrl);
        setIsLoggingOut(false);
        return;
      }
      window.location.assign(routes.homeTownBuffet);
      setIsLoggingOut(false);
    }, 2000);
    localStorage.removeItem('disableOffersBanner');

    // eslint-disable-next-line
  }, [authState, getItem]);

  return (
    <>
      <DefaultAppBar />
      <Container>
        <Box
          mt={10}
          mb={10}
          textAlign="center"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <img alt="SkySlope" src={PaperAirplane} aria-hidden />
          <Typography variant="h4" sx={{ paddingTop: theme.spacing(5) }}>
            Logging out...
          </Typography>
          <Typography variant="subtitle2" sx={{ paddingTop: theme.spacing(2) }}>
            Thank you for choosing SkySlope Forms.
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default Logout;
