import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const useIsSmall = (): boolean => {
  const theme = useTheme();

  return useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });
};

export default useIsSmall;
