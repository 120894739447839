import type { MutableRefObject, RefObject } from 'react';
import { useEffect, useRef } from 'react';
import { useAuth } from '@skyslope/auth-react';
import initSignInWidget from '@skyslope/auth-sign-in-widget';

export interface UseOktaSignInWidgetOptions {
  startOnRegistrationView?: boolean;
  useOptionalLinks?: boolean;
  showSignInWithGoogleBtn?: boolean;
}

const useOktaSignInWidget = ({
  startOnRegistrationView,
  useOptionalLinks = true,
  showSignInWithGoogleBtn = false,
}: UseOktaSignInWidgetOptions): MutableRefObject<HTMLDivElement> | RefObject<HTMLDivElement> => {
  const widgetAnchorRef = useRef<HTMLDivElement>(null);
  const {
    authState: { isAuthenticated },
    userManager,
  } = useAuth();

  useEffect(() => {
    if (widgetAnchorRef.current && !isAuthenticated) {
      const widget = initSignInWidget({
        anchorEl: widgetAnchorRef.current,
        viewOptions: {
          applicationNameOverride: 'SkySlope Forms',
          logoChoice: 'Forms',
          useOptionalLinks: true,
          showSignInWithGoogleBtn,
          showSignInWithSkySlopeBtn: false,
          startOnRegistrationView,
        },
        authConfigOptions: {
          userManager: userManager,
          idpDiscovery: {
            requestContext: window.auth.okta.idpDiscovery.requestContext,
          },
          redirectAfterPasswordResetUrl: window.location.origin,
        },
      });

      return () => widget.remove();
    }
  }, [userManager, isAuthenticated, startOnRegistrationView, useOptionalLinks, showSignInWithGoogleBtn]);

  return widgetAnchorRef;
};

export default useOktaSignInWidget;
