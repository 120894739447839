import { useQuery } from '@tanstack/react-query';

import { NHDServer } from '../../../resources/server/NHDServer';
import { useIsCaUser } from '../user/useIsCaUser';

export const nhdProvidersKey = ['nhd-providers'];
export const useNHDProviders = () => {
  const isCAUser = useIsCaUser();
  return useQuery({
    queryKey: nhdProvidersKey,
    queryFn: NHDServer.GetProviders.bind(NHDServer),
    enabled: isCAUser ?? false,
  });
};

type NHDOption = { displayName: string; value: string };

export function useNHDProvidersOptions(): { data: NHDOption[] | undefined; isLoading: boolean } {
  const providers = useNHDProviders();
  const options: NHDOption[] | undefined = providers.data?.map((p) => ({ displayName: p.name, value: p.id }));
  const allOptions = options ? [...options, { displayName: "I don't have one.", value: 'None' }] : undefined;
  return { data: allOptions, isLoading: providers.isInitialLoading };
}
