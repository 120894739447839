import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import AccountUserServer from 'resources/server/AccountUserServer';
import type { Roles } from 'store/types';

export const getRoleKey = 'get-role-by-userid';

const useGetRoleByUserId = (userId?: string): UseQueryResult<Roles> =>
  useQuery({
    queryKey: [getRoleKey, userId],
    queryFn: async () => {
      if (userId !== undefined) {
        return AccountUserServer.GetRolebyUserId(userId);
      } else {
        return {} as Roles;
      }
    },
    enabled: !!userId,
    staleTime: 1000 * 60 * 10,
  });

export default useGetRoleByUserId;
