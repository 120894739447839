import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import useUpdateAuthProfileCacheMutation from 'hooks/domain/user/mutations/useUpdateAuthProfileCacheMutation';
import { userMetaKey } from 'hooks/domain/user/queries/useGetUserSettingsMetaQuery';
import useGetUserSettingsQuery, { userSettingsKey } from 'hooks/domain/user/queries/useGetUserSettingsQuery';

const useRefreshAuthCache = (): void => {
  const [isProcessed, setIsProcessed] = useState(false);
  const { mutate: updateAuthProfileCache } = useUpdateAuthProfileCacheMutation();
  const queryClient = useQueryClient();
  const { data: userSettings, isInitialLoading: isLoadingUserSettings } = useGetUserSettingsQuery();

  useEffect(() => {
    if (!isProcessed && !isLoadingUserSettings && userSettings) {
      updateAuthProfileCache(
        { userId: userSettings.id },
        {
          onSettled: () => {
            // whether success or failure
            // mark processed, we do not want to DDoS ourselves accidentally
            setIsProcessed(true);
          },
          onSuccess: () => {
            queryClient.invalidateQueries([userSettingsKey]);
            // auth profiles drive a lot of logic in the app and we should not use cached versions after updates
            // so removeQueries instead of invalidate
            queryClient.removeQueries([userMetaKey]);
          },
        }
      );
    }
  }, [userSettings, isLoadingUserSettings, updateAuthProfileCache, queryClient, isProcessed]);
};

export default useRefreshAuthCache;
