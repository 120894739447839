import { useFeatureFlags } from '../../useFeatureFlags';

import { useAgentUCEnablement } from './queries/useAgentUCEnablement';
import { useSubscriberUCEnablement } from './queries/useSubscriberUCEnablement';

export const useUtilityConnectEnablement = () => {
  const { isUtilityConnectEnabled } = useFeatureFlags();
  const agentEnablement = useAgentUCEnablement();
  const brokerEnablement = useSubscriberUCEnablement();

  agentEnablement.isFetched;

  return {
    isLoading: agentEnablement.isInitialLoading || brokerEnablement.isInitialLoading,
    isEligible: isUtilityConnectEnabled && brokerEnablement.data?.subscribed,
    isEnabled: agentEnablement.data?.subscribed,
  };
};
