import { brokerAdminRoleId } from 'constants/BrokerageManagementRoles';
import useGetUserSettingsQuery from 'hooks/domain/user/queries/useGetUserSettingsQuery';

import useGetRoleByUserId from './useGetRoleByUserId';

const useIsBrokerageManagementRole = (): boolean => {
  const { data: userSettings } = useGetUserSettingsQuery();
  const userId = userSettings?.id;
  const roles = useGetRoleByUserId(userId);
  const roleList = roles.data?.access;

  if (roleList?.length) {
    const isBrokerageManagementRole = roleList.some((roleList) => roleList.roleId === brokerAdminRoleId);
    return isBrokerageManagementRole;
  } else {
    return false;
  }
};

export default useIsBrokerageManagementRole;
